import React from 'react';
import './settings-container.scss';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';
import { ReactComponent as SettingsIcon } from '../../../../assets/images/title/settings.svg';
import TitleWithIcon from '../../../../components/title/title-icon';
import PostSubmissionSettings from '../../../../components/survey-edit/settings-tab/post-submission-settings';
import DisplayConfigurator from '../../../../components/survey-edit/settings-tab/display-configurator';

// SettingsContainer component
function SettingsContainer() {
    return (
        <Box className='settings-container'>
            <TitleWithIcon title='Settings' Icon={SettingsIcon} />
            <DisplayConfigurator />
            <Divider sx={{ mt: 4, mb: 4 }} />
            <PostSubmissionSettings />
        </Box>
    )
}

export default SettingsContainer;
