import React from 'react';
import './survey-menu-action-view.scss';
import { Box, IconButton } from '@mui/material';
import TooltipText from '../tooltip-text/tooltip-text';
import { ReactComponent as GridIcon } from '../../assets/images/survey-dashboard/grid-icon.svg';
import { ReactComponent as ListIcon } from '../../assets/images/survey-dashboard/list-icon.svg';
import { colorTheme } from '../../assets/theme';

// Constants
const VIEW_LIST = 'VIEW_LIST';
const VIEW_TILE = 'VIEW_TILE';

// SurveyView component
const SurveyMenuActionView = ({ view, handleActiveView }) => {
    return(
        <Box className="view">
            <TooltipText name="Show as tiles" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.colors.white} margin={'5px 0px 0px 0px'}>
            <IconButton className={`view-button${view === VIEW_TILE ? ' active' : ''}`} onClick={() => handleActiveView(VIEW_TILE)}>
                <GridIcon/>
            </IconButton>
            </TooltipText>
            <TooltipText name="Show as list" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.colors.white} margin={'5px 0px 0px 0px'}>
                <IconButton className={`view-button${view === VIEW_LIST ? ' active' : ''}`} onClick={() => handleActiveView(VIEW_LIST)}>
                    <ListIcon/>
                </IconButton>
            </TooltipText>
        </Box>
    )
};

export default SurveyMenuActionView;