import React from 'react';
import { TextField, Box, Typography } from "@mui/material";
import CustomTextField from '../../../../components/form/questions-text-field';
import { SECTION } from "../../../../assets/constants";
import { useQuestionTitle } from './../../../../hooks/editor/use-question-title';
import './question-container.scss';
import { colorTheme } from '../../../../assets/theme';

function QuestionTitle(props) {
    const {
        questionTitle,
        questionSubtitle,
        handleTitleChange,
        ACTION_TITLE_EDIT,
        ACTION_SUBTITLE_EDIT
    } = useQuestionTitle(props);

    return (
        <div className="title">
            <Box display="flex" alignItems="center" width={'90%'}>
                <Typography variant="h6" sx={{ marginRight: '6px', color: '#282828', paddingTop: '15px', fontWeight: '600' }}>
                    {props.index + 1}.
                </Typography>
                <Box width="100%">
                    <TextField
                        className='question-title'
                        multiline={true}
                        label={props.question.element_type === SECTION ? 'Enter section header' : 'Enter Your question here...'}
                        value={questionTitle ? questionTitle : ''}
                        onChange={(e) => handleTitleChange(e.target.value, ACTION_TITLE_EDIT)}
                        variant="standard"
                        fullWidth
                        sx={{
                            '& .MuiFormLabel-root': {
                                fontStyle: 'normal !important', // Remove italic style
                                color: colorTheme.theme.text.darkGrey, // Set label color to black
                                top: '-4px', // Set label top position
                            },
                            '& .MuiInputBase-input': {
                                color: '#282828', // Set input text color to black
                                fontSize: '20px', // Set input font size to 24px
                                fontWeight: '600', // Set input font weight to bold
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottom: '1px solid black', // Bottom line color on hover
                            },
                            '& .MuiInput-underline:after': {
                                borderBottom: '1px solid #1B4972', // Bottom line color when focused
                            },
                        }}
                    />
                </Box>
            </Box>
            {props.question.element_type !== SECTION && (
                <Box mt={2.5} width={'100%'} mb={3}>
                    <CustomTextField
                        questionSubtitle={questionSubtitle}
                        handleTitleChange={handleTitleChange}
                        ACTION_SUBTITLE_EDIT={ACTION_SUBTITLE_EDIT}
                        placeholder='Question Description (optional)'
                        value={questionSubtitle ? questionSubtitle : ''}
                        onChange={(e) => handleTitleChange(e.target.value, ACTION_SUBTITLE_EDIT)}
                    />
                </Box>
            )}
        </div>
    );
}

export default QuestionTitle;
