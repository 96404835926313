import React from 'react';
import { Typography, Alert } from "@mui/material";
import InputField from "../form/input-field";
import PrimaryButton from "../buttons/primary-button";

const SignInFormComponent = ({ form, handleChange, generalAuthError, loading, signIn, colorTheme, INPUT_EMAIL, INPUT_PASSWORD, handleRegisterRedirect, verificationTriggered }) => (
    <div className="sign-in-container form-container">
        <Typography variant="body1" className="sign-in-page-description">Don’t have an account? <span onClick={handleRegisterRedirect}>Create account</span></Typography>
        {verificationTriggered && <Typography variant="body1" className="sign-in-page-description">Verification successfull, you can sign in now</Typography>}
        <div className="form-inner">
            <form className='form-inputs' noValidate autoComplete="off">
                <InputField type="email" label="User Email" value={form.email} onChange={(e) => handleChange(e.target.value, INPUT_EMAIL)} />
                <InputField type="password" label="Password" value={form.password} onChange={(e) => handleChange(e.target.value, INPUT_PASSWORD)} />
            </form>
            {
                generalAuthError !== '' && <span>
                    <Alert className='email-error' severity='error'>{generalAuthError}</Alert>
                </span>
            }
        </div>
        <div className='bottom-buttons'>

            <PrimaryButton onClick={signIn} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="LOG IN"
                isLoading={loading}
            />
        </div>
    </div>

);

export default SignInFormComponent;
