import React from 'react';
import './question-types-dialog.scss';
import questionTypes from '../../../../assets/question-types';


function QuestionTypesDialog(props) {

    const handleQuestionClick = (value, styleType) => {
        props.handleAddQuestion(value, styleType);
    };


    return (
        <div className='choose-question-type-container'>
            {
                questionTypes.map((column, index) => {
                    return (
                        <div className="col" key={`col-${index}`}>
                            {
                                column.map((type, index) => {
                                    return (
                                        <div className='type' key={index}>
                                            <div className='type-text'>{type.label}</div>
                                            {
                                                type.questions.map((question, index) => {
                                                    return (
                                                        <div className='question flex-center' key={`q-${index}`}
                                                            onClick={() => handleQuestionClick(question.value, question.style_type)}
                                                        >
                                                            {question.icon}
                                                            <span className="label">{question.label}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    );
                })
            }
        </div>
    )
}

export default QuestionTypesDialog;
