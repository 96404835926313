import React from 'react';
import { Typography, Alert } from "@mui/material";
import InputField from "../form/input-field";
import PrimaryButton from "../buttons/primary-button";
import SecondaryButton from "../buttons/secondary-button";

const ForgotPasswordComponent = ({ forgotEmail, handleForgotScreenText, forgotError, generalAuthError, handleBack, handleSendEmail, colorTheme, ArrowRight, isLoading }) => (
    <div className='forgot-password-container form-container'>
        <Typography variant="body1" className="sign-in-page-description">Enter your email to receive a verification
            code for resetting your password.</Typography>
        <div className='form-inputs'>
            <InputField type="email" label="Email" value={forgotEmail} onChange={(e) => handleForgotScreenText(e.target.value)} />
            {
                forgotError !== '' && <span>
                    <Alert className='forgot-error' severity='error'>{forgotError}</Alert>
                </span>
            }
        </div>
        <div className='bottom-buttons'>
            <SecondaryButton onClick={handleBack} color="#FFFFFF" hoverColor="#EDF0F4" text="Back to login" arrow={<ArrowRight />} />
            <PrimaryButton onClick={handleSendEmail} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="Send"
                isLoading={isLoading}
            />
            {
                generalAuthError !== '' && <span>
                    <Alert className='email-error' severity='error'>{generalAuthError}</Alert>
                </span>
            }
        </div>
    </div>
);

export default ForgotPasswordComponent;
