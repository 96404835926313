import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { updateOrganizationDefaultStyleDataAction } from '../../redux/actions';
import { useSnackbar } from 'notistack';
import { getS3Credentials, uploadFile } from "../../redux/actions";
import { lastActivity } from '../../utils/last-activity';
import _ from 'lodash';

// This hook is used in the Default Style tab
const useDefaultStyle = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar(); // Use enqueueSnackbar from notistack
    const [openedSaveDialog, setOpenedSaveDialog] = useState(false); // Set the opened save dialog to false
    const organizationDefaultStyleData = useSelector(state => state.organizationDefaultStyleData); // Get the organization default style data from the redux store
    const [localDefaultStyleData, setLocalDefaultStyleData] = useState(organizationDefaultStyleData);
    const [saveChangesButtonVisible, setSaveChangesButtonVisible] = useState(false); // Set the save changes button visibility to false
    const saveDefaultStyleMessage = useSelector(state => state.saveDefaultStyleMessage); // Get the save default style message from the redux store
    const [file, setFile] = useState(null); // useState for the file
    const [defaultStyleLogo, setDefaultStyleLogo] = useState(null); // useState for the default style logo file
    const [fetchingCredentials, setFetchingCredentials] = useState(false); // useState for fetching credentials
    const [fileName, setFileName] = useState(null); // useState for the file name
    const [isLoading, setIsLoading] = useState(false); // useState for loading
    const uploadData = useSelector(state => state.uploadData); // Access upload data from Redux store.
    const cognitoUsers = useSelector(state => state.cognitoUsers); // Get the cognito users from the redux store
    const userData = useSelector(state => state.userData); // Get the user data from the redux store
    const inputFileRef = useRef(null); // Create a ref for the input file

    // Maximum file size for default style logo
    const MAX_FILE_SIZE = 5; // in MB

    // Breadcrumbs path segments
    const pathSegments = [
        { name: "Transform", path: "/" },
        { name: "Survey", path: "/survey" },
        { name: "Default Style", path: "/style" }
    ];

    // Function to check if changes are made
    function checkIfChangesMade() {
        if (!_.isEqual(localDefaultStyleData, organizationDefaultStyleData)) {
            setSaveChangesButtonVisible(true);
        } else {
            setSaveChangesButtonVisible(false);
        }
    }

    // UseEffect hook to check if changes are made
    useEffect(() => {
        checkIfChangesMade();
    }, [localDefaultStyleData, organizationDefaultStyleData]);

    // Function to update the default style data
    const updateDefaultStyleData = async (attributes = null) => {
        // New organization default style data with the updated attributes 
        let newDefaultStyleData = {
            ...localDefaultStyleData,
            ...attributes
        }

        // Update the organization default style data
        await dispatch(updateOrganizationDefaultStyleDataAction(newDefaultStyleData)).then(() => {
            console.log('Organization default style details data updated successfully');
            enqueueSnackbar('Organization default style details data updated successfully', { variant: 'success' });
            lastActivity(userData.email, cognitoUsers, dispatch);
        });

        setOpenedSaveDialog(false);
        setSaveChangesButtonVisible(false);
        setFile(null);
        setIsLoading(false);
        setFetchingCredentials(false);
    };

    useEffect(() => {
        if(fetchingCredentials && uploadData && defaultStyleLogo && localDefaultStyleData) {
            console.log('Upload data:', uploadData);
            dispatch(uploadFile(uploadData, defaultStyleLogo, fileName))
            .then(() => {
                let attributes = {
                    picture: uploadData.url + "/" + uploadData.fields.key
                };

                updateDefaultStyleData(attributes);
            });
        }
    }, [fetchingCredentials, uploadData, defaultStyleLogo]);

    // Handle change function for the input fields
    const handleDefaultStyleChange = (key) => (e) => {
        setLocalDefaultStyleData(prevData => ({
            ...prevData,
            colors: {
                ...prevData.colors,
                [key]: e.target.value,
                ['value']: 'customTheme'
            }
        }));
    };
    
    // Handle color preset change function
    const handleColorPresetChange = (theme) => {
        setLocalDefaultStyleData(prevData => ({
            ...prevData,
            colors: {
                ...theme
            }
        }));
    };

    // Toggle the opened save dialog
    const toggleSaveDialog = () => {
        console.log('saveDefaultStyleMessage.message', saveDefaultStyleMessage)
        if(!saveDefaultStyleMessage || openedSaveDialog){
            setOpenedSaveDialog(!openedSaveDialog);
        }else{
            handleSaveDefaultStyleDetails();
        }
    };

    // Handle save default style details function
    const handleSaveDefaultStyleDetails = () => {
        setIsLoading(true);
        
        if(organizationDefaultStyleData.picture != localDefaultStyleData.picture){

            // Create a new file object with the file name + timestamp + file extension
            const newFile = new File([file], file.name.substring(0, file.name.lastIndexOf('.')) + '-' + Date.now() + file.name.substring(file.name.lastIndexOf('.')), { type: file.type });
            
            if (newFile.type && newFile.name, newFile.type.size > MAX_FILE_SIZE * 1000000) {
                enqueueSnackbar('File size exceeds the maximum limit of 5MB', { variant: 'error' });
                return;
            }
    
            if(file) {
                setDefaultStyleLogo(newFile);
                setFileName(newFile.name);
                dispatch(getS3Credentials(newFile.name, newFile.type)).then(() => {
                    setFetchingCredentials(true);
                });
            }
        }else{
            updateDefaultStyleData();
        }
    };

    // Handle default style logo change function
    const handleDefaultStyleLogoChange = (e) => {
        const new_file = e.target.files[0];
        if(!new_file) return;
        const reader = new FileReader();
        reader.onloadend = () => {
            setLocalDefaultStyleData(prevData => ({
                ...prevData,
                picture: reader.result
            }));
        }
        reader.readAsDataURL(new_file);

        setFile(new_file);
    };

    // Handle function to set the file to null
    const handleSetFile = () => {
        setFile(null);
        inputFileRef.current.value = '';
        setLocalDefaultStyleData(prevData => ({
            ...prevData,
            picture: organizationDefaultStyleData.picture
        }));
    };

    // Return the values
    return {
        organizationDefaultStyleData,
        localDefaultStyleData,
        saveChangesButtonVisible,
        pathSegments,
        handleDefaultStyleChange,
        handleColorPresetChange,
        openedSaveDialog,
        toggleSaveDialog,
        handleSaveDefaultStyleDetails,
        handleDefaultStyleLogoChange,
        file,
        handleSetFile,
        isLoading,
        inputFileRef
    };
};

export default useDefaultStyle;