import React from 'react';
import { Fab, Typography } from '@mui/material';
import './licence-button.scss';
import { colorTheme } from '../../assets/theme';

// component to render the button, now accepting backgroundColor as a prop
const LicenceButton = ({ text, backgroundColor, textColor, fontColor, borderColor }) => {
    // Default color if no backgroundColor prop is provided
    const defaultBgColor = colorTheme.theme.colors.white;


    return (
        <Fab
            sx={{
                backgroundColor: backgroundColor || defaultBgColor,
                color: fontColor || colorTheme.theme.colors.black,
                border: borderColor || 'none',
                textTransform: 'capitalize',
                padding: '0 7px',
                height: '22px',
                boxShadow: 'none',
                borderRadius: '2px',
                ':hover': {
                    backgroundColor: backgroundColor || defaultBgColor, // Keeps the background color the same on hover
                    boxShadow: 'none' // Ensures no boxShadow is applied on hover
                },
            }}
            variant="extended"
            className="licence-button"
            size="small"
        >
            <Typography variant='body2' sx={{ color: textColor }} className='licence-button-text'>
                {text}
            </Typography>
        </Fab>
    );
};

export default LicenceButton;
