// // utils/roleUtils.js

// // This function determines the user's role based on the user data.
// export function determineUserRole(userData) {
//     if (userData.role === 'owner') {
//         return 'Owner';
//     } else if (userData.role === 'all') {
//         return 'Survey & Insights Admin';
//     } else if (userData.role === 'survey' && userData.surveyAccessLevel === 'admin') {
//         return 'Survey Admin';
//     } else if (userData.role === 'insights') {
//         return 'Insights Admin';
//     } else {
//         return 'Member'; // Default or minimal role if none of the above
//     }
// }

// // This function determines the user's license based on the user data
// export function determineUserLicense(userData) {
//     console.log(userData);
//     if (userData.role === 'owner') {
//         return ['Owner'];
//     } else if (userData.role === 'all') {
//         return ['Survey Admin', 'Insights Admin'];
//     } else if (userData.role === 'survey' && userData.surveyAccessLevel === 'admin') {
//         return ['Survey Admin'];
//     } else if (userData.role === 'insights') {
//         return ['Insights Admin'];
//     } else {
//         return 'Member'; // Default or minimal role if none of the above
//     }
// }


// utils/roleUtils.js

// This function determines the user's role based on the user data.
export function determineUserRole(userData) {
    if (userData.role === 'owner') {
        return 'Owner';
    } else if (userData.insightsLicense === 'true' && userData.surveyLicense === 'true') {
        return 'Survey & Insights Admin';
    } else if (userData.surveyLicense === 'true') {
        return 'Survey Admin';
    } else if (userData.insightsLicense === 'true') {
        return 'Insights Admin';
    } else {
        return 'Member'; // Default or minimal role if none of the above
    }
}

// This function determines the user's license based on the user data
export function determineUserLicense(userData) {
    if (userData.role === 'owner') {
        return ['Owner'];
    } else if (userData.insightsLicense === 'true' && userData.surveyLicense === 'true') {
        return ['Survey Admin', 'Insights Admin'];
    } else if (userData.surveyLicense === 'true') {
        return ['Survey Admin'];
    } else if (userData.insightsLicense === 'true') {
        return ['Insights Admin'];
    } else {
        return 'Member'; // Default or minimal role if none of the above
    }
}