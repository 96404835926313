import _, { last, template } from 'lodash';

import {
    SURVEY_DEFAULT_WELCOME_NOTE,
    SURVEY_DEFAULT_WELCOME_NOTE_TITLE
} from '../../assets/constants';

import {
    SET_ERROR,
    USER_TEMPLATE_FETCHED,
    CREATE_BLANK_SURVEY,
    ADD_QUESTION,
    REMOVE_QUESTION,
    UPDATE_QUESTION,
    REORDER_QUESTION,
    SET_QUESTION_ERRORS,
    USER_TOKEN_SET,
    SET_RESPONDENTS_LIST,
    SET_CUSTOM_LIST,
    DELETE_RESPONDENTS_LIST,
    SET_RESPONDENT_ERRORS,
    DELETE_CUSTOM_LIST,
    GET_QUESTIONS,
    CUSTOMER_REGISTERED,
    REGISTRATION_ERROR_COMPANY_EXISTS,
    REGISTRATION_ERROR_DOMAIN_EXISTS,
    REGISTRATION_ERROR_OTHER,
    REGISTRATION_ERROR_COMPANY_RESET,
    SET_CURRENT_SURVEY,
    CUSTOM_LISTS_FETCHED,
    UPDATE_SURVEY_STYLE_COLORS,
    UPDATE_SURVEY_STYLE_LOGO,
    S3_CREDENTIALS_FETCHED,
    FILE_UPLOADED,
    SURVEYS_FETCHED,
    UPDATE_CURRENT_SURVEY,
    UPDATE_SURVEY_NAME,
    LAST_EDITED_TIME,
    SET_EDIT_PAGE_TAB,
    PUBLISH_CURRENT_SURVEY,
    PUBLISH_CURRENT_SURVEY_ERROR,
    DELETE_RESPONDENTS,
    SCHEDULE_CURRENT_SURVEY,
    SCHEDULE_CURRENT_SURVEY_ERROR,
    RESET_ERROR,
    CLOSE_CURRENT_SURVEY,
    UPDATE_RESPONDENTS_LIST,
    SET_QUESTION_ID,
    UPDATE_GENERAL_SETTINGS,
    DOWNLOAD_SURVEY_DATA,
    SEND_REMINDERS,
    SET_USERS_ON_SURVEY,
    STORE_USER_DATA,
    LIST_COGNITO_USERS,
    ADD_COGNITO_USER,
    DELETE_COGNITO_USER,
    UPDATE_COGNITO_USER,
    EMAIL_RESEND,
    EMAIL_RESEND_ERROR,
    ASSIGN_TO_SURVEY,
    USER_EXIST_ON_WORKSPACE,
    SET_ACTIVE_EDIT_SURVEY_TAB,
    SET_SIDEBAR_ACTIVE_PAGE,
    STORE_ORGANIZATION_DATA,
    STORE_ORGANIZATION_DEFAULT_STYLE_DATA,
    SET_SAVE_DEFAULT_STYLE_MESSAGE,
    DELETE_FILE_FROM_S3,
} from "../../assets/action-constants";
import {
    getUserDetailsDb,
    postSurveyQuestion,
    getSurveyQuestion,
    registerCustomerDb,
    saveList,
    getLists,
    getRespondentsDb,
    updateRespondents,
    addColumnToRespondentsLists,
    deleteRespondentsDb,
    deleteList,
    getS3CredentialsDb,
    uploadFileToS3,
    deleteSurveyQuestion,
    deleteSurveyDb,
    startSurveyDb,
    getSurveyDb,
    getSurveysDb,
    updateSurveyDb,
    publishSurveyDb, scheduleSurveyDb, closeSurveyDb,
    downloadSurvey,
    sendSurveyReminders,
    copySurveyDb,
    listCognitoUsers,
    addCognitoUser,
    deleteCogintoUser,
    resendCustomerEmailDb,
    verifyCustomerDb,
    assignSurveyDb,
    getUsersOnSurvey,
    postTemplate,
    updateCognitoUser,
    getOrganizationData,
    postOrganizationData,
    getOrganizationSurveysDb,
    getOrganizationDefaultStyleData,
    postOrganizationDefaultStyleData,
    deleteFileFromS3
} from '../../services/db-service';
import { determineUserRole, determineUserLicense } from '../../utils/role';

const uuid = require('uuid');

// USER AUTHENTICATION AND COGNITO

export const resetErrors = () => {
    console.log('reset errors');
    return dispatch => {
        return dispatch({
            type: RESET_ERROR,
        })
    }
};

export const storeUserData = (data) => {
    return dispatch => {
        return dispatch({
            type: STORE_USER_DATA,
            payload: data
        })
    }
};

export const registerCustomer = (customer) => {
    console.log('register customer, customer: ', customer);
    return dispatch => {
        return registerCustomerDb(customer).then(res => {
            if (res.type === REGISTRATION_ERROR_COMPANY_EXISTS || res.type === REGISTRATION_ERROR_DOMAIN_EXISTS || res.type === REGISTRATION_ERROR_OTHER) {
                return dispatch({
                    type: SET_ERROR,
                    payload: res,
                })
            }
            console.log('res: ', res);
            return dispatch({
                type: CUSTOMER_REGISTERED,
                payload: res,
            })
        }).catch(err => {
            console.log('error when registering customer: ', err);
            return dispatch({
                type: SET_ERROR,
                payload: REGISTRATION_ERROR_OTHER,
            })
        })
    }
};

export const resendCustomerEmail = (customerData) => {
    console.log('resend customer data: ', customerData)
    return dispatch => {
        return resendCustomerEmailDb(customerData).then(res => {
            return dispatch({
                type: EMAIL_RESEND
            })
        }).catch(err => {
            console.log('error when resending customer email: ', err)
            return dispatch({
                type: SET_ERROR,
                payload: EMAIL_RESEND_ERROR
            })
        })
    }
}

export const verifyCustomer = (customerData) => {
    console.log('verify customer: ', customerData)
    return dispatch => {
        return verifyCustomerDb(customerData).then(res => {
            console.log('verify vustomer: ', res)
            return dispatch({
                type: EMAIL_RESEND
            })
        }).catch(err => {
            console.log('error when verifing customer: ', err)
            return dispatch({
                type: SET_ERROR,
                payload: EMAIL_RESEND_ERROR
            })
        })
    }
}

export const resetRegistrationError = () => {
    console.log('reset registration error');
    return dispatch => {
        return dispatch({
            type: REGISTRATION_ERROR_COMPANY_RESET,
        })
    }
};

export const getUserDetails = () => {
    return dispatch => {
        return getUserDetailsDb().then(res => {
            return dispatch({
                type: USER_TEMPLATE_FETCHED,
                payload: res,
            })
        }).catch(err => {
            console.log('error when fetching template: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when fetching template'
            })
        })
    }
};


// SURVEY FUNCTIONALITY

export const getSurveys = () => {
    console.log('get surveys');
    return dispatch => {
        return getSurveysDb().then(res => {
            return dispatch({
                type: SURVEYS_FETCHED,
                payload: { surveys: res },
            })
        }).catch(err => {
            console.log('error getting surveys: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when getting surveys'
            })
        })
    };
};

export const getSurvey = (surveyId) => {
    console.log('get survey', surveyId);
    return dispatch => {
        return getSurveyDb(surveyId).then(res => {
            return dispatch({
                type: SURVEYS_FETCHED,
                payload: res,
            })
        }).catch(err => {
            console.log('error getting surveys: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when getting surveys'
            })
        })
    };
};

// organization surveys

export const getOrganizationSurveys = () => {
    console.log('get organization surveys');
    return dispatch => {
        return getOrganizationSurveysDb().then(res => {
            return dispatch({
                type: SURVEYS_FETCHED,
                payload: { surveys: res },
            })
        }).catch(err => {
            console.log('error getting surveys: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when getting surveys'
            })
        })
    };
};



// TEMPLATE FUNCTIONALITY

export const createFromTemplate = (organizationDefaultStyleData, templateDetails, template) => {
    // Add colors to template details
    templateDetails.colors = {
        ...organizationDefaultStyleData.colors
    }

    templateDetails.companyLogo = organizationDefaultStyleData.picture;

    console.log('create from template, template: ', template);
    
    const details = templateDetails;
    
    let currentQuestion = uuid.v4();
    let prevQuestion = null;

    const questions = template.map(element => {
        const elementId = currentQuestion;
        const nextQuestion = uuid.v4();

        const question = {
            element_id: elementId,
            id: elementId,
            nextQuestion: nextQuestion,
            prevQuestion: prevQuestion,
            expanded: true,
            ...element,
            ...(element.items_input ? { items_input: prevQuestion } : {}),
        };

        prevQuestion = elementId;
        currentQuestion = nextQuestion;
        return question;
    });

    if (questions.length > 0) {
        questions[questions.length - 1].nextQuestion = null;
    }

    return dispatch => {
        return dispatch(fromTemplate(details, questions)).then(res => {
            let surveyId = res.payload.response.copyId;
            return dispatch(getSurvey(surveyId)).then(res => {
                return dispatch(setCurrentSurvey(res.payload));

            });
        }).catch(error => {
            console.error('Failed to process template:', error);
        });
    };
};

export const fromTemplate = (details, template) => {
    return dispatch => {
        return postTemplate(details, template).then(res => {
            return dispatch({
                type: 'TEMPLATE_POST_SUCCESS',
                payload: {
                    response: res.data
                }
            })
        }).catch(err => {
            console.log('error posting template: ', err);
            return dispatch({
                type: 'TEMPLATE_POST_FAILURE',
                error: 'Error when posting template'
            })
        })
    }
}

// SURVEY EDITOR



export const createEmptySurvey = (organizationDefaultStyleData, companyName) => {
    console.log('create empty survey');
    let survey = {
        id: uuid.v4(),
        colors: {
            ...organizationDefaultStyleData.colors
        },
        companyLogo: organizationDefaultStyleData.picture,
        invitationTemplate: "invitation-template.html",
        invitationSubject: `${companyName} wants your feedback`,
        invitationFromName: 'survey@transforminsight.com',
        startImmediately: true,
        language: 'en', // todo make configurable,
        surveyAccessToken: uuid.v4(),
        confirmationNote: true,
        confirmNameTitle: SURVEY_DEFAULT_WELCOME_NOTE_TITLE,
        confirmNameText: SURVEY_DEFAULT_WELCOME_NOTE,
        userCustom: false,
        questionNumeration: true,
        progressBar: true,
        edit_after_submit: false,
    };
    return dispatch => {
        return startSurveyDb(survey).then(res => {
            const surveyDb = res.data;
            surveyDb.status = 'DRAFT'
            return dispatch({
                type: CREATE_BLANK_SURVEY,
                payload: {
                    // if something was added in backend we want to save it so we dont overwrite it on update
                    survey: {
                        ...survey,
                        ...surveyDb,
                    }
                },
            })
        }).catch(err => {
            console.log('error starting survey: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when starting survey'
            })
        })
    };
};

export const updateCurrentSurvey = (survey) => {
    console.log('update survey, survey: ', survey);
    return dispatch => {
        return updateSurveyDb({
            ...survey,
            questions: undefined,
            lists: undefined,
            tableData: undefined,
            respondents: undefined,
        }).then(res => {
            return dispatch({
                type: UPDATE_CURRENT_SURVEY,
                payload: { survey },
            })
        }).catch(err => {
            console.log('error updating survey: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when updating survey'
            })
        })
    };
};

export const setCurrentSurvey = (survey) => {
    console.log('set current survey,  survey: ', survey);
    return dispatch => {
        return dispatch({
            type: SET_CURRENT_SURVEY,
            payload: survey,
        })
    }
};

export const setActiveEditSurveyTab = (tab) => {
    console.log('set active edit survey tab: ', tab);
    return dispatch => {
        return dispatch({
            type: SET_ACTIVE_EDIT_SURVEY_TAB,
            payload: { tab },
        })
    }
};

export const setSidebarActivePage = (page) => {
    console.log('set sidebar active page: ', page);
    return dispatch => {
        return dispatch({
            type: SET_SIDEBAR_ACTIVE_PAGE,
            payload: { page },
        })
    }
};

export const copyCurrentSurvey = (survey) => {
    console.log('copy survey')
    return dispatch => {
        return copySurveyDb(survey.id).then(res => {
            return dispatch(getOrganizationSurveys())
        }).catch(err => {
            console.log('error deleting survey')
            return dispatch({
                type: SET_ERROR,
                payload: 'Error while deleting survey',
            })
        })
    }
}
// Work in progress
export const deleteCurrentSurvey = (survey) => {
    console.log('delete survey')
    console.log('survey: ', survey)
    return dispatch => {
        return deleteSurveyDb(survey.id, survey.createdBy).then(res => {
            return dispatch(getOrganizationSurveys())
        }).catch(err => {
            console.log('error deleting survey')
            return dispatch({
                type: SET_ERROR,
                payload: 'Error while deleting survey',
            })
        })
    }
}


export const assignUserToSurvey = (surveyId, usersData, email, attributes, surveyName, access, callerData, cognitoUsers) => {
    return dispatch => {
        return assignSurveyDb(surveyId, usersData, email, attributes, surveyName, access, callerData).then(res => {
            return dispatch({
                type: ASSIGN_TO_SURVEY,
                payload: {
                    cognitoUsers: cognitoUsers,
                    addedUser: res.data.User
                }
            })
        }).catch(err => {
            console.log('error assignin to survey')
            return dispatch({
                type: SET_ERROR,
                payload: 'error assignin to survey',
            })
        })
    }
}

export const updateSurveyName = name => {
    console.log('update survey, name: ', name);
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_SURVEY_NAME,
            payload: { name },
        });
        const { currentSurvey } = getState();
        return (dispatch(updateCurrentSurvey({
            ...currentSurvey,
            name,
        })))
    }
};

export const updateSurveyEditTime = lastEditedTime => {
    console.log('update survey, last edited time: ', lastEditedTime);
    return (dispatch, getState) => {
        dispatch({
            type: LAST_EDITED_TIME,
            payload: { lastEditedTime },
        });
        const { currentSurvey } = getState();
        return (dispatch(updateCurrentSurvey({
            ...currentSurvey,
            lastEditedTime,
        })))
    }
}

export const getAssignedUsers = surveyId => {
    console.log('assigned users, surveyId: ', surveyId);
    return dispatch => {
        return getUsersOnSurvey(surveyId).then(res => {
            return dispatch({
                type: SET_USERS_ON_SURVEY,
                payload: res
            })
        }).catch(err => {
            console.log('error listing assigned users: ', err);
            return dispatch({
                type: SET_ERROR,
            })
        })
    };
};

export const publishSurvey = (surveyId, endDate, userId) => {
    console.log('publish survey, surveyId: ', surveyId);
    console.log('userId: ', userId);
    // Get current user
    return dispatch => {
        return publishSurveyDb(surveyId, endDate).then(res => {
            return dispatch({
                type: PUBLISH_CURRENT_SURVEY,
                payload: {
                    userId: userId
                },
            })
        }).catch(err => {
            console.log('error publishing survey: ', err);
            return dispatch({
                type: SET_ERROR,
                payload: PUBLISH_CURRENT_SURVEY_ERROR,
            })
        })
    };
};

export const scheduleSurvey = (survey) => {
    console.log('schedule survey, surveyId: ', survey.id);
    return dispatch => {
        return scheduleSurveyDb(survey).then(res => {
            return dispatch({
                type: SCHEDULE_CURRENT_SURVEY,
            })
        }).catch(err => {
            console.log('error scheduling survey: ', err);
            return dispatch({
                type: SET_ERROR,
                payload: PUBLISH_CURRENT_SURVEY_ERROR,
            })
        })
    };
};

export const sendReminders = (surveyId, reminderObject) => {
    console.log('send reminders, surveyId: ', surveyId);

    const groupMappings = {
        EMAIL_BOUNCED: 'BOUNCED',
        SENT: 'NOT STARTED',
        STARTED: 'IN PROGRESS',
    };
      
    const newGroups = reminderObject.reminderGroups.map(group => groupMappings[group] || group);
      
    const newReminder = {
        subject: reminderObject.reminderSubject,
        fromEmail: reminderObject.reminderFrom,
        emailContent: reminderObject.reminderBody,
        sentTime: new Date().toISOString(),
        groups: newGroups.join(', '),
    };

    return (dispatch, getState) => {
        return sendSurveyReminders(surveyId, reminderObject).then(res => {
            dispatch({
                type: SEND_REMINDERS
            })
            if(res.data.successfullySent) {
                const { currentSurvey } = getState();
                return (dispatch(updateCurrentSurvey({ ...currentSurvey, sentReminders: [...currentSurvey.sentReminders || [], newReminder] })))
            }
        }).catch(err => {
            console.log('error sending reminders: ', err);
            return dispatch({
                type: SET_ERROR,
                payload: 'Error when sending reminders',
            })
        })
    }
}

export const closeSurvey = survey => {
    console.log('close survey, surveyId: ', survey.id);
    return dispatch => {
        return closeSurveyDb(survey).then(res => {
            return dispatch({
                type: CLOSE_CURRENT_SURVEY,
            })
        }).catch(err => {
            console.log('error closing survey: ', err);
            return dispatch({
                type: SET_ERROR,
                payload: "Error when closing survey",
            })
        })
    };
};

export const publishSurveyScheduleClose = survey => {
    console.log('publish survey, and schedule closing: surveyId: ', survey.id);
    return dispatch => {
        // todo if publish succeeds but scheduling fails, the invitations have been sent
        // but we inform user of error in publishing?
        return publishSurveyDb(survey.id).then(res => {
            return scheduleSurveyDb(survey).then(res => {
                return dispatch({
                    type: PUBLISH_CURRENT_SURVEY,
                })
            }).catch(err => {
                console.log('error scheduling survey: ', err);
                dispatch({
                    type: PUBLISH_CURRENT_SURVEY,
                })
                return dispatch({
                    type: SET_ERROR,
                    payload: SCHEDULE_CURRENT_SURVEY_ERROR,
                })
            })
        }).catch(err => {
            console.log('error publishing survey: ', err);
            return dispatch({
                type: SET_ERROR,
                payload: PUBLISH_CURRENT_SURVEY_ERROR,
            })
        })
    };
};

export const setEditPageActiveTab = (tab) => {
    console.log('set edit page tab');
    return dispatch => {
        return dispatch({
            type: SET_EDIT_PAGE_TAB,
            payload: { tab },
        })
    }
};

export const getQuestions = surveyId => {
    console.log('get questions, survey id: ', surveyId);
    return dispatch => {
        return getSurveyQuestion(surveyId).then(res => {
            return dispatch({
                type: GET_QUESTIONS,
                payload: { questions: res.data.map(q => q.question) },
            })
        }).catch(err => {
            console.log('error fetching questions: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when fetching questions'
            })
        })
    };
};


export const addQuestion = (surveyId, question) => {
    console.log('add question, question: ', question);
    return (dispatch, getState) => {
        return postSurveyQuestion(surveyId, question).then(res => {
            // update nextQuestion of previous question
            const { currentSurvey } = getState();
            const prevQuestion = question.prevQuestion && currentSurvey.questions ?
                currentSurvey.questions.find(q => q.id === question.prevQuestion) : null;
            if (prevQuestion) {
                console.log('update previous question');
                const updatedPrevQuestion = { ...prevQuestion, nextQuestion: question.id };
                return dispatch(updateQuestion(surveyId, updatedPrevQuestion)).then(() => {
                    return dispatch({
                        type: ADD_QUESTION,
                        payload: { question },
                    })
                })
            }
            return dispatch({
                type: ADD_QUESTION,
                payload: { question },
            })
        }).catch(err => {
            console.log('error adding question: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when adding question'
            })
        })
    };
};

// clone question and update previous and next questions
export const cloneQuestion = (surveyId, question) => {
    // Assuming logging is conditional based on environment
    if (process.env.NODE_ENV === 'development') {
        console.log('clone question, question: ', question);
    }

    // Example of a more selective deep clone, adjust according to needs
    let newQuestion = {
        ...question,
        // Deep clone only the properties that absolutely need it
        someNestedProperty: _.cloneDeep(question.someNestedProperty),
    };

    newQuestion.id = uuid.v4();
    newQuestion.prevQuestion = question.id;

    return dispatch => {
        dispatch(addQuestion(surveyId, newQuestion));
    }
};
// remove question and update previous and next questions
export const removeQuestion = (surveyId, question) => {
    console.log('remove question, question: ', question);
    return async (dispatch, getState) => {
        try {
            const { currentSurvey } = getState();
            const prevQuestion = question.prevQuestion && currentSurvey.questions ?
                currentSurvey.questions.find(q => q.id === question.prevQuestion) : null;
            const nextQuestion = question.nextQuestion && currentSurvey.questions ?
                currentSurvey.questions.find(q => q.id === question.nextQuestion) : null;

            const updatedPrevQuestion = prevQuestion ? { ...prevQuestion, nextQuestion: question.nextQuestion } : null;
            const updatedNextQuestion = nextQuestion ? { ...nextQuestion, prevQuestion: question.prevQuestion } : null;

            // Prepare all promises for deletion and updates
            const promises = [deleteSurveyQuestion(surveyId, question.id)];
            if (updatedPrevQuestion) {
                promises.push(dispatch(updateQuestion(surveyId, updatedPrevQuestion)));
            }
            if (updatedNextQuestion) {
                promises.push(dispatch(updateQuestion(surveyId, updatedNextQuestion)));
            }

            // Execute all operations in parallel
            await Promise.all(promises);

            dispatch({
                type: REMOVE_QUESTION,
                payload: { question },
            });
        } catch (err) {
            console.error('error removing question: ', err);
            dispatch({
                type: SET_ERROR,
                error: 'Error when removing question'
            });
        }
    };
};

export const updateQuestion = (surveyId, question) => {
    console.log('update question, question: ', question);

    return dispatch => {
        return postSurveyQuestion(surveyId, question).then(res => {
            console.log('update question res: ', res);
            return dispatch({
                type: UPDATE_QUESTION,
                payload: { question },
            })
        }).catch(err => {
            console.log('error updating question: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when updating question'
            })
        })
    };
};

export const reorderQuestions = (surveyId, updatedQuestions) => {
    console.log('reorder questions, questions: ', updatedQuestions);
    return (dispatch, getState) => {
        const { currentSurvey } = getState();
        const currentQuestions = currentSurvey.questions;
        let promises = [];
        updatedQuestions.forEach(question => {
            if (!_.isEqual(question, currentQuestions.find(q => q.id === question.id))) {
                promises.push(dispatch(updateQuestion(surveyId, question)));
            }
        });
        dispatch({
            type: REORDER_QUESTION,
            payload: { updatedQuestions }, // todo send only actually updated questions
        });
        return Promise.all(promises);
    }
};

export const setQuestionErrors = (errorCount) => {
    console.log('set question errors');
    return dispatch => {
        return dispatch({
            type: SET_QUESTION_ERRORS,
            payload: { errorCount },
        })
    }
};

// SETTINGS PAGE IN SURVEY EDITOR

export const getRespondents = surveyId => {
    console.log('get respondents, survey id: ', surveyId);
    return dispatch => {
        return getRespondentsDb(surveyId).then(res => {
            console.log('get respondents res: ', res);
            return dispatch({
                type: SET_RESPONDENTS_LIST,
                payload: { list: res },
            })
        }).catch(err => {
            console.log('error getting respondents: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when getting respondents'
            })
        })
    }
};

export const updateRespondentList = (surveyId, reduxList, list, method) => {
    console.log('set respondent list, list: ', reduxList);
    return dispatch => {
        return updateRespondents(surveyId, list, method).then(res => {
            console.log('updateRespondents res: ', res);
            return dispatch({
                type: UPDATE_RESPONDENTS_LIST,
                payload: { reduxList },
            })
        }).catch(err => {
            console.log('error updating respondents: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when updating respondents'
            })
        })
    }
};

export const addColumnToRespondentList = (surveyId, column, reduxList, method) => {
    console.log('add column to respondent list, column: ', column);
    return dispatch => {
        return addColumnToRespondentsLists(surveyId, column, method).then(res => {
            console.log('add column to respondents res: ', res);
            return dispatch({
                type: UPDATE_RESPONDENTS_LIST,
                payload: { reduxList },
            })
        }).catch(err => {
            console.log('error adding column to respondents: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when adding column to respondents'
            })
        })
    }   
};

export const downloadSurveyData = (surveyId, templateId) => {
    console.log('download survey data, template: ', templateId);
    return dispatch => {
        return downloadSurvey(surveyId, templateId).then(res => {
            console.log('downloading survey res:', res);

            return dispatch({
                type: DOWNLOAD_SURVEY_DATA,
                payload: { downloadUrl: res }
            })
        }).catch(err => {
            console.log('error downloading survey data: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error downloading survey data'
            })
        })
    }
}

export const deleteRespondents = (surveyId, respondents) => {
    console.log('delete respondents: ', respondents);
    return dispatch => {
        return deleteRespondentsDb(surveyId, respondents).then(res => {
            console.log('deleteRespondents res: ', res);
            return dispatch({
                type: DELETE_RESPONDENTS,
                payload: { respondents },
            })
        }).catch(err => {
            console.log('error deleting respondents: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when deleting respondents'
            })
        })
    }
};

export const deleteRespondentList = (surveyId, respondents) => {
    console.log('delete respondent list');
    return dispatch => {
        return deleteRespondentsDb(surveyId, respondents).then(res => {
            console.log('deleteRespondents res: ', res);
            return dispatch({
                type: DELETE_RESPONDENTS_LIST,
                payload: { respondents },
            })
        }).catch(err => {
            console.log('error deleting respondents: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when deleting respondents'
            })
        })
    }
};

export const updateRespondentErrors = (errorCount) => {
    console.log('set respondent errors');
    return dispatch => {
        return dispatch({
            type: SET_RESPONDENT_ERRORS,
            payload: { errorCount },
        })
    }
};

export const updateCustomList = (surveyId, list) => {
    console.log('update custom list, list: ', list);
    return dispatch => {
        return saveList(surveyId, list.id, list.data, list.title).then(res => {
            return dispatch({
                type: SET_CUSTOM_LIST,
                payload: { list },
            })
        }).catch(err => {
            console.log('error saving list: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when saving list'
            })
        })
    }
};

export const getCustomLists = surveyId => {
    console.log('get custom lists');
    return dispatch => {
        return getLists(surveyId).then(res => {
            return dispatch({
                type: CUSTOM_LISTS_FETCHED,
                payload: { lists: res },
            })
        }).catch(err => {
            console.log('error getting lists: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when getting lists'
            })
        })
    }
};

export const deleteCustomList = (surveyId, listId) => {
    console.log('delete custom list, list id: ', listId);
    return dispatch => {
        return deleteList(surveyId, listId).then(res => {
            return dispatch({
                type: DELETE_CUSTOM_LIST,
                payload: { listId },
            })
        }).catch(err => {
            console.log('error deletinc custom list: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when deleting custom list'
            })
        })
    }
};

// STYLE PAGE IN SURVEY EDITOR

export const updateSurveyColors = colors => {
    console.log('update survey, colors: ', colors);
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_SURVEY_STYLE_COLORS,
            payload: { colors },
        });
        const { currentSurvey } = getState();
        return (dispatch(updateCurrentSurvey({ ...currentSurvey, colors })))
    }
};

export const updateSurveyLogo = logo => {
    console.log('update survey, logo: ', logo);
    return (dispatch, getState) => {
        const { currentSurvey } = getState();
        const oldCompanyLogoUrl = currentSurvey.companyLogo;
        if (!currentSurvey?.defaultCompanyLogo && oldCompanyLogoUrl) {
            dispatch(deleteFileFromS3Action(oldCompanyLogoUrl));
        }
        dispatch({
            type: UPDATE_SURVEY_STYLE_LOGO,
            payload: { logo },
        });
        return (dispatch(updateCurrentSurvey({ ...currentSurvey, companyLogo: logo, defaultCompanyLogo: false })))
    }
};

export const updateGeneralSettings = (generalSettings) => {
    console.log('update general settings of survey: ' + JSON.stringify(generalSettings))
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_GENERAL_SETTINGS,
            payload: { generalSettings },
        });
        const { currentSurvey } = getState();
        return (dispatch(updateCurrentSurvey({ ...currentSurvey, ...generalSettings })))
    }
};

export const getS3Credentials = (fileName, fileType) => {
    console.log('get s3 credentials, file name: ', fileName);
    return dispatch => {
        return getS3CredentialsDb(fileName, fileType).then(res => {
            console.log('fetch credentials res: ', res);
            return dispatch({
                type: S3_CREDENTIALS_FETCHED,
                payload: { uploadData: res.data.data },
            })
        }).catch(err => {
            console.log('error fetching credentials: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when fetching credentials'
            })
        })
    }
};

export const uploadFile = (presignedPostData, file) => {
    console.log('upload file', presignedPostData);
    return dispatch => {
        return uploadFileToS3(presignedPostData, file).then(res => {
            console.log('upload file res: ', res);
            return dispatch({
                type: FILE_UPLOADED,
                payload: { logoUrl: res },
            })
        }).catch(err => {
            console.log('error uploading file: ', err);
            return dispatch({
                type: SET_ERROR,
                error: 'Error when uploading file'
            })
        })
    }
};

export const saveCurrentQuestionId = (questionId) => {
    console.log('set curr questionId: ', questionId);
    return dispatch => {
        dispatch({
            type: SET_QUESTION_ID,
            payload: { questionId }
        })
    }
}


export const listCognitoUsersAction = (token) => {
    return dispatch => {
        return listCognitoUsers(token).then(res => {
            const usersWithRoles = res.map(user => {
                // Convert attributes array to an object
                const attributes = user.Attributes.reduce((acc, attr) => {
                    acc[attr.Name] = attr.Value;
                    return acc;
                }, {});
                return {
                    ...user,
                    computedRole: determineUserRole({
                        role: attributes['custom:role'],
                        insightsLicense: attributes['custom:insightsLicense'],
                        surveyLicense: attributes['custom:surveyLicense']
                    }),
                    licences: determineUserLicense({
                        role: attributes['custom:role'],
                        insightsLicense: attributes['custom:insightsLicense'],
                        surveyLicense: attributes['custom:surveyLicense']
                    }),

                };
            });

            return dispatch({
                type: LIST_COGNITO_USERS,
                payload: usersWithRoles
            });
        })
    }
}







export const addCognitoUserAction = (email, name, surname, surveyLicense, surveyAccessLevel, insightsLicense, role, cognitoUsers, callerData) => {
    console.log('add cognito user action')
    return dispatch => {
        return addCognitoUser(email, name, surname, surveyLicense, surveyAccessLevel, insightsLicense, role, callerData).then(res => {
            return dispatch({
                type: ADD_COGNITO_USER,
                payload: {
                    cognitoUsers: cognitoUsers,
                    addedUser: res
                }
            })
        }).catch(err => {
            return dispatch({
                type: SET_ERROR,
                payload: USER_EXIST_ON_WORKSPACE
            })
        })
    }
}


export const deleteCognitoUserAction = (email, cognitoUsers) => {
    console.log('delete cognito user action')
    return dispatch => {
        return deleteCogintoUser(email).then(res => {
            return dispatch({
                type: DELETE_COGNITO_USER,
                payload: {
                    cognitoUsers: cognitoUsers,
                    deletedUser: email
                }
            })
        })
    }
}

// update user data

export const updateCognitoUserAction = (email, attributes, cognitoUsers) => {
    console.log('Update Cognito user action');

    return dispatch => {
        return updateCognitoUser(email, attributes).then(res => {
            console.log('Successfully updated user: ', res);
            return dispatch({
                type: 'UPDATE_COGNITO_USER',
                payload: {
                    updatedUser: res,
                    cognitoUsers: cognitoUsers,
                    addedUser: res
                }
            });
        }).catch(err => {
            console.log('Error updating user: ', err);
            return dispatch({
                type: 'SET_ERROR',
                payload: 'USER_EXIST_ON_WORKSPACE'
            });
        });
    };
}

export const getOrganizationDataAction = () => {
    console.log('get organization data action');
    return dispatch => {
        return getOrganizationData().then(res => {
            // Check if organizationData exists in the response and if it does, store it in the redux store
            if (res != '') {
                return dispatch({
                    type: STORE_ORGANIZATION_DATA,
                    payload: { data: res }
                })
            }
        }).catch(err => {
            console.log('Error fetching organization data: ', err);
            return dispatch({
                type: 'SET_ERROR',
                payload: 'Error fetching organization data'
            });
        });
    }
}
export const updateOrganizationDataAction = (data) => {
    console.log('update organization data action');
    return dispatch => {
        return postOrganizationData(data).then(res => {
            return dispatch({
                type: STORE_ORGANIZATION_DATA,
                payload: { data: data }
            })
        }).catch(err => {
            console.log('Error updating organization data: ', err);
            return dispatch({
                type: 'SET_ERROR',
                payload: 'Error updating organization data'
            });
        });
    }
}

export const getOrganizationDefaultStyleDataAction = () => {
    console.log('get organization default style data action');
    return dispatch => {
        return getOrganizationDefaultStyleData().then(res => {
            // Check if organizationData exists in the response and if it does, store it in the redux store
            if (res != '') {
                return dispatch({
                    type: STORE_ORGANIZATION_DEFAULT_STYLE_DATA,
                    payload: { data: res }
                })
            }
        }).catch(err => {
            console.log('Error fetching organization data: ', err);
            return dispatch({
                type: 'SET_ERROR',
                payload: 'Error fetching organization default style data'
            });
        });
    }
}

export const updateOrganizationDefaultStyleDataAction = (data) => {
    console.log('update organization default style data action');
    return dispatch => {
        return postOrganizationDefaultStyleData(data).then(res => {
            return dispatch({
                type: STORE_ORGANIZATION_DEFAULT_STYLE_DATA,
                payload: { data: data }
            })
        }).catch(err => {
            console.log('Error updating organization data: ', err);
            return dispatch({
                type: 'SET_ERROR',
                payload: 'Error updating organization default style data'
            });
        });
    }
}

export const setSaveDefaultStyleMessage = (message) => {
    console.log('set save default style message');
    return dispatch => {
        return dispatch({
            type: SET_SAVE_DEFAULT_STYLE_MESSAGE,
            payload: { message },
        })
    }
}

export const deleteFileFromS3Action = (fileUrl) => {
    console.log('delete file from S3 action');
    return dispatch => {
        return deleteFileFromS3(fileUrl).then(res => {
            return dispatch({
                type: DELETE_FILE_FROM_S3,
                payload: { fileUrl }
            })
        }).catch(err => {
            console.log('Error deleting file from S3: ', err);
            return dispatch({
                type: 'SET_ERROR',
                payload: 'Error deleting file from S3'
            });
        });
    }
}   