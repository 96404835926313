import React from 'react';
import { Box } from "@mui/material";
import CustomFormControlLabel from '../../../../components/form/custom-form-control-label';
import './question-container.scss';
import { ReactComponent as CopyIcon } from "../../../../assets/images/question-container/duplicate.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/question-container/delete.svg";
import { ReactComponent as CogIcon } from "../../../../assets/images/settings-icon.svg";
import IconButton from '@mui/material/IconButton';
import styled from "@mui/material/styles/styled";
import questionTypes from '../../../../assets/question-types';
import TooltipText from '../../../../components/tooltip-text/tooltip-text';
import { colorTheme } from '../../../../assets/theme';
import useQuestionSettings from '../../../../hooks/editor/use-question-basic-settings';
import {
    SECTION
} from '../../../../assets/constants';

const StyledIconButton = styled(IconButton)({
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
    padding: '4px',
    '& svg path': {
        transition: 'fill 0.3s ease',
    },
    '&:hover': {
        backgroundColor: colorTheme.theme.app.lightestGrey,
        'svg path': {
            fill: colorTheme.theme.transform.darkBlue,
        }
    },
});

const getQuestionTypeInfo = (elementType, styleType = null) => {
    for (const column of questionTypes) {
        for (const type of column) {
            for (const question of type.questions) {
                if (question.value === elementType) {
                    if (styleType && styleType !== 'image') {
                        return { icon: question.icon, label: question.label };
                    } else if (styleType === 'image' && question.style_type === 'image') {
                        return { icon: question.icon, label: question.label };
                    } else if (!styleType) {
                        return { icon: question.icon, label: question.label };
                    }
                }
            }
        }
    }
    return null;
};

function QuestionBasicSettings(props) {
    const {
        requiredChecked,
        pageBreakChecked,
        hiddenChecked,
        handleSettingsChange,
        loading
    } = useQuestionSettings(props.question);

    const questionTypeInfo = getQuestionTypeInfo(props.question.element_type, props.question.style_type);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            className="question-settings"
            sx={{
                borderTop: '1px solid #D7D7D7',
                height: '58px',
                paddingLeft: '18px',
                paddingRight: '20px',
                position: 'relative' // Added for loader positioning
            }}
        >
            <Box display="flex" alignItems="center">
                <Box display="flex" alignItems="center">
                    {questionTypeInfo?.icon}
                    <span style={{ marginLeft: 10 }}>{questionTypeInfo?.label}</span>
                </Box>
            </Box>
            <Box display="flex" alignItems="center">
                {
                    props.question.element_type === SECTION ? (
                        <Box display="flex" alignItems="center">
                            <Box mr={'42px'} mt={'9px'}>
                                <CustomFormControlLabel
                                    checked={pageBreakChecked}
                                    onChange={() => { handleSettingsChange('SETTINGS_ACTION_PAGE_BREAK') }}
                                    name="check-page-break"
                                    label="Page Break"
                                    color={colorTheme.theme.transform.darkBlueTwo}
                                    labelFontSize={16}
                                    labelFontWeight={400}
                                    thumbColor='#7B7D89'
                                />
                            </Box>
                            <Box mr={'22px'} ml={'-22px'} mt={'9px'}>
                                <CustomFormControlLabel
                                    checked={hiddenChecked}
                                    onChange={() => { handleSettingsChange('SETTINGS_ACTION_HIDDEN') }}
                                    name="check-hidden"
                                    label="Visible for Respondents"
                                    color={colorTheme.theme.transform.darkBlueTwo}
                                    labelFontSize={16}
                                    labelFontWeight={400}
                                    thumbColor='#7B7D89'
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box display="flex" alignItems="center">
                            <Box mr={'22px'} mt={'9px'}>
                                <CustomFormControlLabel
                                    checked={requiredChecked}
                                    onChange={() => { handleSettingsChange('SETTINGS_ACTION_REQUIRED') }}
                                    name="check-required"
                                    label="Required"
                                    color={colorTheme.theme.transform.darkBlueTwo}
                                    labelFontSize={16}
                                    labelFontWeight={400}
                                    thumbColor='#7B7D89'
                                />
                            </Box>
                            <Box mr={'42px'} mt={'9px'}>
                                <CustomFormControlLabel
                                    checked={pageBreakChecked}
                                    onChange={() => { handleSettingsChange('SETTINGS_ACTION_PAGE_BREAK') }}
                                    name="check-page-break"
                                    label="Page Break"
                                    color={colorTheme.theme.transform.darkBlueTwo}
                                    labelFontSize={16}
                                    labelFontWeight={400}
                                    thumbColor='#7B7D89'
                                />
                            </Box>
                        </Box>
                    )
                }
                <Box sx={{ gap: '0px', display: 'flex' }}>
                    <>

                        <TooltipText name="Advanced Settings" placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 0px'}>
                            <StyledIconButton
                                sx={{ padding: '5px' }}
                                className="settings-item icon" onClick={props.handleAdvancedView}>
                                <CogIcon />
                            </StyledIconButton>
                        </TooltipText>

                        <TooltipText name="Duplicate" placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 0px'}>
                            <StyledIconButton
                                sx={{ padding: '5px' }}
                                className="settings-item icon" onClick={() => handleSettingsChange('SETTINGS_ACTION_DUPLICATE')}>
                                <CopyIcon />
                            </StyledIconButton>
                        </TooltipText>
                        <TooltipText name="Delete" placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 0px'}>
                            <StyledIconButton
                                sx={{ padding: '5px' }}
                                className="settings-item icon" onClick={() => handleSettingsChange('SETTINGS_ACTION_DELETE')}>
                                <DeleteIcon />
                            </StyledIconButton>
                        </TooltipText>
                    </>
                </Box>
            </Box>
        </Box>
    );
}

export default QuestionBasicSettings;
