import React from 'react';
import { TextField, Box } from '@mui/material';
import { colorTheme } from '../../assets/theme';

const InputField = ({
    type,
    textFieldWidth,
    label,
    value,
    onChange,
    placeholder = '',
    boxMarginBottom = '20px',
    inputPadding = '14.5px 12px',
    inputfontSize = '16px',
    labelFontSize = '12px',
    labelMarginBottom = '8px',
}) => {
    // ... rest of your component

    return (
        <Box style={{ position: 'relative', marginBottom: boxMarginBottom, width: textFieldWidth }}> {/* Added width here */}
            {
                label && (
                    <Box component="label" sx={{
                        color: colorTheme.theme.text.lightGrey,
                        display: 'block',
                        marginBottom: labelMarginBottom,
                        fontSize: labelFontSize,
                    }}>
                        {label}
                    </Box>
                )
            }
            <TextField
                autoFocus={false}
                name="email"
                type={type}
                fullWidth
                variant="outlined"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                InputLabelProps={{
                    shrink: true,
                }}
                sx={{
                    width: textFieldWidth, // Conditionally set the width
                    '& .MuiOutlinedInput-root': {
                        background: 'white',
                        '& fieldset': {
                            // borderColor: '#1B4972', // Change the outline color
                            // border: '1px solid' // Make the border 1px solid
                        },
                        '&:hover fieldset': {
                            borderColor: '#4F4F4F', // Change the outline color on hover
                            border: '1px solid', // Make the border 1px solid
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#1B4972', // Change the outline color when focused
                            borderWidth: 1,
                        },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: '#1B4972',
                        transform: 'translate(14px, -6px) scale(0.75)',
                    },
                    '&:hover .MuiInputLabel-root': {
                        color: '#4F4F4F',
                    },
                    '.MuiInputLabel-root.MuiInputLabel-shrink': {
                        transform: 'translate(14.5px, -6px) scale(0.75)',
                    },
                    // label transform
                    '& .MuiInputLabel-root': {
                        transform: 'translate(14.5px, 16px) scale(1)',
                    },
                    '& .MuiInputBase-input': {
                        padding: inputPadding,
                        lineHeight: '1',
                        height: 'initial',
                        fontSize: inputfontSize,
                    },
                    '& .MuiFormLabel-root': {
                        lineHeight: '1',
                    },
                }}
            />
        </Box>
    );
};

export default InputField;
