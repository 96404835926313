import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogActions, DialogContentText, Box } from '@mui/material';
import { ReactComponent as CreateIcon } from './../../assets/images/actions/icon-create.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/actions/icon-plus-blue.svg';
import { colorTheme } from '../../assets/theme';
import { CUSTOM_LIST_FILE, RESPONDENTS_FILE } from "../../assets/constants";
import HeaderDialog from './header-dialog';
import PrimaryButton from '../buttons/primary-button';
import InputField from '../form/input-field';
import TextButton from '../buttons/text-button';
import CustomCheckboxLabel from '../form/custom-checkbox-label';

// AddNewList component for dialog
const AddNewList = ({ open, handleClose, handleConfirm, type, attributes, addAttributes, checkAttribute, listName, setListName }) => {
    const [newAttributeName, setNewAttributeName] = useState(''); // State to store new attribute name
    const [addingNewAttribute, setAddingNewAttribute] = useState(false); // State to store adding new attribute
    const [customAttributes, setCustomAttributes] = useState([{ value: '' }]); // State to store custom attributes
    const [openedDialog, setOpenedDialog] = useState(false); // State to store opened dialog

    const setNewAttribute = (e) => setNewAttributeName(e.target.value); // Function to set new attribute
    const addNewAttributeField = () => setCustomAttributes([...customAttributes, { value: '' }]); // Function to add new attribute field
    const removeAttribute = (index) => setCustomAttributes(customAttributes.filter((_, i) => i !== index)); // Function to remove attribute
    const cancelNewAttribute = () => resetState(); // Function to cancel new attribute

    useEffect(() => {
        setOpenedDialog(open ? true : false);
        resetState();
    }, [open]);

    // Function to reset state
    const resetState = () => {
        setNewAttributeName('');
        setAddingNewAttribute(false);
        setCustomAttributes([{ value: '' }]);
    };

    // Function to add new attribute
    const addNewAttribute = () => {
        if (newAttributeName.trim()) {
            addAttributes([{ title: newAttributeName, checked: true }]);
            resetState();
        }
    };

    // Function to set new attribute for custom list
    const setNewAttributeAdditionalList = (e, index) => {
        const updatedAttributes = [...customAttributes];
        updatedAttributes[index].value = e.target.value;
        setCustomAttributes(updatedAttributes);
    };

    // Function to save attributes
    const handleSaveAttributes = () => {
        if (type === CUSTOM_LIST_FILE) {
            const validAttributes = customAttributes.filter(attr => attr.value.trim() !== '').map(attr => ({
                title: attr.value,
                checked: true
            }));
            addAttributes(validAttributes);
        }
        handleConfirm();
    };

    return (
        <Dialog open={openedDialog} onClose={handleClose}
            sx={{ '& .MuiDialog-paper': { width: '550px' } }} // Set the dialog width
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog Icon={CreateIcon} handleClose={handleClose} title={`Create List`} />
            <DialogContent
                sx={{ pl: 2, pr: 2, pt: 2.5, pb: 2, display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <DialogContentText
                        variant='body2'
                        sx={{ color: colorTheme.theme.text.mediumGrey, lineHeight: "18px", fontSize: '15px' }}>
                        List Name
                    </DialogContentText>
                    <InputField
                        value={listName}
                        label={null}
                        placeholder={'Type here'}
                        textFieldWidth="100%"
                        inputPadding="14.5px 10px"
                        lineHeight="20px"
                        inputfontSize="15px"
                        boxMarginBottom="0px"
                        labelFontSize="12px"
                        labelMarginBottom="8px"
                        onChange={(e) => setListName(e.target.value)}
                        readOnly={type === CUSTOM_LIST_FILE ? false : true}
                        hoverDisabled={type === CUSTOM_LIST_FILE ? false : true}
                    />
                </Box>
                {
                    type === RESPONDENTS_FILE &&
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <DialogContentText
                            variant='body2'
                            sx={{ color: colorTheme.theme.text.mediumGrey, lineHeight: "18px", fontSize: '15px' }}>
                            Choose attributes
                        </DialogContentText>
                        <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap'}}>
                            {attributes && attributes.map((attribute, index) => (
                                <CustomCheckboxLabel
                                    key={index}
                                    checked={attribute.checked}
                                    onChange={() => {
                                        checkAttribute(attribute.title);
                                    }}
                                    label={attribute.title}
                                />
                            ))}
                        </Box>
                        {
                            !addingNewAttribute && <TextButton name="Add other attribute" onClick={setAddingNewAttribute} size={'small'} margin='0px' fontSize='14px' width='fit-content' borderRadius='23px' Icon={PlusIcon} />
                        }
                        {
                            addingNewAttribute &&
                            <InputField
                                value={newAttributeName}
                                label={'Attribute Name'}
                                placeholder={''}
                                textFieldWidth="100%"
                                inputPadding="14.5px 10px"
                                lineHeight="20px"
                                inputfontSize="15px"
                                boxMarginBottom="0px"
                                labelFontSize="15px"
                                onChange={setNewAttribute}
                                buttonActions={true}
                                buttonActionLeft={addNewAttribute}
                                buttonActionRight={cancelNewAttribute}
                            />
                        }
                    </Box>
                }
                {
                    type === CUSTOM_LIST_FILE &&
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <DialogContentText
                            variant='body2'
                            sx={{ color: colorTheme.theme.text.mediumGrey, lineHeight: "18px", fontSize: '15px' }}>
                            Attribute
                        </DialogContentText>
                        <InputField
                            value={customAttributes[0].value}
                            label={null}
                            placeholder={'Type here'}
                            textFieldWidth="100%"
                            inputPadding="14.5px 10px"
                            lineHeight="20px"
                            inputfontSize="15px"
                            boxMarginBottom="0px"
                            labelFontSize="12px"
                            labelMarginBottom="8px"
                            onChange={(e) => setNewAttributeAdditionalList(e, 0)} 
                            buttonActions={null}
                        />
                        {
                            customAttributes.slice(1).map((attribute, index) => (
                                <InputField
                                    key={index + 1}
                                    value={attribute.value}
                                    label={null}
                                    placeholder={'Type here'}
                                    textFieldWidth="100%"
                                    inputPadding="14.5px 10px"
                                    lineHeight="20px"
                                    inputfontSize="15px"
                                    boxMarginBottom="0px"
                                    labelFontSize="12px"
                                    labelMarginBottom="8px"
                                    onChange={(e) => setNewAttributeAdditionalList(e, index + 1)}
                                    buttonActions={true}
                                    buttonActionRight={() => removeAttribute(index + 1)}
                                />
                            ))
                        }
                        {
                            !addingNewAttribute && <TextButton name="Add other attribute" onClick={addNewAttributeField} size={'small'} margin='0px' fontSize='14px' width='fit-content' borderRadius='23px' Icon={PlusIcon} />
                        }
                    </Box>
                }
            </DialogContent>
            <DialogActions sx={{ pt: 2, pb: 2, pr: 2, pl: 2 }}>
                <PrimaryButton onClick={handleSaveAttributes} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="Save" />
            </DialogActions>
        </Dialog>
    );
};

export default AddNewList;