import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ITEM_HEIGHT = 48; // Height of each item
const ITEM_PADDING_TOP = 8 // Top padding of the menu

// // MenuProps for the select component
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 240,
//     },
//   },
// };

// ClassicSelect component
const ClassicSelect = ({ label, value, list, onChange, isLabelList = false, selectPadding = '11.25px 12px', selectWidth = null, selectFontSize = '14px', selectLineHeight = 1, selectColor = 'initial' }) => {
  return (
    <div>
      <FormControl sx={{ width: selectWidth ? selectWidth : '100%' }}>
        <Select
          displayEmpty
          value={value}
          onChange={onChange}
          input={<OutlinedInput sx={{ padding: '10px 12px' }} />}
          renderValue={(selected) => {
            if (isLabelList) {
              const selectedItem = list.find(item => item.value === selected);
              return selectedItem ? selectedItem.label : label;
            }
            // Display the label if no value is selected
            return selected || label;
          }}
          MenuProps={
           // add paperProps here STYLE 
            {
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  width: selectWidth ? selectWidth : 240,
                },
              },
            }
          }
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            '.MuiSelect-select': {
              padding: selectPadding,
              lineHeight: selectLineHeight,
              height: 'initial',
              fontSize: selectFontSize,
              minHeight: 'initial',
              color: selectColor
            },
          }}
        >
          {isLabelList ? (
            list.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                sx={{
                  fontSize: '12px',
                }}
              >
                {item.label}
              </MenuItem>
            ))
          ) : (
            list.map((name) => (
              <MenuItem
                key={name}
                value={name}
                sx={{
                  fontSize: '12px',
                }}
              >
                {name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default ClassicSelect;