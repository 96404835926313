import React, { useEffect, useRef } from "react";
import { HexColorPicker } from "react-colorful";

// ColorPicker component
const ColorPicker = ({ color, opened, setColorPickerOpened, handleChange }) => {
    const pickerRef = useRef(null); // Create a reference to the color picker

    // Function to handle clicks outside the color picker
    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setColorPickerOpened(false);
        }
    };

    // Add an event listener to the document when the color picker is opened
    useEffect(() => {
        if (opened) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [opened]);

    // Function to handle color change
    const handleColorChange = (color) => {
        handleChange({ target: { value: color } });
    };

    return (
        opened && (
            <div ref={pickerRef} style={{ position: 'absolute', right: '28px', top: '0px', transform: 'translate(100%, 0%)', zIndex: '100' }}>
                <HexColorPicker color={color} onChange={handleColorChange} />
            </div>
        )
    );
};
export default ColorPicker;