import React from "react";
import { Box, Typography } from "@mui/material";
import { colorTheme } from "../../assets/theme";

// ValueInfo component
const ValueInfo = ({ label, value, labelMarginBottom = "6px", valueFontSize = "16px", valueFontWeight = "500", valueLineHeight = "20px" }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { sm: 'flex-start' } }}>
            <Box component="label" sx={{
                color: colorTheme.theme.text.lightGrey,
                display: 'block',
                marginBottom: labelMarginBottom,
                fontSize: '12px',
            }}>
                {label}
            </Box>
            <Typography variant="body2" sx={{ fontSize: valueFontSize, color: colorTheme.theme.transform.black, fontWeight: valueFontWeight, lineHeight: valueLineHeight }}>
                {value === "" ? "/" : value}
            </Typography>
        </Box>
    );
}

export default ValueInfo;