import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';

const HeaderDialog = ({ Icon, AvatarComponent, handleClose, title }) => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #E0E0E0',
            p: 1,
            height: '35px',
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: '8px', gap: 1 }}>
                {Icon && <Icon />}
                {AvatarComponent && <AvatarComponent />}
                <Typography sx={{ flexShrink: 0 }}>{title}</Typography>
            </Box>
            <IconButton onClick={handleClose} size="small">
                <CloseIcon />
            </IconButton>
        </Box>
    );
};

export default HeaderDialog;

