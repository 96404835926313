import React from 'react';
import { Box, DialogContent, Dialog } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import HeaderDialog from './header-dialog';
import PrimaryButton from '../buttons/primary-button';
import InputField from '../form/input-field';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';



export default function ForgotPassword({ handleClose, open, title, buttonText, setResetPassword, email, setEmail
}) {
    const { enqueueSnackbar } = useSnackbar();


    const handleSendCode = async () => {
        try {

            const res = await Auth.forgotPassword(email);
            console.log('Confirmation code sent to your email:', res);

            enqueueSnackbar('Confirmation code sent to your email.', { variant: 'success' });
            setResetPassword(true);
            handleClose();
            // Proceed to confirmation code step
        } catch (error) {
            console.error('Error sending confirmation code:', error);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };



    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '460px'
                }
            }}
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog handleClose={handleClose} title={title} />

            <DialogContent sx={{ p: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        gap: 2,
                    }}>

                    <InputField
                        label="Enter email"
                        textFieldWidth='429px'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />


                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'flex-end' }}>
                        <PrimaryButton
                            onClick={handleSendCode}
                            color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text={buttonText} />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog >
    );
}
