import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from 'notistack';
import { addCognitoUserAction, deleteCognitoUserAction, updateCognitoUserAction, listCognitoUsersAction } from "../../redux/actions";
import { ReactComponent as Accepted } from "./../../assets/images/table/icon-check.svg";
import { ReactComponent as Pending } from "./../../assets/images/table/icon-pending.svg";
import moment from "moment";
import { lastActivity } from '../../utils/last-activity';


const useTeammates = () => {
    const dispatch = useDispatch();
    const cognitoUsers = useSelector(state => state.cognitoUsers);
    const [teamData, setTeamData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [role, setRole] = useState('owner'); // Default role
    const [surveyLicense, setSurveyLicense] = useState('false');
    const [insightsLicense, setInsightsLicense] = useState('false');
    const [isLoading, setIsLoading] = useState(false);
    const userData = useSelector(state => state.userData)

    // Define the headers for the table
    const headCells = [
        { id: 'user', numeric: false, disablePadding: true, label: 'Teammate', width: '197px' },
        { id: 'email', numeric: false, disablePadding: false, label: 'Email', width: '235px' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Invitation Status', width: '165px' },
        { id: 'role', numeric: false, disablePadding: false, label: 'Team Role', width: '261px' },
        { id: 'lastActive', numeric: false, disablePadding: false, label: 'Active', width: '141px' },
    ];

    const status = {
        'FORCE_CHANGE_PASSWORD': { label: 'Pending', icon: Pending },
        'CONFIRMED': { label: 'Accepted', icon: Accepted },
    }


    // Log the licenses whenever they change
    useEffect(() => {
        console.log('Licenses:', surveyLicense, insightsLicense);
        console.log('Role:', role);
    }, [surveyLicense, insightsLicense, role]);

    // Function to handle adding a user
    const handleAddUser = async (email) => {
        let name = '';
        let surname = '';
        let callerData = { name: userData.firstName, lastName: userData.lastName }
        lastActivity(userData.email, cognitoUsers, dispatch);
        let surveyAccessLevel = '';
        if (surveyLicense === 'true') {
            surveyAccessLevel = 'admin';
        }
        const res = await dispatch(addCognitoUserAction(email, name, surname, surveyLicense, surveyAccessLevel, insightsLicense, role, cognitoUsers, callerData));
        console.log('Response:', res);

        dispatch(listCognitoUsersAction())
        if (res.type !== 'SET_ERROR') {
            enqueueSnackbar('Successfully added user', { variant: 'success' });
        } else {
            enqueueSnackbar('User with provided email is already invited to workspace', { variant: 'error' });
        }
    };

    // Function to handle deleting a user
    const handleDelete = (email) => {
        lastActivity(userData.email, cognitoUsers, dispatch);
        dispatch(deleteCognitoUserAction(email, cognitoUsers));
        enqueueSnackbar('User deleted successfully', { variant: 'success' });

    };

    // Function to handle editing a user
    const handleEdit = async (email) => {

        let attributes = {
            surveyLicense,
            insightsLicense,
            role
        }
        lastActivity(userData.email, cognitoUsers, dispatch);
        const res = await dispatch(updateCognitoUserAction(email, attributes, cognitoUsers));
        console.log('Response:', res);
        enqueueSnackbar('User updated successfully', { variant: 'success' });
        dispatch(listCognitoUsersAction())

    };

    // Define the path segments for the breadcrumbs
    const pathSegments = [
        { name: "Transform", path: "/" },
        { name: "Settings", path: "/settings" },
        { name: "Teammates", path: "/settings" }
    ];


    // Format user data
    useEffect(() => {
        if (cognitoUsers) {
            console.log('Cognito users:', cognitoUsers);
            const formattedData = cognitoUsers.map(user => {


                return {
                    id: user.Username,
                    user: user.UserStatus === 'FORCE_CHANGE_PASSWORD' ? 'Awaiting Info...' :
                        `${user.Attributes.find(attr => attr.Name === 'given_name')?.Value} ${user.Attributes.find(attr => attr.Name === 'family_name')?.Value}`,
                    email: user.Attributes.find(attr => attr.Name === 'email')?.Value,
                    status: status[user.UserStatus].label,
                    icon: status[user.UserStatus].label === 'Accepted' ? <Accepted /> : <Pending />,
                    role: user.licences,
                    lastActive: user.Attributes.find(attr => attr.Name === 'custom:lastActive') ? moment(user.Attributes.find(attr => attr.Name === 'custom:lastActive').Value).fromNow() : 'Not available',
                    computedRole: user.computedRole,
                    avatar: user.Attributes.find(attr => attr.Name === 'picture')?.Value,
                    isCurrent: user.Attributes.find(attr => attr.Name === 'email')?.Value === userData.email
                };


            });
            setTeamData(formattedData);


            console.log('Formatted data:', teamData);
        }
    }, [cognitoUsers]);

    const teamRoleOptions = [
        { value: '', label: 'Show all' },
        { value: 'Owner', label: 'Owner' },
        { value: 'Survey Admin', label: 'Survey admin' },
        { value: 'Insights Admin', label: 'Insights admin' },
        { value: 'Both', label: 'Survey & Insights admin' }
    ]

    const licenseColors = {
        'Survey Admin': '#FFBD6F',   // Yellowish color for "Survey"
        'Insights Admin': '#FAE36E',  // Blue color for "Insights"
        'Owner': '#ABD78A',           // Red color for "Owner"
        'Pending': '#D8D8D8', // Gray color for "Awaiting Info"
    };

    const textColors = {
        'Survey Admin': '#231F20', // Black color for all other statuses
        'Insights Admin': '#231F20', // Black color for all other statuses
        'Owner': '#231F20', // Black color for all other statuses
        'Pending': '#F9F9F9', // Gray color for "Awaiting Info"
    };


    return {
        headCells,
        teamData,
        handleAddUser,
        handleDelete,
        handleEdit,
        pathSegments,
        setInsightsLicense,
        setSurveyLicense,
        setRole,
        teamRoleOptions,
        licenseColors,
        textColors,
        isLoading

    };
};

export default useTeammates;