import React from 'react';
import { TableHead, TableRow, TableCell } from '@mui/material';
import { ACTIVE_STATUS_COLUMN } from '../../assets/constants';
import { colorTheme } from '../../assets/theme';
import { leftPosition } from '../../utils/sticky-column';
import InputField from '../../components/form/input-field-label';
import ListsTableActions from "./lists-table-actions";
import { ReactComponent as CancelIcon } from '../../assets/images/actions/icon-x.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/actions/icon-save-3.svg';

// ListsTableHead component
const ListsTableHead = ({ state, respondentList, addingColumn, cancelAddingColumn, saveColumn, newColumnName, setNewColumnName }) => {
    return (
        <TableHead>
            <TableRow sx={{
                borderRadius: '4px 4px 0px 0px',
                height: '52px', // Set the row height
                // '& > *': { padding: '6px 16px' },
            }}>
                {state.columns.filter(headCell => headCell.field !== 'tableData' && state.selectedColumns.includes(headCell.field)).map((headCell, index) => (
                    <TableCell
                        sx={{
                            border: 'none !important',
                            pr: '100px',
                            pl: '0px !important',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            boxSizing: 'border-box',
                            backgroundColor: colorTheme.theme.colors.teamTableHeaderBackground,
                            borderBottom: 'none',
                            width: '0',
                            paddingTop: '12px !important',
                            paddingBottom: '12px !important',
                            ...(index === 0 && { // If the index is 0, set the padding-left to 24px
                                pl: '24px !important',
                            }),
                            ...(state.columns.length > 1 && ([ACTIVE_STATUS_COLUMN.field.toLowerCase()].includes(headCell.field.toLowerCase())) && {
                                pr:  state.columns[0].field == ACTIVE_STATUS_COLUMN.field ? '24px !important' : '50px !important',
                            }),
                            ...(state.columns.length > 1 && respondentList && {  // If the respondentList is true, set 'Name' and 'Survey Progress' columns to sticky
                                ...([ACTIVE_STATUS_COLUMN.field.toLowerCase(), 'name'].includes(headCell.field.toLowerCase()) && { pl: '24px !important ' }),
                                position: [ACTIVE_STATUS_COLUMN.field.toLowerCase(), 'name'].includes(headCell.field.toLowerCase()) ? 'sticky' : 'static',
                                left: leftPosition(state.columns, state.selectedColumns, headCell.field.toLowerCase(), index),
                                zIndex: 1,
                            }),
                        }}
                        key={headCell.field}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.title}
                    </TableCell>
                ))}
                {addingColumn &&
                <TableCell
                        sx={{
                            border: 'none !important',
                            pr: '0px',
                            pl: '0px !important',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            boxSizing: 'border-box',
                            backgroundColor: colorTheme.theme.colors.teamTableHeaderBackground,
                            borderBottom: 'none',
                            width: '0',
                            paddingTop: '0px !important',
                            paddingBottom: '0px !important',
                            height: '52px',
                        }}
                        key={'addingColumn'}
                    >
                         <InputField
                            value={newColumnName || ''}
                            label={null}
                            placeholder={'Attribute Name'}
                            textFieldWidth="150px"
                            inputPadding="10px 12px"
                            lineHeight="20px"
                            inputfontSize="14px"
                            boxMarginBottom="0px"
                            labelFontSize="12px"
                            labelMarginBottom="8px"
                            onChange={(e) => setNewColumnName(e.target.value)}
                        />
                    </TableCell>}
                <TableCell
                    sx={{
                        borderTopRightRadius: '4px',
                        border: 'none !important',
                        backgroundColor: colorTheme.theme.colors.teamTableHeaderBackground,
                        minWidth: '66px',
                        position: 'sticky',
                        right: '0px',
                        zIndex: 1,
                        paddingTop: '8px !important',
                        paddingBottom: '8px !important',
                    }}
                    key='actions'
                    align="right">
                        {
                            addingColumn && (<ListsTableActions firstAction={saveColumn} secondAction={cancelAddingColumn} FirstIcon={SaveIcon} SecondIcon={CancelIcon} />)
                        }
                    </TableCell>
            </TableRow>
        </TableHead>
    )
};

export default ListsTableHead;