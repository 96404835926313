import React from "react";
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { colorTheme } from "../../assets/theme";
import { Box } from '@mui/material';


// Make styles


// ToggleSwitchValue component
const ToggleSwitchValue = ({ label, value, onChange, description }) => {


    return (
        <Box display="flex" flexDirection="column">
            <FormControlLabel
                control={
                    <Switch
                        checked={value}
                        onChange={e => onChange(e.target.checked)}


                        sx={{
                            '& .MuiSwitch-switchBase': {
                                color: '#7B7D89',
                                '&:hover': {
                                    backgroundColor: '#7B7D8933', // Add hover color for unchecked state
                                },
                            },
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#1e4675',
                                '&:hover': {
                                    backgroundColor: '#1e467533',
                                },
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: '#1e4675',
                            },
                            '& .MuiSwitch-track': {
                                backgroundColor: colorTheme.theme.transform.gray,
                            },
                        }}
                    />

                }
                label={label}

            />
            {
                description &&
                <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 400, lineHeight: '16px', color: colorTheme.theme.text.lightGrey }}>{description}</Typography>
            }
        </Box>
    );
};

export default ToggleSwitchValue;
