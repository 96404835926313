import React from "react";
import './style-edit-box.scss';
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import { Box, Typography } from '@mui/material';
import { colorTheme } from "../../assets/theme";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as SaveIcon } from "../../assets/images/actions/icon-save-2.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/actions/icon-delete.svg";
import { ReactComponent as ImageIcon } from "../../assets/images/actions/icon-image.svg";
import PrimaryButton from "../buttons/primary-button";
import TitleWithIcon from "../title/title-icon";
import TitleTooltip from "../title/title-tooltip";
import ColorBox from "./color-box";
import SaveDefaultStyle from "../dialogs/save-default-style";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(() => ({
    radio: {
        color: colorTheme.theme.text.lightGrey,
        transition: 'color 0.3s ease-in-out',
        '&.Mui-checked': {
            color: colorTheme.theme.transform.darkBlueTw + ' !important',
        },
        '&:hover': {
            color: colorTheme.theme.transform.darkBlueTwo + ' !important',
            background: 'transparent',
        },
    },
}));

// StyleEditBox component
const StyleEditBox = ({ title, Icon, surveyStyle = false, themeColors, localDefaultStyleData, saveChangesButtonVisible, handleDefaultStyleChange, handleColorPresetChange, openedSaveDialog, toggleSaveDialog, handleSaveDefaultStyleDetails, handleDefaultStyleLogoChange, file, handleSetFile, isLoading, inputFileRef }) => {
    const classes = useStyles();

    return (
        <Box className="default-style-edit-box" width={'fit-content'} style={{ padding: surveyStyle ? '0px' : '10px' }}>
            <TitleWithIcon title={title} Icon={Icon} />
            <Box className="custom-color-settings" mt={5}>
                <TitleTooltip title="Custom color settings" tooltipText="Customize the look of your survey by setting your preferred colors. The 'Main Color' is applied to the survey's header and footer. The 'Navigation Color' is used for interactive elements such as buttons, progress bars, and active page indicators." />
                <Box width="fit-content">
                    <ColorBox name="MAIN COLOR:" marginTop={2} value={surveyStyle ? localDefaultStyleData.main : localDefaultStyleData.colors.main} handleChange={surveyStyle ? e => handleDefaultStyleChange(e.target.value, 'main') : handleDefaultStyleChange('main')} />
                    <ColorBox name="NAVIGATION COLOR:" marginTop={2} value={surveyStyle ? localDefaultStyleData.highlight : localDefaultStyleData.colors.highlight} handleChange={surveyStyle ? e => handleDefaultStyleChange(e.target.value, 'highlight') : handleDefaultStyleChange('highlight')} />
                </Box>
            </Box>
            <Box className="color-presents" mt={5}>
                <TitleTooltip title="Color presets" tooltipText="Select from predefined color presets for a quick and balanced design. Each preset combines a main color and a navigation color optimized for visual appeal and user engagement." />
                <Box mt={2.5}>
                    <FormControl component="fieldset">
                        <RadioGroup row name="theme-color" value={surveyStyle ? localDefaultStyleData.value : localDefaultStyleData.colors.value} style={{ display: 'flex', gap: '16px' }} onChange={surveyStyle ? (e) => handleColorPresetChange(e.target.value) : (e) => handleColorPresetChange(themeColors.find(theme => theme.value === e.target.value))}>
                            {
                                themeColors.map((theme, index) => {
                                    const style = {
                                        background:
                                            `linear-gradient(to bottom, ${theme.main} 0%, ${theme.main} 70%, white 70%, white 74%, ${theme.highlight} 74%, ${theme.highlight} 100%)`
                                    };
                                    return (
                                        <FormControlLabel
                                            className='radio'
                                            key={index}
                                            labelPlacement="top"
                                            value={theme.value}
                                            control={<Radio
                                                className={classes.radio}
                                                style={{ padding: '0px', marginTop: '10px', width: '20px', height: '20px' }}
                                                color="secondary"
                                            />}
                                            style={{ margin: '0px' }}
                                            label={<div className='color-half' style={style} />}
                                        />
                                    )
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
            <Box className="company-logo" mt={5}>
                <TitleTooltip title="Company logo" tooltipText="Upload your company logo to personalize your surveys and enhance brand recognition." />
                <Box mt={2.5} display="flex" alignItems="center" gap={2}>
                    <Button variant="outlined" size="medium" color="primary" component="span" className='upload-button'
                        startIcon={<ImageIcon style={{ marginTop: '-1px' }} />}
                        style={{
                            padding: '10px 16px',
                            borderRadius: '30px',
                            color: colorTheme.theme.transform.darkBlueTwo,
                            border: '1px solid ' + colorTheme.theme.transform.darkBlueTwo,
                            lineHeight: '1',
                        }}
                        onClick={() => inputFileRef.current.click()}
                    >
                        Upload Logo
                    </Button>
                    <input
                        accept="image/*"
                        type="file"
                        onChange={surveyStyle ? (e) => handleSetFile(e) : handleDefaultStyleLogoChange}
                        style={{ display: 'none' }}
                        ref={inputFileRef}
                    />
                    {
                        file && !surveyStyle &&
                        <Box display="flex" alignItems="center" gap={2.5}>
                            <Typography sx={{ textDecoration: 'underline', color: colorTheme.theme.transform.darkBlueTwo }}> {file.name} </Typography>
                            <IconButton onClick={() => handleSetFile()} style={{ padding: '7px' }} className="color-picker-button">
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    }
                </Box>
            </Box>
            {
                saveChangesButtonVisible &&
                <>
                    <Box width={'fit-content'} mt={'auto'}>
                        <PrimaryButton onClick={toggleSaveDialog} text="Save changes" color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} Icon={SaveIcon} iconMargin="-1px 0px 0px" isLoading={isLoading && !openedSaveDialog} />
                    </Box>
                    <SaveDefaultStyle open={openedSaveDialog} handleClose={toggleSaveDialog} handleSaveDefaultStyleDetails={handleSaveDefaultStyleDetails} isLoading={isLoading} />
                </>
            }
        </Box>
    );
};
export default StyleEditBox;