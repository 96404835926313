import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Box } from '@mui/material';
import { ReactComponent as DeleteIcon } from "./../../assets/images/actions/icon-delete.svg";
import { ReactComponent as EditIcon } from "./../../assets/images/actions/icon-edit.svg";
import { colorTheme } from "./../../assets/theme";
import { styled } from '@mui/system';
import TooltipText from '../tooltip-text/tooltip-text';

const StyledIconButton = styled(IconButton)({
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
    padding: '7px',
    '& svg path': {
        transition: 'fill 0.3s ease',
    },
    '&:hover': {
        backgroundColor: colorTheme.theme.app.lightestGrey,
        'svg path': {
            fill: colorTheme.theme.transform.darkBlue,
        }
    },
});

// TableActions component
function TableActions({ row, onClickEdit, onClickDelete, isTeammates }) {
    const editTooltip = row.isCurrent ? "You cannot edit your role." : "Edit";
    const deleteTooltip = row.isCurrent ? "You cannot delete yourself." : "Delete";

    return (
        <>

            {/* Edit button with conditional tooltip and disabled state */}
            {isTeammates && (
                <><TooltipText name={editTooltip} placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 0px'} backgroundIcon='#eef0f4'>
                    <span> {/* Wrapper span to allow tooltip functionality when IconButton is disabled */}
                        <StyledIconButton
                            onClick={() => !row.isCurrent && onClickEdit(row)}
                            style={{ opacity: row.isCurrent ? 0.3 : 1 }}
                            disabled={row.isCurrent}
                        >
                            <EditIcon />
                        </StyledIconButton>
                    </span>
                </TooltipText><Box mr={3} sx={{ display: 'inline-block' }}></Box></>
            )}

            {/* Spacer to add gap between buttons */}
            {/* Delete button with conditional tooltip and disabled state */}
            <TooltipText name={deleteTooltip} placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 2px'}>
                <span> {/* Wrapper span to allow tooltip functionality when IconButton is disabled */}
                    <StyledIconButton
                        onClick={() => !row.isCurrent && onClickDelete(row)}
                        style={{ opacity: row.isCurrent ? 0.3 : 1 }}
                        disabled={row.isCurrent}
                    >
                        <DeleteIcon />
                    </StyledIconButton>
                </span>
            </TooltipText>
        </>
    );
}

// Define the prop types for the TableActions component
TableActions.propTypes = {
    row: PropTypes.object.isRequired, // An object representing a row in the table
    onClickEdit: PropTypes.func, // A function to handle editing a row
    onClickDelete: PropTypes.func, // A function to handle deleting a row
};

export default TableActions;
