import React from 'react';
import { Typography, Switch, FormControlLabel, Grid } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import './profile.scss';
import TwoFactorAuthDialog from '../dialogs/two-fa';
import TwoFactorAuthEnabled from '../dialogs/totp-enabled';
import useTwoFactorAuth from '../../hooks/profile/use-twa';
import CustomFormControlLabel from '../form/custom-form-control-label';


const TwoFactorAuthSection = ({ isTwoFactorAuthEnabled, onTwoFactorAuthChange, setIsTwoFactorAuthEnabled }) => {
    const {
        openDialog, openTwoFactorAuthEnabled, setOpenTwoFactorAuthEnabled,
        totpCode, setTotpCode, handleTwoFactorAuthChange, handleVerifyTOTPCode,
        isLoading, qrCodeUrl, handleCloseDialog
    } = useTwoFactorAuth(onTwoFactorAuthChange, setIsTwoFactorAuthEnabled);




    return (
        <Grid item xs={12}>
            <CustomFormControlLabel
                checked={isTwoFactorAuthEnabled}
                onChange={handleTwoFactorAuthChange}
                label="Two-Step Authentication"
                color={colorTheme.theme.transform.darkBlueTwo}
                labelFontSize={16}
                labelFontWeight={600}
                thumbColor='white'
            />

            <TwoFactorAuthDialog
                open={openDialog}
                onClose={handleCloseDialog}
                qrCodeUrl={qrCodeUrl}
                totpCode={totpCode}
                setTotpCode={setTotpCode}
                onVerify={handleVerifyTOTPCode}
                isLoading={isLoading}
            />

            <TwoFactorAuthEnabled
                open={openTwoFactorAuthEnabled}
                onClose={() => setOpenTwoFactorAuthEnabled(false)} />

            {isTwoFactorAuthEnabled ? (
                <Typography variant="body2" sx={{ width: '75%', lineHeight: '22px' }}>
                    Two-step authentication is currently enabled for your account. Each time you log in to TRANSFORM, you are required to provide two forms of identification: your password and a verification code generated by your authenticator app. This additional security measure ensures that only you can access your account, even if someone else knows your password. If you are having any difficulties, please
                    <span style={{ color: colorTheme.theme.transform.darkBlueTwo }}> contact customer support.</span>
                </Typography>
            ) : (
                <Typography variant="body2" sx={{ width: '75%', lineHeight: '22px' }}>
                    Two-step authentication is currently not enabled for your account. Enabling this feature adds an additional layer of security by requiring two forms of identification to log in: your password and a verification code from an authenticator app. This ensures that only you can access your account, providing extra protection against unauthorized access. If you are having any question, please do not hesitate to
                    <span style={{ color: colorTheme.theme.transform.darkBlueTwo }}> contact customer support.</span>
                </Typography>
            )}
        </Grid>
    );
};

export default TwoFactorAuthSection;
