// Importing useState hook from React
import { useState } from 'react';

// Custom hook to enhance table functionality
export function useSurveyTable(rows) {
    // State variables
    const [page, setPage] = useState(0); // Current page
    const [surveysPerPage, setSurveysRowsPerPage] = useState(5); // Rows per page
    const filteredRows = rows;

    // Handler to change page
    const handleChangePage = (event, newPage) => setPage(newPage);

    // Handler to change rows per page
    const handleChangeRowsPerPage = (event) => {
        setSurveysRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Calculate the number of empty rows
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * surveysPerPage - rows.length) : 0;

    // Return the state and handlers
    return {
        handleChangePage,
        handleChangeRowsPerPage,
        filteredRows,
        page,
        surveysPerPage,
        emptyRows,
    };
}