import React from 'react';
import { Box, Typography } from '@mui/material';
import { colorTheme } from '../../assets/theme';

// TitleWithIcon component to display a title with an icon
const TitleWithIcon = ({ title, Icon, fontSize = null }) => {
    return (
        <Box display="flex" alignItems="center" gap={1.5}>
            <Icon />
            <Typography variant="h5" sx={{ 
                lineHeight: '24px', 
                color: colorTheme.theme.transform.black,
                ...(fontSize && { fontSize })
                }}>
                    {title}
                </Typography>
        </Box>
    );
};

export default TitleWithIcon;