import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { set } from 'lodash';


// Constants for the password policy

const capitalRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/);

// Constants for the input types
const INPUT_EMAIL = 'EMAIL';
const INPUT_PASSWORD = 'PASSWORD';
const CONFIRMATION_CODE = 'CONFIRMATION'
const NEW_PASSWORD = 'NEW_PASSWORD'
const NEW_PASSWORD_CONFIRMATION = 'NEW_PASSWORD_CONFIRMATION'
const FAMILY_NAME = 'FAMILY_NAME'
const FIRST_NAME = 'FIRST_NAME'
const INPUT_NUMBER = 'NUMBER'

export default function useSignIn() {

    const history = useHistory(); // History hook
    const [form, setForm] = useState({}); // Form data
    const [verificationTriggered, setVerificationTrigger] = useState(false) // If the user has triggered the verification
    const [loading, setLoading] = useState(false); // If the user is in the process of signing in
    const [passwordError, setPasswordError] = useState(false); // If the password does not satisfy the policy requirements
    const [confirmPassError, setConfirmPassError] = useState(false); // If the password confirmation does not match the original password
    const [forgotPassword, setForgotPassword] = useState(false); // If the user is on the forgot password screen
    const [forgotEmail, setForgotEmail] = useState(''); // Email for the forgot password screen
    const [generalAuthError, setGeneralAuthError] = useState(''); // General error message for the sign in screen
    const [forgotError, setForgotError] = useState(''); // Error message for the forgot password screen
    const [submitedForgotRequest, setSubmitedForgotRequest] = useState(false); // If the user has submitted the forgot password request
    const [mainPage, setMainPage] = useState(true); // If the user is on the main page
    const [invitedUser, setInvitedUser] = useState(false); // If the user is invited 
    const [newUserData, setNewUserData] = useState(); // User data for the invited user
    const [mfaChallenge, setMFAChallenge] = useState(false); // If the user has a MFA challenge
    const [mfaCode, setMfaCode] = useState('');
    const [handleNewPassword, setHandleNewPassword] = useState(false);
    const [firstNameReqiured, setFirstNameRequired] = useState(false);
    const [lastNameRequired, setLastNameRequired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Snackbar hook
    const { enqueueSnackbar } = useSnackbar();



    // UseEffect to check if the user is already logged in
    useEffect(() => {
        // console.log('userData: ', props.userData)
        if (window.location.href.includes('confirmation_code')) {
            setVerificationTrigger(true)
        }
        (async () => {
            let user = await Auth.currentUserInfo()
            console.log('user logged in: ', user)
            if (user !== null) {
                history.push('/edit')
            }
        })()
    }, [])

    // UseEffect to check if the password satisfies the policy requirements
    useEffect(() => {
        if (!passwordError.error) {

        }
    }, [passwordError])

    // Function to handle the input change
    const handleChange = (text, type) => {
        // eslint-disable-next-line default-case
        switch (type) {
            case (INPUT_EMAIL):
                setForm({
                    ...form,
                    email: text
                });
                break;
            case (INPUT_PASSWORD):
                setForm({
                    ...form,
                    password: text
                });
                break;
            case (CONFIRMATION_CODE):
                setForm({
                    ...form,
                    confirmation_code: text
                })
                break;
            case (NEW_PASSWORD):
                if (text.length >= 8) {
                    isPasswordError(text);
                }
                form.new_password_confirmation && text !== form.new_password_confirmation ? setConfirmPassError(true) : setConfirmPassError(false)
                setForm({
                    ...form,
                    new_password: text
                })
                break;
            case (NEW_PASSWORD_CONFIRMATION):
                text === '' || text !== form.new_password ? setConfirmPassError(true) : setConfirmPassError(false)
                setForm({
                    ...form,
                    new_password_confirmation: text
                })
                break;
            case (FIRST_NAME):
                if (text === '') {
                    setFirstNameRequired(true)
                }
                else {
                    setFirstNameRequired(false)
                }
                setForm({
                    ...form,
                    first_name: text
                })

                break;

            case (FAMILY_NAME):
                if (text === '') {
                    setLastNameRequired(true)
                }
                else {
                    setLastNameRequired(false)
                }
                setForm({
                    ...form,
                    family_name: text
                })
                break;
            case (INPUT_NUMBER):
                setForm({
                    ...form,
                    number: text.trim()
                })
                break;
        }
    };

    // Function to sign in the user
    const signIn = async () => {
        try {
            if (form.email && form.password) {
                console.log('signing in')
                console.log('form: ', form)
                setLoading(true);

                const user = await Auth.signIn(form.email, form.password);
                console.log('user: ', user)

                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setNewUserData(user);
                    setInvitedUser(true)
                    setMainPage(false)
                    setHandleNewPassword(true)
                }
                else if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    setMainPage(false)
                    setLoading(false);
                    setNewUserData(user);
                    console.log('setNewUserData: ', setNewUserData);
                    setMFAChallenge(true);
                }
                else {
                    window.open(`http://${window.location.host}`, '_self')
                }
            } else {
                throw new Error('Email and password required')
            }
        } catch (error) {
            console.error('Sign-in error: ', error);
            setLoading(false);
            if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
                setGeneralAuthError('Email and/or Password are incorrect')
            }
            // Show an error message to the user
        }
    };

    // Function to submit the forgot password request
    const forgotPassSubmit = async () => {
        setIsLoading(true);
        setGeneralAuthError('')
        try {
            await Auth.forgotPasswordSubmit(forgotEmail, form.confirmation_code, form.new_password)
            enqueueSnackbar('Password changed successfully!', { variant: 'success' });
            setMainPage(true)
            setForgotPassword(false)

        } catch (error) {
            console.log('err: ', error)
            setGeneralAuthError(error.message)
        }
        setIsLoading(false);
    }

    // Function to resend the confirmation code
    const resendConfirmationCode = async () => {
        setIsLoading(true);
        try {
            setGeneralAuthError('');
            const resend = await Auth.forgotPassword(forgotEmail);
            console.log('code resent successfully ', resend);
            setSubmitedForgotRequest(true)
            setForgotPassword(false)

            setMainPage(false)

        } catch (err) {
            console.log('error resending code: ', err);
            setForgotError('User not found')
        }
        setIsLoading(false);
    }

    // Function to handle the forgot password button click
    const handleForgotClick = () => {
        setGeneralAuthError('');
        setForgotPassword(true)
        setHandleNewPassword(false)
        setSubmitedForgotRequest(false)
        setMainPage(false)
    }

    // Function to handle the back button on the forgot password screen
    const handleBack = () => {
        setForgotPassword(false)
        setMainPage(true)
        setMFAChallenge(false)
        setHandleNewPassword(false)
        handleForgotScreenText('');
        setForgotError('');
    }

    // Function to handle the email input for the forgot password screen
    const handleForgotScreenText = (value) => {
        setForgotEmail(value)
    }

    // Function to resend the confirmation code
    const handleSendEmail = async () => {
        const resendResponse = await resendConfirmationCode()
    }

    // Function to set the new password for the invited user
    const handleNewPass = async () => {
        setIsLoading(true);
        isPasswordError(form.new_password);
        isConfirmPasswordError(form.new_password_confirmation);

        console.log('form: ', form);

        const isFirstNameValid = form.first_name && form.first_name.trim() !== '';
        const isLastNameValid = form.family_name && form.family_name.trim() !== '';
        const isPhoneNumberValid = form.number;

        setFirstNameRequired(!isFirstNameValid);
        setLastNameRequired(!isLastNameValid);

        if (!passwordError.error && !confirmPassError.error && isFirstNameValid && isLastNameValid) {
            try {
                // Complete the new password challenge
                await Auth.completeNewPassword(newUserData, form.new_password);
                console.log('Password updated successfully');

                // Prepare attributes to update
                const attributesToUpdate = {
                    'given_name': form.first_name,
                    'family_name': form.family_name,
                };

                if (form.number !== '') {
                    attributesToUpdate.phone_number = form.number.replace(/\s+/g, '');

                }

                // Update user attributes
                console.log('attributesToUpdate: ', attributesToUpdate);
                const res = await Auth.updateUserAttributes(newUserData, attributesToUpdate);
                console.log('User attributes updated successfully:', res);

                // Redirect to the home page
                window.open(`http://${window.location.host}`, '_self');
                setIsLoading(false);
            } catch (err) {
                console.log('Error updating user attributes:', err);
            }
        }
    };

    // Function to check if the password satisfies the policy requirements
    const isPasswordError = (password) => {
        if (!password || password === '') {
            setPasswordError({
                error: true,
                errorMessage: 'Required'
            })
        } else if (!capitalRegex.test(password)) {
            setPasswordError({
                error: true,
                errorMessage: 'Your Password does not satisfy the Current Policy Requirements'
            })
        } else {
            setPasswordError({
                error: false,
                errorMessage: ''
            })
        }
    };

    const isRequired = () => {

        setPasswordError({
            error: true,
            errorMessage: 'Required'
        })

    }

    // Function to check if the repeat password exists
    const isConfirmPasswordError = (password) => {
        if (!password || password === '') {
            setConfirmPassError({
                error: true,
                errorMessage: 'Required'
            })
        }
    };

    // Function to redirect to the register page
    const handleRegisterRedirect = () => {
        window.open(`/register`, '_self')
    }

    // Function to handle the verification of the MFA code
    const handleVerifyMfa = async () => {
        setLoading(true);
        try {
            setGeneralAuthError('');
            // Consider removing console logs in production
            console.log('mfaCode: ', mfaCode);
            const user = await Auth.signIn(form.email, form.password);
            await Auth.confirmSignIn(
                user, // the CognitoUser object
                mfaCode, // the TOTP code entered by the user
                'SOFTWARE_TOKEN_MFA' // MFA challenge type
            );

            // Redirect using React-friendly methods if available

        } catch (error) {
            console.error('Failed to verify MFA code:', error);
            setGeneralAuthError('Failed to verify MFA code. Please try again.');
        } finally {
            window.open(`http://${window.location.host}`, '_self');
            setLoading(false);

        }
    };



    return {
        form,
        loading,
        passwordError,
        confirmPassError,
        generalAuthError,
        forgotPassword,
        forgotEmail,
        forgotError,
        submitedForgotRequest,
        mainPage,
        invitedUser,
        mfaChallenge,
        handleChange,
        signIn,
        handleForgotClick,
        handleBack,
        handleForgotScreenText,
        handleSendEmail,
        handleNewPass,
        handleRegisterRedirect,
        resendConfirmationCode,
        verificationTriggered,
        forgotPassSubmit,
        mfaCode,
        setMfaCode,
        handleNewPassword,
        setHandleNewPassword,
        handleVerifyMfa,
        INPUT_EMAIL,
        INPUT_PASSWORD,
        CONFIRMATION_CODE,
        NEW_PASSWORD,
        NEW_PASSWORD_CONFIRMATION,
        FAMILY_NAME,
        FIRST_NAME,
        INPUT_NUMBER,
        firstNameReqiured,
        lastNameRequired,
        isLoading


    };

}

