// Import necessary hooks and functions
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { updateCognitoUserAction } from "../../redux/actions";
import { fetchUserDetails } from '../../utils/fetch-user';

// Define a custom hook for editing user profile
export function useEditProfile(userData, onCancel) {
    // Define state variables for user data
    const [firstName, setFirstName] = useState(userData.firstName);
    const [lastName, setLastName] = useState(userData.lastName);
    const [email, setEmail] = useState(userData.email);
    const [phoneNumber, setPhoneNumber] = useState(userData.phone_number);

    // Get dispatch function from Redux
    const dispatch = useDispatch();

    // Get cognitoUsers from Redux state
    const cognitoUsers = useSelector(state => state.cognitoUsers);

    // Get enqueueSnackbar function from notistack
    const { enqueueSnackbar } = useSnackbar();

    // When userData changes, update state variables
    useEffect(() => {
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setEmail(userData.email);
        setPhoneNumber(userData.phone_number);
    }, [userData]);

    // Define a function to save user data
    const saveUserData = async () => {
        // Prepare attributes for update
        const attributes = {
            firstName,
            lastName,
            email,
            phoneNumber
        };

        try {
            // Dispatch action to update user in Cognito
            await dispatch(updateCognitoUserAction(email, attributes, cognitoUsers));

            // Fetch updated user details
            fetchUserDetails(dispatch, enqueueSnackbar);

            // Call onCancel callback
            onCancel();

            // Show success message
            enqueueSnackbar('User data updated successfully', { variant: 'success' });
        } catch (error) {
            // Show error message
            enqueueSnackbar('Error updating user data', { variant: 'error' });
        }
    };

    // Return state variables and functions
    return {
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        saveUserData
    };
}