import React from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { colorTheme } from '../../assets/theme';

// CustomRadioLabel component
const CustomRadioLabel = ({ value, label, checked, fontSize = '14px', customKey = null, disabled = false }) => {
    return (
        <FormControlLabel
            className='radio'
            labelPlacement="end"
            value={value}
            key={customKey}
            control={
                <Radio
                    checked={checked}
                    disabled={disabled} // Prosljeđivanje disabled propa
                    style={{ padding: '0px', marginTop: '0px', width: '20px', height: '20px' }}
                    sx={{
                        color: colorTheme.theme.transform.darkBlueTwo,
                        '&.Mui-checked': {
                            color: colorTheme.theme.transform.darkBlueTwo,
                        },
                    }}
                />
            }
            sx={{
                margin: '0px',
                gap: '8px',
                color: colorTheme.theme.transform.black,
                '& .MuiFormControlLabel-label': {
                    fontSize: fontSize,
                },
            }}
            label={label}
        />
    );
};

export default CustomRadioLabel;
