import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadSurveyData } from "../../../../redux/actions";
import { Box } from '@mui/material';
import { ReactComponent as SettingsIcon } from '../../../../assets/images/title/settings.svg';
import { colorTheme } from "../../../../assets/theme";
import TitleWithIcon from "../../../../components/title/title-icon";
import TitleEditTab from "../../../../components/title/title-edit-tab";
import PrimaryButton from '../../../../components/buttons/primary-button';
import axios from "axios";

const ResultsContainer = () => {
    const dispatch = useDispatch(); // Dispatch function from Redux
    const currentSurvey = useSelector(state => state.currentSurvey); // Current survey data
    const downloadUrl = useSelector(state => state.downloadUrl); // Get the downloadUrl state from the redux store
    const [urlFetched, setUrlFetched] = useState(false); // State to check if the download URL is fetched

    // Function to handle the download button click
    const handleDownloadButton = () => {
        dispatch(downloadSurveyData(currentSurvey.id, currentSurvey.id));
        setUrlFetched(true);
    }

    // useEffect hook to download the survey data when the download url is fetched
    useEffect(() => {
        if (downloadUrl && urlFetched) {
            const FileDownload = require('js-file-download');
            let axiosInstance = axios.create();

            delete axiosInstance.defaults.headers.common["Authorization"];
            axiosInstance({
                url: downloadUrl.data,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                FileDownload(response.data, 'zipped_survey_docs.zip');
                setUrlFetched(false);
            });

        }
    }, [downloadUrl])

    return (
        <Box className='settings-container'>
            <TitleWithIcon title='Results' Icon={SettingsIcon} />
            <Box className='download-page'>
                <Box mt={5}>
                    {
                        currentSurvey.status !== 'DRAFT' ?
                            <>
                                <TitleEditTab title='Download questions and responses of published survey' marginBottom={3} />
                                <PrimaryButton text='Download survey data' color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} onClick={handleDownloadButton} />
                            </>
                            :
                            <TitleEditTab title="Survey isn't published." />
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default ResultsContainer;