import React from "react";
import { TableRow, TableCell } from '@mui/material';
import { ReactComponent as CancelIcon } from '../../assets/images/actions/icon-x.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/actions/icon-save-3.svg';
import { ACTIVE_STATUS_COLUMN } from "../../assets/constants";
import { leftPosition } from '../../utils/sticky-column';
import ListsTableActions from "./lists-table-actions";
import InputField from '../../components/form/input-field-label';
import StatusColumn from './status-column';

// ListsTableAddingRow component
const ListsTableAddingRow = ({ state, addingRow, setAddingRow, confirmAddingRow, cancelAddingRow, respondentList}) => {
    return (
        <TableRow>
            {state.columns.filter(headCell => headCell.field !== 'tableData' && state.selectedColumns.includes(headCell.field)).map((headCell, index) => (
                <TableCell
                    key={headCell.field}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sx={{
                        border: 'none',
                        pr: '24px',
                        pl: '0px !important',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        boxSizing: 'border-box',
                        borderBottom: 'none',
                        borderTop: '1px solid #E0E0E0',
                        background: 'white',
                        width: '0',
                        paddingTop: '0px !important',
                        paddingBottom: '0px !important',
                        ...(index === 0 && { // If the index is 0, set the padding-left to 24px
                            pl: '24px !important',
                        }),
                        ...(respondentList && {  // If the respondentList is true, set 'Name' and 'Survey Progress' columns to sticky
                            ...([ACTIVE_STATUS_COLUMN.field.toLowerCase(), 'name'].includes(headCell.field.toLowerCase()) && { pl: '24px !important ' }),
                            position: [ACTIVE_STATUS_COLUMN.field.toLowerCase(), 'name'].includes(headCell.field.toLowerCase()) ? 'sticky' : 'static',
                            left: leftPosition(state.columns, state.selectedColumns, headCell.field.toLowerCase(), index),
                            zIndex: 1,
                        }),
                    }}
                >
                    {
                        headCell.field !== ACTIVE_STATUS_COLUMN.field ? (
                            <InputField
                                value={addingRow[headCell.field] || ''}
                                label={null}
                                placeholder={headCell.title}
                                textFieldWidth="100%"
                                inputPadding="10px 12px"
                                lineHeight="20px"
                                inputfontSize="14px"
                                boxMarginBottom="0px"
                                labelFontSize="12px"
                                labelMarginBottom="8px"
                                onChange={(e) => setAddingRow({
                                    ...addingRow,
                                    [headCell.field]: e.target.value,
                                })}
                            />
                        ) : (<StatusColumn status={'NOT-SENT'} />)
                    }
                </TableCell>
            ))}
            <TableCell
                sx={{
                    pr: '16px',
                    borderBottom: 'none',
                    borderTop: '1px solid #E0E0E0',
                    background: 'white',
                    position: 'sticky',
                    right: '0px',
                    zIndex: 1,
                    paddingTop: '10px !important',
                    paddingBottom: '10px !important',
                }}
                align="right"
            >
                <ListsTableActions firstAction={confirmAddingRow} secondAction={cancelAddingRow} FirstIcon={SaveIcon} SecondIcon={CancelIcon} />
            </TableCell>
        </TableRow>
    )
};

export default ListsTableAddingRow;