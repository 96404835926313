import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Slider, Box, Grid, ListItem, IconButton, Typography } from "@material-ui/core";
import List from '@material-ui/core/List';
import { Fab } from '@mui/material';
import './multi-slider-question.scss';
import AdvancedSettings from "../../question-advanced-settings";
import { updateQuestion } from "../../../../../../redux/actions";
import { isStringDigits, isError, handleRemoveButton } from "../questionUtil";
import { colorTheme } from '../../../../../../assets/theme';
import CustomNumberTextField from "../../../../../../components/form/question-number-input";
import TextField from '@mui/material/TextField';
import { ReactComponent as DeleteIcon } from "../../../../../../assets/images/actions/delete-bin.svg";
import { ReactComponent as CollaboratorIcon } from "../../../../../../assets/images/actions/plus-btn.svg";
import TooltipIcon from "../../../../../../components/tooltip-text/tooltip-icon";

const uuid = require('uuid');

const MultiSliderQuestion = ({ template, handleAdvancedView, advancedSettings }) => {
    const question = template;
    console.log('MultiSliderQuestion:', question);
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);

    // Initialize slidersList with three default sliders
    const initialSliders = [
        { item_id: uuid.v4(), item_text: 'Option 1', item_order: 0, item_value: 0 },
        { item_id: uuid.v4(), item_text: 'Option 2', item_order: 1, item_value: 0 },
        { item_id: uuid.v4(), item_text: 'Option 3', item_order: 2, item_value: 0 }
    ];

    const [sliderValue, setSliderValue] = useState(0);
    const [slidersList, setSlidersList] = useState(initialSliders);
    const [allRequired, setAllRequired] = useState(true);

    const [sliderDetails, setSliderDetails] = useState({
        items_min: '0',
        items_max: '10',
        items_increment: '1'
    });

    useEffect(() => {
        // Set slider details from the template or use defaults
        setSliderDetails({
            items_min: question.items_min || '0',
            items_max: question.items_max || '10',
            items_increment: question.items_increment || '1',
            items_default_value: question.items_default_value || 0,
            all_required: question.all_required !== undefined ? question.all_required : true
        });

        if (question.items_default_value) {
            setSliderValue(question.items_default_value);
        }

        // Check if items exist in the question
        if (question.items) {
            console.log('Items found in question:', question.items);
            setSlidersList(question.items);
        } else {
            console.log('No items found in question, initializing with default sliders');
            setSlidersList(initialSliders);
            updateMultiSliderQuestion({ items: initialSliders });
        }
    }, [question]);


    const delayedUpdate = useCallback(_.debounce(question =>
        dispatch(updateQuestion(currentSurvey.id, question)), 500),
        [dispatch, currentSurvey.id]);

    const updateMultiSliderQuestion = (value) => {
        let newQuestion = _.cloneDeep(question);
        const newObject = Object.assign(newQuestion, value);
        delayedUpdate(newObject);
    };

    const handleSliderDetails = ({ value, name }) => {
        setSliderDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
        if (isStringDigits(value)) {
            updateMultiSliderQuestion({ [name]: Number(value) });
        }
    };

    const handleSliderAdd = () => {
        const newSliderList = [
            ...slidersList,
            {
                item_text: '',
                item_id: uuid.v4(),
                item_order: slidersList.length,
                item_value: 0
            }
        ];
        setSlidersList(newSliderList);
        updateMultiSliderQuestion({ items: newSliderList });
    };

    const handleSliderText = (index) => e => {
        let temp = [...slidersList];
        temp[index].item_text = e.target.value;
        setSlidersList(temp);
        updateMultiSliderQuestion({ items: temp });
    };

    const handleSliderValue = index => (e, value) => {
        let temp = [...slidersList];
        temp[index].item_value = value;
        setSlidersList(temp);
        updateMultiSliderQuestion({ items: temp });
    };

    const handleSliderChange = () => {
        updateMultiSliderQuestion({ items: slidersList });
    };

    const handleRemoveSlider = (id) => {
        const newList = slidersList.filter(slider => slider.item_id !== id);
        setSlidersList(newList);
        updateMultiSliderQuestion({ items: newList });
    };

    const handleAllRequiredSwitch = (checked) => {
        updateMultiSliderQuestion({ all_required: checked });
        setAllRequired(checked);
    };

    function generateMarks(min, max, increment) {
        const marks = [];
        for (let value = min; value <= max; value += increment) {
            marks.push({
                value,
                label: value === min || value === max ? `${value}` : ''
            });
        }
        return marks;
    }

    const min = Number(sliderDetails.items_min) || 0;
    const max = Number(sliderDetails.items_max) || 10;
    const increment = Number(sliderDetails.items_increment) || 1;
    const marks = generateMarks(min, max, increment);

    return (
        <div className='component-container'>
            <Box className='multi-slider-question-container'>
                <Box sx={{ display: 'flex', gap: '24px', mb: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                        <Typography component="label">Minimum value</Typography>
                        <CustomNumberTextField
                            name='items_min'
                            value={sliderDetails.items_min}
                            onChange={e => handleSliderDetails(e.target)}
                            error={isError(sliderDetails.items_min)}

                        />
                        <TooltipIcon title={'Minimum value'} content={'Sets the lowest possible value for the slider. This is the starting point of the scale. Default is 1.'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                        <Typography component="label">Maximum value</Typography>
                        <CustomNumberTextField
                            name='items_max'
                            value={sliderDetails.items_max}
                            onChange={e => handleSliderDetails(e.target)}
                            error={isError(sliderDetails.items_max)}

                        />
                        <TooltipIcon title={'Maximum value'} content={'Sets the highest possible value for the slider. This defines the end point of the scale. Default is 10.'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                        <Typography component="label">Slider increments</Typography>
                        <CustomNumberTextField
                            name='items_increment'
                            value={sliderDetails.items_increment}
                            onChange={e => handleSliderDetails(e.target)}
                            error={isError(sliderDetails.items_increment)}

                        />
                        <TooltipIcon title={'Slider increments'} content={'Determines the step size between each value on the slider. Smaller increments allow for finer granularity in responses. Default increment is 1.'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                    </Box>
                </Box>
                <Grid item xs={10}>
                    <List>
                        {slidersList.map((slider, index) => (
                            <ListItem pl={0} className='slider-list-item' key={slider.item_id}>

                                <TextField
                                    id="outlined-helperText"
                                    value={slider.item_text}
                                    onChange={handleSliderText(index)}

                                    label={`Option ${index + 1}`}
                                    placeholder="Type here"
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    sx={{
                                        width: '213px',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#D7D7D7', // Default border color
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#D7D7D7', // Border color on hover
                                            },
                                            '&.MuiInputBase-multiline': {
                                                padding: '0',        // Adjust padding for multiline input
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#4F4F4F', // Change the outline color on hover
                                                border: '1px solid #4F4F4F', // Make the border 1px solid
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#1B4972', // Change the outline color when focused
                                                borderWidth: 1,
                                            },
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#1B4972',
                                        },
                                    }}
                                />
                                <Box width='576px' mt={3}>
                                    <Slider
                                        value={slider.item_value}
                                        getAriaValueText={(value) => `${value}`}
                                        aria-labelledby="discrete-slider-small-steps"
                                        step={increment}
                                        marks={marks}
                                        min={min}
                                        max={max}
                                        valueLabelDisplay='on'
                                        onChange={handleSliderValue(index)}
                                        onChangeCommitted={handleSliderChange}
                                        width='569px'
                                    />
                                </Box>
                                <Box display="flex" ml={-1}>
                                    <IconButton className="icon-button-end" onClick={() => handleRemoveSlider(slider.item_id)} >
                                        <DeleteIcon />
                                    </IconButton>

                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0 }}>
                    <Fab variant="extended" size="small" onClick={handleSliderAdd}
                        className='add-option-hover'
                        style={
                            {
                                borderRadius: '30px',
                                color: '#1e4675',
                                border: '1.5px solid #1e4675',
                                height: '30px',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                marginBottom: '32px',
                                gap: '5px',
                                '&:hover': {
                                    backgroundColor: 'red', // Change this to the desired hover color
                                },

                            }
                        }
                    >

                        <CollaboratorIcon />
                        Add Option
                    </Fab>
                </Box>
            </Box>
            {advancedSettings && (
                <AdvancedSettings defaultValue={handleSliderValue} setView={handleAdvancedView} template={question} />
            )}
        </div>
    );
};

export default MultiSliderQuestion;
