export default [
	{
		question_text: "DISCOVERING THE COMPANY NETWORK",
		element_type: 'section',
		section_description: "<p>We are in the process of exploring ways to make KAFD Leadership more \nagile, responsive, and collaborative to the organization and the \nemployee’s needs.   </p><p>This is a short survey to visualize how we work in \nour Leadership-teams. Your input, expertise and experience are important\n to strengthen our Leadership insights and agility. This survey won’t \ntake more than 10 minutes.  If you are having any problems, please \ncontact us on this email.   </p><p>The support team: support@Transform.com.sa  </p><p>Thank you for your inputs.</p>",
		element_order: 1,
		style_type: null,
	},
	{
		items_min_number: 1,
		element_type: 'multi_select',
		required: true,
		question_text: 'CONSENT',
		style_type: 'text',
		page_break: true,
		items: [
			{
				item_id: 'multi-item-id-1',
				item_text: 'Yes, I want to participate in the survey',
				item_order: 1
			}
		],
		element_order: 2,
		remove_numbering: true,
		question_explanation_string: 'Press YES to consent that the data you provide to us can be processed to show how network analytics provide unique insights. REMEMBER that you need to press the YES button below to move on. The data will be personally identifiable and is therefore treated confidentially. Participation is completely voluntary. If you consent, you can revoke your consent at any time. If you do not wish to consent, you will end the survey HERE. '
	},
	{
		element_type: 'text_box',
		max_characters: 250,
		max_characters_allowed: true,
		page_break: true,
		element_order: 3,
		question_text: "How would you describe the culture of KAF?"
	},
	{
		element_type: 'multi_slider_single_picker',
		items_source_type: 'QUESTION',
		items_input: true,
		items_max_text: "High",
		items_name_field: 'item_text',
		required: true,
		items_min_text: "Low",
		element_order: 4,
		question_text: "Indicate how strong your competence is in your chosen expert areas?",
		items_default_value: 0,
		items_min: 0,
		items_increment: 1,
		item_other_allowed: 0,
		items_max: 10,
		page_break: true,
		question_explanation_string: "Consider your chosen skills listed below, indicate your level at each of them.",
		all_required: true
	},
	{
		element_type: 'single_slider_single_picker',
		item_default_value: 0,
		item_max: 10,
		item_max_text: "Very good at sharing",
		item_min: 0,
		item_min_text: "Not good at sharing",
		required: true,
		element_order: 5,
		question_text: "How good are the other leaders to sharing material (documents, presentations, etc.) with you?",
		item_min: 0,
		item_increment: 1,
		page_break: true,
	},
	{
		element_type: 'search_choose',
		item_add_text: "Add person",
		item_other_allowed: true,
		items_max_number: 3,
		required: true,
		relationship: true,
		text_in_button_switch: true,
		text_in_response_switch: true,
		element_order: 6,
		item_fields: ["Name"],
		items: [{ item_id: "item_id_0", Name: "Placeholder" }],
		question_text: "Who are the key 3 leaders who affect the culture inside KAFD the most?",
		question_explanation_string: "Choose up to 3 colleagues",
		page_break: false,
	},
	{
		question_text: "DISCOVERING THE COMPANY NETWORK",
		element_type: 'section',
		section_description: "<p>We are in the process of exploring ways to make KAFD Leadership more \nagile, responsive, and collaborative to the organization and the \nemployee’s needs.   </p><p>This is a short survey to visualize how we work in \nour Leadership-teams. Your input, expertise and experience are important\n to strengthen our Leadership insights and agility. This survey won’t \ntake more than 10 minutes.  If you are having any problems, please \ncontact us on this email.   </p><p>The support team: support@Transform.com.sa  </p><p>Thank you for your inputs.</p>",
		element_order: 1,
		style_type: null,
	},
	{
		items_min_number: 1,
		element_type: 'multi_select',
		required: true,
		question_text: 'CONSENT',
		style_type: 'text',
		page_break: true,
		items: [
			{
				item_id: 'multi-item-id-1',
				item_text: 'Yes, I want to participate in the survey',
				item_order: 1
			}
		],
		element_order: 2,
		remove_numbering: true,
		question_explanation_string: 'Press YES to consent that the data you provide to us can be processed to show how network analytics provide unique insights. REMEMBER that you need to press the YES button below to move on. The data will be personally identifiable and is therefore treated confidentially. Participation is completely voluntary. If you consent, you can revoke your consent at any time. If you do not wish to consent, you will end the survey HERE. '
	},
	{
		element_type: 'text_box',
		max_characters: 250,
		max_characters_allowed: true,
		page_break: true,
		element_order: 3,
		question_text: "How would you describe the culture of KAF?"
	},
	{
		element_type: 'multi_slider_single_picker',
		items_source_type: 'QUESTION',
		items_input: true,
		items_max_text: "High",
		items_name_field: 'item_text',
		required: true,
		items_min_text: "Low",
		element_order: 4,
		question_text: "Indicate how strong your competence is in your chosen expert areas?",
		items_default_value: 0,
		items_min: 0,
		items_increment: 1,
		item_other_allowed: 0,
		items_max: 10,
		page_break: true,
		question_explanation_string: "Consider your chosen skills listed below, indicate your level at each of them.",
		all_required: true
	},
	{
		element_type: 'single_slider_single_picker',
		item_default_value: 0,
		item_max: 10,
		item_max_text: "Very good at sharing",
		item_min: 0,
		item_min_text: "Not good at sharing",
		required: true,
		element_order: 5,
		question_text: "How good are the other leaders to sharing material (documents, presentations, etc.) with you?",
		item_min: 0,
		item_increment: 1,
		page_break: true,
	},
	{
		element_type: 'search_choose',
		item_add_text: "Add person",
		item_other_allowed: true,
		items_max_number: 3,
		required: true,
		relationship: true,
		text_in_button_switch: true,
		text_in_response_switch: true,
		element_order: 6,
		item_fields: ["Name"],
		items: [{ item_id: "item_id_0", Name: "Placeholder" }],
		question_text: "Who are the key 3 leaders who affect the culture inside KAFD the most?",
		question_explanation_string: "Choose up to 3 colleagues",
		page_break: false,
	},
	{
		question_text: "DISCOVERING THE COMPANY NETWORK",
		element_type: 'section',
		section_description: "<p>We are in the process of exploring ways to make KAFD Leadership more \nagile, responsive, and collaborative to the organization and the \nemployee’s needs.   </p><p>This is a short survey to visualize how we work in \nour Leadership-teams. Your input, expertise and experience are important\n to strengthen our Leadership insights and agility. This survey won’t \ntake more than 10 minutes.  If you are having any problems, please \ncontact us on this email.   </p><p>The support team: support@Transform.com.sa  </p><p>Thank you for your inputs.</p>",
		element_order: 1,
		style_type: null,
	},
	{
		items_min_number: 1,
		element_type: 'multi_select',
		required: true,
		question_text: 'CONSENT',
		style_type: 'text',
		page_break: true,
		items: [
			{
				item_id: 'multi-item-id-1',
				item_text: 'Yes, I want to participate in the survey',
				item_order: 1
			}
		],
		element_order: 2,
		remove_numbering: true,
		question_explanation_string: 'Press YES to consent that the data you provide to us can be processed to show how network analytics provide unique insights. REMEMBER that you need to press the YES button below to move on. The data will be personally identifiable and is therefore treated confidentially. Participation is completely voluntary. If you consent, you can revoke your consent at any time. If you do not wish to consent, you will end the survey HERE. '
	},
	{
		element_type: 'text_box',
		max_characters: 250,
		max_characters_allowed: true,
		page_break: true,
		element_order: 3,
		question_text: "How would you describe the culture of KAF?"
	},
	{
		element_type: 'multi_slider_single_picker',
		items_source_type: 'QUESTION',
		items_input: true,
		items_max_text: "High",
		items_name_field: 'item_text',
		required: true,
		items_min_text: "Low",
		element_order: 4,
		question_text: "Indicate how strong your competence is in your chosen expert areas?",
		items_default_value: 0,
		items_min: 0,
		items_increment: 1,
		item_other_allowed: 0,
		items_max: 10,
		page_break: true,
		question_explanation_string: "Consider your chosen skills listed below, indicate your level at each of them.",
		all_required: true
	},
	{
		element_type: 'single_slider_single_picker',
		item_default_value: 0,
		item_max: 10,
		item_max_text: "Very good at sharing",
		item_min: 0,
		item_min_text: "Not good at sharing",
		required: true,
		element_order: 5,
		question_text: "How good are the other leaders to sharing material (documents, presentations, etc.) with you?",
		item_min: 0,
		item_increment: 1,
		page_break: true,
	},
	{
		element_type: 'search_choose',
		item_add_text: "Add person",
		item_other_allowed: true,
		items_max_number: 3,
		required: true,
		relationship: true,
		text_in_button_switch: true,
		text_in_response_switch: true,
		element_order: 6,
		item_fields: ["Name"],
		items: [{ item_id: "item_id_0", Name: "Placeholder" }],
		question_text: "Who are the key 3 leaders who affect the culture inside KAFD the most?",
		question_explanation_string: "Choose up to 3 colleagues",
		page_break: false,
	},
	{
		question_text: "DISCOVERING THE COMPANY NETWORK",
		element_type: 'section',
		section_description: "<p>We are in the process of exploring ways to make KAFD Leadership more \nagile, responsive, and collaborative to the organization and the \nemployee’s needs.   </p><p>This is a short survey to visualize how we work in \nour Leadership-teams. Your input, expertise and experience are important\n to strengthen our Leadership insights and agility. This survey won’t \ntake more than 10 minutes.  If you are having any problems, please \ncontact us on this email.   </p><p>The support team: support@Transform.com.sa  </p><p>Thank you for your inputs.</p>",
		element_order: 1,
		style_type: null,
	},
	{
		items_min_number: 1,
		element_type: 'multi_select',
		required: true,
		question_text: 'CONSENT',
		style_type: 'text',
		page_break: true,
		items: [
			{
				item_id: 'multi-item-id-1',
				item_text: 'Yes, I want to participate in the survey',
				item_order: 1
			}
		],
		element_order: 2,
		remove_numbering: true,
		question_explanation_string: 'Press YES to consent that the data you provide to us can be processed to show how network analytics provide unique insights. REMEMBER that you need to press the YES button below to move on. The data will be personally identifiable and is therefore treated confidentially. Participation is completely voluntary. If you consent, you can revoke your consent at any time. If you do not wish to consent, you will end the survey HERE. '
	},
	{
		element_type: 'text_box',
		max_characters: 250,
		max_characters_allowed: true,
		page_break: true,
		element_order: 3,
		question_text: "How would you describe the culture of KAF?"
	},
	{
		element_type: 'multi_slider_single_picker',
		items_source_type: 'QUESTION',
		items_input: true,
		items_max_text: "High",
		items_name_field: 'item_text',
		required: true,
		items_min_text: "Low",
		element_order: 4,
		question_text: "Indicate how strong your competence is in your chosen expert areas?",
		items_default_value: 0,
		items_min: 0,
		items_increment: 1,
		item_other_allowed: 0,
		items_max: 10,
		page_break: true,
		question_explanation_string: "Consider your chosen skills listed below, indicate your level at each of them.",
		all_required: true
	},
	{
		element_type: 'single_slider_single_picker',
		item_default_value: 0,
		item_max: 10,
		item_max_text: "Very good at sharing",
		item_min: 0,
		item_min_text: "Not good at sharing",
		required: true,
		element_order: 5,
		question_text: "How good are the other leaders to sharing material (documents, presentations, etc.) with you?",
		item_min: 0,
		item_increment: 1,
		page_break: true,
	},
	{
		element_type: 'search_choose',
		item_add_text: "Add person",
		item_other_allowed: true,
		items_max_number: 3,
		required: true,
		relationship: true,
		text_in_button_switch: true,
		text_in_response_switch: true,
		element_order: 6,
		item_fields: ["Name"],
		items: [{ item_id: "item_id_0", Name: "Placeholder" }],
		question_text: "Who are the key 3 leaders who affect the culture inside KAFD the most?",
		question_explanation_string: "Choose up to 3 colleagues",
		page_break: false,
	}
];
