import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { updateQuestion } from "../../redux/actions";

// Custom hook for managing advanced settings of a question
const useQuestionAdvancedSettings = (question) => {
    const dispatch = useDispatch(); // Access the Redux dispatch function
    const currentSurvey = useSelector(state => state.currentSurvey); // Access the current survey state from Redux

    // State for tracking if numeration is checked
    const [numerationChecked, setNumerationChecked] = useState(false);
    // State for tracking if short question name is checked
    const [shortQuestionNameChecked, setShortQuestionNameChecked] = useState(false);
    // State for storing the question export name
    const [questionExportName, setQuestionExportName] = useState('');

    // Effect hook to set initial state based on the question prop
    useEffect(() => {
        if (question.question_export_name !== undefined) {
            setShortQuestionNameChecked(true);
            if (question.question_export_name.length !== 0) {
                setQuestionExportName(question.question_export_name);
            }
        }
        if (question.remove_numbering === false) {
            setNumerationChecked(true);
        }
    }, [question]); // Re-run the effect if the question prop changes

    // Debounced function for updating the question to prevent rapid state updates
    const delayedUpdate = useCallback(_.debounce(updatedQuestion =>
        dispatch(updateQuestion(currentSurvey.id, updatedQuestion)), 1000), [dispatch, currentSurvey.id]);

    // Function to update advanced question fields
    const updateAdvancedQuestionFields = (value) => {
        const updatedObject = Object.assign(question, value);
        delayedUpdate(updatedObject);
    };

    // Handler for changing numeration setting
    const handleNumerationChange = () => {
        const updateObject = { remove_numbering: !numerationChecked };
        setNumerationChecked(prev => !prev);
        updateAdvancedQuestionFields(updateObject);
    };

    // Handler for toggling the short question name setting
    const handleShortQuestionNameSwitch = (checked) => {
        setShortQuestionNameChecked(!shortQuestionNameChecked);
        const updateObject = checked ? { question_export_name: questionExportName } : { question_export_name: undefined };
        updateAdvancedQuestionFields(updateObject);
    };

    // Handler for changing the short question text
    const handleShortQuestionText = (inputValue) => {
        setQuestionExportName(inputValue);
        updateAdvancedQuestionFields({ question_export_name: inputValue });
    };

    // Return state and handlers for use in components
    return {
        numerationChecked,
        shortQuestionNameChecked,
        questionExportName,
        handleNumerationChange,
        handleShortQuestionNameSwitch,
        handleShortQuestionText
    };
};

export default useQuestionAdvancedSettings;