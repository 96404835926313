import React from "react";
import './default-style-container.scss';
import { Box } from '@mui/material';
import ThemePreview from "../../../components/theme-preview/theme-preview";
import themeColors from "../../../assets/theme-colors";
import StyleEditBox from "../../../components/style-edit-box/style-edit-box";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import useDefaultStyle from "../../../hooks/default-style/use-default-style";
import { ReactComponent as StyleIcon } from "../../../assets/images/title/style.svg";

// DefaultStyleContainer component to render the default style tab
const DefaultStyleContainer = () => {
    
    const { 
        localDefaultStyleData,
        saveChangesButtonVisible,
        pathSegments,
        handleDefaultStyleChange,
        handleColorPresetChange,
        openedSaveDialog,
        toggleSaveDialog,
        handleSaveDefaultStyleDetails,
        handleDefaultStyleLogoChange,
        file,
        handleSetFile,
        inputFileRef,
        isLoading
    } = useDefaultStyle(); // Use the useDefaultStyle hook to get the default style data

    return (
        <Box mt={3} width={'100%'}>
            {/* Render the Breadcrumbs component with the defined path segments */}
            <Breadcrumbs pathSegments={pathSegments} />
            <Box mt={2.5}>
                <Box className="default-style-container" display="flex" flexDirection="row" justifyContent="space-between" maxWidth={'1260px'}>
                    <StyleEditBox title="Default Style" Icon={StyleIcon} themeColors={themeColors} localDefaultStyleData={localDefaultStyleData} saveChangesButtonVisible={saveChangesButtonVisible} handleDefaultStyleChange={handleDefaultStyleChange} handleColorPresetChange={handleColorPresetChange} openedSaveDialog={openedSaveDialog} toggleSaveDialog={toggleSaveDialog} handleSaveDefaultStyleDetails={handleSaveDefaultStyleDetails} handleDefaultStyleLogoChange={handleDefaultStyleLogoChange} file={file} handleSetFile={handleSetFile} inputFileRef={inputFileRef} isLoading={isLoading}/>
                    <ThemePreview themeColor={localDefaultStyleData.colors} companyLogo={localDefaultStyleData.picture} />
                </Box>      
            </Box>
        </Box>
    );
}
export default DefaultStyleContainer;