import React from 'react';
import './signin-page.scss';
import BrandingPanel from '../components/branding-panel/branding-panel';
import SignIn from '../components/sign-in/sign-in';
import { Box } from '@mui/system';

// SigninPage component
function SigninPage(props) {
    return (
        <Box className="signin-page">
            <BrandingPanel />
            <SignIn />
        </Box>
    )
}

export default SigninPage;

