import { useEffect, useState } from 'react';

export const useTeammateAttributes = (user) => {
    const [licenses, setLicenses] = useState(user?.licences);
    const [name, setName] = useState('Awaiting Info...');

    console.log(user)

    useEffect(() => {

        const givenName = user?.Attributes.find(attr => attr.Name === 'given_name')?.Value;
        const familyName = user?.Attributes.find(attr => attr.Name === 'family_name')?.Value;
        setLicenses(user?.licences)
        setName(`${givenName || ''} ${familyName || 'Awaiting Info...'}`.trim());
        console.log('user', user)

    }, [user]);

    return { licenses, name };
}