import React from 'react';
import { Box } from '@mui/material';
import InputField from '../form/input-field-label';
import PrimaryButton from '../buttons/primary-button';
import { colorTheme } from '../../assets/theme';
import PhoneInputField from '../form/phone-input-field-label';
import { ReactComponent as SaveIcon } from "./../../assets/images/actions/icon-save.svg";
import { useEditProfile } from '../../hooks/profile/use-edit-profile';

const EditProfileInfo = ({ userData, onCancel }) => {
    // Destructure functions and states from useEditProfile hook
    const {
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        saveUserData
    } = useEditProfile(userData, onCancel);


    // Render editable fields with save and cancel buttons
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            gap: 2,
            ml: 5,
            '@media (max-width:1200px)': {
                flexDirection: 'column',
            }
        }}>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                '@media (max-width:1200px)': {
                    width: '100%',
                }
            }}>
                {/* Name */}
                <InputField
                    label="Name"
                    textFieldWidth={'300px'}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    sx={{
                        '@media (max-width:1200px)': {
                            width: '100%',
                        }
                    }}
                />

                {/* Email */}
                <InputField
                    label="Email"
                    textFieldWidth={'300px'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                        '@media (max-width:1200px)': {
                            width: '100%',
                        }
                    }}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                '@media (max-width:1200px)': {
                    width: '100%',
                }
            }}>
                {/* Surname */}
                <InputField
                    label="Surname"
                    textFieldWidth={'300px'}
                    value={lastName}
                    mb={2}
                    onChange={(e) => setLastName(e.target.value)}
                    sx={{
                        '@media (max-width:1200px)': {
                            width: '100%',
                        }
                    }}
                />
                {/* Phone */}
                <Box mt={0.6}>
                    <PhoneInputField value={phoneNumber} onChange={setPhoneNumber} width={'297px'} marginBottom={'5px'} inputPadding={'13.5px 13px'} />
                </Box>
            </Box>
            <Box sx={{
                position: { sm: 'absolute' },
                display: 'flex',
                right: 0,
                top: 0,
                mt: { xs: 2, sm: 0 },
                mr: { sm: 2 },
                '@media (max-width:1200px)': {
                    position: 'relative',
                    mt: 2,
                    mr: 0,
                }
            }}>
                <Box mr={2}>
                    {/* Cancel Button */}
                    <PrimaryButton onClick={
                        onCancel
                    } color="white" hoverColor="#EDF0F4" text="Cancel" hoverText={colorTheme.theme.transform.darkBlue} />
                </Box>
                {/* Save Button */}
                <PrimaryButton
                    text="Save"
                    Icon={SaveIcon}
                    color={colorTheme.theme.transform.darkBlueTwo}
                    hoverColor={colorTheme.theme.transform.darkBlue}
                    onClick={saveUserData}
                />

            </Box>
        </Box>
    );
};

export default EditProfileInfo;