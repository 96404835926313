import React from 'react';
import './survey-counter.scss';
import { Box, Typography } from '@material-ui/core';
import TextButton from '../buttons/text-button';

// SurveyCounter component
const SurveyCounter = (props) => {
    const { displayCount, surveysToShow, setDisplayCount, numberToShow } = props;

    // Function to load more surveys
    const handleLoadMore = () => {
        setDisplayCount(displayCount + numberToShow < surveysToShow.length ? displayCount + numberToShow : surveysToShow.length)
    };

    // Function to show less surveys
    const handleShowLess = () => {
        setDisplayCount(displayCount > numberToShow ? Math.max(displayCount - numberToShow, numberToShow) : displayCount);
    }

    return (
        <Box className='survey-counter'>
        <Typography variant="body2" className="survey-counter-text">Showing <span>{displayCount}</span> out of <span>{surveysToShow.length}</span> surveys.</Typography>
        {
            surveysToShow.length < numberToShow ? null :
            (displayCount < surveysToShow.length ? 
                <TextButton name="Load more" onClick={handleLoadMore} size="big" /> :
                <TextButton name="Show less" onClick={handleShowLess} size="big" />)
        }
        </Box>
    )
}
 
export default SurveyCounter;