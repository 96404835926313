// Function to calculate the left position of the sticky column
export function leftPosition (columns, selectedColumns, columnName, index){
    if(columnName.toLowerCase() === 'name' && selectedColumns.map(column => column.toLowerCase()).includes('survey_const_active_status_column') && columns.map(column => column.field.toLowerCase()).indexOf('survey_const_active_status_column') < index){
        return '151px';
    }else if(columnName.toLowerCase() === 'name'){
        return '0px';
    }else if(columnName.toLowerCase() === 'survey_const_active_status_column' && columns.map(column => column.field.toLowerCase()).indexOf('name') > index){
        return '0px';
    }else if(columnName.toLowerCase() === 'survey_const_active_status_column'){
        return '161px';
    }
}