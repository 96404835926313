import { useState, useEffect } from 'react';

// This hook is used to manage the state and behavior of the Edit/Add User Dialog
export function useEditAddUserDialog(selectedRow, handleClose, handleAction, setInsightsLicense, setSurveyLicense, setRole) {
    // State for the license, initialized with the computedRole of the selected row or an empty string
    const [license, setLicense] = useState(selectedRow?.computedRole || '');
    // State for the email, initialized with the email of the selected row or an empty string
    const [email, setEmail] = useState(selectedRow?.email || '');

    const [isLoading, setIsLoading] = useState(false);

    const [roleDescription, setRoleDescription] = useState('Select a role to view its description');

    const [roleTitle, setRoleTitle] = useState('');



    // Effect to update the email and license state when the selected row changes
    useEffect(() => {
        setEmail(selectedRow?.email || '');
        setLicense(selectedRow?.computedRole || '');
        setRoleDescription(licenseRoles[selectedRow?.computedRole]?.description || 'Select a role to view its description');
    }, [selectedRow]);

    // Function to handle saving changes, closes the dialog and performs the action with the current email
    const handleSaveChanges = async () => {
        setIsLoading(true);
        const res = await handleAction(email);
        handleClose();
        setIsLoading(false);
    };

    // Mapping of license types to their corresponding surveyLicense, insightsLicense, and role values
    const licenseRoles = {
        'Owner': { surveyLicense: 'true', insightsLicense: 'true', role: 'owner', description: "Has full control over the entire platform, including access to all workspace and dashboard settings, and all apps. Can manage other users' roles and has the authority to modify all settings.", roleTitle: 'Owner' },
        'Survey Admin': { surveyLicense: 'true', insightsLicense: 'false', role: 'survey', description: "Responsible for managing the Survey app. Has the authority to create, edit, and delete surveys, manage survey settings, and view all survey data.", roleTitle: 'Survey Admin' },
        'Insights Admin': { surveyLicense: 'false', insightsLicense: 'true', role: 'insights', description: "Responsible for managing the Insights app. Has the authority to access and analyze all insights data, manage settings within the Insights app, and modify report configurations.", roleTitle: 'Insights Admin' },
        'Survey & Insights Admin': { surveyLicense: 'true', insightsLicense: 'true', role: 'all', description: "Combines the roles of Survey Admin and Insights Admin. Has full access to both the Survey and Insights apps, including their settings, data management, and user permissions within these apps.", roleTitle: 'Survey & Insights Admin' },
    };

    // Function to handle changes to the license, updates the license state and the corresponding surveyLicense, insightsLicense, and role states
    const handleLicenseChange = (newLicense) => {
        setLicense(newLicense);
        const roleInfo = licenseRoles[newLicense];
        if (roleInfo) {
            setRoleDescription(roleInfo.description);
            setSurveyLicense(roleInfo.surveyLicense);
            setInsightsLicense(roleInfo.insightsLicense);
            setRole(roleInfo.role);
            setRoleTitle(roleInfo.roleTitle);
        }
    };

    // Return the state and handlers to be used by the component
    return { email, setEmail, license, handleLicenseChange, handleSaveChanges, roleDescription, isLoading, roleTitle };
}