import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { MenuItem, Radio } from "@material-ui/core";
import { Box, Typography } from '@mui/material';
import _ from "lodash";
import UploadListFromQuestion from "../../upload-list-from-question";
import { updateQuestion } from "../../../../../../redux/actions";
import { getListName, mapMenuItems, getListId } from "../../list-utils";
import ToggleSwitchValue from "../../../../../../components/form/toogle-switch-value";
import './single-select-question.scss';
import { isError } from "../questionUtil";
import CustomTextField from "../../../../../../components/form/questions-text-field";
import Select from '@mui/material/Select';
import { Divider } from "@material-ui/core";
import ListPreviousQuestions from "../../list-previous-questions";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { ITEMS_SOURCE_TYPE_LIST, ITEMS_SOURCE_TYPE_QUESTION, LIST_RADIO, QUESTION_RADIO } from '../../../../../../assets/constants';

const uuid = require('uuid');
const LIST = 'LIST';
const IMAGE_STYLE = 'image';

const SingleSelectAdvSettings = (props) => {
    let { template } = props;
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);
    const question = props.template;
    const [textInRespondentAnswerSwitch, setTextInRespondentAnswerSwitch] = useState(false);
    const [textInRespondentAnswerField, setRespondentAnswerField] = useState('');
    const [optionsSourceSwitch, setOptionsSourceSwitch] = useState(false);
    const [selectedList, setSelectedList] = useState('');
    const [mappedLists, setMappedLists] = useState([]);
    const [itemsNameField, setItemsNameField] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [allowUserAnswerSwitch, setAllowUserAnswerSwitch] = useState(false);
    const [numberOfAllowedAnswers, setNumberOfAllowedAnswers] = useState('1');
    const [isEnabled, setIsEnabled] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedRadio, setSelectedRadio] = useState('');

    useEffect(() => {
        if (currentSurvey.lists) {
            setMappedLists(mapMenuItems(currentSurvey, mappedList, 'custom'));
        }
        if (currentSurvey.respondents) {
            setMappedLists(mapMenuItems(currentSurvey, mappedList, 'respondents'));
            console.log(mappedLists);
        }
        if (template.items_source) {
            setSelectedList(getListId(template.items_source, currentSurvey.lists, currentSurvey.id));
            setOptionsSourceSwitch(true);
        }
        if (template.item_placeholder_text !== undefined) {
            setTextInRespondentAnswerSwitch(true);
            if (template.item_placeholder_text.length !== 0) {
                setRespondentAnswerField(template.item_placeholder_text);
            }
        }
        if (template.item_other_allowed === true) {
            setAllowUserAnswerSwitch(true);
            if (template.items_other_allowed) {
                setNumberOfAllowedAnswers(template.items_other_allowed);
            }
        }
        if (template.answer_source) {
            setIsEnabled(template.answer_source);
        }
        if (template.items_source_type === LIST) {
            setSelectedRadio(LIST_RADIO);
        } else if (template.items_source_type === ITEMS_SOURCE_TYPE_QUESTION) {
            setSelectedRadio(QUESTION_RADIO);
        }
    }, [template, currentSurvey]);

    let mappedList = [];

    const delayedUpdate = useCallback(_.debounce(question =>
        dispatch(updateQuestion(currentSurvey.id, question)), 300),
        [dispatch, currentSurvey.id]);

    const updateSingleSelectAdvancedSettings = (question) => {
        let newQuestion = _.cloneDeep(template);
        const newObject = Object.assign(newQuestion, question);
        delayedUpdate(newObject);
    };


    const handleTextInResponseInput = (inputValue) => {
        updateSingleSelectAdvancedSettings({ item_placeholder_text: inputValue });
        setRespondentAnswerField(inputValue);
    };

    const handleListSelect = ({ value }) => {
        const selectedList = currentSurvey.lists?.find(list => list.id === value) ||
            { columns: currentSurvey.respondents['columns'], data: currentSurvey.respondents['data'] };

        if (selectedList && selectedList.columns && selectedList.data) {
            const firstColumn = selectedList.columns.find(column => column.title !== 'tableData' && column.title !== 'Status')?.title;
            if (firstColumn) {
                const newOptions = selectedList.data.map((dataItem, index) => ({
                    item_id: uuid.v4(),
                    item_text: dataItem[firstColumn] || `Option ${index + 1}`,
                    order: index
                }));
                setOptions(newOptions);

                updateSingleSelectAdvancedSettings({
                    items_source_type: LIST,
                    items_source: getListName(value, mappedLists),
                    items_source_id: value,
                    items: newOptions,
                    answer_source: true
                });
            }
        }

        setSelectedList(value);
    };

    const handleAnswersSwitch = (checked) => {
        let updateObject = checked ? { item_other_allowed: true, items_other_allowed: Number(numberOfAllowedAnswers) } : { item_other_allowed: false, items_other_allowed: undefined };
        updateSingleSelectAdvancedSettings(updateObject);
        setAllowUserAnswerSwitch(!allowUserAnswerSwitch);
    };


    const handleSelectMenuChange = (event) => {
        const { checked, value } = event.target;
        let updateObject = {}
        if (value === QUESTION_RADIO) {
            updateObject = checked ? { items_source_type: ITEMS_SOURCE_TYPE_QUESTION } : { items_source_type: undefined, items_input: undefined }
        } else if (value === LIST_RADIO) {
            updateObject = checked ? { items_source_type: ITEMS_SOURCE_TYPE_LIST, items_input: getListName(selectedList, mappedLists) } : { items_source_type: undefined, items_input: undefined }
        }
        setSelectedRadio(value);  // Update the selected radio state
        updateSingleSelectAdvancedSettings(updateObject);
    };

    const handleToggleSwitch = (checked) => {
        setIsEnabled(checked);
        if (!checked) {
            updateSingleSelectAdvancedSettings({
                answer_source: false,
                items_source_type: undefined,
                items_source: undefined,
                items_source_id: undefined,
                items: []
            });
            setSelectedList('');
            setSelectedQuestion('');
            setSelectedRadio('');  // Unselect both radio buttons
        } else {
            updateSingleSelectAdvancedSettings({ answer_source: true });
        }
    }

    const handleListUploaded = (newList) => {
        const updatedMappedLists = [...mappedLists, newList];
        setMappedLists(updatedMappedLists);
        setSelectedList(newList.id);

        const firstColumn = newList.columns.find(column => column.title !== 'tableData' && column.title !== 'Status')?.title;
        if (firstColumn) {
            const newOptions = newList.data.map((dataItem, index) => ({
                item_id: uuid.v4(),
                item_text: dataItem[firstColumn] || `Option ${index + 1}`,
                order: index
            }));
            setOptions(newOptions);

            updateSingleSelectAdvancedSettings({
                items_source_type: LIST,
                items_source: getListName(newList.id, updatedMappedLists),
                items_source_id: newList.id,
                items: newOptions,
                answer_source: true
            });
        }
    };

    if (question.style_type !== IMAGE_STYLE) {
        return (
            <div className='advanced-settings-single-select'>
                <Box>
                    <Box ml={1.5} mt={-1.5}>
                        <FormControlLabel
                            control={
                                <ToggleSwitchValue
                                    label='Allow respondents to provide their own answer.'
                                    value={allowUserAnswerSwitch}
                                    onChange={handleAnswersSwitch}
                                    description={'Enable this to allow respondents to input their own answer.'}
                                />
                            }
                        />
                    </Box>
                    <Box mt={2.5}
                        className={!allowUserAnswerSwitch ? 'disabled' : ''}
                    >
                        <Typography
                            sx={{ fontSize: '15px', color: '#4F4F4F', marginBottom: '12px' }}
                        >Customize Input Field Placeholder:</Typography>
                        <CustomTextField
                            disabled={!allowUserAnswerSwitch}
                            placeholder="Enter placeholder text (e.g., 'Type your answer here')"
                            value={textInRespondentAnswerField}
                            onChange={e => handleTextInResponseInput(e.target.value)}
                            width='391px'
                        />
                    </Box>
                    <Divider
                        style={{
                            marginRight: '20px',
                            marginBottom: '20px',
                            marginTop: '32px',
                        }} />

                    <Box>
                        <ToggleSwitchValue
                            label='Set Answer Source'
                            value={isEnabled}
                            onChange={handleToggleSwitch}
                            description='Enable this feature to specify where the answers for this question will come from. Choose to either link to existing lists, upload a new list, or use answers from a previous question.'
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: -0.5, gap: '4px' }}
                        className={!isEnabled ? 'disabled' : ''}
                    >
                        <FormControlLabel
                            ml={0}
                            control={
                                <Radio
                                    checked={selectedRadio === LIST_RADIO}
                                    value={LIST_RADIO}
                                    onChange={handleSelectMenuChange}
                                    name='options-source-switch'

                                    sx={{
                                        '& .MuiRadio-root': {
                                            color: '#D7D7D7', // Default color
                                            '&.Mui-checked': {
                                                color: '#1c4972', // Color when checked
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent', // Remove hover effect
                                            },
                                        },
                                    }}
                                />
                            }
                            label='Use a List'
                            className='options-source-switch'
                        />

                        <FormControl variant="outlined" style={{ width: '174px' }}>
                            <InputLabel
                                sx={{
                                    '&.Mui-focused': {
                                        color: '#1B4972', // Color of the label when the select is focused
                                        top: '0px', // Adjust the label position when focused
                                    },
                                    '&.MuiInputLabel-shrink': {
                                        top: '0px', // Adjust the label position when shrink
                                    },
                                    top: '-3px',
                                }}>
                                Select list
                            </InputLabel>
                            <Select
                                labelId='source-option-select-label'
                                id='source-option-select'
                                value={selectedList || ''}
                                input={<OutlinedInput label="Select List" />}
                                onChange={e => handleListSelect(e.target)}
                                disabled={selectedRadio !== LIST_RADIO}  // Disable based on radio button state
                                sx={{
                                    '.MuiSelect-select': {
                                        padding: '13px', // Padding of the select
                                        backgroundColor: '#FFFFFF',
                                    },
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#D7D7D7', // Default border color
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1B4972', // Border color on hover
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1B4972', // Border color when focused
                                        borderWidth: '1px', // Optionally, adjust the border width when focused
                                    },
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    PaperProps: {
                                        style: {
                                            marginLeft: 0,
                                        },
                                    },
                                }}


                            >         {mappedLists && mappedLists.length > 0 ? (
                                mappedLists.map((listItem, index) => (
                                    <MenuItem key={index} value={listItem.id}>
                                        {listItem.title}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem
                                    onClick={(e) => e.preventDefault()}
                                    sx={{
                                        pointerEvents: 'none',
                                        display: 'block',
                                        whiteSpace: 'normal',
                                        textAlign: 'left',
                                        padding: '10px',
                                        '&:hover': {
                                            backgroundColor: 'transparent', // Prevents background color change on hover
                                        }

                                    }}
                                >
                                    <Typography sx={{
                                        color: '#4F4F4F',
                                        pointerEvents: 'none',
                                    }}>
                                        No uploaded lists on this survey.
                                        <br />
                                        Please click on Upload New List button or upload list on
                                        <span
                                            style={{ textDecoration: 'none', color: '#1B4972', marginLeft: '5px', pointerEvents: 'auto', cursor: 'pointer' }}
                                        >
                                            Lists Page
                                        </span>.
                                    </Typography>
                                </MenuItem>
                            )}
                            </Select>
                        </FormControl>
                        <Typography variant="body1" ml={2} sx={{ color: '#4F4F4F' }}>or</Typography>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} pt={4} mb={0} ml={2}
                            className={!isEnabled ? 'disabled' : ''}
                        >
                            <UploadListFromQuestion disabled={!isEnabled || question.items_source_type !== ITEMS_SOURCE_TYPE_LIST}
                                onListUploaded={handleListUploaded}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 0, gap: '4px' }}
                        className={!isEnabled ? 'disabled' : ''}
                    >
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={selectedRadio === QUESTION_RADIO}
                                    value={QUESTION_RADIO}
                                    onChange={handleSelectMenuChange}
                                    name='questions-source-switch'
                                    sx={{
                                        '& .MuiRadio-root': {
                                            color: '#D7D7D7', // Default color
                                            '&.Mui-checked': {
                                                color: '#1c4972', // Color when checked
                                            },
                                            '&:hover': {
                                                backgroundColor: 'transparent', // Remove hover effect
                                            },
                                        },
                                    }}
                                />
                            }
                            label='Use Previous Answers'
                            className='options-source-switch'
                        />


                        <ListPreviousQuestions
                            source={question.items_source_type}
                            questionId={question.id}
                            questions={currentSurvey.questions}
                            setItemsNameField={setItemsNameField}
                            setSelectedQuestion={setSelectedQuestion}
                            sourceQuestion={selectedQuestion}
                            disabled={!isEnabled || question.items_source_type !== ITEMS_SOURCE_TYPE_QUESTION}
                        />

                    </Box>
                </Box>
            </div >
        )
    }
    return null;
};

export default SingleSelectAdvSettings;
