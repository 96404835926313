import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Grid from '@material-ui/core/Grid';
import ListItem from "@material-ui/core/ListItem";
import { Fab } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from "../../../../../../assets/images/actions/delete-bin.svg";
import { ReactComponent as CollaboratorIcon } from "../../../../../../assets/images/actions/plus-btn.svg";
import { ReactComponent as UploadIcon } from "../../../../../../assets/images/actions/upload-image.svg";
import TextField from '@mui/material/TextField';
import { getS3Credentials, saveCurrentQuestionId, updateQuestion, uploadFile } from "../../../../../../redux/actions";
import './single-select-question.scss';
import AdvancedSettings from '../../question-advanced-settings';
import { Select, FormControl } from '@mui/material';


const uuid = require('uuid');
const TEXT_STYLE = 'text';
const TEXT_BUTTON_STYLE = 'text_button';
const IMAGE_STYLE = 'image';

function SingleSelectQuestion({ template, handleAdvancedView, advancedSettings }) {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);
    const uploadData = useSelector(state => state.uploadData);
    const imageUrl = useSelector(state => state.logoUrl);
    const questionId = useSelector(state => state.questionId);
    const [image, setImage] = useState(null);
    const [imagesList, setImagesList] = useState([]);
    const [numberOfAllowedAnswers, setNumberOfAllowedAnswers] = useState('1');
    const [menuItemOption, setMenuItemOption] = useState(TEXT_STYLE);
    const [allowUserAnswerSwitch, setAllowUserAnswerSwitch] = useState(false);
    const [options, setOptions] = useState([
        { item_id: uuid.v4(), item_text: 'Option 1', order: 0 },
        { item_id: uuid.v4(), item_text: 'Option 2', order: 1 },
        { item_id: uuid.v4(), item_text: 'Option 3', order: 2 }
    ]);

    const isFirstRun = useRef(true);
    const instanceId = useRef(uuid.v4()).current; // Unique identifier for this instance

    const delayedUpdate = useCallback(_.debounce(question =>
        dispatch(updateQuestion(currentSurvey.id, question)), 600),
        [dispatch, currentSurvey.id]);

    const resetOptionsToInitial = () => {
        setOptions([
            { item_id: uuid.v4(), item_text: 'Option 1', order: 0 },
            { item_id: uuid.v4(), item_text: 'Option 2', order: 1 },
            { item_id: uuid.v4(), item_text: 'Option 3', order: 2 }
        ]);
        updateSingleSelectQuestion({ items: options });

    };

    useEffect(() => {
        if (template.style_type) {
            setMenuItemOption(template.style_type);
        } else {
            setMenuItemOption(TEXT_STYLE);
            if (!template.items) {
                resetOptionsToInitial();

            }
        }

        if (template.style_type === IMAGE_STYLE && template.items) {
            setImagesList(template.items);
        } else if (template.style_type === IMAGE_STYLE && !template.items) {
            const initialItems = [
                { item_id: uuid.v4(), item_url: '', item_text: '' },
                { item_id: uuid.v4(), item_url: '', item_text: '' },
                { item_id: uuid.v4(), item_url: '', item_text: '' }
            ];
            setImagesList(initialItems);
            updateSingleSelectQuestion({ items: initialItems });
        }

        if ((template.style_type === TEXT_STYLE || template.style_type === TEXT_BUTTON_STYLE) && template.items) {
            setOptions(template.items);

        }
        else if ((template.style_type === TEXT_STYLE || template.style_type === TEXT_BUTTON_STYLE) && !template.items) {
            updateSingleSelectQuestion({ items: options });
        }

        if (template.item_other_allowed === true) {
            setAllowUserAnswerSwitch(true);
            if (template.items_other_allowed) {
                setNumberOfAllowedAnswers(template.items_other_allowed);
            }
        }

        // if (!template.answer_source) {
        //     resetOptionsToInitial();
        // }

    }, [template]);

    useEffect(() => {
        if (image) {
            dispatch(uploadFile(uploadData, image.file));
        }
    }, [uploadData]);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (imageUrl && questionId === template.id) {
            const updatedImagesList = [...imagesList];
            updatedImagesList[image.index].item_url = imageUrl;
            setImagesList(updatedImagesList);
            updateSingleSelectQuestion({ items: updatedImagesList });
        }
    }, [imageUrl]);

    const updateSingleSelectQuestion = (question) => {
        let newQuestion = _.cloneDeep(template);
        const newObject = Object.assign(newQuestion, question);
        delayedUpdate(newObject);
    };

    const onImage = async (event, index) => {
        const file = event.target.files[0];
        try {
            dispatch(getS3Credentials(file.name + uuid.v1(), file.type));
            setImage({ file, index });
        } catch (error) {
            console.log('Error on upload ' + error);
        }
    };

    const handleStyleMenuChange = (value) => {
        let initialItems = [];
        if (value === IMAGE_STYLE) {
            initialItems = [
                { item_id: uuid.v4(), item_url: '', item_text: '' },
                { item_id: uuid.v4(), item_url: '', item_text: '' },
                { item_id: uuid.v4(), item_url: '', item_text: '' }
            ];

            setImagesList(initialItems);
        } else {
            initialItems = [
                { item_id: uuid.v4(), item_text: 'Option 1', order: 0 },
                { item_id: uuid.v4(), item_text: 'Option 2', order: 1 },
                { item_id: uuid.v4(), item_text: 'Option 3', order: 2 }
            ];
            setOptions(initialItems);
        }
        let newQuestion = _.cloneDeep(template);
        newQuestion.style_type = value;
        newQuestion.items = initialItems;
        setMenuItemOption(value);
        updateSingleSelectQuestion(newQuestion);
    };

    const handleTextField = (index) => e => {
        let temp = [...options];
        temp[index].item_text = e.target.value;
        setOptions(temp);
        updateSingleSelectQuestion({ items: temp });
    };

    const handleImagesText = (index) => e => {
        let temp = [...imagesList];
        temp[index].item_text = e.target.value;
        setImagesList(temp);
        updateSingleSelectQuestion({ items: temp });
    };

    const handleOptionRemove = (id) => {
        let filteredData;
        if (menuItemOption === IMAGE_STYLE) {
            filteredData = imagesList.filter(item => item.item_id !== id);
            setImagesList(filteredData);
        } else {
            filteredData = options.filter(item => item.item_id !== id);
            setOptions(filteredData);
        }
        updateSingleSelectQuestion({ items: filteredData });
    };

    const handleOptionAdd = () => {
        if (menuItemOption === IMAGE_STYLE) {
            const newImageList = [
                ...imagesList,
                {
                    item_id: uuid.v4(),
                    item_url: '',
                    item_text: ''
                }
            ];
            setImagesList(newImageList);
            updateSingleSelectQuestion({ items: newImageList });
        } else {
            const newOptionsList = [
                ...options,
                {
                    item_text: `Option ${options.length + 1}`,
                    item_id: uuid.v4(),
                    order: options.length
                }
            ];
            setOptions(newOptionsList);
            updateSingleSelectQuestion({ items: newOptionsList });
        }
    };


    const handleUploadButton = (index) => {
        dispatch(saveCurrentQuestionId(template.id));
        document.getElementById(`upload-image-${instanceId}-${index}`).click();
    };

    const displayImages = () => {
        return (
            <Box>
                <Typography mt={3}>
                    Answers:
                </Typography>
                <Box className="image-box">
                    {imagesList.map((image, index) => (
                        <ListItem
                            className="image-list-item"
                            key={image.item_id}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px', padding: '10px', borderRadius: '8px', width: 'max-content' }}
                        >
                            <Box pb={0} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <Typography style={{ fontSize: '14px' }} pb={0} color="#4F4F4F">Option {index + 1}</Typography>

                                <IconButton id="image-delete" onClick={() => handleOptionRemove(image.item_id, imagesList, setImagesList)} edge="end">
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                            {image.item_url ? (
                                <Box className="upload-placeholder" style={{ width: '213px', height: '124px' }}>
                                    <img src={image.item_url} alt="description" className="image" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </Box>


                            ) : (
                                <Box
                                    className="upload-placeholder"
                                    onClick={() => handleUploadButton(index)}
                                    style={{ width: '213px', height: '124px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
                                >
                                    <UploadIcon />
                                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', color: '#1B4972', fontWeight: '500', marginTop: '1.5px' }}>
                                        UPLOAD IMAGE
                                    </Typography>
                                </Box>
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => onImage(e, index)}
                                onClick={(e) => (e.target.value = '')}
                                className="upload-input"
                                id={`upload-image-${instanceId}-${index}`}
                                hidden
                            />
                            <TextField
                                className='image-text-input-field'
                                id="outlined-helperText"
                                variant="outlined"
                                value={image.item_text}
                                onChange={handleImagesText(index)}
                                placeholder="Type here"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                    style: {
                                        color: '#D7D7D7', // Default label color

                                    },
                                }}
                                style={{ width: '215px', height: '48px' }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#D7D7D7', // Default border color


                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#4F4F4F', // Border color on hover
                                        },
                                        '&.MuiInputBase-multiline': {
                                            padding: '0', // Adjust padding for multiline input

                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#D7D7D7', // Default border color
                                            border: '1px solid #D7D7D7', // Default border color', // Make the border 1px solid
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#1B4972', // Change the outline color when focused
                                            borderWidth: 1,
                                        },
                                    },
                                }}
                            />
                        </ListItem>
                    ))}
                </Box>
            </Box>
        );
    };

    const uploadImageView = () => {
        return (
            <div className="upload-image">
                {displayImages()}
                <Box mt={3}>
                    <Fab variant="extended" size="small"
                        className='add-option-hover'
                        style={
                            {
                                borderRadius: '30px',
                                color: '#1e4675',
                                border: '1.5px solid #1e4675',
                                height: '30px',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                marginBottom: '32px',
                                gap: '5px',
                                '&:hover': {
                                    backgroundColor: 'red', // Change this to the desired hover color
                                },

                            }
                        }

                        onClick={handleOptionAdd}
                    >
                        <CollaboratorIcon />

                        Add Option

                    </Fab>
                </Box>
            </div>
        );
    };

    const addOptionsView = () => {
        return (
            <Grid item >
                <Typography mt={3}>
                    Answers:
                </Typography>
                <Box className={menuItemOption === TEXT_BUTTON_STYLE ? 'text-button-box' : ''}>
                    {options.map((option, index) => (
                        <ListItem key={option.item_id} className={menuItemOption === TEXT_BUTTON_STYLE ? 'text-button-option' : ''}>
                            {menuItemOption !== TEXT_BUTTON_STYLE && (
                                <IconButton className='icon-button-start' edge='start' color='inherit' aria-label='drag' size='small'>
                                    <RadioButtonUncheckedIcon color='action' fontSize='inherit' />
                                </IconButton>
                            )}
                            <TextField
                                id="outlined-helperText"
                                onChange={handleTextField(index)}
                                value={option.item_text}
                                label={`Option ${index + 1}`}
                                placeholder="Type here"
                                variant="outlined"
                                size="small"
                                className={menuItemOption === TEXT_BUTTON_STYLE ? 'text-button-field' : ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#D7D7D7', // Default border color
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#D7D7D7', // Border color on hover
                                        },
                                        '&.MuiInputBase-multiline': {
                                            padding: '0',        // Adjust padding for multiline input
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#4F4F4F', // Change the outline color on hover
                                            border: '1px solid #4F4F4F', // Make the border 1px solid
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#1B4972', // Change the outline color when focused
                                            borderWidth: 1,
                                        },
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: '#1B4972',
                                    },
                                }}
                            />
                            <Box mr={2}>
                                <IconButton onClick={() => {
                                    handleOptionRemove(option.item_id);
                                }}
                                    className='icon-button-end' edge='end' aria-label='delete'>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </ListItem>
                    ))}
                </Box>
                <Box mt={3}>
                    <Fab variant="extended" size="small" onClick={handleOptionAdd}
                        className='add-option-hover'
                        style={
                            {
                                borderRadius: '30px',
                                color: '#1e4675',
                                border: '1.5px solid #1e4675',
                                height: '30px',
                                backgroundColor: '#FFFFFF',
                                boxShadow: 'none',
                                marginBottom: '32px',
                                gap: '5px',
                                '&:hover': {
                                    backgroundColor: 'red', // Change this to the desired hover color
                                },

                            }
                        }
                    >
                        <CollaboratorIcon />

                        Add Option
                    </Fab>
                </Box>
            </Grid>
        );
    };

    const selectView = () => {
        return menuItemOption === IMAGE_STYLE ? uploadImageView() : addOptionsView();
    };

    return (
        <div className='single-select-question-container'>
            {(menuItemOption === TEXT_STYLE || menuItemOption === TEXT_BUTTON_STYLE) && (
                <div className='style-section'>
                    <Typography width='auto' color={'#4F4F4F'} mr={2}>
                        Select Button Style for Answers
                    </Typography>
                    <FormControl>
                        <Select
                            value={menuItemOption}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={e => handleStyleMenuChange(e.target.value)}
                            sx={{
                                '.MuiSelect-select': {
                                    padding: '13px', // Padding of the select
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#D7D7D7', // Default border color
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1B4972', // Border color on hover
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1B4972', // Border color when focused
                                    borderWidth: '1px', // Optionally, adjust the border width when focused
                                },
                            }}
                        >
                            <MenuItem value={TEXT_STYLE}> Radio button </MenuItem>
                            <MenuItem value={TEXT_BUTTON_STYLE}> Text button </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            )}
            <div>
                {selectView()}
                {advancedSettings && <AdvancedSettings setView={handleAdvancedView} template={template} />}
            </div>
        </div>
    );
}

export default SingleSelectQuestion;
