import React from 'react';
import { Box, Typography } from '@mui/material';
import { Paper, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useListsTable } from '../../hooks/survey/use-lists-table'
import { CUSTOM_LIST_FILE } from "../../assets/constants";
import { colorTheme } from "../../assets/theme";
import { ReactComponent as SearchIcon } from '../../assets/images/survey-dashboard/search-icon.svg';
import { ReactComponent as DeleteIcon2 } from '../../assets/images/actions/icon-delete-2.svg';
import { ReactComponent as AddRowIcon } from '../../assets/images/actions/icon-add-row.svg';
import { ReactComponent as AddColumnIcon } from '../../assets/images/actions/icon-add-column.svg';
import ListsTableHead from './lists-table-head';
import ListsTableAddingRow from './lists-table-adding-row';
import ListsTableRow from './lists-table-row';
import ListsTableEmptyRow from './lists-table-empty-row';
import TextField from "@material-ui/core/TextField";
import InputSearch from '../../components/input-search/input-search';
import TextButton from '../../components/buttons/text-button';
import ExportMenu from './export-menu';
import ColumnsMenu from './columns-menu';
import SortMenu from './sort-menu';
import ErrorText from '../info/error-text';

// ListsTable component
function ListsTable(props) {

    const {
        state,
        title,
        searchQuery,
        rowsPerPageOptionsNumbers,
        deletingRow,
        page,
        rowsPerPage,
        editingRow,
        newRespondent,
        sortOrder,
        addingRow,
        addingColumn,
        newColumnName,
        newColumnRef,
        setNewColumnName,
        cancelAddingColumn,
        saveColumn,
        startAddingColumn,
        setAddingRow,
        initiateEdit,
        confirmEdit,
        cancelEdit,
        handleNewRespondentChange,
        handleChangePage,
        handleChangeRowsPerPage,
        initiateDelete,
        confirmDelete,
        cancelDelete,
        handleSearchQueryChange,
        handleColumnOrderChange,
        handleColumnSelectionChange,
        handleSortOrderChange,
        handleTitleChange,
        startAddingRow,
        confirmAddingRow,
        cancelAddingRow
    } = useListsTable(props); // Get table state and functions from custom table hook

    return (
        <Box>
            <Paper
                className='team-table-paper'
                sx={{ width: '100%', mb: 0, boxShadow: 'none' }}>
                {(state.data.length > 0 || (props.type === 'CUSTOM_LIST_FILE' && props.updateData !== null)) &&
                    <Box display='flex' mt={4} alignItems='flex-end' gap='16px'>
                        <Box>
                            {
                                props.type == CUSTOM_LIST_FILE && (
                                    <Box display={"flex"} alignItems={"center"}  mb={2.5} gap>
                                        <Typography variant='body1' sx={{ color: colorTheme.theme.transform.black, fontWeight: '500' }}>LIST:</Typography>
                                        <TextField
                                            value={title}
                                            onChange={e => {
                                                handleTitleChange(e.target.value)
                                            }}
                                            fullWidth
                                            name="title"
                                            autoComplete="title"
                                            InputProps={{
                                                disableUnderline: true,
                                                style: { fontWeight: '500', fontSize: '16px', color: colorTheme.theme.transform.black, borderBottom: '1px solid #E0E0E0' },
                                            }}
                                        />
                                        </Box>
                                )
                            }
                            <InputSearch value={searchQuery} placeholder="Search" onChange={handleSearchQueryChange} icon={<SearchIcon />} padding={'8px 16px 8px 6px'} height={'40px'} width='100%'/>
                        </Box>
                       {
                            props.errorCount > 0 && <Box sx={{ alignSelf: 'center' }}>
                                <ErrorText error={`There are errors in your list.`} />
                            </Box>
                       }    
                        <Box display='flex' gap={0.5} sx={{ marginLeft: 'auto' }}>
                            <TextButton name={props.type == CUSTOM_LIST_FILE ? "Add row" : "Add Respondent"} onClick={startAddingRow} size='small' fontSize='14px' width='auto' margin='auto auto 0 auto' borderRadius='23px' Icon={AddRowIcon} />
                            <TextButton name={"Add Attribute"} onClick={startAddingColumn} size='small' fontSize='14px' width='auto' margin='auto auto 0 auto' borderRadius='23px' Icon={AddColumnIcon} />
                            <SortMenu sortOrder={sortOrder} onSortOrderChange={handleSortOrderChange} />
                            <ColumnsMenu columns={state.columns} onColumnOrderChange={handleColumnOrderChange} onColumnSelectionChange={handleColumnSelectionChange} selectedColumns={state.selectedColumns} />
                            <ExportMenu state={state} title={props.type === CUSTOM_LIST_FILE ? title : 'respondents'}/>
                            <TextButton name='Delete' onClick={() => props.handleDelete(props.listId)} size='small' fontSize='14px' width='auto' margin='auto auto 0 auto' borderRadius='23px' Icon={DeleteIcon2} />
                        </Box>
                    </Box>
                }
                <TableContainer sx={{
                    overflowX: 'auto',
                    border: '1px solid #E0E0E0',
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    borderBottomLeftRadius: state.data.length > 0 ? 'none' : '4px',
                    borderBottomRightRadius: state.data.length > 0 ? 'none' : '4px',
                    marginTop: '16px',
                }}>
                    <Table>
                        <ListsTableHead state={state} respondentList={props.type == CUSTOM_LIST_FILE ? false : true} addingColumn={addingColumn} cancelAddingColumn={cancelAddingColumn} saveColumn={saveColumn} newColumnName={newColumnName} setNewColumnName={setNewColumnName} newColumnRef={newColumnRef} />
                        <TableBody ref={newColumnRef}>
                            {addingRow && (
                                <ListsTableAddingRow state={state} addingRow={addingRow} setAddingRow={setAddingRow} confirmAddingRow={confirmAddingRow} cancelAddingRow={cancelAddingRow} respondentList={props.type == CUSTOM_LIST_FILE ? false : true} />
                            )}
                            {state.filteredData && state.filteredData.length > 0 ?
                                (state.filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    const isEditing = editingRow && editingRow.tableData.id === row.tableData.id;
                                    return (
                                        <ListsTableRow key={index} index={index} row={row} state={state} isEditing={isEditing} initiateEdit={initiateEdit} confirmEdit={confirmEdit} cancelEdit={cancelEdit} initiateDelete={initiateDelete} deletingRow={deletingRow} confirmDelete={confirmDelete} cancelDelete={cancelDelete} newRespondent={newRespondent} handleNewRespondentChange={handleNewRespondentChange} respondentList={props.type == CUSTOM_LIST_FILE ? false : true} addingColumn={addingColumn}/>
                                    );
                                })) : (<>
                                    {
                                        !addingRow &&
                                        <ListsTableEmptyRow EmptyTableIcon={props.EmptyTableIcon} emptyTableTitle={props.emptyTableTitle} emptyTableDescription={props.emptyTableDescription} />
                                    }
                                </>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                {state.filteredData && state.filteredData.length > 0 && (
                    <TablePagination
                        sx={{
                            pt: 5,
                            mr: 0,
                            border: '1px solid #E0E0E0',
                            width: '100%',
                            borderBottomLeftRadius: '4px',
                            borderBottomRightRadius: '4px',
                            borderTop: 'none',
                            '& .MuiTablePagination-toolbar': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                                minHeight: '50px',
                                paddingRight: '50px',
                            },
                            '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                                mr: -1,
                            },
                            '& .MuiTablePagination-input': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                                mr: 1.7,
                            },
                            '& .MuiTablePagination-select': {
                                paddingLeft: '4px !important',
                                minWidth: 'auto !important',
                            },
                            '& .MuiTablePagination-actions': {
                                '& button': {
                                    margin: '0px',
                                    padding: '8px', // Adjust padding to ensure the button is more square
                                    transform: 'scale(0.9)',
                                    minWidth: 'auto', // Remove any minimum width restrictions
                                    width: '25px', // Equal width and height to form a square
                                    height: '25px', // Set height equal to width
                                    '& svg': {
                                        marginLeft: '0px', // Adjust if needed
                                        marginRight: '0px', // Adjust if needed
                                    },
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Set the background color on hover
                                        borderRadius: '50%', // Make the hover background rounded
                                        transform: 'scale(1.1)', // Optionally scale a bit on hover
                                    }
                                }
                            }
                        }}
                        rowsPerPageOptions={rowsPerPageOptionsNumbers}
                        component="div"
                        count={state.filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={props.paginationText}
                    />
                )}
            </Paper>
        </Box>
    );
}

export default ListsTable;
