// Importing necessary React and Material-UI components
import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Box, Button, Divider } from "@material-ui/core";
// Importing SVG icons as React components
import { ReactComponent as Close } from "../../../../assets/images/close.svg";
import { ReactComponent as CogIcon } from "../../../../assets/images/settings-icon.svg";
// Custom components for form elements
import ToggleSwitchValue from "../../../../components/form/toogle-switch-value";
import CustomTextField from "../../../../components/form/questions-text-field";

// Constants for question types
import {
    MULTI_SELECT,
    MULTI_SLIDER_SINGLE_PICKER,
    SEARCH_CHOOSE,
    SINGLE_LINE_TEXT,
    SINGLE_SLIDER_SINGLE_PICKER,
    TEXT_BOX,
    SINGLE_SELECT,
    SECTION
} from "../../../../assets/constants";
// Advanced settings components for different question types
import TextBoxAdvanced from "./questions/text-box-question/text-box-advanced-settings";
import SingleLineAdvanced from "./questions/single-line-text-question/single-line-advanced-settings";
import MultiSelectAdvanced from "./questions/multi-select-question/multi-select-advanced-settings";
import SingleSliderAdvancedSettings from "./questions/single-slider-question/single-slider-advanced-settings";
import MultiSliderAdvancedSettings from "./questions/multi-slider-question/multi-slider-advanced-settings";
import SearchChooseAdvancedSettings from "./questions/drop-down-question/drop-down-advanced-settings";
import SingleSelectAdvSettings from "./questions/single-select-question/single-select-advanced-part";
import SectionAdvancedSettings from './questions/section/section-advanced-setting';
// Custom hook for managing question advanced settings
import useQuestionAdvancedSettings from "./../../../../hooks/editor/use-question-advanced-settings";
// Stylesheet for the component
import './question-advanced-settings.scss';

// Function to render the appropriate advanced settings component based on the question type
const renderQuestion = (question) => {
    switch (question.element_type) {
        case TEXT_BOX:
            return <TextBoxAdvanced template={question} />;
        case SINGLE_LINE_TEXT:
            return <SingleLineAdvanced template={question} />;
        case MULTI_SELECT:
            return <MultiSelectAdvanced template={question} />;
        case SINGLE_SLIDER_SINGLE_PICKER:
            return <SingleSliderAdvancedSettings template={question} />;
        case MULTI_SLIDER_SINGLE_PICKER:
            return <MultiSliderAdvancedSettings template={question} />;
        case SEARCH_CHOOSE:
            return <SearchChooseAdvancedSettings template={question} />;
        case SINGLE_SELECT:
            return <SingleSelectAdvSettings template={question} />;
        case SECTION:
            return <SectionAdvancedSettings template={question} />;
        default:
            return <div> question not recognized </div>;
    }
}
const IMAGE_STYLE = 'image';
// Main component for question advanced settings
const QuestionAdvancedSettings = (props) => {
    const question = props.template;
    // Destructuring values and functions from the custom hook
    const {
        shortQuestionNameChecked,
        questionExportName,
        handleShortQuestionNameSwitch,
        handleShortQuestionText
    } = useQuestionAdvancedSettings(question);

    // Function to handle close button action
    const handleCloseButton = () => {
        return props.setView;
    };

    return (
        <div className='advanced-settings'>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ position: 'relative' }}
            >
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                        mt: '24px',
                        ml: '12px',
                        mb: '23px',
                    }}
                >
                    <Button size="small" className='settings-button' edge='start'>
                        <CogIcon style={{ marginRight: '8px' }} />
                        <label>Advanced Settings</label>
                    </Button>
                </Box>

                <Box
                    sx={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                    }}
                >
                    <Box
                        onClick={handleCloseButton()} // Attach the click handler here
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            width: '30px',
                            height: '30px',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: '#EEF0F4',
                                borderRadius: '50%',
                            },
                        }}
                    >
                        <Close />
                    </Box>
                </Box>
            </Box>
            {renderQuestion(props.template)}

            {props.template.style_type !== IMAGE_STYLE && props.template.element_type !== SECTION &&
                < Divider
                    style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                        marginBottom: '20px',
                        marginTop: '32px',
                    }} />
            }
            <Box ml={'20px'}>
                <Box ml={1.5} mb={2}>
                    <FormControlLabel
                        control={
                            <ToggleSwitchValue
                                label={props.template.element_type === SECTION ? 'Short Name' : 'Short Question Name'}
                                value={shortQuestionNameChecked}
                                onChange={handleShortQuestionNameSwitch}
                                description={'This name will be used for easier data view when exporting results.'}
                            />
                        }
                    />
                </Box>
                <Box className={!shortQuestionNameChecked ? 'disabled' : ''}>
                    <CustomTextField

                        disabled={!shortQuestionNameChecked}
                        placeholder="Type here"
                        value={questionExportName}
                        onChange={e => handleShortQuestionText(e.target.value)}
                    />
                </Box>
            </Box>
        </div>
    );
};

export default QuestionAdvancedSettings;