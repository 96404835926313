import React from 'react';
import Fab from "@material-ui/core/Fab";
import './edit-container.scss';
import { ReactComponent as PreviewIcon } from "../../../assets/images/menu/survey/preview-icon.svg";
import { ReactComponent as CollaboratorIcon } from "../../../assets/images/add-collaborator-icon.svg";
import Divider from '@mui/material/Divider';
import { colorTheme } from '../../../assets/theme';
import { Box, Typography } from '@mui/material';
import { useEditContainer } from '../../../hooks/survey/use-edit-container';

// EditContainer component
function EditContainer() {

    // Destructure values from custom hook
    const {
        tabs,
        activeTab,
        dividerStyle,
        tabsRef,
        handleTabClick,
        handleAddColaboratorClick,
        handlePreviewClick,
        renderTabContent
    } = useEditContainer();

    return (
        <Box className='edit-container'>
            <Box className="edit-tabs flex-center">
                <Box className="tabs flex-center" ref={tabsRef}>
                    {tabs.map((tab, index) => (
                        <Box className={`tab flex-center ${activeTab === tab.type ? 'active' : ''}`}
                            onClick={() => handleTabClick(tab.type)} key={index}
                        >
                            <Box className="icon">{tab.icon}</Box>
                            <Typography className='tab-text' sx={{ fontSize: '14px', fontWeight: 500 }}>{tab.type}</Typography>
                        </Box>
                    ))}
                    <Divider style={{
                        border: 'none',
                        height: '4px',
                        position: 'absolute',
                        bottom: 0,
                        backgroundColor: colorTheme.theme.transform.yellow,
                        transition: 'width 0.3s ease-in-out, left 0.3s ease-in-out',
                        ...dividerStyle
                    }} />
                </Box>
                <Fab variant="extended" className="add-colaborator" size="small" onClick={handleAddColaboratorClick}>
                    <CollaboratorIcon className="add-colaborator-icon" />
                    Add Collaborator
                </Fab>
                <Fab variant="extended" className="preview" size="small" onClick={handlePreviewClick}>
                    <PreviewIcon className="preview-icon" />
                    Preview
                </Fab>
            </Box>
            <Box className="edit-content">
                {renderTabContent()}
            </Box>
        </Box>
    );
}

export default EditContainer;
