import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { ReactComponent as Icon } from "./../../assets/images/actions/upload.svg";
import { colorTheme } from '../../assets/theme';
import HeaderDialog from './header-dialog';
import PrimaryButton from '../buttons/primary-button';


export default function UploadNewList({ open, handleClose, handleAction, listName, columns }) {

    function handleSave() {
        handleAction(columns);
        handleClose();

    }

    return (
        <Dialog open={open} onClose={handleClose}
            sx={{ '& .MuiDialog-paper': { width: '353px', } }} // Set the dialog width
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog Icon={Icon} handleClose={handleClose} title={'Upload new list'} />
            <DialogContent
                sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                <DialogContentText
                    variant='body2'
                    sx={{ color: colorTheme.theme.text.lightGrey, lineHeight: "24px", pt: 2 }}>
                    Upload list : <span style={{ fontWeight: '500', color: '#4F4F4F' }}>{listName}</span>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pt: 2, pb: 2, pr: 2 }}>
                <PrimaryButton onClick={handleClose} color="white" hoverColor="#EDF0F4" text="Cancel" hoverText={colorTheme.theme.transform.darkBlue} />
                <PrimaryButton onClick={handleSave}
                    color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="Save" />
            </DialogActions>
        </Dialog>
    );
}
