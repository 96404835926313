import React, { useState } from 'react';
import { Box, Avatar } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import { ReactComponent as EditImage } from "./../../assets/images/actions/edit-avatar.svg";
import { ReactComponent as HoverEditImage } from "./../../assets/images/actions/hoverEditIcon.svg";
import { useProfileInfo } from './../../hooks/profile/use-profile-info';
import EditProfileInfo from './edit-profile-info';
import ProfileView from './profile-view';
import TooltipText from '../tooltip-text/tooltip-text';

const ProfileInfo = () => {
    const { userData, userAvatar, handleAvatarChange, isDefaultAvatar, togglePhoneNumberInput,
        showPhoneNumberInput, hasPHoneNumber, setHasPhoneNumber, onEdit, onCancel, editMode } = useProfileInfo();

    const [isHovered, setIsHovered] = useState(false);

    const avatarSrc = userAvatar;
    const avatarStyle = {
        width: { xs: 100, sm: 174 },
        height: { xs: 100, sm: 174 },
        ...(isDefaultAvatar ? {} : { border: `3px solid ${colorTheme.theme.transform.darkBlueTwo}` }),
    };

    return (
        <Box sx={{ position: 'relative', p: 1, mb: 6, boxShadow: 'none' }}>
            <Box sx={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
            }}>
                <Box sx={{
                    display: 'flex', alignItems: 'center', gap: 2,
                    '@media (max-width:1200px)': {
                        display: 'ruby'
                    },
                    '@media (max-width:600px)': {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%'
                    }
                }}>
                    <Box sx={{
                        position: 'relative', display: 'flex', alignItems: 'center', gap: 0,
                        '@media (max-width:600px)': {
                            display: 'inline-block'
                        }
                    }}>
                        <Avatar sx={avatarStyle} src={avatarSrc} />
                        <input
                            accept="image/*"
                            type="file"
                            onChange={handleAvatarChange}
                            style={{ display: 'none' }}
                            id="avatar-upload"
                        />
                        <TooltipText
                            name="Edit Profile Image"
                            placement="bottom"
                            backgroundColor={colorTheme.theme.transform.darkBlueTwo}
                            textColor={colorTheme.theme.transform.white}
                            margin={'-15px 0px 0px 0px'}
                        >


                            <label
                                htmlFor="avatar-upload"
                                style={{
                                    position: 'absolute',
                                    bottom: 15,
                                    right: 35,
                                    transform: 'translate(50%, 50%)',
                                    padding: '0.5em',
                                    cursor: 'pointer',

                                }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    {isHovered ? <HoverEditImage /> : <EditImage />}
                                </div>
                            </label>
                        </TooltipText>

                    </Box>
                    {/* view  */}
                    {!editMode && (
                        <ProfileView onEdit={onEdit}
                            togglePhoneNumberInput={togglePhoneNumberInput}
                            showPhoneNumberInput={showPhoneNumberInput}
                            hasPhoneNumber={hasPHoneNumber}
                            setHasPhoneNumber={setHasPhoneNumber}
                        />
                    )}

                    {/* edit */}
                    {editMode &&
                        <EditProfileInfo userData={userData} onCancel={onCancel} />
                    }

                </Box>
            </Box>
        </Box>
    );
};

export default ProfileInfo;
