import { Box } from '@material-ui/core';
import React from 'react';


const SectionAdvancedSettings = (props) => {



    return (
        <Box mt={'-15px'}></Box>
    );
};

export default SectionAdvancedSettings;
