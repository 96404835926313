
import { createTheme } from '@mui/material/styles'; // Import the createTheme function from MUI

// Create a theme instance with the createTheme function
const colorTheme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                root: {
                    // Apply styles to the root element
                    '&:focus': {
                        backgroundColor: '#ffddec',
                        // borderColor won't apply here as it needs to target the notched outline specifically
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    // Apply styles to the notched outline specifically
                    '&:focus': {
                        borderColor: 'brown',
                    },
                    '&:before': {
                        borderColor: 'orange',
                    },
                    '&:after': {
                        borderColor: 'green',
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif'
        ].join(','),
    },
    theme: {
        transform: {
            yellow: "#FEBE10",
            black: "#231F20",
            gray: "#7B7D89",
            lightGray: "#D9DCEA",
            darkBlue: "#0C255C",
            darkBlueTwo: "#1B4972",
            darkGreen: "#0A3B3C"
        },
        text: {
            black: "#231F20",
            darkGrey: "#282828",
            mediumGrey: "#4F4F4F",
            lightGrey: "#828282"
        },
        app: {
            lightGrey: "#E0E0E0",
            lightestGrey: "#EEF0F4",
            red: "#E23D32"
        },
        survey: {
            statusText: "#FFFFFF",
            statusDraft: "#F37E2A",
            statusLive: "#10AE3C",
            statusCompleted: "#7B7D89",
            statusScheduled: "#2178C6",
            statusDraftOpacity: "#F5792012",
            statusLiveOpacity: "#2FBA4E12",
            statusCompletedOpacity: "#7B7D8912",
            statusScheduledOpacity: "#2178C612",
        },
        colors: {
            white: "#FFFFFF",
            pink: "#E91E63",
            purple: "#261440",
            darkGray: "#203455",
            grayDarker: "#707070",
            lightGray: "#EDEDED",
            shadeGray: "#F2F2F2",
            mediumGray: "#BDBDBD",
            gray: "#666666",
            lightPink: "#E9ADC1DB",
            blue: "#203455",
            lightBlue: "#BFC1E1",
            lighterBlue: "#ECEDFC",
            green: "#B1FFD2",
            red: "#FFD0D0",
            error: "#FFEDED",
            orange: "#FF973A",
            lightBlue: "#3288D8",
            successGreen: "#4BC265",
            surveyMenuItemBackgroundActive: "#3949B3",
            surveyMenuItemTextActive: "#FFFFFF",
            teamTableHeaderBackground: "#EDF0F4",
        }
    },
});

// Create a function to set the root variables
const createRootVariables = () => {
    const head = document.head; // Get the head element
    const style = document.createElement('style'); // Create a new style element

    const { theme } = colorTheme;

    let styles = '';

    for (const category in theme) {
        for (const key in theme[category]) {
            styles += `--${category}-${key}: ${theme[category][key]}; `;
        }
    }

    // Set the style element's text content to the root variables
    style.textContent = `:root { ${styles} }`;

    // Append the style element to the head
    head.appendChild(style);
};

// Export the colorTheme and createRootVariables functions
export { colorTheme, createRootVariables };

