import React from "react";
import './text-button.scss';
import Button from '@mui/material/Button';
import { colorTheme } from "../../assets/theme";

const TextButton = ({ name, onClick, size, fontSize = '15px', width = 'initial', margin = 'auto auto 0 auto', borderRadius = '23px', Icon }) => {
    return(
        <Button onClick={onClick} className={`text-button ${size}`} sx={{ 
            background: 'transparent', 
            boxShadow: 'none', 
            fontSize: fontSize, 
            width: width, 
            borderRadius: borderRadius + ' !important',
            color: colorTheme.theme.transform.darkBlueTwo,
            transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
            margin: margin + ' !important',
            display: 'flex',
            alignItems: 'center',
            lineHeight: '1',
            gap: '6px',

            '& svg': {
                marginTop: '-1px',

                '& path': {
                    transition: 'fill 0.3s ease-in-out',
                }
            },
            
            '&:hover': {
                color: colorTheme.theme.transform.darkBlue,

                '& svg path': {
                    fill: colorTheme.theme.transform.darkBlue,
                }
            },

            }} >
            {
                Icon && <Icon className="button-icon" />
            }
            {name}
        </Button>
    )
}

export default TextButton;