import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const CustomTextField = ({
    name,
    label = '',
    placeholder = '',
    value,
    onChange,
    variant = "outlined",
    size = "small",
    multiline = false,
    error = false,
    helperText = '',
    inputProps = {},
    InputProps = {},
    disabled = false,
    sx = {},
    width = '90%',
    type = "text" // Default type is "text"
}) => {
    return (
        <TextField
            name={name}
            label={label}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            variant={variant}
            size={size}
            multiline={multiline}
            error={error}
            helperText={helperText}
            inputProps={{ ...inputProps, type: type }} // Set the type dynamically
            InputProps={{
                ...InputProps,
                startAdornment: <InputAdornment position="start"></InputAdornment>,
                style: {
                    height: '48px',
                    ...InputProps.style
                }
            }}
            disabled={disabled}
            sx={{
                width: width,
                backgroundColor: '#FFFFFF',
                '& .MuiInputLabel-root': {
                    fontStyle: 'normal', // Remove italic style
                    color: '#828282',    // Set label color to #828282
                },

                '& .MuiInputBase-input': {
                    color: 'black', // Set input text color to black
                    height: '48px',
                    padding: '0px !important', // Adjust padding to fit height requirement
                    boxSizing: 'border-box',
                    fontSize: '15px', // Set input font size
                },
                '& .MuiInputAdornment-root': {
                    marginRight: '0px' // Adjust margin for input adornment
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: '#D7D7D7', // Default border color
                    },
                    '&:hover fieldset': {
                        borderColor: '#D7D7D7', // Border color on hover
                    },
                    '&.MuiInputBase-multiline': {
                        padding: '0',        // Adjust padding for multiline input
                    },
                    '&:hover fieldset': {
                        borderColor: '#4F4F4F', // Change the outline color on hover
                        border: '1px solid #4F4F4F', // Make the border 1px solid
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#1B4972', // Change the outline color when focused
                        borderWidth: 1,
                    },
                },
                ...sx
            }}
        />
    );
};

export default CustomTextField;
