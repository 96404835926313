import React from 'react';
import { Dialog, DialogContent, DialogActions, Typography, Box } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import PrimaryButton from '../buttons/primary-button';
import HeaderDialog from './header-dialog'; // Ensure this is the correct path
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';




const SurveyAdminInfo = ({ user, open, onClose, }) => {

    const AvatarComponent = () => (
        <Avatar
            alt="User Avatar"
            src={user.avatar}
            sx={{ width: 24, height: 24, mr: '8px' }}
        />
    );


    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': { width: '575px' }, // Set the dialog width
                BackdropProps: {
                    sx: {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        backdropFilter: 'blur(2.5px)',
                    },
                }
            }}
        >        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid #E0E0E0',
            p: 1,
            height: '35px',
        }}>
                <Box sx={{ display: 'flex', alignItems: 'center', pl: '8px', gap: 0 }}>
                    <AvatarComponent />
                    <Typography sx={{ flexShrink: 0 }}>{user.user}</Typography>
                </Box>
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </Box>

            <DialogContent sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                <Typography variant='body2' width={'99%'} color={colorTheme.theme.text.darkGrey} sx={{ pt: 2, fontWeight: 500 }}>
                    SURVEYS
                </Typography>
                <Typography variant='body2' width={'99%'} color={colorTheme.theme.text.lightGrey} sx={{ pt: 1, }}>
                    {user.user} has an 'Admin' role, granting the user editor permissions across all surveys in the Survey app.
                </Typography>
            </DialogContent>
            <DialogActions sx={{ pt: 2, pb: 2, pr: 2 }}>
                <PrimaryButton onClick={onClose} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="CLOSE" />
            </DialogActions>
        </Dialog>
    );
};

export default SurveyAdminInfo
