import React from 'react';
import { Button, Typography, CircularProgress } from '@mui/material';
import { colorTheme } from '../../assets/theme';

const PrimaryButton = ({ onClick, color, hoverColor, text, Icon, isLoading, iconMargin = '0px', hoverText = "#1B4972", opacity = "1", border = 'none', borderHover = 'none', customTextColor = null }) => (
    <Button
        variant="contained"
        onClick={onClick}

        disabled={isLoading} // Disable button when loading
        sx={{
            textTransform: 'uppercase',
            bgcolor: color,
            opacity: opacity,
            color: customTextColor ? customTextColor : color === colorTheme.theme.transform.darkBlueTwo ? 'white' : colorTheme.theme.transform.darkBlueTwo,
            boxShadow: border,
            padding: '6px 16px', // Set fixed padding
            letterSpacing: '0.4px',
            border: color === 'white' ? '1.5px solid #1B4972' : 'none',
            borderRadius: '20px',
            lineHeight: '24px',
            '&:hover': {
                bgcolor: hoverColor,
                boxShadow: borderHover,
                borderColor: hoverText,

                '& .add-button-text': {
                    color: customTextColor ? customTextColor : color === colorTheme.theme.transform.darkBlueTwo ? 'white' : hoverText,
                },
            },
            '&.Mui-disabled': {
                bgcolor: color, // Set the disabled color to be the same as the color of the button
                color: color === colorTheme.theme.transform.darkBlueTwo ? 'white' : colorTheme.theme.transform.darkBlueTwo,
                width: '100px',
            },
            minWidth: 0,
            width: 'auto',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        {isLoading ? (
            <CircularProgress size={24} color="inherit" />
        ) : (
            <>
                {Icon && <Icon style={{ marginRight: '7px' }} />} {/* Add marginRight for Icon */}
                <Typography className='add-button-text'
                    sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        transition: 'color 0.3s ease-in-out',
                        lineHeight: '24px',
                    }}>{text}</Typography>
            </>
        )}
    </Button>
);

export default PrimaryButton;
