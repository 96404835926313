import React from "react";
import { Dialog, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { ReactComponent as WarningIcon } from "./../../assets/images/actions/icon-warning.svg";
import { colorTheme } from '../../assets/theme';
import HeaderDialog from './header-dialog';
import PrimaryButton from '../buttons/primary-button'

// UploadedNewRespondentsList component for dialog
const UploadedNewRespondentsList = ({ open, handleClose, handleConfirm, text }) => {
    return (
        <Dialog open={open} onClose={handleClose}
            sx={{ '& .MuiDialog-paper': { width: '550px' } }} // Set the dialog width
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog Icon={WarningIcon} handleClose={handleClose} title={`Warning: Potential Data Loss`} />
            <DialogContent
                sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                <DialogContentText
                    variant='body2'
                    sx={{ color: colorTheme.theme.text.darkGrey, lineHeight: "24px", pt: 2 }}>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pt: 2, pb: 2, pr: 2 }}>
                <PrimaryButton onClick={handleClose} color="white" hoverColor="#EDF0F4" text="Cancel" hoverText={colorTheme.theme.transform.darkBlue} />
                <PrimaryButton onClick={handleConfirm} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="Continue" />
            </DialogActions>
        </Dialog>
    );
};

export default UploadedNewRespondentsList;