import React from 'react';
import './survey-view.scss';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as _ from "lodash";
import SurveyCounter from '../../../components/survey-dashboard/survey-counter';
import CreateNewSurveyTile from '../../../components/survey-dashboard/create-new-survey-tile';
import SurveyTable from '../../../components/survey-table/survey-table';
import SurveyTile from '../../../components/survey-dashboard/survey-tile';
import CreateNewSurveyDialog from '../../../components/dialogs/create-new-survey';
import { useSurveyView } from '../../../hooks/survey/use-survey-view';
import SurveyOptions from '../../../components/survey-dashboard/survey-options';

// Constants
const VIEW_LIST = 'VIEW_LIST';
const VIEW_TILE = 'VIEW_TILE';
const NUMBER_OF_SURVEYS_TO_SHOW = 14;

// SurveyView component
function SurveyView(props) {

    // Destructure the custom hook values
    const {
        isCreateNewSurveyDialogOpen,
        toggleCreateNewSurveyDialog,
        setOpenModal,
        surveysFetched,
        surveys,
        view,
        surveysToShow,
        handleMenuClick,
        sortedSurveys,
        displayCount,
        setDisplayCount,
        anchorEl,
        handleMenuClose,
        handleMenuItemClick,
        dialogOpen,
        handleDialogClose,
        handleInvite,
        handleWorkspaceInvite,
        selectedSurveyName,
        handleSelectedRows,
        assignedUsers,
        companyName,
        deleteConfirmed,
        deleteDialogOpen,
        handleDeleteDialogClose,
        surveyToDelete,
        isLoading
    } = useSurveyView(props, NUMBER_OF_SURVEYS_TO_SHOW);

    return (
        <>
            <CreateNewSurveyDialog open={isCreateNewSurveyDialogOpen} handleClose={toggleCreateNewSurveyDialog} setOpenModal={setOpenModal} />
            {
                surveysFetched && surveys && view === VIEW_LIST &&
                <SurveyTable surveys={surveysToShow} handleMenuClick={handleMenuClick} toggleCreateNewSurveyDialog={toggleCreateNewSurveyDialog} />
            }
            {
                surveysFetched && surveys && view === VIEW_TILE &&
                <>
                    <div className="tile-container">
                        <CreateNewSurveyTile toggleCreateNewSurveyDialog={toggleCreateNewSurveyDialog} />
                        {
                            surveysFetched && sortedSurveys && view === VIEW_TILE &&
                            surveysToShow
                                .slice(0, displayCount)
                                .map((survey, index) => {
                                    return (
                                        <SurveyTile key={index} survey={survey} index={index} handleMenuClick={handleMenuClick} />
                                    )
                                })
                        }
                    </div>
                    <SurveyCounter displayCount={displayCount} surveysToShow={surveysToShow} setDisplayCount={setDisplayCount} numberToShow={NUMBER_OF_SURVEYS_TO_SHOW} />
                </>
            }
            {
                !surveysFetched &&
                <CircularProgress className='loader' />
            }
            <SurveyOptions anchorEl={anchorEl} handleMenuClose={handleMenuClose} handleMenuItemClick={handleMenuItemClick} dialogOpen={dialogOpen} handleDialogClose={handleDialogClose} handleInvite={handleInvite} handleWorkspaceInvite={handleWorkspaceInvite} handleSelectedRows={handleSelectedRows} assignedUsers={assignedUsers} companyName={companyName} selectedSurveyName={selectedSurveyName} deleteConfirmed={deleteConfirmed} deleteDialogOpen={deleteDialogOpen} handleDeleteDialogClose={handleDeleteDialogClose} surveyToDelete={surveyToDelete} isLoading={isLoading} />
        </>
    );
}

export default SurveyView;
