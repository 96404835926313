import { useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './phone-input-field.scss';
import { Box } from '@material-ui/core';
import InputField from './input-field';

// Phone input field component
const PhoneInputField = ({ value, onChange }) => {
    const [stateAreaCode, setStateAreaCode] = useState(''); // State to store the area code
    const [phoneNumber, setPhoneNumber] = useState(value); // State to store the phone number

    // UseEffect hook to update the phone number value
    useEffect(() => {
        if (phoneNumber) {
            onChange(stateAreaCode + ' ' + phoneNumber);
        }
    }, [stateAreaCode, phoneNumber]);

    const handlePhoneNumberChange = (e) => {
        // Allow the phone number to be cleared
        const value = e.target.value;
        if (value === '' || /^\d*$/.test(value)) {
            setPhoneNumber(value);
        }
    };
    return (
        <Box className='phone-input'>
            <PhoneInput
                defaultCountry="SA"
                value={stateAreaCode}
                onChange={(stateAreaCode) => setStateAreaCode(stateAreaCode)}
                inputProps={{
                    readOnly: true,
                    style: { width: stateAreaCode.length * 11.5 },
                }}
            />
            <InputField type="number" label="User Phone Number" value={phoneNumber} onChange={handlePhoneNumberChange} />
        </Box>
    );
};

export default PhoneInputField;
