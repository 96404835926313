import React from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as CreateNewSurveyIcon } from "../../assets/images/survey-dashboard/create-new-survey-icon.svg";

// CreateNewSurveyTile component
const CreateNewSurveyTile = (props) => {
    return (
        <>
            <Box className="create-new-survey-tile" onClick={props.toggleCreateNewSurveyDialog}>
                <CreateNewSurveyIcon />
                <Typography variant="h3" className="title">CREATE NEW SURVEY</Typography>
            </Box>
        </>
    )
}

export default CreateNewSurveyTile;