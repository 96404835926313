import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { RESPONDENTS_FILE, ROW_ID } from "../../assets/constants";
import { v4 as uuidv4 } from 'uuid';
import { CUSTOM_LIST_FILE } from '../../assets/constants';

const MAX_ROWS = 10000;
const MAX_COLUMNS = 100; 

// useCreateList hook
export const useCreateList = (props) => {
    const { enqueueSnackbar } = useSnackbar(); // Snackbar notification
    const [addNewListDialogOpened, setAddNewListDialogOpened] = useState(false); // State to store add new list dialog opened
    const [attributes, setAttributes] = useState([]); // State to store attributes
    const [listName, setListName] = useState(null); // State to store list name
    const [triggerConfirm, setTriggerConfirm] = useState(false); // State to trigger confirm after attributes are added
    const [warningDialogOpened, setWarningDialogOpened] = useState(false); // State to store warning dialog opened

    useEffect(() => {
        if (addNewListDialogOpened) {
            setListName(props.type === CUSTOM_LIST_FILE ? '' : 'Respondent list');
            setAttributes(props.type === CUSTOM_LIST_FILE ? [] : [
                { title: 'Email', checked: true },
                { title: 'Name', checked: true },
                { title: 'Country', checked: false },
                { title: 'Department', checked: false },
                { title: 'Job role', checked: false },
                { title: 'Gender', checked: false },
                { title: 'Image URL', checked: false },
            ]);
        }
    }, [addNewListDialogOpened, props.type]);

    // Function to open snackbar
    const openSnackbar = (msg) => {
        enqueueSnackbar(msg, {
            variant: 'error',
        });
    };

    // Function to handle manual list creation
    const createList = (columns, rows) => {
        const dataParse = [columns, ...rows];
        const { isValid, validationMessage } = props.type === RESPONDENTS_FILE
            ? validateRespondentsFile(dataParse)
            : validateCustomListFile(dataParse);

        if (isValid) {
            const transformedData = fileArraysToObjects(dataParse);
            if(props.type === RESPONDENTS_FILE) {
                props.handleUploadedData(transformedData, null, true);
            }else{
                props.handleUploadedData(transformedData, null, null, true, listName);
            }
        } else {
            openSnackbar(validationMessage);
        }
    };

    // Function to convert file arrays to objects
    const fileArraysToObjects = (data) => {
        const header = data[0];
        const dataRows = data.slice(1);
        return {
            columns: header.map(col => ({ title: col, field: col })),
            data: dataRows.map(r => {
                let dataObj = { [ROW_ID]: uuidv4() };
                header.forEach((col, ix) => dataObj[col] = r[ix]);
                return dataObj;
            })
        };
    };

    // Function to check duplicates
    const checkDuplicates = (data) => {
        const lowerCased = data.map(item => item.toString().toLowerCase());
        const set = new Set(lowerCased);
        return lowerCased.length !== set.size;
    };

    // Function to validate respondents file
    const validateRespondentsFile = (data) => {
        let isValid = false;
        let validationMessage = '';
        const columnNames = data[0];
        if (!columnNames) {
            validationMessage = 'Header missing in file!';
        } else if (checkDuplicates(columnNames)) {
            validationMessage = 'Respondent list should not have 2 columns with same name. Please check columns names before you try to upload';
        } else if (data.length > MAX_ROWS) {
            validationMessage = 'File should contain at most ' + MAX_ROWS + ' rows of data!';
        } else if (columnNames.length > MAX_COLUMNS) {
            validationMessage = 'File should contain at most ' + MAX_COLUMNS + ' columns!';
        } else if (!columnNames.map(col => col.toLowerCase()).includes('email')) {
            validationMessage = 'Email column missing in file!';
        } else if (!columnNames.map(col => col.toLowerCase()).includes('name')) {
            validationMessage = 'Name column missing in file!';
        } else {
            isValid = true;
        }
        return { isValid, validationMessage };
    };

    // Function to validate custom list file
    const validateCustomListFile = (data) => {
        let isValid = false;
        let validationMessage = '';
        const columnNames = data[0];
        if (!columnNames) {
            validationMessage = 'Header missing in file!';
        } else if (checkDuplicates(columnNames)) {
            validationMessage = 'Custom list should not have 2 columns with same name. Please check columns names before you try to upload';
        } else {
            isValid = true;
        }
        return { isValid, validationMessage };
    };

    // Handle confirm dialog
    const handleConfirm = (confirm = false) => {
        if(props.type === RESPONDENTS_FILE && props.listId != null && !confirm){
            setWarningDialogOpened(true);
            return;
        }else{
            setTriggerConfirm(true);
        }
    };

    // Handle cancel dialog
    const handleCancel = () => {
        setAddNewListDialogOpened(false);
    }

    // Function to add attributes
    const addAttributes = (attributes) => {
        setAttributes(prevAttributes => {
            const updatedAttributes = [...prevAttributes, ...attributes];
            return updatedAttributes;
        });
    };

    // Function to change attribute status
    const checkAttribute = (attribute) => {
        const newAttributes = attributes.map(attr => {
            if (attr.title === attribute) {
                return { title: attr.title, checked: !attr.checked };
            }
            return attr;
        });
        setAttributes(newAttributes);
    };

     // Handle confirm dialog
     useEffect(() => {
        if (triggerConfirm) {
            const columns = attributes.filter(attr => attr.checked).map(attr => attr.title);
            const rows = [Array(columns.length).fill('')];

            createList(columns, rows);
            setAddNewListDialogOpened(false);
            setTriggerConfirm(false); // Reset the trigger
        }
    }, [triggerConfirm, attributes]);

    // Function to close warning dialog
    const handleCloseWarningDialog = () => {
        setWarningDialogOpened(false);
    };

    // Function to handle confirm warning dialog
    const handleConfirmWarningDialog = () => {
        setWarningDialogOpened(false);
        handleConfirm(true);
    }

    // Return functions
    return {
        listName,
        attributes,
        addNewListDialogOpened,
        warningDialogOpened,
        setListName,
        addAttributes,
        checkAttribute,
        setAddNewListDialogOpened,
        handleConfirm,
        handleCancel,
        handleCloseWarningDialog,
        handleConfirmWarningDialog,
    };
};
