import React from "react";
import { Box } from "@material-ui/core";
import AdvancedSettings from "../../question-advanced-settings";
import './single-slider-question.scss';
import { isError } from "../questionUtil";
import CustomNumberTextField from "../../../../../../components/form/question-number-input";
import { colorTheme } from '../../../../../../assets/theme';
import Slider from '@material-ui/core/Slider';
import useSingleSliderQuestion from "./../../../../../../hooks/editor/single-slider/use-single-slider";
import TooltipIcon from "../../../../../../components/tooltip-text/tooltip-icon";

const SingleSliderQuestion = ({ template, advancedSettings, handleAdvancedView }) => {
    const question = template;

    const { sliderValue, sliderDetails, handleSliderValue, handleSliderDetails } = useSingleSliderQuestion(question);

    function generateMarks(min, max, increment) {
        const marks = [];
        for (let value = min; value <= max; value += increment) {
            marks.push({
                value,
                label: value === min || value === max ? `${value}` : ''
            });
        }
        return marks;
    }

    const min = Number(sliderDetails.item_min) || 0;
    const max = Number(sliderDetails.item_max) || 10;
    const increment = Number(sliderDetails.item_increment) || 1;
    const marks = generateMarks(min, max, increment);

    return (
        <Box className='component-container'>
            <Box className='single-slider-question-container' mb={3}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
                    <Box display='flex' alignItems='center' gap={1.5}>
                        <CustomNumberTextField
                            name='item_min'
                            value={sliderDetails.item_min}
                            onChange={e => handleSliderDetails(e.target)}
                            error={isError(sliderDetails.item_min)}
                            disabled={false}
                            sx={{ marginRight: '8px' }}
                        />
                        <Box component="label" sx={{ marginRight: '8px' }}>Minimum value</Box>
                        <TooltipIcon title={'Minimum value'} content={'Sets the lowest possible value for the slider. This is the starting point of the scale. Default is 1.'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                    </Box>
                    <Box display='flex' alignItems='center' gap={1.5}>
                        <CustomNumberTextField
                            name='item_max'
                            value={sliderDetails.item_max}
                            onChange={e => handleSliderDetails(e.target)}
                            error={isError(sliderDetails.item_max)}
                            disabled={false}
                            sx={{ marginRight: '8px' }}
                        />
                        <Box component="label" sx={{ marginRight: '8px' }}>Maximum value</Box>
                        <TooltipIcon title={'Maximum value'} content={'Sets the highest possible value for the slider. This defines the end point of the scale. Default is 10.'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                    </Box>
                    <Box display='flex' alignItems='center' gap={1.5}>
                        <CustomNumberTextField
                            name='item_increment'
                            value={sliderDetails.item_increment}
                            onChange={e => handleSliderDetails(e.target)}
                            error={isError(sliderDetails.item_increment)}
                            disabled={false}
                            sx={{ marginRight: '8px' }}
                        />
                        <Box component="label" sx={{ marginRight: '8px' }}>Slider increments</Box>
                        <TooltipIcon title={'Slider increments'} content={'Determines the step size between each value on the slider. Smaller increments allow for finer granularity in responses. Default increment is 1.'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                    </Box>
                </Box>

                <Box className='slider-component' mt={8} display='flex' flexDirection='row' sx={{ gap: '48px' }}>
                    <Box component="label">Slider Preview:</Box>
                    <Box width='576px' mt={-0.5}>
                        <Slider
                            value={sliderValue}
                            getAriaValueText={(value) => `${value}`}
                            aria-labelledby="discrete-slider-small-steps"
                            step={Number.parseInt(sliderDetails.item_increment) || 1}
                            marks={marks}
                            min={Number.parseInt(sliderDetails.item_min) || 0}
                            max={Number.parseInt(sliderDetails.item_max) || 10}
                            valueLabelDisplay='on'
                            onChange={handleSliderValue}

                        />
                    </Box>
                </Box>
            </Box>
            {advancedSettings &&
                <AdvancedSettings defaultValue={handleSliderValue} setView={handleAdvancedView} template={question} />}
        </Box>
    );
}

export default SingleSliderQuestion;
