
const itemList = [{item_id: "item_id_0", name: "Nora"},
				  {item_id: "item_id_1", name: "Lucas"},
				  {item_id: "item_id_2", name: "Emma"},
				  {item_id: "item_id_3", name: "Aksel"},
				  {item_id: "item_id_4", name: "Sofia"},
				  {item_id: "item_id_5", name: "Emil"},
				  {item_id: "item_id_6", name: "Olivia"},
				  {item_id: "item_id_7", name: "Jakob"},
				  {item_id: "item_id_8", name: "Maja"},
				  {item_id: "item_id_9", name: "Filip"},
				  {item_id: "item_id_10", name: "Ella"},
				  {item_id: "item_id_11", name: "Noah"},
				  {item_id: "item_id_12", name: "Ida"},
				  {item_id: "item_id_13", name: "Oscar"},
				  {item_id: "item_id_14", name: "Linnea"},
				  {item_id: "item_id_15", name: "Henrik"},
				  {item_id: "item_id_16", name: "Ingrid"},
				  {item_id: "item_id_17", name: "Liam"},
				  {item_id: "item_id_18", name: "Hanna"},
				  {item_id: "item_id_19", name: "William"},
				  {item_id: "item_id_20", name: "Anna"},
				  {item_id: "item_id_21", name: "Isak"},
				  {item_id: "item_id_22", name: "Julie"},
				  {item_id: "item_id_23", name: "Magnus"},
				  {item_id: "item_id_24", name: "Sara"},
				  {item_id: "item_id_25", name: "Mathias"},
				  {item_id: "item_id_26", name: "Amalie"},
				  {item_id: "item_id_27", name: "Jonas"},
				  {item_id: "item_id_28", name: "Emilie"},
				  {item_id: "item_id_29", name: "Alexander"},
]


export default [
		{
			element_type: 'section',
			question_text: "UTFORSK DITT NETTVERK",
			section_description: `<p>Kjære kollegaer,</p><p>I vår stadige søken etter forbedring, smidighet og innovasjon har [FIRMANAVN] valgt en spennende prosess som inkluderer Organisatorisk NettverksAnalyse (ONA). Det starter med organisasjonens viktigste utfordringer/problemer.</p><p>ONA-undersøkelsen gir ledelsen og ansatte helt unik innsikt i hvordan organisasjonen faktisk fungerer som et nettverk av relasjoner.</p><p>Din deltakelse er avgjørende. Gjennom dine svar kan vi samle innsikt som vil hjelpe oss å gjøre [Firmanavn] sterkere og et bedre sted å jobbe.</p><p>Undersøkelsen tar cirka 10 minutter. Kontakt oss på support@transformnorway.no om du har noen problemer: Takk for at du bidrar til å gjøre oss bedre. </p><p><br>Med vennlig hilsen,<br>[FIRMANAVN]<br></p>`,
			element_order: 1,
			style_type: null,
		},
		{
			items_min_number: 1,
			element_type: 'multi_select',
			required: true,
			question_text: 'SAMTYKKE',
			style_type: 'text',
			page_break: true,
			items: [
				{
					item_id: 'multi-item-id-1',
					item_text: 'Ja, jeg ønsker å delta i undersøkelsen',
					item_order: 1
				}
			],
			element_order: 2,
			remove_numbering: true,
			question_explanation_string: 'Trykk på JA for å samtykke til at dataene du oppgir til oss kan behandles. Dataene vil være personlig identifiserbare og behandles derfor konfidensielt. Deltakelse er helt frivillig. HUSK at du må trykke på JA-knappen nedenfor for å gå videre. Hvis du ikke ønsker å gi ditt samtykke, må du avslutte undersøkelsen HER.'
		},              
		


		{
			element_type: 'section',
			question_text: "EXPERTISE",
			element_order: 3,
			section_description:"<p>Ekspertise er den nye valutaen</p>"
		},

		{
			element_order: 5,
			items_min_number: 1,
			element_type: 'multi_select',
			required: true,
			question_text: 'Hva er dine 1-3 viktigste ekspertiseområder/kompetanseområder?',
			item_other_allowed: true,
			style_type: 'text',
			max_characters: 30,
			items_other_allowed: 3,
			item_add_text: 'LEGG TIL EKSPERTISEOMRÅDE/KOMPETANSEOMRÅDE',
			item_placeholder_text: 'LEGG TIL+',
			page_break: true,
			items_max_number: 3,
			page_break:true,
			items: [
				{
					item_id: 'multi-item-id-1',
					item_text: 'Detaljert Design',
					item_order: 1
				},
				{
					item_id: 'multi-item-id-2',
					item_text: 'Interessentengasjement',
					item_order: 2
				},
				{
					item_id: 'multi-item-id-3',
					item_text: 'Prosjektledelse',
					item_order: 3
				},
				{
					item_id: 'multi-item-id-4',
					item_text: 'Kontroll og Planlegging ',
					item_order: 4
				},
				{
					item_id: 'multi-item-id-5',
					item_text: 'Bærekraft, Miljø',
					item_order: 5
				},
				{
					item_id: 'multi-item-id-6',
					item_text: 'Tverrfaglig',
					item_order: 6
				},
				{
					item_id: 'multi-item-id-7',
					item_text: 'Prosjekt- / Forretningsutvikling ',
					item_order: 7
				},
				{
					item_id: 'multi-item-id-8',
					item_text: 'Finans',
					item_order: 8
				},
				{
					item_id: 'multi-item-id-9',
					item_text: 'Prosjektstrukturering',
					item_order: 9
				},
				{
					item_id: 'multi-item-id-10',
					item_text: 'Bygging, Kvalitet',
					item_order: 10
				},
				{
					item_id: 'multi-item-id-11',
					item_text: 'Tillatelser og Lisensiering',
					item_order: 11
				},
				{
					item_id: 'multi-item-id-12',
					item_text: 'Jus og Kontrakter',
					item_order: 12
				},
				{
					item_id: 'multi-item-id-13',
					item_text: 'Forhandling / Megling',
					item_order: 13
				},
				{
					item_id: 'multi-item-id-14',
					item_text: 'Organisasjonsanalyse',
					item_order: 14
				},
				{
					item_id: 'multi-item-id-15',
					item_text: 'Teambygging',
					item_order: 15
				},
				{
					item_id: 'multi-item-id-16',
					item_text: 'Kommunikasjon',
					item_order: 16
				}
			],
			question_explanation_string: 'Velg 1 til 3 ekspertiseområder/kompetanseområder. Hvis du ikke finner noe som passer, kan du legge til ved å klikke på LEGG TIL EKSPERTISE-OMRÅDE og deretter skrive inn ditt ekspertiseområder.'
		
		},
		{
			element_type: 'multi_slider_single_picker',
			items_source_type: 'QUESTION',
			items_input: true,
			items_max_text: "Høy",
			items_name_field: 'item_text',
			required: true,
			items_min_text: "Lav",
			element_order: 6,
			question_text: "Vurder dine ekspertiseområder",
			items_default_value: 0,
			items_min: 0,
			items_increment: 1,
			item_other_allowed: 0,
			items_max: 10,
			page_break: true,
			question_explanation_string: "Vurder din kompetanse innen valgte ekspertiseområder på en skala fra 0 - 10",
			all_required: true
		},


		{
			element_type: 'section',
			element_order: 7,
			page_break: false,
			question_text: "DELINGSKULTUR",
			section_description: "<p>Delingskultur handler om å dele erfaringer og kunnskap mellom mennesker</p>"		
		},
		{
			item_default_value: 0,
			element_type: 'single_slider_single_picker',
			item_max: 10,
			item_max_text: "Veldig god",
			item_min: 0,
			item_min_text: "Ikke spesielt god",
			required: true,
			element_order: 8,
			question_text: "Hvor god er andre til å dele materiale (dokumenter, presentasjoner, etc.) med deg? ",			
			item_min: 0,
			item_increment: 1,
			page_break: true,
		},

		{
			element_type: 'section',
			question_text: "BEDRIFTSKULTUR ",
			element_order: 10,
			section_description:"<p>Bedriftskulturen sier noe om hva som er forventet og ønsket.</p>"

		},       
		{
			item_default_value: 0,
			element_type: 'single_slider_single_picker',
			item_max: 10,
			item_max_text: "Veldig god",
			item_min: 0,
			item_min_text: "Ikke spesielt god",
			required: true,
			element_order: 11,
			question_text: "Hvor god er bedriftskulturen overordnet på en skala fra 0 - 10",			
			item_min: 0,
			item_increment: 1,
			page_break: false,
		},       
		{
			element_type: 'single_line_text',
			initial_number_of_fields: 3,
			max_characters: 30,
			page_break: true,
			element_order: 12,
			items_max_number: 3,
			items_min_number: 3,
			question_text: "Hvordan vil du beskrive kulturen i organisasjonen med 3 ord?"
		},

		{
			element_type: 'search_choose',
			item_add_text: "LEGG TIL PERSON",
			item_other_allowed: true,
			items_max_number: 3,
			required: true,
			relationship: true,
			text_in_button_switch: true,
			text_in_response_switch: true,
			element_order: 13,
			item_fields: ["Name"],
			required_fields: ["Name"],
			items : itemList,
			items_source: "Demo",
			items_source_id: "69e76306-8a43-4805-b842-3563327c1555",
			items_source_type: "LIST",
			question_text: "Hvilke 3 personer påvirker kulturen mest positivt? ",
			item_min: 0,
			page_break: false,
		},
		{
			element_type: 'multi_slider_single_picker',
			items_source_type: 'QUESTION',
			items_input: true,
			items_max_text: "Kulturdriver",
			items_name_field: 'item_text',
			required: true,
			items_min_text: "Liten påvirkning",
			element_order: 14,
			question_text: "I hvilken grad påvirker disse personene kulturen på en skala fra 0 - 10",
			items_default_value: 0,
			items_min: 0,
			items_increment: 1,
			item_other_allowed: 0,
			items_max: 10,
			page_break: true,
			question_explanation_string: "",
			all_required: true
		},
		{
			element_type: 'section',
			element_order: 15,
			page_break: false,
			question_text: "NETTVERK",
			section_description: '<p>Det er i organisasjonens nettverk at kunnskap lever, arbeid skjer og tillit skapes</p>',
		},

		{
			element_type: 'search_choose',
			item_add_text: "LEGG TIL PERSON",
			item_other_allowed: true,
			items_max_number: 3,
			required: true,
			relationship: true,
			item_fields: ["Name"],
			required_fields: ["Name"],
			items_source: "Demo",
			items : itemList,
			items_source_id: "69e76306-8a43-4805-b842-3563327c1555",
			items_source_type: "LIST",
			text_in_button_switch: true,
			text_in_response_switch: true,
			element_order: 16,
			page_break: false,
			question_explanation_string: "Velg opp til tre kollegaer",
			question_export_name: "work-Problemsolving",
			question_text: "Hvilke kolleger kontakter du for informasjonsdeling og samhandling? ",			page_break: true,
		},

		{
			element_type: 'multi_slider_single_picker',
			items_source_type: 'QUESTION',
			items_input: true,
			items_max_text: "Veldig viktig",
			items_name_field: 'Veldig viktig',
			required: true,
			items_min_text: "Ikke veldig viktig",
			element_order: 17,
			question_text: "Hvor viktige er disse kollegene for deg på en skala fra 0 til 10?",
			items_default_value: 0,
			items_min: 0,
			items_increment: 1,
			item_other_allowed: 0,
			items_max: 10,
			page_break: true,
			question_explanation_string: "",
			all_required: true
		},

		{
			element_type: 'search_choose',
			item_add_text: "LEGG TIL PARTNER",
			item_other_allowed: true,
			page_break: false,
			items_max_number: 3,
			required: true,
			relationship: true,
			text_in_button_switch: true,
			item_fields: ["Name"],
			required_fields: ["Name"],
			items_source: "Demo",
			items : itemList,
			items_source_id: "69e76306-8a43-4805-b842-3563327c1555",
			items_source_type: "LIST",
			text_in_response_switch: true,
			element_order: 18,
			question_explanation_string: "Velg opp til tre partnere/leverandører",
			question_export_name: "innovation",
			question_text: "Hvilke partnere/leverandører kontakter du for informasjonsdeling og samhandling?",
		},

		{
			element_type: 'multi_slider_single_picker',
			items_source_type: 'QUESTION',
			items_input: true,
			items_max_text: "Always reach out for inputs",
			items_name_field: 'Veldig viktig',
			required: true,
			items_min_text: "Ikke veldig viktig",
			element_order: 19,
			question_text: "Hvor viktige er partnerne/leverandørene for deg i ditt arbeid? På en skala fra 0 til 10",
			items_default_value: 0,
			items_min: 0,
			items_increment: 1,
			item_other_allowed: 0,
			items_max: 10,
			page_break: true,
			question_explanation_string: "",
			all_required: true
		},

		{
			element_type: 'search_choose',
			item_add_text: "LEGG TIL KUNDE+",
			item_other_allowed: true,
			page_break: false,
			items_max_number: 3,
			required: true,
			relationship: true,
			text_in_button_switch: true,
			item_fields: ["Name"],
			items_name_field: "Name",
			required_fields: [],
			items_source: "Demo",
			items : itemList,
			items_source_id: "69e76306-8a43-4805-b842-3563327c1555",
			items_source_type: "LIST",
			text_in_response_switch: true,
			element_order: 20,
			question_explanation_string: "Velg opp til tre kunder. Om du ikke jobber med kunder ",
			question_export_name: "tech-Problemsolving",
			question_text: "Hvilke kunder kontakter du for informasjonsdeling og samhandling?  "
		},
		{
			element_type: 'multi_slider_single_picker',
			items_source_type: 'QUESTION',
			items_input: true,
			items_max_text: "Veldig viktig ",
			items_name_field: 'item_text',
			required: true,
			items_min_text: "Ikke veldig viktige",
			element_order: 21,
			question_text: "Hvor viktige er kundene for deg i arbeidet ditt på en skala fra 0 til 10?",			
			items_default_value: 0,
			items_min: 0,
			items_increment: 1,
			item_other_allowed: 0,
			items_max: 10,
			page_break: true,
			question_explanation_string: "",
			all_required: true
		},


		{
			element_type: 'section',
			element_order: 22,
			page_break: false,
			question_text: "MANUELLE PROSESSER",
			section_description: "<p>Manuelle prosesser skaper ofte misnøye i organisasjonen. </p>",
		},

		{
			element_order: 23,
			items_min_number: 1,
			element_type: 'multi_select',
			required: true,
			question_text: "Hvilke TRE manuelle prosesser mener du vi bør forenkle for å gjøre arbeidet enklere? ",
			item_other_allowed: true,
			style_type: 'text',
			max_characters: 30,
			items_other_allowed: 3,
			item_add_text: "LEGG TIL EN MANUELL PROSESS",
			item_placeholder_text: 'LEGG TIL+',
			page_break: true,
			items_max_number: 3,
			page_break:true,
			items: [
				{
					item_id: 'multi-item-id-1',
					item_text: 'Ineffektive / uorganiserte møter',
					item_order: 1
				},
				{
					item_id: 'multi-item-id-2',
					item_text: 'Finne de rette personene til å hjelpe',
					item_order: 2
				},
				{
					item_id: 'multi-item-id-3',
					item_text: 'Finne informasjon og dokumenter',
					item_order: 3
				},
				{
					item_id: 'multi-item-id-4',
					item_text: 'Mangel på klare oppgaver',
					item_order: 4
				},
				{
					item_id: 'multi-item-id-5',
					item_text: 'Dele informasjon og dokumenter',
					item_order: 5
				},
				{
					item_id: 'multi-item-id-6',
					item_text: 'Manuelle rutiner som burde vært automatisert ',
					item_order: 6
				},
				{
					item_id: 'multi-item-id-7',
					item_text: 'For mange prosesser',
					item_order: 7
				},
				{
					item_id: 'multi-item-id-8',
					item_text: 'Tunge og tidkrevende prosesser',
					item_order: 8
				},
				{
					item_id: 'multi-item-id-9',
					item_text: 'Forberedelse og dokumentasjon',
					item_order: 9
				},
				{
					item_id: 'multi-item-id-10',
					item_text: 'Ineffektivitet i fjernarbeidsteam',
					item_order: 10
				},
				{
					item_id: 'multi-item-id-11',
					item_text: 'Dårlig regional kommunikasjon',
					item_order: 11
				},
				{
					item_id: 'multi-item-id-12',
					item_text: 'Unødvendige e-poster',
					item_order: 12
				},
				{
					item_id: 'multi-item-id-13',
					item_text: 'Usikkerhet om hvor man finner ekspertise',
					item_order: 13
				},
				{
					item_id: 'multi-item-id-14',
					item_text: 'Rapportering og dokumentasjon',
					item_order: 14
				},
				{
					item_id: 'multi-item-id-15',
					item_text: 'Mangel på kommunikasjon',
					item_order: 15
				},
			],
			question_explanation_string: "Hvis du ikke finner noe som passer, kan du legge til ved å klikke på LEGG TIL EN MANUELL PROSESS og oppgi ditt valg. Du kan legge til opptil 3 separate tidstyver."		
		},
		{
			element_type: 'multi_slider_single_picker',
			items_source_type: 'QUESTION',
			items_input: true,
			items_max_text: "10 timer eller mer i uken",
			items_name_field: 'item_text',
			required: true,
			items_min_text: "Ingen tid",
			element_order: 24,
			question_text: "Hvor mange timer kan du spare per uke om de manuelle prosessene automatiseres/forenkles?",
			items_default_value: 0,
			items_min: 0,
			items_increment: 1,
			item_other_allowed: 0,
			items_max: 10,
			page_break: true,
			question_explanation_string: "",
			all_required: true
		},
		{
			element_type: 'section',
			element_order: 25,
			page_break: false,
			question_text: "TAKK FOR DIN DELTAGELSE",
			section_description: `<p> Din innsikt er viktig for oss, og din deltagelse i denne prosessen markerer et viktig skritt mot å realisere en samarbeidsorientert, effektivt og inkluderende organisasjon </p><p>Hva skjer videre?\t\t\t </p><ul><li>Dataanalyse og Innsikt: Vi vil analysere de innsamlede dataene for å kartlegge vårt interne nettverk og identifisere nøkkeltrender og mønstre i samarbeid og kunnskapsdeling </li><li>Strategisk Aksjonsplan: Basert på analysen, vil vi utvikle målrettede tiltak for å styrke våre interne nettverk, fremme innovasjon og effektivisere kunnskapsflyten.\t</li></ul><p>Med vennlig hilsen, </p><p> [FIRMANAVN]</p>`
		},
		{
			max_characters: 250,
			max_characters_allowed: true,
			element_type: 'text_box',
			page_break: true,
			required: false,
			element_order: 26,
			question_explanation_string: "Opp til 250 tegn",
			question_text: "Her kan du poste noen av dine refleksjoner og kommentarer om undersøkelsen eller annen relevant tilbakemelding!"		}
	]

