import React from 'react';
import './header.scss';
import { colorTheme } from '../assets/theme';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import AccountMenu from "./account-menu.js"
import transformLogoWhite from "../assets/images/white_transform.svg";
import AppMenu from './app-menu.js';
import ProtectedComponent from '../helper/protected-component';
import TooltipText from '../components/tooltip-text/tooltip-text.js';
import { ReactComponent as HelpIcon } from "../assets/images/menu/header/help-header-icon.svg";
import { ReactComponent as NotificationsIcon } from "../assets/images/menu/header/notifications-header-icon.svg";
import { ReactComponent as SettingsIcon } from "../assets/images/menu/header/settings-header-icon.svg";
import SurveyStatus from '../assets/survey-status.js';
import useHeader from '../hooks/header/use-header.js';

// Header component
function Header() {

    const {
        handleLogoClick,
        renderLogo,
        handleActionClick,
        handleAppMenuClick,
        userData,
        currentSurvey,
        surveyPage,
        activeEditPageTab,
        ACTION_SETTINGS
    } = useHeader(); // Desctructure from useHeader hook

    return (
        <Box className='header-container flex-center'>
            <Box className='user-settings flex-center'>
                <AppMenu />
            </Box>
            <img className="transform-logo" src={transformLogoWhite} alt="transform-logo" onClick={() => handleLogoClick()} />
            <Box className={`app`}>
            {
                renderLogo() // Render the logo based on the current path
            }
            </Box>
            {
                // Display the survey info if the currentSurvey, surveyPage and activeEditPageTab.tab is available
                currentSurvey && surveyPage && activeEditPageTab &&
                <Box className="survey-info">
                    <Typography variant='h6' style={{ fontSize: '16px', fontWeight: '400', letterSpacing: '0.15px'}}><span onClick={() => handleAppMenuClick('survey')} >Surveys</span><span> / {currentSurvey.name ? currentSurvey.name : 'Untitled'}</span></Typography>
                    <SurveyStatus status={currentSurvey.status} className="status-icon" />
                </Box>
            }
            <Box className="icons">
                <TooltipText name="Support" placement="bottom" backgroundColor={colorTheme.theme.transform.yellow} textColor={colorTheme.theme.transform.black} margin={'9px 0px 0px 0px'}>
                    <HelpIcon className='help-icon circle' style={{ color: colorTheme.theme.colors.lightGray }} />
                </TooltipText>
                <ProtectedComponent licenses={[]}>
                    <TooltipText name="Notifications" placement="bottom" backgroundColor={colorTheme.theme.transform.yellow} textColor={colorTheme.theme.transform.black} margin={'9px 0px 0px 0px'}>
                        <NotificationsIcon className='notification-icon' style={{ color: colorTheme.theme.colors.lightGray }} />
                    </TooltipText>
                </ProtectedComponent>
                <ProtectedComponent licenses={[]}>
                    <TooltipText name="Settings" placement="bottom" backgroundColor={colorTheme.theme.transform.yellow} textColor={colorTheme.theme.transform.black} margin={'9px 0px 0px 0px'}>
                        <SettingsIcon className='settings-icon' style={{ color: colorTheme.theme.colors.lightGray }} onClick={() => handleActionClick(ACTION_SETTINGS)} />
                    </TooltipText>
                </ProtectedComponent>
            </Box>
            <Box className="user-profile">
                {userData && <AccountMenu />}
            </Box>
        </Box>
    );
}

export default Header;