import React from 'react';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from '@material-ui/core/Dialog';
import Team from "../../users-management-page/team-tab"
import InviteUser from "../../users-management-page/invite-user-dialog"
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { DialogContent, Fab } from '@material-ui/core';
import { ReactComponent as EditIcon } from '../../assets/images/options/icon-edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/options/icon-delete.svg';
import { ReactComponent as PreviewIcon } from '../../assets/images/options/icon-preview.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/images/options/icon-duplicate.svg';
import { ReactComponent as PublishIcon } from '../../assets/images/options/icon-publish.svg';
import { ReactComponent as Collaborator } from '../../assets/images/options/icon-collaborator.svg';
import { colorTheme } from '../../assets/theme';
import DeleteSurveyDialog from '../dialogs/delete-survey';

// StyledMenu component
const StyledMenu = withStyles({
    paper: {
        boxShadow: '0px 1px 2.5px 0px #7B7D8982',
        marginTop: '26px',
        marginLeft: '26px'
    },
    list: {
        padding: '5px 0px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        {...props}
    />
));

// StyledMenuItem component
const StyledMenuItem = withStyles((theme) => ({
    root: {
        padding: '7.5px 20px 7.5px 14px',
        '& .MuiListItemIcon-root svg path': {
            transition: 'fill 0.3s'
        },
        '&:hover': {
            backgroundColor: colorTheme.theme.app.lightestGrey,

            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: colorTheme.theme.transform.darkBlue,
                '& svg path': {
                    fill: colorTheme.theme.transform.darkBlue,
                }
            },
        },
        '& .MuiListItemText-primary': {
            color: colorTheme.theme.text.mediumGrey,
            lineHeight: '1',
            transition: 'color 0.3s',
        },
        '& .MuiListItemIcon-root': {
            minWidth: '20px',
            display: 'flex',
            justifyContent: 'center',
            marginRight: '9px',
            transition: 'color 0.3s',
        },
    },
}))(MenuItem);

// SurveyOptions component
const SurveyOptions = (props) => {

    // Destructure the props
    const {
        anchorEl,
        handleMenuClose,
        handleMenuItemClick,
        dialogOpen,
        handleDialogClose,
        handleInvite,
        handleWorkspaceInvite,
        handleSelectedRows,
        assignedUsers,
        companyName,
        selectedSurveyName,
        deleteConfirmed,
        deleteDialogOpen,
        handleDeleteDialogClose,
        surveyToDelete,
        isLoading
    } = props;

    return (
        <>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <StyledMenuItem onClick={() => handleMenuItemClick('Edit')}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Edit" />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleMenuItemClick('Delete')}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleMenuItemClick('Preview')}>
                    <ListItemIcon>
                        <PreviewIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Preview" />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleMenuItemClick('Publish')}>
                    <ListItemIcon>
                        <PublishIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Publish" />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleMenuItemClick('Duplicate')}>
                    <ListItemIcon>
                        <DuplicateIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Duplicate" />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleMenuItemClick('Add_colaborators')}>
                    <ListItemIcon>
                        <Collaborator fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Add collaborator" />
                </StyledMenuItem>
            </StyledMenu>
            <Dialog className='colaborators-dialog' open={dialogOpen} onClose={handleDialogClose} maxWidth='md'>
                <DialogContent className='dialog-content'>
                    <div className='buttons-flex'>
                        <Fab variant='extended'
                            size='medium'
                            color='primary'
                            aria-label='add'
                            className='assign-button'
                            onClick={handleInvite}>
                            Assign selected users
                        </Fab>
                        <div className='invite-user-cmp'>
                            <InviteUser company={companyName} handleInvite={handleWorkspaceInvite} buttonName='Invite and assign new member' />
                        </div>
                    </div>
                    {assignedUsers && <Team source='survey-view' className='team-view' surveyName={`Add collaborator to ${selectedSurveyName} survey`} selectedRows={handleSelectedRows} assignedUsers={true} />}
                </DialogContent>
            </Dialog>
            <DeleteSurveyDialog open={deleteDialogOpen} handleClose={handleDeleteDialogClose} handleAction={deleteConfirmed} isLoading={isLoading} surveyToDelete={surveyToDelete}/>
        </>)
};

export default SurveyOptions;