import React, { useEffect, useState } from 'react';
import './survey-dashboard-container.scss';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie';
import Popup from 'reactjs-popup';
import * as _ from "lodash";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createEmptySurvey, getSurveys, listCognitoUsersAction, getOrganizationSurveys } from "../../../redux/actions";
import SurveyView from "./survey-view";
import TemplateView from './template-view';
import startFromScratchLogo from "../../../assets/images/start-from-scratch-logo.svg";
import startFromTemplateLogo from "../../../assets/images/start-from-template-logo.svg";
import usersRolesLogo from "../../../assets/images/users-roles-logo.svg";
import SurveyMenuAction from '../../../components/survey-menu-action/survey-menu-action';

// Styles for the component
const useStyles = makeStyles({
    root: {
        width: 170,
        height: 180
    },
});

// Constants to store the action types
const ACTION_START_SURVEY_BLANK = 'ACTION_START_SURVEY_BLANK';
const ACTION_START_SURVEY_TEMPLATE = 'ACTION_START_SURVEY_TEMPLATE';
const ACTION_MANAGE_ROLES = 'ACTION_MANAGE_ROLES';

// Constants
const VIEW_LIST = 'VIEW_LIST';
const VIEW_TILE = 'VIEW_TILE';

function SurveyDashboardContainer(props) {
    const classes = useStyles(); // Styles
    const history = useHistory(); // History hook to navigate
    const dispatch = useDispatch(); // Dispatch hook to dispatch actions
    const surveysFetched = useSelector(state => state.surveysFetched); // State to store if the surveys are fetched
    const cognitoUsers = useSelector(state => state.cognitoUsers); // State to store the cognito users 
    const [searchValue, setSearchValue] = useState(''); // State to store the search value
    const [surveyStatus, setSurveyStatus] = useState(''); // State to store the survey status
    const [selectedSort, setSelectedSort] = useState('name'); // State to store the selected sort
    const surveys = useSelector(state => state.surveys); // State to store the surveys
    const [sortType, setSortType] = useState('asc'); // State to store the sort type
    const [sortedSurveys, setSortedSurveys] = useState(_.cloneDeep(surveys)); // State to store the sorted surveys
    const [view, setView] = useState(Cookies.get('activeView') || VIEW_TILE); // State to store the view of the surveys (list or tile)
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const openModal = () => setOpen(true);
    const userTemplate = useSelector(state => state.userTemplate);

    // UseEffect to fetch the surveys and cognito users on the component mount
    useEffect(() => {
        if (!surveysFetched && userTemplate) {
            dispatch(getOrganizationSurveys());
            console.log(surveysFetched)
            // dispatch(getSurveys());
        }
        if (!cognitoUsers) {
            dispatch(listCognitoUsersAction())
        }
    }, [userTemplate, surveysFetched]);

    // UseEffect to sort the surveys when the selected sort changes or the surveys change
    useEffect(() => {
        if (selectedSort) {
            handleSortChange(selectedSort);
        }
    }, [surveys]);

    // Function to handle the sort change
    const handleSortChange = (value, type = 'asc') => {
        setSelectedSort(value);
        setSortType(type);

        // If the value is 'started' or 'lastEditedTime', toggle the sort direction
        let sortDirection = type;
        if (value === 'started' || value === 'lastEditedTime') {
            sortDirection = type === 'asc' ? 'desc' : 'asc';
        }

        // Sort the surveys based on the value and sort direction
        setSortedSurveys(
            _.orderBy(
                surveys,
                [s => {
                    if (s[value]) {
                        // For 'started' and 'lastEditedTime', return the date object, for others return the lowercase string
                        return value === 'started' || value === 'lastEditedTime' ? new Date(s[value]) : s[value].toLowerCase();
                    }
                    return 'untitled';
                }],
                [sortDirection]  // Sort direction
            )
        );
    };

    // Function to handle the change of the survey status
    const handleSurveyStatusChange = (status) => {
        setSurveyStatus(status);
    };

    // Function to handle the change of the search value
    const handleSearchChange = (value) => {
        setSearchValue(value);
    };

    // Function to handle the change of the view
    const handleActiveView = (view) => {
        setView(view);
        Cookies.set('activeView', view); // Set the view in the cookies
    }

    return (
        <div className='survey-dashboard-container flex-column-center'>
            <SurveyMenuAction surveyStatus={surveyStatus} handleSurveyStatusChange={handleSurveyStatusChange} selectedSort={selectedSort} handleSortChange={handleSortChange} sortType={sortType} searchValue={searchValue} handleSearchChange={handleSearchChange} view={view} handleActiveView={handleActiveView} />
            <div className="surveys-container flex-column-center">
                {
                    surveysFetched &&
                    <>
                        <SurveyView searchValue={searchValue} handleSearchChange={handleSearchChange} surveyStatus={surveyStatus} view={view} setView={setView} sortType={sortType} handleSortChange={handleSortChange} sortedSurveys={sortedSurveys} selectedSort={selectedSort} userTemplate={props.userTemplate} setOpenModal={openModal} />

                        <TemplateView open={open} handleClose={closeModal} />

                    </>
                }
                {
                    !surveysFetched &&
                    <CircularProgress className='loader' />
                }
            </div>
        </div>
    );

}



export default SurveyDashboardContainer;