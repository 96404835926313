import React, { useEffect, useCallback, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { MenuItem, Radio } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Box, Typography } from "@mui/material";
import { Divider } from "@material-ui/core";
import { updateQuestion } from "../../../../../../redux/actions";
import ListPreviousQuestions from "../../list-previous-questions";
import UploadListFromQuestion from "../../upload-list-from-question";
import SingleSliderAdvancedSettings from "../single-slider-question/single-slider-advanced-settings";
import { getListName, mapMenuItems, getListId } from "../../list-utils";
import { ITEMS_SOURCE_TYPE_LIST, ITEMS_SOURCE_TYPE_QUESTION, LIST_RADIO, QUESTION_RADIO } from '../../../../../../assets/constants';
import ToggleSwitchValue from "../../../../../../components/form/toogle-switch-value";
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

const LIST = 'LIST';
const uuid = require('uuid');

const MultiSliderAdvancedSettings = (props) => {
    const question = props.template;
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [itemsNameField, setItemsNameField] = useState('');
    const [selectedList, setSelectedList] = useState('');
    const [mappedLists, setMappedLists] = useState([]);
    const [isEnabled, setIsEnabled] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState('');

    let mappedList = [];

    useEffect(() => {
        if (currentSurvey.lists) {
            setMappedLists(mapMenuItems(currentSurvey, mappedList, 'custom'));
        }
        if (currentSurvey.respondents) {
            setMappedLists(mapMenuItems(currentSurvey, mappedList, 'respondents'))
        }

        if (question.answer_source) {
            setIsEnabled(question.answer_source);
        }
        if (question.items_input) {
            if (question.items_source_type === ITEMS_SOURCE_TYPE_LIST) {
                setSelectedList(getListId(question.items_input, currentSurvey.lists, currentSurvey.id));
                setSelectedRadio(LIST_RADIO);
            }
            if (question.items_source_type === ITEMS_SOURCE_TYPE_QUESTION) {
                setSelectedQuestion(question.items_input);
                setSelectedRadio(QUESTION_RADIO);
            }
        }

    }, [question, currentSurvey])


    const delayedUpdate = useCallback(_.debounce(question =>
        dispatch(updateQuestion(currentSurvey.id, question)), 1000),
        [dispatch, currentSurvey.id]);

    const updateMultiSliderAdvancedSettings = (value) => {
        let newQuestion = _.cloneDeep(question)
        const newObject = Object.assign(newQuestion, value)
        delayedUpdate(newObject);
    }

    useEffect(() => {
        if (itemsNameField && selectedQuestion) {
            updateMultiSliderAdvancedSettings({ items_name_field: itemsNameField, items_input: selectedQuestion });
        }
    }, [itemsNameField, selectedQuestion])

    const handleSelectMenuChange = (event) => {
        const { checked, value } = event.target;
        let updateObject = {}
        if (value === QUESTION_RADIO) {
            updateObject = checked ? { items_source_type: ITEMS_SOURCE_TYPE_QUESTION } : { items_source_type: undefined, items_input: undefined }
        } else if (value === LIST_RADIO) {
            updateObject = checked ? { items_source_type: ITEMS_SOURCE_TYPE_LIST, items_input: getListName(selectedList, mappedLists) } : { items_source_type: undefined, items_input: undefined }
        }
        setSelectedRadio(value);  // Update the selected radio state
        updateMultiSliderAdvancedSettings(updateObject);
    };

    const handleListSelect = ({ value }) => {
        const selectedList = currentSurvey.lists?.find(list => list.id === value) ||
            { columns: currentSurvey.respondents['columns'], data: currentSurvey.respondents['data'] };

        if (selectedList && selectedList.columns && selectedList.data) {
            const firstColumn = selectedList.columns.find(column => column.title !== 'tableData' && column.title !== 'Status')?.title;
            if (firstColumn) {
                const newOptions = selectedList.data.map((dataItem, index) => ({
                    item_id: uuid.v4(),
                    item_text: dataItem[firstColumn] || `Option ${index + 1}`,
                    item_value: 1,
                    order: index
                }));
                updateMultiSliderAdvancedSettings({
                    items_source_type: LIST,
                    items_source: getListName(value, mappedLists),
                    items_source_id: value,
                    items: newOptions
                });
            }
        }

        setSelectedList(value);
    };

    const handleToggleSwitch = (checked) => {
        setIsEnabled(checked);
        if (!checked) {
            updateMultiSliderAdvancedSettings({
                answer_source: false,
                items_source_type: undefined,
                items_input: undefined,
                items: []
            });
            setSelectedList('');
            setSelectedQuestion('');
            setSelectedRadio('');
        } else {
            updateMultiSliderAdvancedSettings({ answer_source: true });
        }
    };

    const handleListUploaded = (newList) => {
        const updatedMappedLists = [...mappedLists, newList];
        setMappedLists(updatedMappedLists);
        setSelectedList(newList.id);

        if (newList && newList.columns && newList.data) {
            const firstColumn = newList.columns.find(column => column.title !== 'tableData' && column.title !== 'Status')?.title;
            if (firstColumn) {
                const newOptions = newList.data.map((dataItem, index) => ({
                    item_id: uuid.v4(),
                    item_text: dataItem[firstColumn] || `Option ${index + 1}`,
                    item_value: 1,
                    order: index
                }));
                updateMultiSliderAdvancedSettings({
                    items_source_type: LIST,
                    items_source: getListName(newList.id, updatedMappedLists),
                    items_source_id: newList.id,
                    items: newOptions
                });
            }
        }
    };

    return (
        <div className='multi-slider-advanced-settings'>
            <Box ml={'19px'} mt={-1.5}>
                <ToggleSwitchValue
                    label='Set Answer Source'
                    value={isEnabled}
                    onChange={handleToggleSwitch}
                    description='Enable this feature to specify where the answers for this question will come from. Choose to either link to existing lists, upload a new list, or use answers from a previous question.'
                />
            </Box>
            <Box
                className={!isEnabled ? 'disabled' : ''}
                sx={{ display: 'flex', alignItems: 'center', mt: 2.5, ml: 2.5, gap: '4px' }}>
                <FormControlLabel
                    ml={0}
                    control={
                        <Radio
                            checked={selectedRadio === LIST_RADIO}
                            value={LIST_RADIO}
                            onChange={handleSelectMenuChange}
                            name='options-source-switch'

                        />
                    }
                    label='Use a List'
                    className='options-source-switch'
                />

                <FormControl variant="outlined" style={{ width: '174px' }}>
                    <InputLabel
                        sx={{
                            '&.Mui-focused': {
                                color: '#1B4972', // Color of the label when the select is focused
                                top: '0px', // Adjust the label position when focused
                            },
                            '&.MuiInputLabel-shrink': {
                                top: '0px', // Adjust the label position when shrink
                            },
                            top: '-3px',
                        }}>
                        Select list
                    </InputLabel>
                    <Select
                        labelId='source-option-select-label'
                        id='source-option-select'
                        value={selectedList || ''}
                        input={<OutlinedInput label="Select List" />}
                        onChange={e => handleListSelect(e.target)}
                        disabled={selectedRadio !== LIST_RADIO}  // Disable based on radio button state
                        sx={{
                            '.MuiSelect-select': {
                                padding: '13px', // Padding of the select
                                backgroundColor: '#FFFFFF',
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#D7D7D7', // Default border color
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1B4972', // Border color on hover
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1B4972', // Border color when focused
                                borderWidth: '1px', // Optionally, adjust the border width when focused
                            },
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            PaperProps: {
                                style: {
                                    marginLeft: 0,
                                },
                            },
                        }}
                    >
                        {mappedLists && mappedLists.length > 0 ? (
                            mappedLists.map((listItem, index) => (
                                <MenuItem key={index} value={listItem.id}>
                                    {listItem.title}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem
                                onClick={(e) => e.preventDefault()}
                                sx={{
                                    pointerEvents: 'none',
                                    display: 'block',
                                    whiteSpace: 'normal',
                                    textAlign: 'left',
                                    padding: '10px',
                                    '&:hover': {
                                        backgroundColor: 'transparent', // Prevents background color change on hover
                                    }

                                }}
                            >
                                <Typography sx={{
                                    color: '#4F4F4F',
                                    pointerEvents: 'none',
                                }}>
                                    No uploaded lists on this survey.
                                    <br />
                                    Please click on Upload New List button or upload list on
                                    <span
                                        style={{ textDecoration: 'none', color: '#1B4972', marginLeft: '5px', pointerEvents: 'auto', cursor: 'pointer' }}
                                    >
                                        Lists Page
                                    </span>.
                                </Typography>
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Typography ml={2} mr={2} variant="body1" sx={{ color: '#4F4F4F' }}>or</Typography>
                <UploadListFromQuestion disabled={!isEnabled || question.items_source_type !== ITEMS_SOURCE_TYPE_LIST} onListUploaded={handleListUploaded} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2.5, ml: 2.5, gap: '4px' }}
                className={!isEnabled ? 'disabled' : ''}
            >
                <FormControlLabel
                    control={
                        <Radio
                            checked={selectedRadio === QUESTION_RADIO}
                            value={QUESTION_RADIO}
                            onChange={handleSelectMenuChange}
                            name='questions-source-switch'
                        />
                    }
                    label='Use Previous Answers'
                    className='options-source-switch'
                />
                <ListPreviousQuestions
                    source={question.items_source_type}
                    questionId={question.id}
                    questions={currentSurvey.questions}
                    setItemsNameField={setItemsNameField}
                    setSelectedQuestion={setSelectedQuestion}
                    sourceQuestion={selectedQuestion}
                    disabled={!isEnabled || question.items_source_type !== ITEMS_SOURCE_TYPE_QUESTION}
                />
            </Box>
            <Divider
                style={{
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginBottom: '20px',
                    marginTop: '32px',
                }} />
            <SingleSliderAdvancedSettings template={question} from='multi-slider' />
        </div>
    )
}

export default MultiSliderAdvancedSettings;
