import React from 'react';
import { Box, Typography, Alert } from "@mui/material";
import InputField from "../form/input-field";
import PhoneInputField from "../form/phone-input-field";
import Tooltip from '@mui/material/Tooltip';
import PrimaryButton from "../buttons/primary-button";
import { colorTheme } from "../../assets/theme";

const PasswordInputComponent = ({ form, handleChange, passwordError, confirmPassError, handleNewPass, passPolicy, NEW_PASSWORD, NEW_PASSWORD_CONFIRMATION, FIRST_NAME, FAMILY_NAME, INPUT_NUMBER, firstNameReqiured, lastNameRequired, isLoading }) => (
    <div className='confirm-invitation-container form-container'>
        <Typography variant="body1" mt={1.5} mb={5} sx={{
            lineHeight: '19px', color: colorTheme.theme.text.mediumGrey
        }}>Welcome to Transform! To get started, please set a new password and complete your profile details.</Typography>
        <div className='form-inputs'>
            <Box flex={1} display="flex" flexDirection="row" justifyContent="left" alignItems="left">
                <Typography fontSize={15}>YOUR INFORMATION</Typography>
            </Box>
            <InputField label="First name*" value={form.first_name} onChange={(e) => handleChange(e.target.value, FIRST_NAME)} />

            {firstNameReqiured && (
                <span className='confirm-password-error'>
                    <Alert className='pass-error' severity='error'>Required</Alert>
                </span>
            )}
            <InputField label="Last name*" value={form.family_name} onChange={(e) => handleChange(e.target.value, FAMILY_NAME)} />
            {lastNameRequired && (
                <span className='confirm-password-error'>
                    <Alert className='pass-error' severity='error'>Required</Alert>
                </span>
            )}
            <PhoneInputField value={form.number ? form.number : ''} onChange={(number) => handleChange(number, INPUT_NUMBER)} />

            <Box mt={3} flex={1} display="flex" flexDirection="row" justifyContent="left" alignItems="left">
                <Typography fontSize={15}>SET YOUR NEW PASSWORD</Typography>

            </Box>
            <Box mt={-1.5} flex={1} display="flex" justifyContent="left" alignItems="left">
                <Typography color={colorTheme.theme.text.lightGrey} fontSize={14} textAlign="left" sx={{ lineHeight: '19px' }}>
                    Create a new password that's at least 8 characters long and includes a mix of letters, numbers, and symbols for enhanced security.
                </Typography>
            </Box>

            <Tooltip title={passPolicy}>
                <Box>
                    <InputField type="password" label="New password*" value={form.new_password} onChange={(e) => handleChange(e.target.value, NEW_PASSWORD)} />
                </Box>
            </Tooltip>
            {
                passwordError.error && passwordError.errorMessage &&
                <span className='password-error'>
                    <Alert className='pass-error' severity='error'>{passwordError.errorMessage}</Alert>
                </span>
            }
            <InputField type="password" label="Confirm new password*" value={form.new_password_confirmation} onChange={(e) => handleChange(e.target.value, NEW_PASSWORD_CONFIRMATION)} />
            {
                confirmPassError && (
                    confirmPassError.errorMessage === 'Required' ?
                        <span className='confirm-password-error'>
                            <Alert className='pass-error' severity='error'>{confirmPassError.errorMessage}</Alert>
                        </span> :
                        <span className='confirm-password-error'>
                            <Alert className='pass-error' severity='error'>Password confirmation does not match the original</Alert>
                        </span>
                )
            }
        </div>
        <div className='bottom-buttons'>
            <PrimaryButton
                isLoading={isLoading}
                onClick={handleNewPass} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="COMPLETE SETUP" />
        </div>
    </div>
);

export default PasswordInputComponent;