import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './phone-input-field.scss';
import InputField from './input-field-text-label';
import { colorTheme } from '../../assets/theme';
import { countryCodes } from '../../utils/phoneAreaCodes';

const PhoneInputField = ({ value, onChange, lineHeight = '14px', marginBottom = '6px', inputPadding = '15px 13px' }) => {
    const [stateAreaCode, setStateAreaCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [label, setLabel] = useState('');

    const splitPhoneNumber = (phoneNumber) => {
        for (let code of countryCodes) {
            if (phoneNumber.startsWith(code)) {
                const restOfPhoneNumber = phoneNumber.slice(code.length);
                return { stateAreaCode: code, restOfPhoneNumber };
            }
        }
        throw new Error("Invalid phone number format or unknown country code");
    };

    useEffect(() => {
        if (value) {
            try {
                const { stateAreaCode, restOfPhoneNumber } = splitPhoneNumber(value);
                setStateAreaCode(stateAreaCode);
                setPhoneNumber(restOfPhoneNumber);
                setLabel(restOfPhoneNumber);
            } catch (error) {
                console.error(error);
            }
        }
    }, [value]);

    useEffect(() => {
        if (stateAreaCode && phoneNumber) {
            onChange(stateAreaCode + phoneNumber);
        }
    }, [stateAreaCode, phoneNumber, onChange]);

    const handleAreaCodeChange = (code) => {
        if (!code.startsWith('+')) {
            code = '+' + code;
        }
        setStateAreaCode(code);
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (value === '' || /^\d+$/.test(value)) {
            setPhoneNumber(value);
            if (value === '') {
                setLabel(''); // Reset the label when the input is empty
            } else {
                setLabel(value);
            }
        }
    };

    const calculatePhoneInputWidth = () => {
        if (stateAreaCode.length === 2) {
            return '220px';
        }
        else if (stateAreaCode.length === 3) {
            return '208px';
        }
        else if (stateAreaCode.length === 4) {
            return '196px';
        }
    };

    return (
        <Box>
            <Box component="label" sx={{
                color: colorTheme.theme.text.lightGrey,
                display: 'block',
                marginBottom: '8px',
                fontSize: '12px',
                letterSpacing: '0px',
                lineHeight: '10px'
            }}>
                Phone
            </Box>
            <Box className='phone-input' sx={{ display: 'flex', width: '300px' }}>
                <PhoneInput
                    defaultCountry="SA"
                    value={stateAreaCode}
                    onChange={handleAreaCodeChange}
                    inputProps={{
                        readOnly: true,
                        style: { width: `${stateAreaCode.length * 12}px` }, // Adjust width as needed
                        onClick: () => { }, // Ensure this input is clickable
                    }}
                />
                <InputField
                    type="number"
                    label={label}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    textFieldWidth={calculatePhoneInputWidth()}
                />
            </Box>
        </Box>
    );
};

export default PhoneInputField;
