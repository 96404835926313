import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { addQuestion, reorderQuestions, updateSurveyEditTime } from "../../redux/actions";
import { MULTI_SLIDER_SINGLE_PICKER, SEARCH_CHOOSE, SECTION } from "../../assets/constants";
const uuid = require('uuid');

// Reorder function to move items within a list
const reorder = (list, startIndex, endIndex, deleteCount) => {
    const result = Array.from(list);
    const removed = result.splice(startIndex, deleteCount);
    result.splice(endIndex, 0, ...removed);
    return result;
};

// Custom hook to manage survey questions
const useQuestions = (currentSurvey) => {
    const dispatch = useDispatch();
    const questionsFetched = useSelector(state => state.questionsFetched);
    const [openAddQuestionDialog, setOpenAddQuestionDialog] = useState(false);
    const [prevQuestion, setPrevQuestion] = useState(null);
    const [questionInFocus, setQuestionInFocus] = useState(null);
    const currentTimestamp = new Date().toISOString();

    // Handle opening the add question dialog
    const handleClickAddQuestion = (prevQuestion) => {
        setPrevQuestion(prevQuestion);
        handleAddQuestionDialogOpen();
    };

    // Scroll to a specific question (down)
    const scrollToQuestionDown = (questionId) => {
        const element = document.getElementById(questionId);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset + 200;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    // Scroll to a specific question (up)
    const scrollToQuestionUp = (questionId) => {
        const element = document.getElementById(questionId);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - 800;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    // Add a new question to the survey
    const handleAddQuestion = (type, styleType) => {
        if (type) {
            setOpenAddQuestionDialog(false);

            let question = {
                id: uuid.v4(),
                prevQuestion: prevQuestion,
                element_type: type,
                style_type: styleType ? styleType : null,
                expanded: true,
            };

            if (type === SECTION) {
                question.items = [];
            }

            if (type === SEARCH_CHOOSE) {
                question.relationship = true;
            }

            if (type === MULTI_SLIDER_SINGLE_PICKER) {
                question.items_max = 10;
                question.items_min = 1;
                question.items_increment = 1;
                question.all_required = true;
            }

            handleChangeFocus(question.id);
            dispatch(addQuestion(currentSurvey.id, question));

            setTimeout(() => {
                updateSurveyEditTime(currentTimestamp);
            }, 1000);

        }
    };

    // Open the add question dialog
    const handleAddQuestionDialogOpen = () => {
        setOpenAddQuestionDialog(true);
    };

    // Close the add question dialog
    const handleAddQuestionDialogClose = () => {
        setOpenAddQuestionDialog(false);
    };

    // Change the focus to a specific question
    const handleChangeFocus = (questionId) => {
        if (questionId !== questionInFocus) {
            setQuestionInFocus(questionId);
        }
    };

    // Handle the end of a drag event for reordering questions
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const question = currentSurvey.questions.find(q => q.id === result.draggableId);
        let items;
        if (question && question.element_type === SECTION) {
            const questionsAfterSection = currentSurvey.questions.slice(result.source.index + 1);
            const nextSectionIndex = questionsAfterSection.findIndex(q => q.element_type === SECTION);
            const sectionQuestionsNumber = nextSectionIndex === -1 ?
                questionsAfterSection.length :
                nextSectionIndex;
            items = reorder(
                currentSurvey.questions,
                result.source.index,
                result.destination.index,
                sectionQuestionsNumber + 1
            );
        } else {
            items = reorder(
                currentSurvey.questions,
                result.source.index,
                result.destination.index,
                1
            );
        }

        currentSurvey.questions.forEach(q => {
            const question = document.getElementById(q.id);
            if (question) {
                question.style.height = "";
            }
        });

        let updatedSurveyQuestions = _.cloneDeep(currentSurvey.questions);
        items.forEach((q, index) => {
            let questionIndex = updatedSurveyQuestions.findIndex(question => question.id === q.id);
            if (index === items.length - 1) {
                updatedSurveyQuestions[questionIndex].nextQuestion = null;
            } else {
                updatedSurveyQuestions[questionIndex].nextQuestion = items[index + 1].id;
            }
            if (index === 0) {
                updatedSurveyQuestions[questionIndex].prevQuestion = null;
            } else {
                updatedSurveyQuestions[questionIndex].prevQuestion = items[index - 1].id;
            }
        });

        dispatch(reorderQuestions(currentSurvey.id, updatedSurveyQuestions));
        dispatch(updateSurveyEditTime(currentTimestamp));
    };

    // Handle the start of a drag event
    const onDragStart = (value) => {
        const draggableId = value ? value.draggableId : null;
        if (draggableId) {
            handleChangeFocus(draggableId);
            let questionIndex = currentSurvey.questions.findIndex(q => q.id === draggableId);
            if (currentSurvey.questions[questionIndex].element_type === SECTION) {
                let currentQuestion = currentSurvey.questions[questionIndex + 1];
                while (currentQuestion && currentQuestion.element_type !== SECTION) {
                    const question = document.getElementById(currentQuestion.id);
                    question.style.height = "0px";
                    questionIndex++;
                    currentQuestion = currentSurvey.questions[questionIndex + 1];
                }
            }
        }
    };

    // Move a question up in the list
    const moveQuestionUp = (questionId) => {
        const index = currentSurvey.questions.findIndex(q => q.id === questionId);
        if (index > 0) {
            const items = reorder(
                currentSurvey.questions,
                index,
                index - 1,
                1
            );

            let updatedSurveyQuestions = _.cloneDeep(currentSurvey.questions);
            items.forEach((q, idx) => {
                let questionIndex = updatedSurveyQuestions.findIndex(question => question.id === q.id);
                if (idx === items.length - 1) {
                    updatedSurveyQuestions[questionIndex].nextQuestion = null;
                } else {
                    updatedSurveyQuestions[questionIndex].nextQuestion = items[idx + 1].id;
                }
                if (idx === 0) {
                    updatedSurveyQuestions[questionIndex].prevQuestion = null;
                } else {
                    updatedSurveyQuestions[questionIndex].prevQuestion = items[idx - 1].id;
                }
            });

            dispatch(reorderQuestions(currentSurvey.id, updatedSurveyQuestions));
            dispatch(updateSurveyEditTime(currentTimestamp));
            scrollToQuestionUp(questionId);
        }
    };

    // Move a question down in the list
    const moveQuestionDown = (questionId) => {
        const index = currentSurvey.questions.findIndex(q => q.id === questionId);
        if (index < currentSurvey.questions.length - 1) {
            const items = reorder(
                currentSurvey.questions,
                index,
                index + 1,
                1
            );

            let updatedSurveyQuestions = _.cloneDeep(currentSurvey.questions);
            items.forEach((q, idx) => {
                let questionIndex = updatedSurveyQuestions.findIndex(question => question.id === q.id);
                if (idx === items.length - 1) {
                    updatedSurveyQuestions[questionIndex].nextQuestion = null;
                } else {
                    updatedSurveyQuestions[questionIndex].nextQuestion = items[idx + 1].id;
                }
                if (idx === 0) {
                    updatedSurveyQuestions[questionIndex].prevQuestion = null;
                } else {
                    updatedSurveyQuestions[questionIndex].prevQuestion = items[idx - 1].id;
                }
            });

            dispatch(reorderQuestions(currentSurvey.id, updatedSurveyQuestions));
            scrollToQuestionDown(questionId);
            dispatch(updateSurveyEditTime(currentTimestamp));
        }
    };

    return {
        questionsFetched,                    // Indicates if questions have been fetched
        openAddQuestionDialog,               // State for the add question dialog visibility
        questionInFocus,                     // ID of the question currently in focus
        setPrevQuestion,                     // Sets the previous question
        handleAddQuestion,                   // Function to handle adding a new question
        handleAddQuestionDialogOpen,         // Opens the add question dialog
        handleAddQuestionDialogClose,        // Closes the add question dialog
        handleClickAddQuestion,              // Handles the click to add a question
        handleChangeFocus,                   // Changes the focus to a specific question
        onDragEnd,                           // Handles the end of a drag event
        onDragStart,                         // Handles the start of a drag event
        moveQuestionUp,                      // Moves a question up in the list
        moveQuestionDown,                    // Moves a question down in the list
        SECTION                              // Constant representing the SECTION question type
    };
};

export default useQuestions;
