import React from 'react';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { colorTheme } from '../../assets/theme';

const CustomFormControlLabel = ({ checked, onChange, label, color, labelFontWeight, labelFontSize, thumbColor }) => {
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={onChange}
                    sx={{
                        '& .MuiSwitch-switchBase': {
                            color: thumbColor,
                            '&:hover': {
                                backgroundColor: `${thumbColor}33`, // Add hover color for unchecked state
                            },
                        },
                        '& .MuiSwitch-switchBase.Mui-checked': {
                            color: color,
                            '&:hover': {
                                backgroundColor: `${color}33`,
                            },
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: color,
                        },
                        '& .MuiSwitch-track': {
                            backgroundColor: colorTheme.theme.transform.gray,
                        },
                    }}
                />

            }
            label={<Typography
                fontWeight={labelFontWeight}
                fontSize={labelFontSize}
                sx={{
                    whiteSpace: 'nowrap',
                }}>
                {label}
            </Typography>}
            labelPlacement="start"
            sx={{
                fontSize: '16px',
                fontWeight: 600,
                ml: 0,
                pl: 0,
                justifyContent: 'left',
                width: '100%',
                marginBottom: '8px',
                display: 'flex',
                alignItems: 'center',
            }}
        />
    );
};

export default CustomFormControlLabel;
