import React from "react";
import './input-search.scss';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as XIcon } from '../../assets/images/x-icon.svg';

// InputSearch component
const InputSearch = ({ value, placeholder, onChange, icon, width = 'initial' }) => {
    return (
        <OutlinedInput
            placeholder={placeholder}
            className='input-search'
            value={value}
            onChange={(e) => onChange(e.target.value)}
            startAdornment={
                <InputAdornment position="start" className='input-search-icon'>
                    {icon}
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end" className="delete-icon">
                    {value && <XIcon onClick={() => onChange('')} />}
                </InputAdornment>
            }
            style={{ width: width }}
        />
    );
};

export default InputSearch;
