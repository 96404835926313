import React from "react";
import { ReactComponent as DoneIcon } from '../../assets/images/table/icon-done.svg';
import { ReactComponent as InProgressIcon } from '../../assets/images/table/icon-in-progress.svg';
import { ReactComponent as NotStartedIcon } from '../../assets/images/table/icon-not-started.svg';
import { Box } from "@material-ui/core";

// StatusColumn component
const StatusColumn = ({ status }) => {
    let Icon; // Icon to display
    let label; // Label to display

    // Switch statement to determine the icon and label based on the status
    switch (status) {
        case 'SENT':
            Icon = NotStartedIcon;
            label = 'Not Started';
            break;
        case 'NOT-SENT':
            Icon = NotStartedIcon;
            label = 'Not Sent';
            break;
        case 'NOT-STARTED':
            Icon = NotStartedIcon;
            label = 'Not Started';
            break;
        case 'SUBMITTED':
            Icon = DoneIcon;
            label = 'Done';
            break;
        case 'STARTED':
            Icon = InProgressIcon;
            label = 'In progress';
            break;
        case 'SENDING_ERROR':
            Icon = InProgressIcon;
            label = 'Bounced';
            break;
        case 'EMAIL_BOUNCED':
            Icon = NotStartedIcon;
            label = 'Bounced';
            break;
        case 'REMINDED':
            Icon = NotStartedIcon;
            label = 'Reminded';
            break;
        default:
            Icon = null;
            label = 'Unknown';
    }

    return (
        <Box display="flex" alignItems="center" sx={{ gap: '8px' }}>
            {Icon && <Icon />}
            {label}
        </Box>
    );
}

export default StatusColumn;
