import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Avatar, Box } from '@mui/material';
import TableActions from './table-actions';
import LicenceButton from '../buttons/licence-button';
import { colorTheme } from '../../assets/theme';
import { ReactComponent as AvatarIcon } from "./../../assets/images/table/avatar-table.svg";

// TableRow component
function TableRowComponent({ row, isItemSelected, headCells, handleDelete, handleEdit, onClickEdit, onClickDelete, licenseColors, borderColors, textColor, isTeammates }) {


    return (
        // Render a table row
        <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            sx={{
                height: '56px',
                '& > *': {
                    pt: '0 !important', // Set the padding top to 0
                    pb: '0 !important', // Set the padding bottom to 0
                    pl: '0px',          // Set the padding left to 0
                    pr: '0px',         // Set the padding right to 16px
                },
            }}
        >
            {headCells?.map((headCell) => (
                <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    sx={{
                        pl: '25px !important',
                        color: headCell.id === 'surveys' ? colorTheme.theme.transform.darkBlueTwo : 'inherit',
                        width: headCell.width,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        boxSizing: 'border-box',
                        maxWidth: headCell.width,
                        minWidth: headCell.width,
                    }}
                >
                    {headCell.id === 'user' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {row?.avatar ? (
                                <Avatar
                                    alt="User Avatar"
                                    src={row?.avatar}
                                    sx={{ width: 28, height: 28, mr: '9px' }}
                                />
                            ) : (
                                <AvatarIcon
                                    style={{
                                        width: 28,
                                        height: 28,
                                        marginRight: '9px',
                                        flexShrink: 0
                                    }}
                                />
                            )}
                            <Box
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    maxWidth: '150px', // Adjust the max-width as needed
                                }}
                            >
                                {row[headCell.id]}
                            </Box>
                        </Box>

                    ) :



                        headCell.id === 'status' ? (
                            // Handle status by rendering the status label
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '6px',
                                    }}
                                >
                                    {row.icon}
                                    <span>{row?.status}</span>
                                </Box>
                            </Box>

                        ) : headCell.id === 'role' ? (
                            // Handle license(s) by rendering LicenceButton for each license
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                                {Array.isArray(row?.[headCell.id]) ? (
                                    row[headCell.id].map((license, index) => (
                                        <LicenceButton
                                            key={index}
                                            text={license}
                                            textColor={row?.status === 'Pending' ? textColor['Pending'] : textColor[license]} // Change color based on "Pending" status
                                            backgroundColor={row?.status === 'Pending' ? licenseColors['Pending'] : licenseColors[license]} // Change color based on "Pending" status
                                            borderColor={row?.status === 'Pending' ? borderColors['Pending'] : borderColors[license]} // Change color based on "Pending" status
                                        />
                                    ))
                                ) : null}
                            </Box>
                        ) : headCell.id === 'surveys' ? (
                            // Handle surveys by rendering a clickable element
                            <Box onClick={() => onClickEdit(row)} sx={{ cursor: 'pointer' }}>
                                {row[headCell.id]}
                            </Box>
                        ) : (
                            row?.[headCell.id]
                        )}
                </TableCell>
            ))}

            <TableCell
                sx={{ pr: '20px' }}
                align="right"
            >
                <TableActions row={row} handleDelete={handleDelete} handleEdit={handleEdit} onClickEdit={onClickEdit} onClickDelete={onClickDelete} isTeammates={isTeammates} />
            </TableCell>
        </TableRow>
    );
}

TableRowComponent.propTypes = {
    row: PropTypes.object.isRequired,
    isItemSelected: PropTypes.bool.isRequired,
    labelId: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    headCells: PropTypes.array.isRequired,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
};

export default TableRowComponent;
