import React, { useEffect, useState } from 'react';
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createTheme } from '@mui/material/styles';import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from '@material-ui/lab/Alert';

import { getUserCredentials, verifyCustomerDb } from './services/db-service';
import './App.css';
import './landing-page.scss'
import blackTransformLogo from "./assets/images/black_transform.svg";

const colorTheme = createTheme({
    palette: {
        primary: {
            main: '#3949B3'
        }
    }
});

function LandingPage() {
    // use media query hook
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const [domainValue, setDomainValue] = useState('');
    const [nonExistingDomain, setNonExistingDomain] = useState(false);

    useEffect(() => {
    }, []);

    const handleDomainInput = (value) => {
        setDomainValue(value);
    }

    const handleRedirectToSignin = async () => {
        setNonExistingDomain(false)
        if (domainValue !== '') {
            let hostParts = window.location.host.split('.');
            let data = await getUserCredentials(domainValue)
            if (!data || Object.entries(data).length === 0 ) {
                setNonExistingDomain(true)
            } else {
                const redirectPath = `http://${domainValue}.${hostParts[1]}.${hostParts[2]}/signin`;
                window.open(redirectPath, '_self');
            }
        }
    }

    return (
        <div className="main-container-landing-page">
            <div className="form flex-column-center">
                <div className='check-domain-input'>
                    <div className='domain-input-container'>
                        <div className="transform-logo-container">
                            <img className="transform-logo-img" src={blackTransformLogo} alt="transform21-logo" />
                        </div>
                        <div className='content-container'>
                            <h5 className='caption'> That subdomain is not recognized. Please, provide a subdomain (the part before .transform-norway.no, and it usually consists of your company name) and we will take you to your sign-in page. If you still have issues please reach out to us at support@transformnorway.com</h5>
                            <div className='input-container'>
                                <TextField className='domain-input' label='company' variant='standard'
                                    value={domainValue || ''}
                                    onChange={(e) => handleDomainInput(e.target.value)}
                                    InputProps={{
                                        //use subdomain variable
                                        endAdornment: <InputAdornment position="end" color='blue'>.transforminsight.com</InputAdornment>,
                                    }}
                                />


                                <Fab className='submit-button' variant='extended'
                                    size='medium'
                                    color='primary'
                                    onClick={handleRedirectToSignin}>
                                    Find my company
                            </Fab>
                            </div>
                            {nonExistingDomain && <span className='domain-error-span'>
                                <Alert className='domain-error' severity='error'>That subdomain can't be found, please try another one</Alert>
                            </span>}
                        </div>
                    </div>
                </div>
                {/* Landing page, user not logged in, no company (or non existent company) in URL. */}
            </div>
        </div>
    );
}

export default LandingPage;