import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { updateQuestion } from "../../../redux/actions";
import { isStringDigits } from "./../../../pages/survey-page/edit-tab/questions-tab/questions/questionUtil";

const useSingleSliderQuestion = (question) => {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);

    const [sliderValue, setSliderValue] = useState(question.item_default_value || '1');

    const [sliderDetails, setSliderDetails] = useState({
        item_min: question.item_min || '1',
        item_max: question.item_max || '10',
        item_increment: question.item_increment || '1'
    });


    useEffect(() => {
        let updateObject = {};
        if (question.item_max === undefined) {
            updateObject['item_max'] = 10;
        }
        if (question.item_min === undefined) {
            updateObject['item_min'] = 1;
        }
        if (question.item_increment === undefined) {
            updateObject['item_increment'] = 1;
        }
        if (Object.keys(updateObject).length > 0) {
            let newQuestion = _.cloneDeep(question);
            const newObject = Object.assign(newQuestion, updateObject);
            dispatch(updateQuestion(currentSurvey.id, newObject));
        }
    }, [question, dispatch, currentSurvey.id]);

    const delayedUpdate = useCallback(_.debounce(question =>
        dispatch(updateQuestion(currentSurvey.id, question)), 300), [dispatch, currentSurvey.id]);

    const updateSingleSliderQuestion = (value) => {
        let newQuestion = _.cloneDeep(question);
        const newObject = Object.assign(newQuestion, value);
        delayedUpdate(newObject);
    };

    const handleSliderValue = (event, sliderValue) => {
        setSliderValue(sliderValue);
        updateSingleSliderQuestion({ item_default_value: Number(sliderValue) });
    };

    const handleSliderDetails = ({ value, name }) => {
        setSliderDetails({
            ...sliderDetails,
            [name]: value
        });
        if (isStringDigits(value)) {
            value = Number(value);
            const updateObject = { [name]: value };
            updateSingleSliderQuestion(updateObject);
        }
    };

    return { sliderValue, sliderDetails, handleSliderValue, handleSliderDetails };
};

export default useSingleSliderQuestion;
