import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { updateSurveyName } from "../../redux/actions";

const useSurveyName = (initialName) => {
    const dispatch = useDispatch();
    const [surveyName, setSurveyName] = useState(initialName || '');

    const delayedUpdate = useCallback(
        _.debounce(name => dispatch(updateSurveyName(name)), 500),
        [dispatch]
    );


    const handleSurveyNameChange = (value) => {
        setSurveyName(value);
        delayedUpdate(value);
    };

    return [surveyName, handleSurveyNameChange];
};

export default useSurveyName;
