import React from 'react';
import PropTypes from 'prop-types';
import { Box, Paper, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTeamTable } from '../../hooks/teammates/use-team-table';
import TableHeadComponent from './table-head';
import TableRowComponent from './table-row';
import UserDialogs from './team-table-dialogs';
import AddButton from '../buttons/add-button';
import SearchBar from '../form/search-bar';
import EmptyStateRow from './empty-table-row';
import AddTeammateDialog from '../dialogs/add-teammate-survey';
import SurveyAdminInfo from '../dialogs/survey-admin-info';
import { ReactComponent as CollaboratorIcon } from "./../../assets/images/add-collaborator-icon.svg";
import TitleWithIcon from "../../components/title/title-icon";
import { ReactComponent as TeammatesIcon } from "../../assets/images/title/teammates.svg";
import { ReactComponent as EmptySearchIcon } from "./../../assets/images/actions/empty-search.svg";
import { ReactComponent as EmptyTableIcon } from "./../../assets/images/actions/icon-empty-table.svg";
import { getRowsPerPageOptions } from '../../utils/rows-options';
import './team-table.scss';
import { colorTheme } from '../../assets/theme';


// Define the TeamTable component
export default function TeamTable({ title, rows, headCells, handleDelete, handleEdit, handleAddUser,
    setSurveyLicense, setInsightsLicense, setRole, colSpan, width, teamRoleOptions, licenseColors, borderColors, isLoading,
    textColors, isTeammates
}) {
    // Use the useTeamTable hook to get table functionality

    const rowsPerPageOptionsNumbers = getRowsPerPageOptions(rows.length); // Get the rows per page options based on the length of the rows



    const {
        handleSelectAllClick,
        handleClick,
        handleChangePage,
        handleChangeRowsPerPage,
        isSelected,
        handleSearchChange,
        toggleAddDialog,
        toggleEditDialog,
        toggleDeleteDialog,
        selectedRow,
        isEditDialogOpen,
        isAddDialogOpen,
        isDeleteDialogOpen,
        filteredRows,
        selected,
        page,
        rowsPerPage,
        searchMade,
        statusFilter,
        roleFilter,
        handleStatusFilterChange,
        handleRoleFilterChange,

    } = useTeamTable(rows, headCells);

    // Render the component
    return (

        <>

            {isTeammates ? (
                <UserDialogs
                    isAddDialogOpen={isAddDialogOpen}
                    toggleAddDialog={toggleAddDialog}
                    handleAddUser={handleAddUser}
                    setInsightsLicense={setInsightsLicense}
                    setSurveyLicense={setSurveyLicense}
                    setRole={setRole}
                    isEditDialogOpen={isEditDialogOpen}
                    toggleEditDialog={toggleEditDialog}
                    selectedRow={selectedRow}
                    handleEdit={handleEdit}
                    isDeleteDialogOpen={isDeleteDialogOpen}
                    toggleDeleteDialog={toggleDeleteDialog}
                    handleDelete={handleDelete}
                    isLoading={isLoading}
                />
            ) : (
                // Your else condition goes here. For example:
                <>
                    <AddTeammateDialog
                        open={isAddDialogOpen}
                        handleClose={toggleAddDialog} />

                    <SurveyAdminInfo
                        open={isEditDialogOpen}
                        onClose={toggleEditDialog}
                        user={selectedRow}
                    /></>
            )}

            <Box
                className="team-table-container"
                sx={{ width: { width }, background: "white", borderRadius: "5px" }}>
                <Box pl={3} pt={3}>
                    <TitleWithIcon title={title} Icon={TeammatesIcon} />
                </Box>
                <Box
                    className="search-add"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end', // This ensures vertical center alignment
                        pb: 3, // Margin bottom
                        pt: 3, // Margin top
                        pl: 3, // Margin left
                        pr: 3, // Margin right
                    }}>
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}> {/* Keep this to ensure SearchBar aligns properly */}
                        <SearchBar
                            text={"Search"}
                            width='213spx'
                            height='40px'
                            onChange={(e) => handleSearchChange(e.target.value)}
                        />
                    </Box>
                    <Box
                        className="add-button-search"
                        sx={{ display: 'flex', alignItems: 'center' }}> {/* This ensures the button aligns with the search bar */}
                        <AddButton
                            Icon={CollaboratorIcon} text="Add Teammate" onClick={toggleAddDialog} hoverColor={colorTheme.theme.transform.darkBlue}
                        />
                    </Box>
                </Box>



                <Paper
                    className='team-table-paper'
                    sx={{ width: '100%', mb: 1, boxShadow: 'none', padding: '0px 24px', boxSizing: 'border-box' }}>
                    <TableContainer sx={{ overflowX: 'auto' }}>
                        <Table
                            className='team-table'
                            sx={{ width: '100%', boxShadow: 'none' }}
                            aria-labelledby="tableTitle"
                        >
                            {/* Table head component */}
                            <TableHeadComponent
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={rows.length}
                                headCells={headCells}
                                statusFilter={statusFilter}
                                onStatusFilterChange={handleStatusFilterChange}
                                roleFilter={roleFilter}
                                onRoleFilterChange={handleRoleFilterChange}
                                teamRoleOptions={teamRoleOptions}

                            />

                            {/* Table body */}
                            <TableBody>
                                {filteredRows.length > 0 ? (
                                    filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRowComponent
                                                key={row.id}
                                                row={row}
                                                isItemSelected={isItemSelected}
                                                labelId={labelId}
                                                handleClick={handleClick}
                                                headCells={headCells}
                                                handleDelete={handleDelete}
                                                onClickEdit={toggleEditDialog}
                                                onClickDelete={toggleDeleteDialog}
                                                licenseColors={licenseColors}
                                                borderColors={borderColors || 'none'}
                                                textColor={textColors}
                                                isTeammates={isTeammates}
                                                handleEdit={handleEdit} />

                                        );
                                    })
                                )
                                    : searchMade ? (
                                        <EmptyStateRow
                                            colSpan={colSpan}
                                            icon={EmptySearchIcon}
                                            mainMessage="No results found for your search."
                                            secondaryMessages={[
                                                "Please check the spelling or try a different name, surname, or email address.",
                                                "Consider using partial names or emails if you're not sure of the exact details."
                                            ]}
                                        />
                                    ) : (
                                        <EmptyStateRow
                                            colSpan={colSpan}
                                            icon={EmptyTableIcon}
                                            mainMessage="Hey, it looks like you haven’t added any teammates yet."
                                            secondaryMessages={["Add your first teammate to begin collaborating."]}
                                        />
                                    )}

                            </TableBody>

                        </Table>
                    </TableContainer>
                    {/* Table pagination */}
                    {rows.length > 0 && (
                        <TablePagination
                            sx={{
                                pt: 5,
                                mr: 0,
                                '& .MuiTablePagination-toolbar': {
                                    fontSize: '12px',
                                    color: '#9A9A9A',
                                },
                                '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                                    fontSize: '12px',
                                    color: '#9A9A9A',
                                    mr: -1,
                                },
                                '& .MuiTablePagination-input': {
                                    fontSize: '12px',
                                    color: '#9A9A9A',
                                    mr: 1.7,
                                },
                                '& .MuiTablePagination-actions': {
                                    '& button': {
                                        margin: '0px',
                                        padding: '8px', // Adjust padding to ensure the button is more square
                                        transform: 'scale(0.9)',
                                        minWidth: 'auto', // Remove any minimum width restrictions
                                        width: '25px', // Equal width and height to form a square
                                        height: '25px', // Set height equal to width
                                        '& svg': {
                                            marginLeft: '0px', // Adjust if needed
                                            marginRight: '0px', // Adjust if needed
                                        },
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Set the background color on hover
                                            borderRadius: '50%', // Make the hover background rounded
                                            transform: 'scale(1.1)', // Optionally scale a bit on hover
                                        }
                                    }
                                }
                            }}
                            rowsPerPageOptions={rowsPerPageOptionsNumbers}
                            component="div"
                            count={filteredRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Users per page"
                        />

                    )}
                </Paper>
            </Box></>
    );
}

// Define the prop types for the TeamTable component
TeamTable.propTypes = {
    rows: PropTypes.array.isRequired,
    headCells: PropTypes.array.isRequired,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
};