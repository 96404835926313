// This function converts a Unix timestamp to a local time string.
export function convertUnixToLocalTime(timestamp) {


    // If the timestamp is undefined, null, or falsy, return a default message.
    if (!timestamp) {
        return 'No sign-in time available'; // Default message if timestamp is undefined or null
    }

    // Convert the Unix timestamp to a JavaScript Date object.
    const date = new Date(timestamp * 1000);

    // These options will format the date as "Month Day, Year, HH:MM AM/PM".
    const options = {
        year: 'numeric',  // Display the year as a 4-digit number.
        month: 'long',   // Display the month as a full word (e.g., "December").
        day: 'numeric',  // Display the day as a number.
        hour: '2-digit', // Display the hour as a 2-digit number.
        minute: '2-digit', // Display the minute as a 2-digit number.
        hour12: true,    // Use 12-hour time (as opposed to 24-hour time).
    };

    // Convert the Date object to a string using the specified options and return it.
    return date.toLocaleString('en-US', options);
}