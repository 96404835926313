import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateQuestion } from "../../../redux/actions";

// Constant for custom option value
const CUSTOM_OPTION = 'CUSTOM_OPTION';

// Predefined character limit options
const characterItems = [
    {
        text: '1-2 words (30 characters)',
        value: 30,
    },
    {
        text: 'Sentence (250 characters)',
        value: 250,
    },
    {
        text: 'Paragraph (500 characters)',
        value: 500,
    },
];

// Function to check if a string contains only digits
const isStringDigits = (value) => {
    return /^\d+$/.test(value);
};

// Function to check if the value is an error (non-empty and not digits)
const isError = (value) => {
    return value !== '' && !isStringDigits(value);
};

// Function to check if a value is not null or undefined
const notNull = (value) => {
    return value !== null && value !== undefined;
};

// Custom hook for handling textbox question logic
export const useTextBoxQuestion = (template) => {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);
    const [useMaxCharacters, setUseMaxCharacters] = useState(false);
    const [optionChecked, setOptionChecked] = useState('');
    const [customOption, setCustomOption] = useState('');

    // Debounced update function to avoid frequent dispatches
    const delayedUpdate = useCallback(_.debounce(question =>
        dispatch(updateQuestion(currentSurvey.id, question)), 1000),
        [dispatch, currentSurvey.id]);

    useEffect(() => {
        // Initialize the state based on the template values
        if (template.max_characters) {
            const option = characterItems.find(item => item.value === template.max_characters);
            if (option) {
                setOptionChecked(option.text);
            } else {
                setCustomOption(template.max_characters);
                setOptionChecked(CUSTOM_OPTION);
            }
        }
        if (notNull(template.max_characters_allowed) && template.max_characters_allowed !== useMaxCharacters) {
            setUseMaxCharacters(template.max_characters_allowed);
        }
    }, [template]);

    // Handle change in useMaxCharacters checkbox
    const handleUseMaxCharactersChange = () => {
        const newValue = !useMaxCharacters;
        setUseMaxCharacters(newValue);

        if (newValue === true) {
            let newQuestion = _.cloneDeep(template);
            newQuestion.max_characters_allowed = true;
            dispatch(updateQuestion(currentSurvey.id, newQuestion));
        } else {
            // Reset options and custom input when checkbox is unchecked
            setOptionChecked('');
            setCustomOption('');
            let newQuestion = _.cloneDeep(template);
            newQuestion.max_characters_allowed = false;
            newQuestion.max_characters = null; // Clear max characters
            dispatch(updateQuestion(currentSurvey.id, newQuestion));
        }
    };

    // Handle change in radio button option
    const handleRadioChange = (option) => {
        setOptionChecked(option);
        const item = characterItems.find(i => i.text === option);
        if (item) {
            updateQuestionMaxCharacters(item.value);
        } else if (option === CUSTOM_OPTION && isStringDigits(customOption)) {
            updateQuestionMaxCharacters(customOption);
        }
    };

    // Handle change in custom text field
    const handleTextFieldChange = (value) => {
        setCustomOption(value);
        if (isStringDigits(value)) {
            updateQuestionMaxCharacters(value);
        }
    };

    // Update the question's max characters
    const updateQuestionMaxCharacters = (value) => {
        let newQuestion = _.cloneDeep(template);
        newQuestion.max_characters = value;
        delayedUpdate(newQuestion);
    };

    return {
        useMaxCharacters,
        optionChecked,
        customOption,
        isError,
        handleUseMaxCharactersChange,
        handleRadioChange,
        handleTextFieldChange,
        characterItems,
        CUSTOM_OPTION
    };
};
