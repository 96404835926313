import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import CustomTable from '../pages/survey-page/edit-tab/settings-tab/table';
import InviteUser from './invite-user-dialog'
import { COGNITO_LIST_FILE } from '../assets/constants'
import { addCognitoUserAction, deleteCognitoUserAction, getAssignedUsers } from '../redux/actions'
import { SET_ERROR } from './../assets/action-constants'
import { colorTheme } from '../assets/theme';

const columnHeaderStyle = {
    backgroundColor: colorTheme.theme.colors.teamTableHeaderBackground,
    fontWeight: 'bold'
}

const columns = [
    { title: 'Full Name', field: 'Full Name', editable: 'never', headerStyle: columnHeaderStyle },
    { title: 'Email', field: 'Email', editable: 'never', headerStyle: columnHeaderStyle },
    { title: 'Status', field: 'Status', editable: 'never', headerStyle: columnHeaderStyle },
    { title: 'Role', field: 'Role', editable: 'never', headerStyle: columnHeaderStyle }
]

const company = window.location.host.substring(0, window.location.host.indexOf('.'));

function Team(props) {
    const cognitoUsers = useSelector(state => state.cognitoUsers)
    const userData = useSelector(state => state.userData)
    const assignedUsers = useSelector(state => state.assignedUsers);
    const addedUser = useSelector(state => state.addedUser)

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const [team, setTeam] = useState(null)
    let assignedIds = [];

    useEffect(() => {
        if (props.assignedUsers) {
            assignedUsers.forEach(assignedUser => {
                assignedIds.push(assignedUser.userId)
            })
            if (addedUser) {
                assignedIds.push(addedUser.Username)
            }
        }
    })

    useEffect(() => {
        if (cognitoUsers) {
            validateCognitoUsers(cognitoUsers)
        }
    }, [cognitoUsers])

    const addCognitoUser = async (userEmail, name, surname, dispatch) => {
        let callerData = { name: userData.firstName, lastName: userData.lastName }
        return await dispatch(addCognitoUserAction(userEmail, name, surname, cognitoUsers, callerData))
    }

    const handleInvite = async (userEmail, name, surname) => {
        const res = await addCognitoUser(userEmail, name, surname, dispatch)
        if (res.type !== SET_ERROR) {
            const newTableData = [
                ...team.tableData,
                { Email: userEmail, Status: 'FORCE_CHANGE_PASSWORD', 'Full Name': `${name} ${surname}` }
            ]
            setTeam(prevState => ({
                ...prevState,
                ['tableData']: newTableData
            }))
            enqueueSnackbar('Successfully added user', {
                variant: 'success',
            });
        } else {
            enqueueSnackbar('User with provided email is already invvited to workspace', {
                variant: 'error',
            });
        }
    }

    const deleteData = (deletedData, updatedData) => {
        setTeam(prevState => ({
            ...prevState,
            ['tableData']: updatedData.data
        }))
        dispatch(deleteCognitoUserAction(deletedData[0].Email, cognitoUsers))
    }

    const validateCognitoUsers = (cognitoUsers) => {
        let users = _.cloneDeep(cognitoUsers)
        let data = [];
        users.forEach(user => {
            let userObject = {
                'Status': user.UserStatus
            }
            if (assignedIds.includes(user.Username)) {
                //if component is called in assign tab
                userObject.Status = 'ASSIGNED TO SURVEY'
            }

            user.Attributes.forEach(userAttribute => {
                switch (userAttribute.Name) {
                    case 'email':
                        userObject['Email'] = userAttribute.Value
                        break;
                    case 'given_name':
                        userObject['Full Name'] = userAttribute.Value
                        break;
                    case 'family_name':
                        userObject['Full Name'] += ` ${userAttribute.Value}`
                        break;
                    case 'sub':
                        userObject['userId'] = userAttribute.Value
                        break;
                    case 'custom:role':
                        userObject['Role'] = userAttribute.Value
                        break;
                    default:
                        // Handle the default case here
                        break;
                }

            })
            data.push(userObject)
        });
        setTeam({
            tableData: data,
            tableColumns: columns
        })
    }

    return (
        <>
            <div>{props.source !== 'survey-view' && <InviteUser company={company} handleInvite={handleInvite} />}</div>
            <div>
                {
                    team &&
                    <CustomTable data={team.tableData}
                        columns={team.tableColumns}
                        title={props.surveyName || company}
                        deleteData={deleteData}
                        selectedRows={props.selectedRows}
                        type={COGNITO_LIST_FILE}
                        source={props.source}
                    />
                }
            </div>
        </>
    )
}

export default Team