import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Box, Collapse, Typography } from '@mui/material';
import { ReactComponent as MoreIcon } from "../../assets/images/table/icon-arrow.svg";
import IconButton from '@material-ui/core/IconButton';
import { convertToLocalTime, formatDate } from '../../utils/time';
import { colorTheme } from '../../assets/theme';
import styled from '@mui/material/styles/styled';

const StyledIconButton = styled(IconButton)(({ open }) => ({
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
    '.MuiIconButton-label': {
        width: 'initial',
    },
    '& svg': {
        transition: 'transform 0.3s ease',
        transform: open ? 'rotate(180deg)' : 'rotate(0deg)', // Dodata rotacija
    },
    '& svg path': {
        transition: 'fill 0.3s ease',
    },
    '&:hover': {
        backgroundColor: colorTheme.theme.app.lightestGrey,
        'svg path': {
            fill: colorTheme.theme.transform.darkBlue,
        },
    },
}));

// TableRow component
function CustomTableRow({ row, headCells }) {
    const [openRow, setOpenRow] = useState(false); // State for the open row

    // Function to toggle the row
    const toggleRow = (rowId) => {
        setOpenRow((prevOpenRow) => (prevOpenRow === rowId ? null : rowId)); 
    };
    return (
        // Render a table row
        <>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                sx={{
                    height: '56px',
                    '& > *': {
                        pt: '0 !important', // Set the padding top to 0
                        pb: '0 !important', // Set the padding bottom to 0
                        pl: '0px',          // Set the padding left to 0
                        pr: '0px',         // Set the padding right to 16px
                    },
                }}
            >
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sx={{
                            pl: '16px !important',
                            color: colorTheme.theme.text.darkGrey,
                            width: headCell.width,
                            borderBottom: 'none',
                            borderTop: '1px solid #E0E0E0',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            boxSizing: 'border-box',
                            maxWidth: headCell.width,
                            minWidth: headCell.width,
                        }}
                    >
                        {
                            headCell.id === 'sentTime' ? formatDate(row[headCell.id], false, true) : row[headCell.id]
                        }
                    </TableCell>
                ))}

                <TableCell
                    sx={{ pr: '16px', borderBottom: 'none', borderTop: '1px solid #E0E0E0', }}
                    align="right"
                >
                    <StyledIconButton style={{ width: '28px', height: '28px' }} className="options-icon" onClick={() => toggleRow(row.id)} open={openRow === row.id}>
                        <MoreIcon aria-controls="customized-menu" aria-haspopup="true" />
                    </StyledIconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 , background: colorTheme.theme.app.lightestGrey, borderTop: openRow === row.id ? '1px solid rgba(224, 224, 224, 1)' : 'none', borderBottom: 'none' }} colSpan={headCells.length + 1}>
                    <Collapse in={openRow === row.id} timeout="auto" unmountOnExit>
                        <Box margin={2} sx={{ display: 'flex', flexDirection: 'column', gap: '24px'}}>
                            <Box className={'sent-email-info'} sx={{ display: 'flex', gap: '34px', position: 'relative'}}>
                                <Box sx={{ display: 'flex', gap: '6px'}}>
                                    <Typography variant="body2" sx={{ color: colorTheme.theme.text.lightGrey }}>From:</Typography>
                                    <Typography variant="body2" sx={{ color: colorTheme.theme.text.darkGrey }}>{row?.fromEmail}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: '6px'}}>
                                    <Typography variant="body2" sx={{ color: colorTheme.theme.text.lightGrey }}>To:</Typography>
                                    <Typography variant="body2" sx={{ color: colorTheme.theme.text.darkGrey }}>{row.groups}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', gap: '6px'}}>
                                    <Typography variant="body2" sx={{ color: colorTheme.theme.text.lightGrey }}>Subject:</Typography>
                                    <Typography variant="body2" sx={{ color: colorTheme.theme.text.darkGrey }}>{row.subject}</Typography>
                                </Box>
                            </Box>
                            <Box style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: row.emailContent }}></Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

CustomTableRow.propTypes = {
    row: PropTypes.object.isRequired,
};

export default CustomTableRow;
