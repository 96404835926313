import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Box, Typography } from '@mui/material';
import InputField from '../form/input-field';
import PrimaryButton from '../buttons/primary-button';
import HeaderDialog from './header-dialog';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { colorTheme } from '../../assets/theme';
import { passwordChanged } from '../../utils/pwd-change';
import { fetchUserDetails } from '../../utils/fetch-user';
import OTPInput from '../form/totp-input';

const ResetPassword = ({ handleClose, open, email, onCancel }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const cognitoUsers = useSelector(state => state.cognitoUsers);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleVerifyCode = async () => {
        setIsLoading(true);
        if (newPassword !== repeatNewPassword) {
            enqueueSnackbar('Passwords do not match.', { variant: 'error' });
            return;
        }
        try {
            console.log('User:', email);
            console.log('Code:', code);
            console.log('New Password:', newPassword);

            await Auth.forgotPasswordSubmit(email, code, newPassword); // This also resets the password
            enqueueSnackbar('Password reset successfully.', { variant: 'success' });
            await passwordChanged(email, cognitoUsers, dispatch);
            fetchUserDetails(dispatch);
            onCancel();
            handleClose();
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            console.error('Error verifying code or resetting password:', error);
        }
        setIsLoading(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '450px'
                }
            }}
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog title="Reset Your Password" handleClose={handleClose} />
            <DialogContent sx={{ p: 2 }}>
                <Typography width={'99%'} color={colorTheme.theme.text.mediumGrey} sx={{ pt: 1, fontSize: '15px' }}>
                    Please enter the verification code sent to your email then set your new password.
                </Typography>
                <Box display="flex" flexDirection="column" gap={2}>
                    <Box sx={{ display: 'flex', justifyContent: 'left', mb: 0, textAlign: 'left' }}>
                        <Typography width={'99%'} color={colorTheme.theme.text.mediumGrey} sx={{ pt: 1, fontSize: '15px' }}>
                            Verification Code
                        </Typography>
                    </Box>

                    <OTPInput length={6} onChange={setCode} positon={'left'} />
                    <Box textAlign={'left'} mb={0}>
                        <Typography mb={1} variant="body1" sx={{ fontSize: '15px' }}>NEW PASSWORD</Typography>
                        <Typography mb={1.5} variant="body2"
                            color={colorTheme.theme.text.mediumGrey}
                            sx={{ lineHeight: '19px', fontSize: '15px' }}
                        >Create a new password that's at least 8 characters long and includes a mix of letters, numbers, and symbols for enhanced security.</Typography>
                    </Box>
                    <InputField label="New Password" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    <InputField label="Repeat New Password" type="password" value={repeatNewPassword} onChange={(e) => setRepeatNewPassword(e.target.value)} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'flex-end' }}>
                        <PrimaryButton
                            onClick={handleVerifyCode}
                            isLoading={isLoading}
                            color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="Reset Password" />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ResetPassword;
