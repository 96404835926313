import React from "react";



const InsightsContainer = ({ userTemplate }) => {

    return (
        <div>
            <h1>Insights</h1>
        </div>
    );
}

export default InsightsContainer;