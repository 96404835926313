import _, { forEach, indexOf } from 'lodash';
import themeColors from '../../assets/theme-colors';

import { 
    ACTIVE_STATUS_COLUMN
} from '../../assets/constants';

import {
    SET_ERROR,
    CUSTOMER_REGISTERED,
    REGISTRATION_ERROR_COMPANY_EXISTS,
    REGISTRATION_ERROR_DOMAIN_EXISTS,
    REGISTRATION_ERROR_OTHER,
    REGISTRATION_ERROR_COMPANY_RESET,
    USER_TOKEN_SET,
    USER_TEMPLATE_FETCHED,
    STORE_USER_DATA,
    SET_CURRENT_SURVEY,
    SURVEYS_FETCHED,
    CREATE_BLANK_SURVEY,
    PUBLISH_CURRENT_SURVEY,
    PUBLISH_CURRENT_SURVEY_ERROR,
    UPDATE_CURRENT_SURVEY,
    GET_QUESTIONS,
    ADD_QUESTION,
    UPDATE_QUESTION,
    REMOVE_QUESTION,
    REORDER_QUESTION,
    CUSTOM_LISTS_FETCHED,
    SET_CUSTOM_LIST,
    SET_RESPONDENTS_LIST,
    DELETE_CUSTOM_LIST,
    DELETE_RESPONDENTS_LIST,
    S3_CREDENTIALS_FETCHED,
    FILE_UPLOADED,
    UPDATE_SURVEY_STYLE_COLORS,
    UPDATE_SURVEY_STYLE_LOGO,
    UPDATE_SURVEY_NAME,
    LAST_EDITED_TIME,
    SET_RESPONDENT_ERRORS,
    SET_QUESTION_ERRORS,
    SET_EDIT_PAGE_TAB,
    SCHEDULE_CURRENT_SURVEY,
    RESET_ERROR,
    DELETE_RESPONDENTS,
    CLOSE_CURRENT_SURVEY,
    ASSIGN_TO_SURVEY,
    SET_USERS_ON_SURVEY,
    UPDATE_RESPONDENTS_LIST, SET_QUESTION_ID, UPDATE_GENERAL_SETTINGS, DOWNLOAD_SURVEY_DATA,
    LIST_COGNITO_USERS, ADD_COGNITO_USER, DELETE_COGNITO_USER,
    UPDATE_COGNITO_USER,
    SEND_REMINDERS, USER_EXIST_ON_WORKSPACE,
    SET_ACTIVE_EDIT_SURVEY_TAB,
    SET_SIDEBAR_ACTIVE_PAGE,
    STORE_ORGANIZATION_DATA,
    STORE_ORGANIZATION_DEFAULT_STYLE_DATA,
    SET_SAVE_DEFAULT_STYLE_MESSAGE

} from "../../assets/action-constants";
import { ROW_ID, TAB_QUESTIONS } from "../../assets/constants";

const sortSurveyQuestions = (questions) => {
    const firstQuestion = questions.find(q => !q.prevQuestion);
    console.log('First question found: ', firstQuestion)
    let list = [];
    if (!firstQuestion) {
        return questions;
    }
    list.push(firstQuestion);
    let nextQuestion = questions.find(q => q.id === firstQuestion.nextQuestion);
    console.log('Next question found: ', nextQuestion)
    while (nextQuestion) {
        console.log('next question !: ', nextQuestion)
        list.push(nextQuestion);
        nextQuestion = questions.find(q => q.id === nextQuestion.nextQuestion);
    }
    console.log('sorted questions: ', list);
    return list;
};

const initialState = {
    userTemplate: null,
    currentSurvey: null,
    error: null,
    registrationError: null,
    customerRegistered: false,
    uploadData: null,
    cognitoUsers: null,
    surveys: [],
    surveysFetched: false,
    respondentsFetched: false,
    listsFetched: false,
    reminderSent: false,
    editPageActiveTab: TAB_QUESTIONS,
    activeEditSurveyTab: false,
    sidebarActivePage: null,
    organizationData: {
        companyName: '',
        industry: '',
        founded: '',
        numberOfEmployees: '',
        website: '',
        streetAddress: '',
        city: '',
        country: '',
        picture: '',
    },
    organizationDefaultStyleData: {
        colors: {
            ...themeColors[0]
        },
        picture: '',
    },
    saveDefaultStyleMessage: false,
};

export default (state = initialState, action) => {

    if (action.type === SET_ERROR) {
        if (action.payload && (action.payload.type === REGISTRATION_ERROR_COMPANY_EXISTS || action.payload.type === REGISTRATION_ERROR_OTHER || action.payload.type === REGISTRATION_ERROR_DOMAIN_EXISTS)) {
            return Object.assign({}, state, {
                registrationError: action.payload,
                customerRegistered: false,
            });
        }
        if (action.payload === PUBLISH_CURRENT_SURVEY_ERROR) {
            return Object.assign({}, state, {
                publishingError: "Error when publishing survey",
                surveyPublished: false,
            });
        }
        if (action.payload === USER_EXIST_ON_WORKSPACE) {
            return Object.assign({}, state, {
                addingUserError: action.payload

            });
        }
        return Object.assign({}, state, {
            error: action.payload ? action.payload : action.error,
        });
    }

    if (action.type === RESET_ERROR) {
        return Object.assign({}, state, {
            error: null,
            registrationError: false,
            publishingError: false,
        });
    }
    if (action.type === SEND_REMINDERS) {
        return Object.assign({}, state, {
            reminderSent: true
        });
    }

    if (action.type === STORE_USER_DATA) {
        return Object.assign({}, state, {
            userData: action.payload
        });
    }

    if (action.type === REGISTRATION_ERROR_COMPANY_RESET) {
        return Object.assign({}, state, {
            registrationError: false,
        });
    }

    if (action.type === CUSTOMER_REGISTERED) {
        return Object.assign({}, state, {
            registrationError: null,
            customerRegistered: true,
            customerDetails: action.payload.data
        });
    }

    if (action.type === USER_TEMPLATE_FETCHED) {
        return Object.assign({}, state, {
            userTemplate: action.payload,
        });
    }

    if (action.type === SURVEYS_FETCHED) {
        const { surveys } = action.payload;

        return Object.assign({}, state, {
            surveys: surveys,
            surveysFetched: true,
        });
    }

    if (action.type === CREATE_BLANK_SURVEY) {
        const { survey } = action.payload;

        return Object.assign({}, state, {
            currentSurvey: survey,
            surveysFetched: false,
            publishingError: null,
            surveyPublished: false,
            respondentsFetched: false,
            listsFetched: false,
            editPageActiveTab: TAB_QUESTIONS,
        });
    }

    if (action.type === SET_CURRENT_SURVEY) {
        return Object.assign({}, state, {
            currentSurvey: action.payload,
            questionsFetched: false,
            listsFetched: false,
            respondentsFetched: false,
            editPageActiveTab: TAB_QUESTIONS,
            surveyPublished: false,
            surveysFetched: false,
        });
    }

    if (action.type === SET_ACTIVE_EDIT_SURVEY_TAB) {
        return Object.assign({}, state, {
            activeEditSurveyTab: action.payload
        });
    }

    if (action.type === SET_SIDEBAR_ACTIVE_PAGE) {
        return Object.assign({}, state, {
            sidebarActivePage: action.payload
        });
    }

    if (action.type === STORE_ORGANIZATION_DATA) {
        return Object.assign({}, state, {
            organizationData: action.payload.data
        });
    }

    if (action.type === STORE_ORGANIZATION_DEFAULT_STYLE_DATA) {
        return Object.assign({}, state, {
            organizationDefaultStyleData: action.payload.data
        });
    }

    if (action.type === UPDATE_SURVEY_NAME) {
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        const { name } = action.payload;

        return Object.assign({}, state, {
            currentSurvey: {
                ...survey,
                name,
            },
        });
    }

    if (action.type === LAST_EDITED_TIME) {
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        const { lastEditedTime } = action.payload;

        return Object.assign({}, state, {
            currentSurvey: {
                ...survey,
                lastEditedTime,
            },
        });
    }

    if (action.type === PUBLISH_CURRENT_SURVEY) {
        return Object.assign({}, state, {
            publishingError: null,
            surveyPublished: true,
            currentSurvey: {
                ...state.currentSurvey,
                status: 'LIVE',
                publishedTime: new Date().toISOString(),
                publishedBy: action.payload.userId,
            }
        });
    }

    if (action.type === SCHEDULE_CURRENT_SURVEY) {
        return Object.assign({}, state, {
            publishingError: null,
            surveyPublished: true,
            currentSurvey: {
                ...state.currentSurvey,
                status: 'SCHEDULED',
            }
        });
    }

    if (action.type === CLOSE_CURRENT_SURVEY) {
        return Object.assign({}, state, {
            publishingError: null,
            currentSurvey: {
                ...state.currentSurvey,
                status: 'COMPLETED',
                statusTimestamp: '#' + new Date().toISOString(),
            }
        });
    }

    if (action.type === UPDATE_CURRENT_SURVEY) {
        const { survey } = action.payload;
        return Object.assign({}, state, {
            currentSurvey: survey,
        });
    }

    if (action.type === SET_EDIT_PAGE_TAB) {
        const { tab } = action.payload;

        return Object.assign({}, state, {
            editPageActiveTab: tab,
        });
    }

    if (action.type === GET_QUESTIONS) {
        const { questions } = action.payload;
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        survey.questions = questions;
        survey.questions = sortSurveyQuestions(survey.questions);

        return Object.assign({}, state, {
            currentSurvey: survey,
            questionsFetched: true,
        });
    }

    if (action.type === ADD_QUESTION) {
        const { question } = action.payload;
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        if (!survey.questions) {
            survey.questions = [];
        }
        survey.questions.push(question);
        survey.questions = sortSurveyQuestions(survey.questions);
        return Object.assign({}, state, {
            currentSurvey: survey,
        });
    }

    if (action.type === REMOVE_QUESTION) {
        const { question } = action.payload;
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        _.remove(survey.questions, q => q.id === question.id);
        return Object.assign({}, state, {
            currentSurvey: survey,
        });
    }

    if (action.type === UPDATE_QUESTION) {
        const { question } = action.payload;
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : { questions: [] };

        // Use findIndex to locate the question in the survey
        const questionIndex = survey.questions.findIndex(q => q.id === question.id);

        // Check if the question was found before trying to replace it
        if (questionIndex !== -1) {
            survey.questions.splice(questionIndex, 1, question);
            return Object.assign({}, state, {
                currentSurvey: survey,
            });
        } else {
            // Optionally handle the error more explicitly, e.g., log or set an error message in state
            console.error("Failed to find the question to update.");
            // Return the state unmodified if the question wasn't found
            return state;
        }
    }


    if (action.type === REORDER_QUESTION) {
        const { updatedQuestions } = action.payload;
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        updatedQuestions.forEach(q => {
            let qIndex = survey.questions.findIndex(question => question.id === q.id);
            survey.questions[qIndex].prevQuestion = q.prevQuestion;
            survey.questions[qIndex].nextQuestion = q.nextQuestion;
        });
        survey.questions = sortSurveyQuestions(survey.questions);
        return Object.assign({}, state, {
            currentSurvey: survey,
        });
    }

    if (action.type === SET_QUESTION_ERRORS) {
        const { errorCount } = action.payload;
        return Object.assign({}, state, {
            questionErrors: errorCount,
        });
    }

    if (action.type === SET_RESPONDENTS_LIST) {
        const { list } = action.payload;
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        survey.respondents = list;

        return Object.assign({}, state, {
            reminderSent: false,
            currentSurvey: survey,
            respondentsFetched: true,
        });
    }

    if (action.type === DOWNLOAD_SURVEY_DATA) {
        const { downloadUrl } = action.payload
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        survey.downloadUrl = downloadUrl;

        return Object.assign({}, state, {
            currentSurvey: survey,
            downloadUrl
        });
    }

    if (action.type === UPDATE_RESPONDENTS_LIST) {
        const { reduxList } = action.payload;
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        survey.respondents = reduxList;

        return Object.assign({}, state, {
            currentSurvey: survey,
            respondentsFetched: true,
        });
    }

    if (action.type === DELETE_RESPONDENTS_LIST) {
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        survey.respondents = {
            columns: [ACTIVE_STATUS_COLUMN],
            data: [
                
            ],
        };

        return Object.assign({}, state, {
            currentSurvey: survey,
        });
    }

    if (action.type === DELETE_RESPONDENTS) {
        const deletedRespondents = _.cloneDeep(action.payload.respondents);
        const deletedRespondentIds = deletedRespondents.map(r => r[ROW_ID]);
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        _.remove(survey.respondents.data, r => deletedRespondentIds.includes(r[ROW_ID]));

        return Object.assign({}, state, {
            currentSurvey: survey,
        });
    }


    if (action.type === SET_RESPONDENT_ERRORS) {
        const { errorCount } = action.payload;
        return Object.assign({}, state, {
            respondentErrors: errorCount,
        });
    }

    if (action.type === SET_CUSTOM_LIST) {
        const { list } = action.payload;
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        if (!survey.lists) {
            survey.lists = [];
        }
        const listIndex = survey.lists.findIndex(l => l.id === list.id);
        if (listIndex !== -1) {
            survey.lists[listIndex] = list;
        } else {
            survey.lists.push(list);
        }

        return Object.assign({}, state, {
            currentSurvey: survey,
        });
    }

    if (action.type === CUSTOM_LISTS_FETCHED) {
        const { lists } = action.payload;
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        survey.lists = lists;

        return Object.assign({}, state, {
            currentSurvey: survey,
            listsFetched: true,
        });
    }

    if (action.type === DELETE_CUSTOM_LIST) {
        console.log('delete custom list action: ', action.payload);
        const { listId } = action.payload;
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        _.remove(survey.lists, l => l.id === listId);

        return Object.assign({}, state, {
            currentSurvey: survey,
        });
    }

    if (action.type === UPDATE_SURVEY_STYLE_COLORS) {
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        const { colors } = action.payload;

        return Object.assign({}, state, {
            currentSurvey: {
                ...survey,
                colors,
            },
            surveysFetched: false,
        });
    }

    if (action.type === UPDATE_SURVEY_STYLE_LOGO) {
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        const { logo } = action.payload;

        return Object.assign({}, state, {
            currentSurvey: {
                ...survey,
                companyLogo: logo,
            },
            surveysFetched: false,
        });
    }

    if (action.type === UPDATE_GENERAL_SETTINGS) {
        let survey = state.currentSurvey ? _.cloneDeep(state.currentSurvey) : {};
        const { generalSettings } = action.payload;

        return Object.assign({}, state, {
            currentSurvey: {
                ...survey,
                ...generalSettings,
            },
            surveysFetched: false,
        });
    }

    if (action.type === S3_CREDENTIALS_FETCHED) {
        const { uploadData } = action.payload;

        return Object.assign({}, state, {
            uploadData,
        });
    }

    if (action.type === FILE_UPLOADED) {
        const { logoUrl } = action.payload;

        return Object.assign({}, state, {
            logoUrl,
        });
    }

    if (action.type === SET_QUESTION_ID) {
        const { questionId } = action.payload;
        return Object.assign({}, state, {
            questionId,
        });
    }

    if (action.type === LIST_COGNITO_USERS) {
        return Object.assign({}, state, {
            cognitoUsers: action.payload,
        });
    }

    if (action.type === ADD_COGNITO_USER) {
        const { addedUser, cognitoUsers } = action.payload;
        return Object.assign({}, state, {
            cognitoUsers: [...cognitoUsers, addedUser]
        });
    }

    if (action.type === ASSIGN_TO_SURVEY) {
        const { addedUser, cognitoUsers } = action.payload;
        if (cognitoUsers) {
            return Object.assign({}, state, {
                cognitoUsers: [...cognitoUsers, addedUser],
                addedUser: addedUser
            });
        }
    }

    if (action.type === SET_USERS_ON_SURVEY) {
        return Object.assign({}, state, {
            assignedUsers: action.payload
        });
    }

    if (action.type === DELETE_COGNITO_USER) {
        const { deletedUser, cognitoUsers } = action.payload
        const temp = [];

        cognitoUsers.forEach(cognitoUser => {
            let found = cognitoUser.Attributes.find(attribute => attribute.Value === deletedUser)
            if (!found) {
                temp.push(cognitoUser)
            }
        })
        return Object.assign({}, state, {
            cognitoUsers: temp
        });
    }

    if (action.type === UPDATE_COGNITO_USER) {
        const { updatedUser, cognitoUsers } = action.payload;
        const temp = [];
        cognitoUsers.forEach(cognitoUser => {
            if (cognitoUser.Username === updatedUser.Username) {
                temp.push(updatedUser)
            } else {
                temp.push(cognitoUser)
            }
        })
        return Object.assign({}, state, {
            cognitoUsers: temp
        });
    }

    if (action.type === SET_SAVE_DEFAULT_STYLE_MESSAGE) {
        return Object.assign({}, state, {
            saveDefaultStyleMessage: action.payload
        });
    }

    return state;
};
