import React from 'react';
import { Box, Typography } from '@mui/material';
import PrimaryButton from '../buttons/primary-button';
import { colorTheme } from '../../assets/theme';
import { ReactComponent as EditIcon } from "./../../assets/images/actions/edit.svg";
import PhoneInputField from '../form/phone-input-field-label';
import { useProfileInfo } from './../../hooks/profile/use-profile-info';
import TooltipIcon from '../tooltip-text/tooltip-icon';

const ProfileView = ({ onEdit }) => {
    const { userData, phoneNumber, role, togglePhoneNumberInput, showPhoneNumberInput, setPhoneNumber, changePhoneNumber, roleDescription } = useProfileInfo();

    const handlePhoneNumberChange = (newPhoneNumber) => {
        console.log("Updated Phone Number:", newPhoneNumber); // For debugging
        setPhoneNumber(newPhoneNumber);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: { xs: 0, sm: 5 }, mt: { xs: 2, sm: 0 } }}>
            <Typography fontWeight={700} variant="h6">{userData?.firstName} {userData?.lastName}</Typography>
            <Box sx={{
                display: 'flex', gap: { xs: 2, sm: 7 }, mt: 2, flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'start', sm: 'center' }
            }}>
                {/* Role Section */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { sm: 'flex-start' } }}>
                    <Typography mb={0.5} variant="caption" color={colorTheme.theme.text.lightGrey}>Role</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                        <Typography variant="body1">{role}</Typography>
                        <TooltipIcon content={roleDescription} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                    </Box>
                </Box>
                {/* Email Section */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { sm: 'flex-start' } }}>
                    <Typography mb={0.5} variant="caption" color={colorTheme.theme.text.lightGrey}>Email</Typography>
                    <Typography variant="body1">{userData?.email}</Typography>
                </Box>
                {/* Phone Number Section */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { sm: 'flex-start' }, height: '50px' }}>
                    {!showPhoneNumberInput && (
                        <Typography mt={0} mb={0.5} variant="caption" color={colorTheme.theme.text.lightGrey}>Phone Number</Typography>
                    )}

                    {!showPhoneNumberInput ? (
                        <Typography variant="body1" sx={{
                            cursor: phoneNumber ? 'default' : 'pointer',
                            color: phoneNumber ? colorTheme.theme.text.darkGrey : colorTheme.theme.transform.darkBlueTwo,
                            padding: '4px 8px',
                            borderRadius: '30px',
                            transition: 'color 0.3s ease-in-out, background-color 0.3s ease-in-out',
                            marginLeft: '-8px',
                            lineHeight: '20px',
                            '&:hover': phoneNumber ? {} : {
                                color: colorTheme.theme.transform.darkBlue,
                                backgroundColor: '#0C255C12',
                            }
                        }}
                            onClick={phoneNumber ? null : () => togglePhoneNumberInput(true)}
                        >
                            {phoneNumber ? phoneNumber : 'Add Phone Number'}
                        </Typography>
                    ) : (
                        <>
                            <Box pt={0}>
                                <PhoneInputField
                                    width={'297px'}
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    onBlur={togglePhoneNumberInput} // Optionally hide the input when focus is lost
                                    lineHeight={1.66}
                                    marginBottom={'4px'}
                                />
                                <Box sx={{ display: 'flex', gap: 0.75, mt: 1.5, alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%' }}>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            color: colorTheme.theme.text.darkGrey,
                                            fontSize: '15px',
                                            width: '62px',
                                            padding: '4px',
                                            transition: 'color 0.3s ease-in-out, background-color 0.3s ease-in-out',
                                            borderRadius: '23px',
                                            '&:hover': {
                                                color: colorTheme.theme.transform.black,
                                                backgroundColor: colorTheme.theme.colors.lightGray,
                                            },
                                        }}
                                        onClick={() => togglePhoneNumberInput(false)}
                                    >
                                        Cancel
                                    </Typography>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            color: colorTheme.theme.transform.darkBlueTwo,
                                            fontSize: '15px',
                                            width: '49px',
                                            padding: '4px',
                                            transition: 'color 0.3s ease-in-out, background-color 0.3s ease-in-out',
                                            borderRadius: '23px',
                                            '&:hover': {
                                                color: colorTheme.theme.transform.darkBlue,
                                                backgroundColor: colorTheme.theme.app.lightestGrey,
                                            },
                                        }}

                                        onClick={changePhoneNumber}
                                    >
                                        Save
                                    </Typography>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
            {/* Edit Button */}
            <Box sx={{ position: { sm: 'absolute' }, right: 0, top: 0, mt: { xs: 2, sm: 0 }, mr: { sm: 2 } }}>
                <PrimaryButton
                    color={colorTheme.theme.transform.darkBlueTwo}
                    Icon={EditIcon}
                    hoverColor={colorTheme.theme.transform.darkBlue}
                    onClick={onEdit} text="Edit" />
            </Box>
        </Box >
    );
};

export default ProfileView;
