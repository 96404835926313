import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { updateQuestion } from "../../../redux/actions";

// Utility function to check if a string contains only digits
const isStringDigits = (value) => /^\d+$/.test(value);

// Custom hook for managing the state and logic of single line advanced settings
const useSingleLineAdvancedSettings = (question) => {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);

    const [validationMinCheck, setValidationMinCheck] = useState(question.validation_min || false);
    const [validationMaxCheck, setValidationMaxCheck] = useState(question.validation_max || false);
    const [inputOption, setInputOption] = useState({
        items_min_number: question.items_min_number || '1',
        items_max_number: question.items_max_number || '3'
    });

    const updateSingleLineQuestion = (value) => {
        const updatedObject = Object.assign(_.cloneDeep(question), value);
        dispatch(updateQuestion(currentSurvey.id, updatedObject));
    };

    const handleMinValidationCheck = (checked) => {
        const minNumber = isStringDigits(inputOption.items_min_number) ? Number(inputOption.items_min_number) : 1;

        setValidationMinCheck(checked);
        let updateObject = { 'items_min_number': minNumber, 'validation_min': checked };
        setInputOption(prevState => ({
            ...prevState, items_min_number: minNumber
        }));

        updateSingleLineQuestion(updateObject);
    };

    const handleMaxValidationCheck = (checked) => {
        const maxNumber = isStringDigits(inputOption.items_max_number) ? Number(inputOption.items_max_number) : 3;

        setValidationMaxCheck(checked);
        let updateObject = { 'items_max_number': maxNumber, 'validation_max': checked };
        setInputOption(prevState => ({
            ...prevState, items_max_number: maxNumber
        }));
        updateSingleLineQuestion(updateObject);
    };

    const handleTextInput = (evt) => {
        const { name, value } = evt.target;
        setInputOption(prevState => ({
            ...prevState, [name]: value
        }));
        if (isStringDigits(value)) {
            updateSingleLineQuestion({ [name]: Number(value) });
        }
    };

    return {
        validationMinCheck,
        validationMaxCheck,
        inputOption,
        handleMinValidationCheck,
        handleMaxValidationCheck,
        handleTextInput,
    };
};

export default useSingleLineAdvancedSettings;
