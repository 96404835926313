import React, { useEffect, useState } from 'react';
import './datetime-picker.scss';
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentSurvey } from "../../../redux/actions";
import {  Typography } from '@material-ui/core';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { colorTheme } from '../../../assets/theme';
import { ReactComponent as DateRangeIcon } from '../../../assets/images/icons/survey/date-range-icon.svg';
import {
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import dayjs from 'dayjs';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import CustomRadioLabel from '../../form/custom-radio-label';
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";

const OpenPickerIcon = () => <DateRangeIcon fontSize="large" />;

// Material UI styles
const useStyles = makeStyles({
    dateTimerPicker: {
        '& .MuiInputBase-root': {
            '& input': {
                padding: '8px 12px',
                fontWeight: 500,
                color: colorTheme.theme.text.mediumGrey,
                textTransform: 'uppercase',
            },
            '& .MuiIconButton-root': {
                '& .MuiSvgIcon-root path': {
                    fill: colorTheme.theme.transform.darkBlueTwo,
                },
                '&:hover': {
                    backgroundColor: 'transparent',
                },
                '& .MuiTouchRipple-root': {
                    display: 'none',
                },
            },
            '& fieldset': {
                border: '1px solid #D7D7D7',
                transition: 'border 0.3s ease-in-out',
            },
            '&:hover:not(.Mui-disabled)': {
                '& fieldset': {
                    border: '1px solid var(--transform-darkBlueTwo)',
                },
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid var(--transform-darkBlueTwo)',
                outline: 'none',
            },
            '&:focus:not(.Mui-disabled)': {
                '& fieldset': {
                    border: '1px solid var(--transform-darkBlueTwo)',
                    outline: 'none',
                },
            },
        },
        '& .Mui-disabled': {
            '& fieldset': {
                border: '1px solid #D7D7D7 !important',
            },
        },
    },
});


// DatetimePicker component
function DatetimePicker(props) {
    const dispatch = useDispatch(); // Get dispatch function from redux
    const currentSurvey = useSelector(state => state.currentSurvey); // Get current survey from redux
    const [manualStop, setManualStop] = useState(currentSurvey.endDate ? false : true); // Set initial state for manual stop
    const classes = useStyles(); // Custom styles 

    // Set initial state for selected start date and end date
    const [selectedStartDate, setSelectedStartDate] = useState(
        currentSurvey.startDate ? new Date(currentSurvey.startDate) : null
    );

    // Set initial state for selected end date
    const [selectedEndDate, setSelectedEndDate] = useState(
        currentSurvey.endDate ? new Date(currentSurvey.endDate) : null
    );

    // Set initial state for startImmediately
    const [startImmediately, setStartImmediately] = useState(currentSurvey.startImmediately ? true : false);

    // Update current survey with selected start date
    useEffect(() => {
        if (!currentSurvey.startDate) {
            dispatch(updateCurrentSurvey({ ...currentSurvey, startDate: selectedStartDate }));
        }
    }, []);

    // Update current survey with selected end date
    useEffect(() => {
        if (currentSurvey.endDate) {
            setSelectedEndDate(currentSurvey.endDate)
        } else {
            setSelectedEndDate(null)
        }
    }, [currentSurvey])

    // Function to handle start date change
    const handleStartDateChange = (date) => {
        setSelectedStartDate(date.toISOString());
        dispatch(updateCurrentSurvey({ ...currentSurvey, startDate: date.toISOString() }));
    };

    // Function to handle end date change
    const handleEndDateChange = (date) => {
        setSelectedEndDate(date.toISOString());
        dispatch(updateCurrentSurvey({ ...currentSurvey, endDate: date.toISOString() }));
    };

    // Function to handle start checkbox
    const handleStartCheckbox = (value) => {
        setStartImmediately(value);
        if(value){
            setSelectedStartDate(null);
            dispatch(updateCurrentSurvey({ ...currentSurvey, startImmediately: value, startDate: null }));
        }else{
            dispatch(updateCurrentSurvey({ ...currentSurvey, startImmediately: value }));
        }
    };

    // Function to handle end checkbox
    const handleEndCheckbox = (value) => {
        setManualStop(value);
        if(value){
            setSelectedEndDate(null);
            dispatch(updateCurrentSurvey({ ...currentSurvey, endDate: null }));
        }
    };

    // Function to handle remove button
    const handleRemoveButton = () => {
        setSelectedEndDate(null);
        dispatch(updateCurrentSurvey({ ...currentSurvey, endDate: null }));
    }

    return (
        <>
            <Box className="datetime-picker-container">
                <Box>
                    <Typography variant="h6" className="title" style={{ fontSize: '16px', color: colorTheme.theme.text.mediumGrey, textTransform: 'uppercase', fontWeight: 400, marginBottom: '6px' }}>Start time</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px'}}>
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    checked={startImmediately}
                                    onChange={e => handleStartCheckbox(e.target.checked)}
                                    name='start-checkbox' color="primary"
                                    disabled={props.disabled}
                                />
                            }
                            label="Start Immediately"
                            className='start-checkbox'
                            style={{ width: 'max-content' }}
                        /> */}
                        <FormControl component="fieldset" sx={{ width: 'fit-content' }}>
                            <RadioGroup name="start-time" value={startImmediately ? 'true' : 'false'} style={{ display: 'flex', gap: '32px', flexDirection: 'ROW' }} onChange={(e) => handleStartCheckbox(e.target.value === 'true')}>
                                <CustomRadioLabel value={'true'} label={'Start Immediately'} checked={startImmediately} fontSize='16px' />
                                <CustomRadioLabel value={'false'} label={'Schedule Start'} checked={!startImmediately} fontSize='16px' />
                            </RadioGroup>
                        </FormControl>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} disabled={props.disabled}>
                            <Box className={`flex-row-center ${currentSurvey.startImmediately ? 'disabled-block' : ''}`}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker
                                        className={classes.dateTimerPicker}
                                        views={['year', 'month', 'day', 'hours', 'minutes']}
                                        onChange={handleStartDateChange}
                                        value={selectedStartDate != null ? dayjs(selectedStartDate) : null}
                                        disabled={currentSurvey.startImmediately}
                                        components={{
                                            OpenPickerIcon,
                                          }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                        </MuiPickersUtilsProvider>
                    </Box>
                </Box>

                <Box>
                    <Typography variant="h6" className="title" style={{ fontSize: '16px', color: colorTheme.theme.text.mediumGrey, textTransform: 'uppercase', fontWeight: 400, marginBottom: '6px' }}>End time</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px'}}>
                    <FormControl component="fieldset" sx={{ width: 'fit-content' }}>
                        <RadioGroup name="end-time" value={manualStop ? 'true' : 'false'} style={{ display: 'flex', gap: '32px', flexDirection: 'ROW' }} onChange={(e) => handleEndCheckbox(e.target.value === 'true')}>
                            <CustomRadioLabel value={'true'} label={'Manual Stop'} checked={manualStop} fontSize='16px' />
                            <CustomRadioLabel value={'false'} label={'Schedule End'} checked={!manualStop} fontSize='16px' />
                        </RadioGroup>
                    </FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} disabled={props.disabled}>
                        <Box className={`flex-row-center ${manualStop ? 'disabled-block' : ''}`}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                <DateTimePicker
                                    className={classes.dateTimerPicker}
                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                    onChange={handleEndDateChange}
                                    value={selectedEndDate != null ? dayjs(selectedEndDate) : null}
                                    disabled={manualStop}
                                    components={{
                                        OpenPickerIcon,
                                    }}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                    </MuiPickersUtilsProvider>
                    </Box>
                </Box>
                {/* <Tooltip title='Remove end date'>
                    <IconButton onClick={handleRemoveButton}>
                        <HighlightOffIcon />
                    </IconButton>
                </Tooltip> */}
            </Box>
        </>
    )

}

export default DatetimePicker;