export const validatePassword = (password, setError) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigits = /\d/.test(password);
    const hasSpecialChar = /[^A-Za-z0-9]/.test(password);

    let classesMatched = 0;
    [hasUpperCase, hasLowerCase, hasDigits, hasSpecialChar].forEach(criterion => {
        if (criterion) classesMatched++;
    });

    if (password.length >= minLength && classesMatched >= 3) {
        setError('');
        return true; // Password is valid
    } else {
        setError('Not a valid password.');
        return false; // Password is not valid
    }
};