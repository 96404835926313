import React from 'react';
import AdvancedSettings from "../../question-advanced-settings";
import './single-line-text-question.scss';
import CustomNumberTextField from '../../../../../../components/form/question-number-input';
import { Box, Typography } from '@mui/material';
import useSingleLineQuestion from '../../../../../../hooks/editor/single-line-text/use-single-line-text';

// This component is used to render the single line text question in the survey editor.
function SingleLineQuestion({ template, advancedSettings, handleAdvancedView }) {
    const { inputOption, handleTextInput, isError } = useSingleLineQuestion(template);

    return (
        <div className='single-line-text-question-container'>
            <div className='answer-details'>
                {/* Number of Answer text fields respondents can fill out */}
                <Box display='flex' alignItems='center' gap={1.5}>
                    <Typography variant='body1' color={'#4F4F4F'}>
                        Number of Answer text fields respondents can fill out:
                    </Typography>
                    <CustomNumberTextField
                        name='initial_number_of_fields'
                        value={inputOption.initial_number_of_fields}
                        onChange={handleTextInput}
                        error={isError(inputOption.initial_number_of_fields)}
                    />
                </Box>
                {/* Maximum number of characters per answer */}
                <Box display='flex' alignItems='center' gap={1.5} mb={3} mt={2}>
                    <Typography variant='body1' color={'#4F4F4F'}>
                        Maximum number of characters per answer:
                    </Typography>
                    <CustomNumberTextField
                        name='max_characters'
                        value={inputOption.max_characters}
                        onChange={handleTextInput}
                        error={isError(inputOption.max_characters)}
                    />
                </Box>
            </div>
            {/* Advanced settings for the single line text question */}
            {advancedSettings && <AdvancedSettings setView={handleAdvancedView} template={template} />}
        </div>
    );
}

export default SingleLineQuestion;
