import React from 'react';
import { Fab, Typography } from '@mui/material';
import { colorTheme } from '../../assets/theme';

// component to render the button
const AddButton = ({ text, Icon, onClick, hoverColor }) => {
    return (
        <Fab onClick={onClick} sx={{ 
            backgroundColor: colorTheme.theme.colors.white, 
            color: colorTheme.theme.transform.darkBlueTwo, 
            // border: `1px solid ${colorTheme.theme.transform.darkBlueTwo}`,
            // Box shadow inside 1.5 instead of border
            boxShadow: `0px 0px 0px 1.5px ${colorTheme.theme.transform.darkBlueTwo}`,
            transition: 'border 0.3s ease, color 0.3s ease, background-color 0.3s ease',
            // boxShadow: 'none !important',
            padding: '3px 10px !important',
            maxHeight: '30px',
            zIndex: 1,
            '&:hover': {
                // border: `1px solid ${hoverColor}`,
                boxShadow: `0px 0px 0px 1.5px ${hoverColor}`,
                color: hoverColor,
                backgroundColor: "#1B497214",
                // boxShadow: 'none',

                'svg path': {
                    fill: hoverColor,
                },
            },
            'svg path': {
                transition: 'fill 0.3s ease',
            },
            }} 
            variant="extended" className="add-button" size="small">
            {
                Icon && <Icon className="add-button-icon" />
            }
            <Typography className='add-button-text' ml={Icon ? 0.625 : 0} sx={{ fontSize: '14px', fontWeight: '500', lineHeight: 1}}>{text}</Typography>
        </Fab>
    );
};

export default AddButton;
