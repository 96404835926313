import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { colorTheme } from '../../assets/theme';

const InputField = ({
    type,
    textFieldWidth,
    label,
    value,
    error,
    onChange,
    autoComplete,
    inputPadding = '13px 12px',
    fontSize = '16px',
    lineHeight = 1,
    inputMarginLeft = '0px',
    placeholderColor = '#4F4F4F' // Default placeholder color
}) => {
    const [inputType, setInputType] = useState(type);
    console.log('value', textFieldWidth);

    return (
        <Box style={{ position: 'relative', marginLeft: inputMarginLeft }}>
            <TextField
                autoFocus={false}
                name="email"
                type={inputType}
                fullWidth
                variant="outlined"
                value={value}
                onChange={onChange}
                autoComplete={autoComplete}
                placeholder={label}
                error={error}
                sx={{
                    padding: '0px',
                    width: textFieldWidth,
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {},
                        '&:hover fieldset': {
                            borderColor: '#4F4F4F',
                            border: '1px solid',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: error ? '#E23D32' : '#1B4972',
                            borderWidth: 1,
                        },
                    },
                    '& .MuiInputLabel-root': {
                        display: 'none', // Hide the label
                    },
                    '& .MuiInputBase-input': {
                        padding: inputPadding,
                        lineHeight: lineHeight,
                        height: 'initial',
                        fontSize: fontSize,
                        '&::placeholder': {
                            color: placeholderColor, // Change the placeholder color
                            opacity: 1, // Add this line
                        },
                        '&&&&:hover:-webkit-autofill, &&&&:focus:-webkit-autofill': {
                            transition: 'background-color 5000s ease-in-out 0s',
                            '-webkit-text-fill-color': '#333 !important',
                            backgroundColor: 'transparent !important',
                        },
                    },
                }}
                InputProps={{
                    style: { color: colorTheme.theme.transform.black },
                }}
            />
        </Box>
    );
};

export default InputField;
