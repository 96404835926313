import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, IconButton, Box, Typography } from '@mui/material';
import SelectDialog from '../form/select-dialog';
import InputField from '../form/input-field';
import HeaderDialog from './header-dialog';
import PrimaryButton from '../buttons/primary-button';
import { colorTheme } from '../../assets/theme';
import { ReactComponent as CollaboratorIcon } from "./../../assets/images/add-collaborator-icon.svg";
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { assignUserToSurvey } from "../../redux/actions";
import { useDispatch } from 'react-redux';

export default function AddTeammateDialog({ open, handleClose, Icon }) {
    const [email, setEmail] = useState('');
    const [surveySelections, setSurveySelections] = useState([{ survey: '', role: '', name: '' }]);
    const surveys = useSelector(state => state.surveys);
    const cognitoUsers = useSelector(state => state.cognitoUsers);
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userData);

    console.log('Surveys:', surveys);

    const handleAddSurvey = () => {
        if (surveySelections.length < surveys.length) {
            setSurveySelections([...surveySelections, { survey: '', role: '', name: '' }]);
        }
    };


    const handleSurveyChange = (index, type, value) => {
        const newSelections = surveySelections.map((item, i) => {
            if (i === index) {
                if (type === 'survey') {
                    const selectedSurvey = surveys.find(survey => survey.id === value);
                    return { ...item, [type]: value, name: selectedSurvey.name };
                }
                return { ...item, [type]: value };
            }
            return item;
        });
        setSurveySelections(newSelections);
    };

    const handleRemoveSurvey = (index) => {
        const newSelections = surveySelections.filter((_, i) => i !== index);
        setSurveySelections(newSelections);
    };

    const handleSaveChanges = () => {
        // Log selected surveys and roles
        console.log('Selected surveys and roles:', surveySelections);


        for (const selection of surveySelections) {
            if (selection.survey && selection.role) {
                console.log('Assigning user to survey:', selection.survey, 'with role:', selection.role);
                // Call API to assign user to survey
                // Use the following dispatch to call the API
                let callerData = { name: userData.firstName, lastName: userData.lastName }
                console.log(email, selection.survey, selection.role, callerData, selection.name)
                const attributes = {
                    firstName: '',
                    lastName: '',
                };

                console.log(callerData, cognitoUsers)

                dispatch(assignUserToSurvey(
                    selection.survey,
                    undefined,
                    email,
                    attributes,
                    selection.name,
                    selection.role,
                    callerData,
                    cognitoUsers

                ));
            }
        }

        // Close dialog
        handleClose();
    };

    // Options for surveys and roles, adjust these based on your actual data
    const getAvailableSurveyOptions = (index) => {
        const selectedSurveyIds = surveySelections.map(selection => selection.survey);
        return surveys
            .filter(survey => !selectedSurveyIds.includes(survey.id) || surveySelections[index].survey === survey.id)
            .map(survey => ({
                value: survey.id,
                label: survey.name,
            }));
    };

    const roleOptions = [
        { value: 'editor', label: 'Editor' },
        { value: 'viewer', label: 'Viewer' },
    ];

    return (
        <Dialog open={open} onClose={handleClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '550px', // Increased the width slightly
                }
            }}
        >
            <HeaderDialog Icon={CollaboratorIcon} handleClose={handleClose} title={'Add Teammate'} />
            <DialogContent sx={{ p: 2 }}>
                <InputField
                    label="Enter email"
                    textFieldWidth={'364px'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {surveySelections.map((selection, index) => (
                    <div key={index} style={{ marginBottom: 20 }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1, mt: 3 }}>
                            <SelectDialog
                                label="Select Survey"
                                options={getAvailableSurveyOptions(index)}
                                width='364px'
                                value={selection.survey}
                                onChange={(value) => handleSurveyChange(index, 'survey', value)}
                            />
                            <SelectDialog
                                label="Role"
                                options={roleOptions}
                                width='112px'
                                value={selection.role}
                                onChange={(value) => handleSurveyChange(index, 'role', value)}
                            />
                            {index > 0 && (
                                <IconButton
                                    size="small"
                                    color={colorTheme.theme.transform.darkBlue}
                                    onClick={() => handleRemoveSurvey(index)}
                                    sx={{ ml: 1, alignSelf: 'center', padding: 0 }}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                    </div>
                ))}
                {surveySelections.length < surveys.length && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', flex: 1, gap: 2, mt: 1 }}>
                        <Typography fontSize={12} color={colorTheme.theme.transform.darkBlue} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleAddSurvey}>
                            <span style={{ marginRight: 5 }}>+</span> Add access to another survey
                        </Typography>
                    </Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'flex-end' }}>
                    <PrimaryButton onClick={handleSaveChanges} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text={'Add'} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}
