import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Box } from "@material-ui/core";
import ToggleSwitchValue from "../../../../../../components/form/toogle-switch-value";
import useTextBoxAdvancedSettings from "../../../../../../hooks/editor/text-box/use-text-box-advanced-settings";
import CustomTextField from "../../../../../../components/form/questions-text-field";
import './text-box-question.scss';

// This component is used to render the advanced settings for the text box question in the survey editor.
const TextBoxAdvancedSettings = (props) => {
    const question = props.template;
    // text box advanced settings hook
    const {
        textInResponseFieldSwitch,
        textInResponseField,
        handleResponseFieldSwitch,
        handleTextInResponseField
    } = useTextBoxAdvancedSettings(question);

    return (
        <div className='advanced-settings-text-box'>
            <div className='customize-response-section'>
                <Box ml={1.5} mb={2} mt={-2}>
                    {/* Toggle switch for customizing the placeholder text for the response field */}
                    <FormControlLabel
                        control={
                            <ToggleSwitchValue
                                label='Customize Placeholder Text for Response Field'
                                value={textInResponseFieldSwitch}
                                onChange={handleResponseFieldSwitch}
                                description={'Set the text that appears in the response field before respondents begin typing their answers.'}
                            />
                        }
                    />
                </Box>
                {/* Text field for entering the text that appears in the response field */}
                <Box
                    className={!textInResponseFieldSwitch ? 'disabled' : ''}>
                    <CustomTextField
                        disabled={!textInResponseFieldSwitch}
                        placeholder="Your answer"
                        value={textInResponseField}
                        onChange={e => handleTextInResponseField(e.target.value)}
                    />
                </Box>
            </div>
        </div>
    );
};

export default TextBoxAdvancedSettings;
