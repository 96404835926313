import React from "react";
import TitleWithIcon from "../../../components/title/title-icon";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import { ReactComponent as BillingIcon } from "../../../assets/images/title/billing.svg";
import { Box } from '@mui/material';

// BillingContainer component
const BillingContainer = () => {
    // Define the path segments for the breadcrumbs
    const pathSegments = [
        { name: "Transform", path: "/" },
        { name: "Settings", path: "/settings" },
        { name: "Billing", path: "/settings" }
    ];

    return (
        <Box mt={3}>
            {/* Render the Breadcrumbs component with the pathSegments */}
            <Breadcrumbs pathSegments={pathSegments} />
            <Box mt={2.5}>
                <Box className="organization-container">    
                    {/* Render the TitleWithIcon component */}
                    <TitleWithIcon title="Billing" Icon={BillingIcon} />
                </Box>  
            </Box>
        </Box>
    );
}

export default BillingContainer;