import _ from "lodash";

import { ROW_ID, STATUS_COLUMN, ACTIVE_STATUS_COLUMN, ACTIVE_STATUS_COLUMN_TIMESTAMP } from "../assets/constants";

export const transformDbQuestions = (dbQuestions) => {
};

export const transformListToDbList = (list) => {
    return list.map(l => {
        return {
            ...l,
            tableData: null,
        }
    })
};

export const transformDbLists = (lists) => {
    let transformedLists = [];
    lists.forEach(list => {
        let data = _.cloneDeep(list.data);
        let allFields = [];
        data.forEach(d => {
            allFields = [...allFields, ..._.keys(d)];
            d.tableData = null;
        });
        const uniqueColumns = _.uniq(allFields);
        let columns = [];
        uniqueColumns.forEach(col => {
            if (col === ROW_ID) {
                return;
            }
            if (col === STATUS_COLUMN.field) {
                columns.push(STATUS_COLUMN);
            } else {
                columns.push({ title: col, field: col });
            }
        });
        transformedLists.push({
            ...list,
            data,
            columns,
        })
    });

    console.log('transformed lists: ', transformedLists);
    return transformedLists;
};

export const transformDbRespondents = (respondentsList) => {
    let data = _.cloneDeep(respondentsList);
    let allFields = [];
    data = data.map(d => {
        const status = d.status;
        const statusText = status.substring(0, status.indexOf('#') !== -1 ? status.indexOf('#') : status.length);
        const statusTimestamp = status.indexOf('#') !== -1 ? status.substring(status.indexOf('#'), status.length) : null;

        return {
            ...d.details,
            [ROW_ID]: d.id,
            [ACTIVE_STATUS_COLUMN.field]: statusText,
            [ACTIVE_STATUS_COLUMN_TIMESTAMP.field]: statusTimestamp,
        }
    });
    data.forEach(d => {
        allFields = [...allFields, ..._.keys(d)];
        d.tableData = null;
    });
    const uniqueColumns = _.uniq(allFields);
    let columns = [];
    uniqueColumns.forEach(col => {
        if (col === ROW_ID || col === ACTIVE_STATUS_COLUMN.field || col === ACTIVE_STATUS_COLUMN_TIMESTAMP.field) {
            return; // we dont want to display id col
        }
        if (col === STATUS_COLUMN.field) {
            columns.push(STATUS_COLUMN);
        } else {
            columns.push({ title: col, field: col });
        }
    });
    const respondents = {
        data,
        columns,
    };

    console.log('transformed respondents: ', respondents);
    return respondents;
};

export const transformDbSurveys = (surveyList) => {
    return surveyList.map(survey => {
        let statusText
        let statusTimestamp
        const status = survey.status;
        if (status) {
            statusText = status.substring(0, status.indexOf('#') !== -1 ? status.indexOf('#') : status.length);
            statusTimestamp = status.indexOf('#') !== -1 ? status.substring(status.indexOf('#'), status.length) : null;
        }
        return {
            ...survey,
            status: statusText || null,
            statusTimestamp: statusTimestamp || null,
        }
    })
};
