import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Grid } from '@mui/material';
import PasswordChangeSection from './password-view';
import TwoFactorAuthSection from './two-factor-auth'
import EditPassword from './change-password';

const SecurityInfo = () => {
    const userData = useSelector(state => state.userData);
    const [isTwoFactorAuthEnabled, setIsTwoFactorAuthEnabled] = useState(userData?.twoFAEnabled === 'true');
    const [changePaswordMode, setChangePasswordMode] = useState(false);

    useEffect(() => {
        setIsTwoFactorAuthEnabled(userData?.twoFAEnabled === 'true');
    }
        , [userData?.twoFAEnabled]);

    const handleTwoFactorAuthChange = (event) => {
        setIsTwoFactorAuthEnabled(event.target.checked);
        console.log('Two-factor authentication toggled', event.target.checked);
    };

    const handleChangePasswordClick = () => {
        setChangePasswordMode(true);
    };



    return (
        <Box sx={{ p: 1, position: 'relative' }}>
            <Typography mb={3} variant="h6" gutterBottom>Security</Typography>
            <Grid container spacing={2} alignItems="center">
                {changePaswordMode && <EditPassword
                    onCancel={() => setChangePasswordMode(false)}
                />}

                {!changePaswordMode && <PasswordChangeSection onChangePasswordClick={handleChangePasswordClick} />}
                <TwoFactorAuthSection
                    isTwoFactorAuthEnabled={isTwoFactorAuthEnabled}
                    onTwoFactorAuthChange={handleTwoFactorAuthChange}
                    setIsTwoFactorAuthEnabled={setIsTwoFactorAuthEnabled}
                />
            </Grid>
        </Box>
    );
};

export default SecurityInfo;
