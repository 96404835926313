import React from 'react';
import { TextField } from '@mui/material';

const ReadOnlyInputField = ({ email, textFieldWidth, greyColor, type = 'email', label = 'Email', inputFontSize = '1rem', inputPadding = '16.5px 14px' }) => {
    console.log('email', email);
    return (
        <TextField
            id="email-readonly"
            name={type}
            label={label}
            value={email || ''}
            type={type}
            fullWidth
            variant="outlined"
            sx={{
                width: textFieldWidth, // Conditionally set the width
                '.MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: greyColor,
                    },
                    '&.Mui-disabled': {
                        WebkitTextFillColor: greyColor,
                        color: greyColor,

                        '& input': {
                            color: greyColor,
                            WebkitTextFillColor: greyColor
                        },
                    },
                    'input': {
                        padding: inputPadding,
                        fontSize: inputFontSize,
                    }
                },
                '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                    color: greyColor, // Adjust label color when disabled/read-only
                },
            }}
            InputProps={{
                readOnly: true,
                style: { color: greyColor },
            }}
            InputLabelProps={{
                shrink: true,
                style: { color: greyColor },
            }}
            disabled={true}
        />
    );
};

export default ReadOnlyInputField;
