import React from "react";
import PropTypes from 'prop-types';
import { Box, Paper, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import TitleWithIcon from "../title/title-icon";
import { ReactComponent as SurveyIcon } from "../../assets/images/title/surveys.svg";
import EmptyTableRow from './empty-table-row';
import { useSurveyTable } from "../../hooks/survey/use-survey-table";
import SurveyTableRowComponent from './survey-table-row';
import SurveyTableHeadComponent from './survey-table-head';
import { colorTheme } from '../../assets/theme';
import PrimaryButton from '../buttons/primary-button';
import { ReactComponent as PlusIcon } from "../../assets/images/actions/icon-plus.svg";
import { getRowsPerPageOptions } from '../../utils/rows-options';

// SurveyTable component
const SurveyTable = ({ surveys, handleMenuClick, toggleCreateNewSurveyDialog }) => {
    const rowsPerPageOptionsNumbers = getRowsPerPageOptions(surveys.length); // Get the rows per page options based on the length of the surveys

    // Define the headers for the table
    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'Name', width: '30%' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Status', width: '15%' },
        { id: 'created', numeric: false, disablePadding: false, label: 'Created', width: '15%' },
        { id: 'edited', numeric: false, disablePadding: false, label: 'Edited', width: '15%' },
        { id: 'published', numeric: false, disablePadding: false, label: 'Published', width: '15%' },
    ];

    const {
        handleChangePage,
        handleChangeRowsPerPage,
        filteredRows,
        page,
        surveysPerPage,
    } = useSurveyTable(surveys, headCells); // Destructure handleChangePage, handleChangeRowsPerPage, filteredRows, page, surveysPerPage from useSurveyTable hook

    return (
        <Box
            className="team-table-container"
            sx={{ width: '100%', background: "white", borderRadius: '5px'}}>
            <Box pl={2.75} pr={2.75} pt={3.5} pb={3.5}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                <TitleWithIcon title="Surveys" Icon={SurveyIcon} />
                <PrimaryButton text="Create new survey" color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} Icon={PlusIcon} onClick={toggleCreateNewSurveyDialog} />
            </Box>
            <Paper
                className='team-table-paper'
                sx={{ width: '100%', mb: 0, boxShadow: 'none', padding: '0px 22px', boxSizing: 'border-box' }}>
                <TableContainer sx={{ overflowX: 'auto' }}>
                    <Table
                        className='team-table'
                        sx={{ width: '100%', margin: 'auto', boxShadow: 'none', tableLayout: 'fixed'}}
                        aria-labelledby="tableTitle"
                    >
                        {/* Table head component */}
                        <SurveyTableHeadComponent
                            rowCount={surveys.length}
                            headCells={headCells}
                        />

                        {/* Table body */}
                        <TableBody>
                            {filteredRows.length > 0 ? (
                                filteredRows.slice(page * surveysPerPage, page * surveysPerPage + surveysPerPage).map((row, index) => {
                                    return (
                                        <SurveyTableRowComponent
                                            key={row.id}
                                            row={row}
                                            rowId={index}
                                            headCells={headCells}
                                            handleMenuClick={handleMenuClick} />
                                    );
                                })
                            ) : (
                                // Empty table row
                                <EmptyTableRow />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Table pagination */}
                {surveys.length > 0 && (
                    <TablePagination
                        sx={{
                            pt: 5,
                            mr: 0,
                            '& .MuiTablePagination-toolbar': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                            },
                            '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                                mr: -1,
                            },
                            '& .MuiTablePagination-input': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                                mr: 1.7,
                            },
                            '& .MuiTablePagination-actions': {
                                '& button': {
                                    margin: '0px',
                                    padding: '8px', // Adjust padding to ensure the button is more square
                                    transform: 'scale(0.9)',
                                    minWidth: 'auto', // Remove any minimum width restrictions
                                    width: '25px', // Equal width and height to form a square
                                    height: '25px', // Set height equal to width
                                    '& svg': {
                                        marginLeft: '0px', // Adjust if needed
                                        marginRight: '0px', // Adjust if needed
                                    },
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Set the background color on hover
                                        borderRadius: '50%', // Make the hover background rounded
                                        transform: 'scale(1.1)', // Optionally scale a bit on hover
                                    }
                                }
                            }
                        }}
                        rowsPerPageOptions={rowsPerPageOptionsNumbers}
                        component="div"
                        count={filteredRows.length}
                        rowsPerPage={surveysPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Surveys per page"
                    />
                )}
            </Paper>
        </Box>
    );
};

// Define the prop types for the SurveyTable component
SurveyTable.propTypes = {
    surveys: PropTypes.array.isRequired,
};

export default SurveyTable;