import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import PrimaryButton from '../buttons/primary-button';
import { colorTheme } from '../../assets/theme';
import { useSelector } from 'react-redux';

const PasswordChangeSection = ({ onChangePasswordClick }) => {
    const userData = useSelector(state => state.userData);

    return (
        <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight={500} gutterBottom>Password</Typography>
            <Box display="flex" alignItems="center" gap={4} mt={0}>
                <Box mt={-1.5} display="block" alignItems="center" gap={0}>
                    <Typography sx={{ fontSize: '25px', marginBottom: '-7px', color: '#4f4f4f' }}>● ● ● ● ● ● ● ● ● ● ●</Typography>
                    <Typography variant="caption" sx={{ ml: 'auto', color: 'grey', fontStyle: 'italic' }}>
                        last changed: {userData?.lastPwdChanged ? userData.lastPwdChanged : 'No data'}
                    </Typography>
                </Box>
                <Box display="block" alignItems="top" gap={1} mt={-3}>
                    <PrimaryButton
                        text="Change Password"
                        onClick={onChangePasswordClick}
                        color={colorTheme.theme.transform.darkBlueTwo}
                        hoverColor={colorTheme.theme.transform.darkBlue}
                    />
                </Box>
            </Box>
        </Grid >
    );
};

export default PasswordChangeSection;
