import React, { useState } from 'react';
import { Typography, MenuItem, Select } from '@mui/material';
import { ReactComponent as ArrowDropUpIcon } from '../../assets/images/table/select-arrow-up.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../assets/images/table/select-arrow-down.svg';

function CustomSelect({ label, options, value, onChange }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div onClick={handleClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
            <Typography sx={{
                fontWeight: 500,
                fontSize: '14px',
                color: (value || open) ? '#1B4972' : '#4F4F4F',
                marginRight: '6px'
            }}>
                {label}
            </Typography>
            {open ? <ArrowDropUpIcon sx={{ color: '#1B4972' }} /> : <ArrowDropDownIcon sx={{ color: 'inherit' }} />}
            <Select
                open={open}
                onClose={handleClose}
                value={value}
                onChange={onChange}
                MenuProps={{
                    anchorEl: anchorEl,
                    getcontentanchorel: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    PaperProps: {
                        style: {
                            marginTop: '16px',
                        },
                    }
                }}
                sx={{ display: 'none' }}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value} sx={{ color: value === option.value ? '#1B4972' : '#4F4F4F', backgroundColor: 'transparent !important' }}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}

export default CustomSelect;
