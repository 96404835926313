// UserDialogs.js
import React from 'react';
import EditAddUserDialog from '../dialogs/edit-add-user';
import DeleteUserDialog from '../dialogs/delete-user';
import { ReactComponent as CollaboratorIcon } from "./../../assets/images/add-collaborator-icon.svg";

function UserDialogs({
    isAddDialogOpen,
    toggleAddDialog,
    handleAddUser,
    setInsightsLicense,
    setSurveyLicense,
    setRole,
    isEditDialogOpen,
    toggleEditDialog,
    selectedRow,
    handleEdit,
    isDeleteDialogOpen,
    toggleDeleteDialog,
    handleDelete,
    isLoading
}) {
    return (
        <>
            <EditAddUserDialog
                open={isAddDialogOpen}
                title="Add Teammate"
                Icon={CollaboratorIcon}
                buttonText="Add"
                handleClose={toggleAddDialog}
                handleAction={handleAddUser}
                setInsightsLicense={setInsightsLicense}
                setSurveyLicense={setSurveyLicense}
                setRole={setRole}
            />
            <EditAddUserDialog
                open={isEditDialogOpen}
                title="Edit Teammate"
                Icon={CollaboratorIcon}
                buttonText="Save Changes"
                handleClose={toggleEditDialog}
                selectedRow={selectedRow}
                handleAction={handleEdit}
                setInsightsLicense={setInsightsLicense}
                setSurveyLicense={setSurveyLicense}
                setRole={setRole}
            />
            <DeleteUserDialog
                open={isDeleteDialogOpen}
                handleClose={toggleDeleteDialog}
                selectedRow={selectedRow}
                handleAction={handleDelete}
                isLoading={isLoading}
            />
        </>
    );
}

export default UserDialogs;
