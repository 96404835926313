import React from 'react';
import { TableRow, TableCell, Typography, Box } from '@mui/material';
import { ReactComponent as EmptyTableIcon } from "./../../assets/images/actions/surveys-table-empty-icon.svg";

// CustomTableEmptyRow component for the table
function CustomTableEmptyRow({ firstParagraph, secondParagraph }) {
    return (
        <>
            <TableRow sx={{ borderBottom: "none", borderTop: "none" }}>
                <TableCell colSpan={6} sx={{ borderBottom: "none", borderTop: "none" }}>
                    <Box textAlign="center" sx={{ width: '100%', pt: 5, pb: 5 }}>
                        <EmptyTableIcon style={{ margin: 'auto', display: 'block' }} />
                        <Typography sx={{ mt: 2 }} varianty="body1" >{firstParagraph}</Typography>
                        <Typography sx={{ mt: 1 }} variant="body2">{secondParagraph}</Typography>
                    </Box>
                </TableCell>
            </TableRow>
        </>
    );
}

export default CustomTableEmptyRow;