import React from "react";
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// TooltipText component to render the tooltip text
const TooltipText = ({children, name, placement, backgroundColor, textColor, margin, boxShadow = 'none', arrow = true }) => {
    const useStylesBootstrap = makeStyles(() => ({ // Custom styles for the tooltip
        tooltip: {
            backgroundColor: backgroundColor,
            color: textColor,
            margin: margin,
            fontFamily: 'Inter, sans-serif',
            fontSize: '10px',
            padding: '4px 8px',
            fontWeight: 400,
            borderRadius: '1px',
            boxShadow: boxShadow
        },
        arrow:
        {
            color: backgroundColor,
        }
    }));

    const tooltipClass = useStylesBootstrap();  // Get the custom styles for the tooltip

    return (
        <Tooltip title={name} classes={tooltipClass} placement={placement} arrow={arrow}>
            {children}
        </Tooltip>
    )
}

export default TooltipText;
