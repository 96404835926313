import React from "react";
import './sign-in.scss';
import { Box, Typography } from "@mui/material";
import TextButton from "../buttons/text-button";
import { colorTheme } from '../../assets/theme';
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-left.svg';
import useSignIn from "../../hooks/sign-in/use-sign-in";
import MFAComponent from "./mfa";
import PasswordInputComponent from "./password-input";
import SignInFormComponent from "./sign-in-form";
import VerificationCodeComponent from "./verification-code";
import ForgotPasswordComponent from "./forgot-password";


// Constants for the password policy
const passPolicy = "At least one upper case English letter [A-Z];  At least one lower case English letter: [a-z]; At least one digit, [0-9]; At least one special character, [#?!@$%^&*-] Minimum eight in length (with the anchors);"
// Constants for the input types


// SignIn component
const SignIn = () => {

    const {
        form,
        loading,
        passwordError,
        confirmPassError,
        generalAuthError,
        forgotPassword,
        forgotEmail,
        forgotError,
        submitedForgotRequest,
        mainPage,
        invitedUser,
        mfaChallenge,
        mfaCode,
        setMfaCode,
        setHandleNewPassword,
        handleNewPassword,
        handleVerifyMfa,
        handleChange,
        signIn,
        handleForgotClick,
        handleBack,
        handleForgotScreenText,
        handleSendEmail,
        handleNewPass,
        handleRegisterRedirect,
        verificationTriggered,
        forgotPassSubmit,
        INPUT_EMAIL,
        INPUT_PASSWORD,
        CONFIRMATION_CODE,
        NEW_PASSWORD,
        NEW_PASSWORD_CONFIRMATION,
        FIRST_NAME,
        FAMILY_NAME,
        INPUT_NUMBER,
        firstNameReqiured,
        lastNameRequired,
        isLoading
    } = useSignIn();


    return (
        <Box className="signin-page-content">
            <Box className="signin-page-container">
                {
                    ((submitedForgotRequest && !mainPage)) ?
                        <Typography variant="h4" className="signin-page-title">Reset Your Password</Typography> :
                        (!forgotPassword ? !mfaChallenge && !handleNewPassword &&
                            <Typography variant="h4" className="signin-page-title">LOG IN</Typography> :
                            <Typography variant="h4" className="signin-page-title">Verify Your Email</Typography>)}
                {mfaChallenge && !mainPage && <Typography variant="h4" className="signin-page-title">Two-Step Authentication</Typography>}
                {handleNewPassword && !mainPage && <Typography variant="h4" className="signin-page-title">Complete Your Account Setup</Typography>}

                {invitedUser ? // If user is invited

                    <PasswordInputComponent
                        form={form}
                        handleChange={handleChange}
                        passwordError={passwordError}
                        confirmPassError={confirmPassError}
                        handleNewPass={handleNewPass}
                        passPolicy={passPolicy}
                        NEW_PASSWORD={NEW_PASSWORD}
                        NEW_PASSWORD_CONFIRMATION={NEW_PASSWORD_CONFIRMATION}
                        FIRST_NAME={FIRST_NAME}
                        FAMILY_NAME={FAMILY_NAME}
                        INPUT_NUMBER={INPUT_NUMBER}
                        firstNameReqiured={firstNameReqiured}
                        lastNameRequired={lastNameRequired}
                        isLoading={isLoading}
                    ></PasswordInputComponent>
                    :
                    <>
                        {submitedForgotRequest && !mainPage && // If the user has submitted the forgot password request
                            <VerificationCodeComponent
                                form={form}
                                handleChange={handleChange}
                                generalAuthError={generalAuthError}
                                forgotPassSubmit={forgotPassSubmit}
                                handleForgotClick={handleForgotClick}
                                colorTheme={colorTheme}
                                CONFIRMATION_CODE={CONFIRMATION_CODE}
                                NEW_PASSWORD={NEW_PASSWORD}
                                NEW_PASSWORD_CONFIRMATION={NEW_PASSWORD_CONFIRMATION}
                                ArrowRight={ArrowRight}
                                isLoading={isLoading}
                            ></VerificationCodeComponent>
                        }
                        {/* mfa */}
                        {mfaChallenge && !mainPage && (

                            <MFAComponent
                                mfaCode={mfaCode}
                                setMfaCode={setMfaCode}
                                handleVerifyMfa={handleVerifyMfa}
                                handleBack={handleBack}
                                ArrowRight={ArrowRight}
                                loading={loading}
                            ></MFAComponent>
                        )}


                        {forgotPassword && !mainPage && // If the user has clicked the forgot password button
                            <ForgotPasswordComponent
                                form={form}
                                handleChange={handleChange}
                                generalAuthError={generalAuthError}
                                forgotEmail={forgotEmail}
                                forgotError={forgotError}
                                handleForgotScreenText={handleForgotScreenText}
                                handleSendEmail={handleSendEmail}
                                colorTheme={colorTheme}
                                INPUT_EMAIL={INPUT_EMAIL}
                                handleBack={handleBack}
                                ArrowRight={ArrowRight}
                                isLoading={isLoading}
                            ></ForgotPasswordComponent>
                        }
                        {mainPage && // If the user is on the main page
                            <><SignInFormComponent
                                form={form}
                                handleChange={handleChange}
                                generalAuthError={generalAuthError}
                                loading={loading}
                                signIn={signIn}
                                colorTheme={colorTheme}
                                INPUT_EMAIL={INPUT_EMAIL}
                                INPUT_PASSWORD={INPUT_PASSWORD}
                                handleRegisterRedirect={handleRegisterRedirect}
                                verificationTriggered={verificationTriggered}
                            ></SignInFormComponent>
                                <TextButton name="Forgot your password?" size="big" onClick={handleForgotClick} /></>
                        }
                    </>
                }
            </Box>
        </Box>
    )
};

export default SignIn;