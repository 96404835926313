import { forwardRef, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import React from "react";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import _ from "lodash";
import MaterialTable, { MTableEditField } from "material-table";
import TextField from "@material-ui/core/TextField";
import { colorTheme } from "../../../../assets/theme";

import { CUSTOM_LIST_FILE, COGNITO_LIST_FILE, ROW_ID, ROW_OK, STATUS_COLUMN } from "../../../../assets/constants";

const uuid = require('uuid');

const COLOR_ERROR = colorTheme.theme.colors.error;
const DEFAULT_PAGE_SIZE = 10;
const RESPONDENT_OK = 'RESPONDENT OK';

function CustomTable(props) {
    const { enqueueSnackbar } = useSnackbar();
    const currentSurvey = useSelector(state => state.currentSurvey);
    const userData = useSelector(state => state.userData);
    const [state, setState] = useState({
        columns: [],
        data: [],
    });
    const [title, setTitle] = useState("");
    const delayedUpdateTitle = useCallback(_.debounce((title, lists) => props.updateTitle(title, lists), 800), []);

    let tableRef = React.createRef();

    const openSnackbar = (msg) => {
        enqueueSnackbar(msg, {
            variant: 'error',
        });
    };

    useEffect(() => {
        if (props.title) {
            setTitle(props.title);
        }
    }, []);

    const handleTitleChange = (title) => {
        setTitle(title)
        delayedUpdateTitle(title, currentSurvey.lists);
    };

    useEffect(() => {
        if (props.data && props.columns) {
            if (tableRef && tableRef.current && tableRef.current.dataManager) {
                // fix: reset sorting to avoid MaterialTable crash if sort index out of bounds
                tableRef.current.dataManager.orderBy = -1;
                tableRef.current.dataManager.orderDirection = "";
            }
            // add default sort by status
            let columns = _.cloneDeep(props.columns);
            let statusColumnIndex = columns.findIndex(col => col.field === STATUS_COLUMN.field);
            if (statusColumnIndex !== -1) {
                columns[statusColumnIndex].defaultSort = 'asc';
            }

            setState(prevState => {
                return {
                    ...prevState,
                    data: _.cloneDeep(props.data),
                    columns: columns,
                }
            })
        }
    }, [props.data, props.columns]);

    const tableIcons = {
        Add: forwardRef((props, ref) => <PersonAddIcon {...props} ref={ref} />)
    }

    const updateData = (data, updatedRecord) => {
        props.updateData({ columns: state.columns, data: data }, updatedRecord);
    };

    const handleRowAdd = (newData) => {
        console.log('handle row add, new data: ', newData);
        return new Promise(resolve => {
            if (props.data) {
                const data = [...state.data];
                data.push({
                    ...newData,
                    [ROW_ID]: newData[ROW_ID] ? newData[ROW_ID] : uuid.v4()
                });
                updateData(data, newData);
            }
            resolve();
        })
    };

    const handleRowUpdate = (newData, oldData) => {
        console.log('handle row update, new data: ', newData);
        console.log('old data: ', oldData);
        return new Promise(resolve => {
            if (props.data) {
                const data = [...state.data];
                data[data.indexOf(oldData)] = newData;
                updateData(data, newData);
            }
            resolve();
        })
    };

    const handleRowDelete = (oldData) => {
        console.log('handle row delete, old data: ', oldData);
        if (oldData.Email === userData.email) {
            openSnackbar('You can not delete yourself from workspace')
            return Promise.reject()
        }
        if (oldData.Role === 'owner') {
            openSnackbar('You can not delete owner of the workspace')
            return Promise.reject()
        }
        const deleteRowIndex = oldData.tableData.id;
        return new Promise(resolve => {
            resolve();
            if (props.data) {
                const data = [...state.data];
                data.splice(deleteRowIndex, 1);
                if (props.deleteData) {
                    props.deleteData([oldData], _.cloneDeep({ columns: state.columns, data: data }));
                } else {
                    updateData(data);
                }
            }
        })
    };

    const handleMultiRowDelete = (deletedData) => {
        console.log('handle multi row delete, deletedData: ', deletedData);
        const deleteRowIndexes = deletedData.map(d => d.tableData.id);
        return new Promise(resolve => {
            resolve();
            if (props.data) {
                const data = [...state.data];
                _.remove(data, (value, index) => deleteRowIndexes.includes(index));
                if (props.deleteData) {
                    props.deleteData(deletedData, _.cloneDeep({ columns: state.columns, data: data }));
                } else {
                    updateData(data);
                }
            }
        })
    };

    const handleSelectedRows = (selectedRows) => {
        if (selectedRows) {
            props.selectedRows(selectedRows)
        }
    }

    const prepareColumns = (columns) => {
        return columns.filter(col => col.title !== 'tableData')
    }

    return (
        <div>
            {props.type !== COGNITO_LIST_FILE ?
                <MaterialTable
                    className="material-table"
                    title={
                        props.type === CUSTOM_LIST_FILE ?
                            <TextField
                                value={title}
                                onChange={e => {
                                    handleTitleChange(e.target.value)
                                }}
                                fullWidth
                                name="title"
                                label="Title"
                                autoComplete="title"
                            /> :
                            props.title
                    }
                    columns={
                        prepareColumns(state.columns).map((c) => ({ ...c, tableData: undefined }))
                    }
                    data={state.data}
                    editable={{
                        onRowAdd: newData => handleRowAdd(newData),
                        onRowUpdate: (newData, oldData) => handleRowUpdate(newData, oldData),
                        onRowDelete: oldData => handleRowDelete(oldData),
                    }}
                    components={{
                        EditField: editProps => <MTableEditField autoFocus={true} {...editProps} />
                    }}
                    options={{
                        draggable: false,
                        sorting: true,
                        fixedColumns: {
                            left: 0,
                        },
                        actionsColumnIndex: 0,
                        rowStyle: rowData => {
                            const status = rowData[STATUS_COLUMN.field];
                            return {
                                backgroundColor: (status !== RESPONDENT_OK && status !== ROW_OK) ? COLOR_ERROR : '',
                            }
                        },
                        selection: true,
                        pageSize: DEFAULT_PAGE_SIZE,
                        // pageSizeOptions: [5, 10, 20, { value: state.data.length, label: 'All' }],
                        exportButton: true,
                        exportAllData: true,
                    }}
                    style={{
                        margin: '10px 0'
                    }}
                    actions={[
                        {
                            tooltip: 'Remove All Selected Respondents',
                            icon: 'delete',
                            onClick: (evt, data) => handleMultiRowDelete(data)
                        }
                    ]}
                    tableRef={tableRef}
                /> :
                <MaterialTable
                    icons={tableIcons}
                    className="material-table"
                    title={props.title}
                    columns={
                        prepareColumns(state.columns).map((c) => ({ ...c, tableData: undefined }))
                    }
                    data={state.data}
                    editable={{
                        onRowDelete: oldData => handleRowDelete(oldData),
                    }}
                    options={{
                        draggable: false,
                        sorting: true,
                        fixedColumns: {
                            left: 0,
                        },
                        actionsColumnIndex: 0,
                        pageSize: DEFAULT_PAGE_SIZE,
                        exportButton: true,
                        exportAllData: true,
                        selection: props.source === 'survey-view',
                    }}
                    style={{
                        margin: '10px 0'
                    }}
                    onSelectionChange={((rows) => handleSelectedRows(rows))}
                    tableRef={tableRef}
                />}
        </div>
    );
}

export default CustomTable;
