import React from "react";
import './organization.scss';
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from 'notistack';
import { ReactComponent as SaveIcon } from "../../assets/images/actions/icon-save.svg";
import countryList from 'react-select-country-list'
import InputField from "../form/input-field-label";
import PrimaryButton from "../buttons/primary-button";
import ClassicSelect from "../form/classic-select";
import { colorTheme } from "../../assets/theme";
import { INDUSTRIES } from '../../assets/constants';
import { updateOrganizationDataAction } from "../../redux/actions";
import { lastActivity } from "../../utils/last-activity";

// OrganizationEdit component
const OrganizationEdit = ({ onEditClick }) => {
    const dispatch = useDispatch(); // Use the useDispatch hook
    const { enqueueSnackbar } = useSnackbar(); // Use the useSnackbar hook
    const organizationData = useSelector(state => state.organizationData); // Get the organization data from the state
    const [localOrganizationData, setLocalData] = useState(organizationData); // Set the local organization data to the organization data from the state
    const countries = countryList().getLabels(); // Get the list of countries
    const cognitoUsers = useSelector(state => state.cognitoUsers); // Get the cognito users from the redux store
    const userData = useSelector(state => state.userData); // Get the user data from the redux store
    const [isLoading, setIsLoading] = useState(false);
    // Handle change function for the input fields
    const handleCompanyChange = (key) => (e) => {
        setLocalData({
            ...localOrganizationData,
            [key]: e.target.value
        });
    };

    // Function to handle the save button
    const handleSave = async () => {
        setIsLoading(true);
        try {
            await dispatch(updateOrganizationDataAction(localOrganizationData)).then(() => {
                onEditClick();
                enqueueSnackbar('Organization data updated successfully', { variant: 'success' });
                lastActivity(userData.email, cognitoUsers, dispatch);
            });
        } catch (error) {
            enqueueSnackbar('Error updating user data', { variant: 'error' });
        }
        setIsLoading(false);
    };

    return (
        <Box className="organization">
            <Box className="organization-column">
                <InputField value={localOrganizationData.companyName} label="Company Name" placeholder="SELECT HERE" textFieldWidth="100%" inputPadding="10px 12px" lineHeight="20px" inputfontSize="14px" boxMarginBottom="0px" labelFontSize="12px" labelMarginBottom="8px" onChange={handleCompanyChange('companyName')} />
                <Box style={{ position: 'relative', marginBottom: '0px', width: '100%' }}>
                    <Box component="label" sx={{
                        color: colorTheme.theme.text.lightGrey,
                        display: 'block',
                        marginBottom: '8px',
                        fontSize: '12px'
                    }}>
                        Industry
                    </Box>
                    <ClassicSelect label="Select 1" value={localOrganizationData.industry} list={INDUSTRIES} onChange={handleCompanyChange('industry')} />
                </Box>
                <InputField value={localOrganizationData.founded} label="Founded" placeholder="Enter year (e.g.,1999)" textFieldWidth="100%" inputPadding="10px 12px" lineHeight="20px" inputfontSize="14px" boxMarginBottom="0px" labelFontSize="12px" labelMarginBottom="8px" onChange={handleCompanyChange('founded')} />
                <InputField value={localOrganizationData.numberOfEmployees} label="Number of employees" placeholder="Enter Number (e.g., 50)" textFieldWidth="100%" inputPadding="10px 12px" lineHeight="20px" inputfontSize="14px" boxMarginBottom="0px" labelFontSize="12px" labelMarginBottom="8px" onChange={handleCompanyChange('numberOfEmployees')} />
            </Box>
            <Box className="organization-column">
                <InputField value={localOrganizationData.website} label="Website" placeholder="Enter Website URL" textFieldWidth="100%" inputPadding="10px 12px" lineHeight="20px" inputfontSize="14px" boxMarginBottom="0px" labelFontSize="12px" labelMarginBottom="8px" onChange={handleCompanyChange('website')} />
                <InputField value={localOrganizationData.streetAddress} label="Street address" placeholder="Enter Company Street Address" textFieldWidth="100%" inputPadding="10px 12px" lineHeight="20px" inputfontSize="14px" boxMarginBottom="0px" labelFontSize="12px" labelMarginBottom="8px" onChange={handleCompanyChange('streetAddress')} />
                <InputField value={localOrganizationData.city} label="City" placeholder="Enter Company City" textFieldWidth="100%" inputPadding="10px 12px" lineHeight="20px" inputfontSize="14px" boxMarginBottom="0px" labelFontSize="12px" labelMarginBottom="8px" onChange={handleCompanyChange('city')} />
                <Box style={{ position: 'relative', marginBottom: '0px', width: '100%' }}>
                    <Box component="label" sx={{
                        color: colorTheme.theme.text.lightGrey,
                        display: 'block',
                        marginBottom: '8px',
                        fontSize: '12px'
                    }}>
                        Country
                    </Box>
                    <ClassicSelect label="Select Country" value={localOrganizationData.country} list={countries} onChange={handleCompanyChange('country')} />
                </Box>
            </Box>
            <Box sx={{ position: { sm: 'absolute' }, right: 40, top: 80, display: 'flex', gap: '1rem' }}>
                <PrimaryButton onClick={onEditClick} color="white" hoverColor="#EDF0F4" text="Cancel" hoverText={colorTheme.theme.transform.darkBlue} />
                <PrimaryButton
                    isLoading={isLoading}
                    onClick={handleSave} text="Save" color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} Icon={SaveIcon} />
            </Box>
        </Box>
    )
};

export default OrganizationEdit;