import React, { useEffect, useState } from 'react';
import './recurring-box.scss'
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select"
import DateFnsUtils from "@date-io/date-fns";
import date from 'date-and-time';
import { Box } from '@mui/material';
import { updateCurrentSurvey } from "../../../redux/actions";
import { MenuItem, TextField, FormControl, Paper, InputLabel, FormGroup } from '@material-ui/core';
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import ToggleSwitchValue from '../../form/toogle-switch-value';
import CustomRadioLabel from '../../form/custom-radio-label';
import { colorTheme } from '../../../assets/theme';
import "date-fns";

// RecurringBox component
function RecurringBox(props) {
    const OCCURENCIES = 'For number of occurencies'; // For number of occurencies
    const FOREVER = 'Forever'; // Forever
    const DATE = 'Until a date';  // Until a date
    const dispatch = useDispatch(); // Dispatch
    const currentSurvey = useSelector(state => state.currentSurvey); // Current survey
    const selectOptions = ['Daily', 'Monthly', 'Quarterly', 'Custom']; // Select options
    const customOptions = ['Hour(s)', 'Day(s)', 'Month(s)'] // Custom options
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']; // Days
    const weeks = Array.from({ length: 10 }, (_, i) => i + 1) // Weeks
    const endingOptions = [FOREVER, OCCURENCIES, DATE]; // Ending options
    const [selectEnding, setSelectEnding] = useState(''); // Select ending
    const [isRecurring, setIsRecurring] = useState(false); // Is recurring
    const [weekDay, setWeekDay] = useState(''); // Week day
    const [rateSelect, setRateSelect] = useState(''); // Rate select
    const [radioGroupValue, setRadioGroupValue] = useState(''); // Radio group value
    const [endGroupValue, setEndGroupValue] = useState('never'); // End group value
    const [recurringTime, setRecurringTime] = useState('08:00'); // Recurring time
    const [selectedEndDate, setSelectedEndDate] = useState(); // Selected end date
    const [numOfOccurencies, setNumOfOccuriencies] = useState(''); // Number of occurencies
    const [dailyCheckbox, setDailyCheckbox] = useState([]); // Daily checkbox
    const [customInterval, setCustomInterval] = useState(''); // Custom interval
    const [customRecurringField, setCustomRecurringField] = useState(''); // Custom recurring field

    const RADIO_VALUE_NEVER = 'never';  // Radio value never
    const RADIO_VALUE_AFTER = 'after' // Radio value after

    // useEffect to set the values of the current survey
    useEffect(() => {
        if (currentSurvey) {
            if (currentSurvey.recurring === true) {
                setIsRecurring(true)
            }
            if (currentSurvey.recurringInterval) {
                setRadioGroupValue(currentSurvey.recurringInterval)
            }
            // if (currentSurvey.endDate){
            //     setSelectEnding(DATE)
            //     setSelectedEndDate(currentSurvey.endDate)
            // }
            if (currentSurvey.recurringRate) {
                setRateSelect(currentSurvey.recurringRate)
            }
            if (currentSurvey.recurringDay) {
                if (typeof currentSurvey.recurringDay === 'string') {
                    setWeekDay(currentSurvey.recurringDay)
                }
                else {
                    setDailyCheckbox(currentSurvey.recurringDay)
                }
            }
            if (currentSurvey.recurringTime) {
                setRecurringTime(currentSurvey.recurringTime)
            }
            if (currentSurvey.customInterval) {
                setCustomInterval(currentSurvey.customInterval)
            }
            if (currentSurvey.recurringInterval === 'Custom' && currentSurvey.recurringRate) {
                setCustomRecurringField(currentSurvey.recurringRate)
            }
            if (currentSurvey.numOfOccurencies) {
                setEndGroupValue(RADIO_VALUE_AFTER)
                setNumOfOccuriencies(currentSurvey.numOfOccurencies)
            } else {
                setEndGroupValue(RADIO_VALUE_NEVER)
            }
        }
    }, [])

    // useEffect to update the current survey
    useEffect(() => {
        if (rateSelect && numOfOccurencies && radioGroupValue) {
            let endDate = calcEndDateBasedOnOccurencies(numOfOccurencies, rateSelect, radioGroupValue)
            dispatch(updateCurrentSurvey({ ...currentSurvey, endDate: endDate }))
        }
    }, [rateSelect])

    // useEffect to set the recurring time
    useEffect(() => {
        setRecurringTime('08:00')
    }, [radioGroupValue])

    // useEffect to update the current survey
    useEffect(() => {
        if (numOfOccurencies && rateSelect && weekDay) {
            dispatch(updateCurrentSurvey({ ...currentSurvey, endDate: calcEndDateBasedOnOccurencies(numOfOccurencies, rateSelect, radioGroupValue) }))
        }
    }, [recurringTime])

    // Function to handle recurring checkbox
    const handleRecurringCheckBox = (checked) => {
        setIsRecurring(checked)
        dispatch(updateCurrentSurvey({ ...currentSurvey, recurring: checked }));

        // Clear the values if the checkbox is unchecked
        if(checked === false){
            dispatch(updateCurrentSurvey({ ...currentSurvey, recurring: checked, recurringInterval: null, recurringRate: null, recurringDay: null, recurringTime: null, endDate: null, numOfOccurencies: null }))
            setRadioGroupValue('')
        }
    }

    // Function to handle rate select
    const handleRateSelect = ({ value }) => {
        dispatch(updateCurrentSurvey({ ...currentSurvey, recurringRate: value }))
        setRateSelect(value)
    }

    // Function to handle group change
    const handleGroupChange = (value) => {
        dispatch(updateCurrentSurvey({ ...currentSurvey, recurringInterval: value }))
        setRadioGroupValue(value)
    }

    // Function to handle end select
    const handleEndSelect = ({ value }) => {
        setSelectEnding(value)
        switch (value) {
            case FOREVER:
                dispatch(updateCurrentSurvey({ ...currentSurvey, endDate: null, numOfOccurencies: null }))
                break;
        }
    }

    // Function to handle week day select
    const weekDaySelect = ({ value }) => {
        dispatch(updateCurrentSurvey({ ...currentSurvey, recurringDay: value }))
        setWeekDay(value)
    }

    // Function to handle time field
    const handleTimeField = (value) => {
        setRecurringTime(value)
        dispatch(updateCurrentSurvey({ ...currentSurvey, recurringTime: value }))
    }

    // Function to handle recurring end date
    const handleRecurringEndDate = (date) => {
        setSelectedEndDate(date);
        dispatch(updateCurrentSurvey({ ...currentSurvey, endDate: date }));
    }

    // Function to handle occuriencies field
    const handleOccurienciesField = (value) => {
        setNumOfOccuriencies(value)
        let endDate = calcEndDateBasedOnOccurencies(value, rateSelect, radioGroupValue)
        dispatch(updateCurrentSurvey({ ...currentSurvey, endDate: endDate, numOfOccurencies: value }))
    }

    // Function to handle week day check box
    const handleWeekDayCheckBox = (value) => {
        let prevValue = dailyCheckbox;
        if (prevValue && prevValue.includes(value)) {
            prevValue = prevValue.filter(item => item !== value)
            setDailyCheckbox(prevValue)
            dispatch(updateCurrentSurvey({ ...currentSurvey, recurringDay: prevValue }))
        } else {
            setDailyCheckbox([
                ...prevValue, value
            ])
            dispatch(updateCurrentSurvey({
                ...currentSurvey, recurringDay: [
                    ...prevValue, value]
            }))
        }
    }

    // Function to calculate the end date based on occurencies
    const calcEndDateBasedOnOccurencies = (numOfOccurencies, rate, interval) => {
        if (numOfOccurencies && rate && interval) {
            let endDate;
            let startDate = new Date(Date.now())
            if (currentSurvey.startImmediately === false) {
                startDate = new Date(currentSurvey.startDate)
            }
            switch (interval) {
                case 'Daily':
                    endDate = date.addDays(startDate, numOfOccurencies)
                    break;
                case 'Monthly':
                    endDate = date.addDays(startDate, rate * 7 * numOfOccurencies)
                    break;
                case 'Quarterly':
                    endDate = date.addDays(startDate, rate * 7 * numOfOccurencies)
                    break;
                case 'Custom':
                    if (customInterval === 'Day(s)') {
                        endDate = date.addDays(startDate, customRecurringField * numOfOccurencies)
                    }
                    else if (customInterval === 'Month(s)') {
                        endDate = date.addMonths(startDate, customRecurringField * numOfOccurencies)
                    }
                    else if (customInterval === 'Hour(s)') {
                        endDate = date.addHours(startDate, customRecurringField * numOfOccurencies)
                    }
            }
            let parsedHours = recurringTime.split(':');
            endDate.setHours(parsedHours[0], parsedHours[1]);
            return endDate
        }
    }

    // Function to create interval string
    const createIntervalString = (num) => {
        switch (num) {
            case 1:
                return `${num}st week`
            case 2:
                return `${num}nd week`
            case 3:
                return `${num}rd week`
            default:
                return `${num}th week`
        }
    }
 
    // Function to handle custom recurring field
    const handleCustomRecurringField = (value) => {
        setCustomRecurringField(value)
        dispatch(updateCurrentSurvey({ ...currentSurvey, recurringRate: value }))
    }

    // Function to handle custom interval
    const handleCustomInterval = ({ value }) => {
        setCustomInterval(value)
        dispatch(updateCurrentSurvey({ ...currentSurvey, customInterval: value }))
    }

    // Function to render time layout
    const renderTimeLayout = () => {
        return (
            <>
                <Box className='time-and-duration'>
                    <span>Time:</span>
                    <TextField
                        disabled={!isRecurring || props.disabled}
                        id="time"
                        label="UTC+2"
                        type="time"
                        value={recurringTime}
                        className='time-picker'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                        onChange={e => handleTimeField(e.target.value)}
                    />
                </Box>
                <Box className='active'>
                    <FormControl className='ending-options'>
                        <span className='effective-span'>Effective:</span>
                        <Select
                            disabled={!isRecurring || props.disabled}
                            className='effective-options'
                            autoWidth={true}
                            variant='outlined'
                            value={selectEnding || ''}
                            onChange={e => handleEndSelect(e.target)} >

                            {endingOptions.map((option, index) => (
                                <MenuItem key={option} value={option} key={index}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        {selectEnding === OCCURENCIES &&
                            <TextField
                                disabled={!isRecurring || props.disabled}
                                className='occurencies'
                                variant='outlined'
                                type='number'
                                size='small'
                                value={numOfOccurencies.toString()}
                                onChange={e => handleOccurienciesField(e.target.value)}
                            />
                        }
                        {selectEnding === DATE &&
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disabled={!isRecurring || props.disabled}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-end"
                                    value={selectedEndDate}
                                    onChange={handleRecurringEndDate}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    className="date-picker"
                                />
                            </MuiPickersUtilsProvider>
                        }
                    </FormControl>
                </Box>
            </>
        )
    }

    return (
        <Box className='recurring-container'>
            {/* <FormControlLabel
                control={
                    <Checkbox
                        checked={isRecurring}
                        onChange={e => handleRecurringCheckBox(e.target.checked)}
                        name='start-checkbox' color="primary"
                        disabled={props.disabled || false}
                    />
                }
                label="Recurring survey"
                className='start-checkbox'

            /> */}
            <Box sx={{ alignSelf: 'flex-start', marginLeft: '-15px'}}>
                <ToggleSwitchValue label='Recurring Survey' value={isRecurring} onChange={handleRecurringCheckBox} />
            </Box>
            <FormControl component="fieldset" >
                <RadioGroup aria-label="gender" name="gender1" value={radioGroupValue} onChange={e => handleGroupChange(e.target.value)}>
                    <Box className='rate-choose' style={{ marginTop: '10px', display: 'flex', gap: '24px', filter: isRecurring || props.disabled ? 'none' : 'grayscale(100%)', opacity: isRecurring || props.disabled ? '1' : '0.5' }}>
                        {selectOptions.map((item, index) => 
                            <CustomRadioLabel key={index} value={item} label={item} checked={radioGroupValue === item} fontSize='15px' />
                        )}
                    </Box>
                </RadioGroup>
            </FormControl>
            <Box className='recurring-type'>
                {radioGroupValue === 'Daily' &&
                    <Box>
                        <FormGroup row className='formgroup'>
                            Send every:
                            {days.map((item, index) => <FormControlLabel label={item} key={index}
                                control={<Checkbox disabled={!isRecurring || props.disabled} color="primary" checked={dailyCheckbox.includes(item)} onChange={e => handleWeekDayCheckBox(e.target.name)} name={item} />}
                            />)}
                        </FormGroup>
                        {renderTimeLayout()}
                    </Box>
                }
                {(radioGroupValue === 'Monthly' || radioGroupValue === 'Quarterly') &&
                    <Box className='monthly-container'>
                        <Box className='monthly-select'>
                            <FormControl className='monthly-form-control' disabled={!isRecurring || props.disabled}>
                                <span className='form-control-text'>Send every</span>
                                <Select
                                    className='select'
                                    id='list-select'
                                    autoWidth={true}
                                    variant='outlined'
                                    value={rateSelect || ''}
                                    onChange={e => handleRateSelect(e.target)} >
                                    {weeks.map((week) => (
                                        <MenuItem key={week} value={week}>
                                            {createIntervalString(week)}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                            <FormControl className='monthly-form-control'>
                                <span className='form-control-text'>On</span>
                                <Select
                                    disabled={!isRecurring || props.disabled}
                                    className='select-options'
                                    autoWidth={true}
                                    variant='outlined'
                                    value={weekDay}
                                    onChange={e => weekDaySelect(e.target)} >
                                    {days.map((day) => (
                                        <MenuItem key={day} value={day}>
                                            {day}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box className='date-time'>
                            {renderTimeLayout()}
                        </Box>
                    </Box>
                }
                {radioGroupValue === 'Custom' &&
                    <Box>
                        <Box className='custom-repeating-rate'>
                            <span className='form-control-text-custom'>Send every</span>
                            <TextField variant='outlined'
                                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                size='small' className='custom-repeating-field'
                                value={customRecurringField}
                                onChange={e => handleCustomRecurringField(e.target.value)}
                                disabled={!isRecurring || props.disabled}
                            />

                            <FormControl disabled={!isRecurring || props.disabled}>
                                <Select
                                    className='custom-select'
                                    id='list-select'
                                    autoWidth={true}
                                    variant='outlined'
                                    value={customInterval || ''}
                                    onChange={e => handleCustomInterval(e.target)} >
                                    {customOptions.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </Box>
                        <Box className='date-time'>
                            {renderTimeLayout()}
                        </Box>
                    </Box>
                }
            </Box>
        </Box >
    )
}

export default RecurringBox;
