// dashboard
export const SURVEYS_FETCHED = 'SURVEYS_FETCHED';

// edit page
export const SET_EDIT_PAGE_TAB = 'SET_EDIT_PAGE_TAB';

// questions
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const ADD_QUESTION = 'ADD_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const REORDER_QUESTION = 'REORDER_QUESTION';
export const SET_QUESTION_ERRORS = 'SET_QUESTION_ERRORS';
export const SET_QUESTION_ID = 'SET_QUESTION_ID';

// editor settings
export const GET_RESPONDENTS = 'GET_RESPONDENTS';
export const SET_RESPONDENTS_LIST = 'SET_RESPONDENTS_LIST';
export const UPDATE_RESPONDENTS_LIST = 'UPDATE_RESPONDENTS_LIST';
export const DELETE_RESPONDENTS = 'DELETE_RESPONDENTS';
export const DELETE_RESPONDENTS_LIST = 'DELETE_RESPONDENTS_LIST';
export const SET_RESPONDENT_ERRORS = 'SET_RESPONDENT_ERRORS';
export const SET_CUSTOM_LIST = 'SET_CUSTOM_LIST';
export const CUSTOM_LISTS_FETCHED = 'CUSTOM_LISTS_FETCHED';
export const DELETE_CUSTOM_LIST = 'DELETE_CUSTOM_LIST';
export const LIST_COGNITO_USERS = 'LIST_COGNITO_USERS';
export const ADD_COGNITO_USER = 'ADD_COGNITO_USER';
export const DELETE_COGNITO_USER = 'DELETE_COGNITO_USER';
export const UPDATE_COGNITO_USER = 'UPDATE_COGNITO_USER';


// editor style
export const UPDATE_SURVEY_STYLE_COLORS = 'UPDATE_SURVEY_STYLE_COLORS';
export const UPDATE_SURVEY_STYLE_LOGO = 'UPDATE_SURVEY_STYLE_LOGO';
export const S3_CREDENTIALS_FETCHED = 'S3_CREDENTIALS_FETCHED';
export const FILE_UPLOADED = 'FILE_UPLOADED';

// db
export const STORE_USER_DATA = 'STORE_USER_DATA';
export const USER_TEMPLATE_FETCHED = 'USER_TEMPLATE_FETCHED';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const CUSTOMER_REGISTERED = 'CUSTOMER_REGISTERED';
export const EMAIL_RESEND = 'EMAIL_RESEND';
export const EMAIL_RESEND_ERROR = 'EMAIL_RESEND_ERROR';
export const REGISTRATION_ERROR_COMPANY_EXISTS = 'REGISTRATION_ERROR_COMPANY_EXISTS';
export const REGISTRATION_ERROR_DOMAIN_EXISTS = 'REGISTRATION_ERROR_DOMAIN_EXISTS';
export const REGISTRATION_ERROR_OTHER = 'REGISTRATION_ERROR_OTHER';
export const REGISTRATION_ERROR_COMPANY_RESET = 'REGISTRATION_ERROR_COMPANY_RESET';
export const USER_EXIST_ON_WORKSPACE = 'USER_EXIST_ON_WORKSPACE';
export const DOWNLOAD_SURVEY_DATA = 'DOWNLOAD_SURVEY_DATA';
export const TEMPLATE_POST_SUCCESS = 'TEMPLATE_POST_SUCCESS'
export const TEMPLATE_POST_FAILURE = 'TEMPLATE_POST_FAILURE'
export const STORE_ORGANIZATION_DATA = 'STORE_ORGANIZATION_DATA';
export const STORE_ORGANIZATION_DEFAULT_STYLE_DATA = 'STORE_ORGANIZATION_DEFAULT_STYLE_DATA';

// flow
export const SET_ERROR = "SET_ERROR";
export const RESET_ERROR = "RESET_ERROR";
export const CREATE_BLANK_SURVEY = 'CREATE_BLANK_SURVEY';
export const SET_CURRENT_SURVEY = 'SET_CURRENT_SURVEY';
export const UPDATE_CURRENT_SURVEY = 'UPDATE_CURRENT_SURVEY';
export const PUBLISH_CURRENT_SURVEY = 'PUBLISH_CURRENT_SURVEY';
export const SCHEDULE_CURRENT_SURVEY = 'SCHEDULE_CURRENT_SURVEY';
export const CLOSE_CURRENT_SURVEY = 'CLOSE_CURRENT_SURVEY';
export const ASSIGN_TO_SURVEY = 'ASSIGN_TO_SURVEY';
export const PUBLISH_CURRENT_SURVEY_ERROR = 'PUBLISH_CURRENT_SURVEY_ERROR';
export const SCHEDULE_CURRENT_SURVEY_ERROR = 'SCHEDULE_CURRENT_SURVEY_ERROR';
export const CLOSE_CURRENT_SURVEY_ERROR = 'CLOSE_CURRENT_SURVEY_ERROR';
export const SEND_REMINDERS = 'SEND_REMINDERS';
export const SET_USERS_ON_SURVEY = 'SET_USERS_ON_SURVEY';

// other
export const UPDATE_SURVEY_NAME = 'UPDATE_SURVEY_NAME';

export const LAST_EDITED_TIME = 'LAST_EDITED_TIME';

//general settings
export const UPDATE_GENERAL_SETTINGS = 'UPDATE_GENERAL_SETTINGS';

// Edit page
export const SET_ACTIVE_EDIT_SURVEY_TAB = 'SET_ACTIVE_EDIT_SURVEY_TAB';

// Sidebar active page
export const SET_SIDEBAR_ACTIVE_PAGE = 'SET_SIDEBAR_ACTIVE_PAGE';

// Save default style message
export const SET_SAVE_DEFAULT_STYLE_MESSAGE = 'SET_SAVE_DEFAULT_STYLE_MESSAGE';

// Delete file from S3
export const DELETE_FILE_FROM_S3 = 'DELETE_FILE_FROM_S3';