export default  {
	"invitationTemplate": "invitation-template.html",
	"invitationSubject": "Transform wants your feedback",
	"invitationFromName": "survey@transforminsight.com",
	"invitationEmailText": `<p>Dear {{Name}},&nbsp;</p>
	<p>Please find your unique link for Transform survey:</p>
	<p>{{{SurveyLink}}}</p>
	<p></p>
	<p>Thank you!</p>
	`,
	"language": "en",
	"started": "2024-04-09T18:42:25.805Z",
	"startImmediately": true,
	"createdBy": "john_user_id",
	"domainName": "development",
	"statusTimestamp": null,
	"name": "COLLABORATION NETWORK TEMPLATE",
	"status": "DRAFT",
	"confirmationNote": true,
	"userCustom": true,
	"confirmNameTitle": "Welcome [Respondent's Name]",
	"confirmNameText": "Dear [Company Name] colleagues, we'd like to invite you to fill out the Collaboration survey for a research we are conducting. "
}
	

