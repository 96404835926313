import React, { useEffect, useState } from 'react';
import './theme-preview.scss';
import Button from "@material-ui/core/Button";
import Pagination from "@material-ui/lab/Pagination";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Slider from "@material-ui/core/Slider";
import { Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@material-ui/styles';
import { hexValidator } from '../../utils/styleUtils'
import { Box } from '@mui/material';
import transformLogoWhite from "../../assets/images/white_transform_large.svg";

// Create custom MUI theme
const createCustomTheme = (theme) => {
    if (hexValidator(theme.main) && hexValidator(theme.highlight)) {
        return createTheme({
            palette: {
                primary: {
                    main: theme.main,
                },
                secondary: {
                    main: theme.highlight,
                },
            },
        });
    }
};

// ThemePreview component
function ThemePreview(props) {
    const [sliderValue, setSliderValue] = useState(10); // State for slider value
    const [muiTheme, setMuiTheme] = useState({}); // State for MUI theme

    // Update MUI theme when themeColor changes
    useEffect(() => {
        if (props.themeColor) {
            setMuiTheme(createCustomTheme(props.themeColor));
        }
    }, [props.themeColor]);

    // Handle slider change
    const handleSliderChange = (event, value) => {
        if (value !== sliderValue) {
            setSliderValue(value);
        }
    };

    return (
        <Box className="preview flex-column-center">
            {
                props.themeColor &&
                <ThemeProvider theme={muiTheme}>
                    <Box className='header-container' style={{ backgroundColor: props.themeColor.main }}>
                        {
                            props.companyLogo ? <img className="placeholder-logo" src={props.companyLogo} alt="placeholder-logo" /> :
                                <Typography variant="h6"
                                    sx={{ fontSize: '24px', lineHeight: '1', fontWeight: '500', color: '#fff', textTransform: 'uppercase' }}
                                >Your logo goes here</Typography>
                        }
                    </Box>
                    <Box className="page-container flex-column-center">
                        <Pagination count={35} page={3} color='secondary' />
                        <Box className="question-container-preview flex-column-center">
                            <Box className="question-text">
                                1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc congue condimentum tellus?
                            </Box>
                            <Box className="question-explanation">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </Box>
                            <Box className="question">
                                <Box className='title'>
                                    <Typography id="discrete-slider" className='slider-title' gutterBottom>
                                        Lorem ipsum 01
                                    </Typography>
                                </Box>

                                <Slider
                                    color='secondary'
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay='on'
                                    marks={[{ value: 0, label: '0' }, { value: 2, label: null },
                                    { value: 4, label: null }, { value: 6, label: null },
                                    { value: 8, label: null }, { value: 10, label: null },
                                    { value: 12, label: null }, { value: 14, label: '14' },]}
                                    defaultValue={10}
                                    value={sliderValue}
                                    step={1}
                                    min={0}
                                    max={14}
                                    onChange={handleSliderChange}
                                />
                            </Box>
                            <Box className='question-actions'>
                                <Button
                                    variant="contained"
                                    color="default"
                                    className='action-button'
                                    startIcon={<ChevronLeftIcon />}
                                >
                                    Forrige
                                </Button>
                                <Button
                                    variant="contained"
                                    color="default"
                                    className='action-button button-next'
                                    endIcon={<ChevronRightIcon />}
                                    style={{ backgroundColor: props.themeColor.highlight }}
                                >
                                    Neste
                                </Button>
                            </Box>
                        </Box>
                        <Box className='progress-bar'>
                            <Box className='completed' style={{ width: '70%', backgroundColor: props.themeColor.highlight }} />
                            <Box className='left' style={{ width: '30%', backgroundColor: props.themeColor.highlight, opacity: '60%' }} />
                        </Box>
                    </Box>
                    <Box className="footer-container" style={{ backgroundColor: props.themeColor.main }}>
                        <Typography variant="h6" className='footer-text' style={{ fontSize: '10px', color: '#fff' }} mb={.75}>
                            Powered by:
                        </Typography>
                        <img className="transform-logo" src={transformLogoWhite} alt="transform-logo" />
                    </Box>
                </ThemeProvider>
            }
        </Box>
    )

}

export default ThemePreview;