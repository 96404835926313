import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import './editor-tab-container.scss';
import { ReactComponent as AddIcon } from "../../../../assets/images/menu/survey/add.svg";
import QuestionTypesDialog from "./question-types-dialog";
import QuestionContainer from "./question-container";
import useSurveyName from './../../../../hooks/editor/use-survey-name';
import useQuestions from './../../../../hooks/editor/use-question';
import _ from 'lodash';



const getItemStyle = (isDragging, draggableStyle, hidden = false) => ({
    userSelect: "none",
    height: hidden ? "1px" : "",
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    // padding: grid,
});

const MemoizedQuestionContainer = React.memo(QuestionContainer);

function EditorTabContainer() {
    const currentSurvey = useSelector(state => state.currentSurvey);

    const [surveyName, handleSurveyNameChange] = useSurveyName(currentSurvey?.name);
    const {
        questionsFetched,
        openAddQuestionDialog,
        questionInFocus,
        handleAddQuestion,
        handleAddQuestionDialogClose,
        handleClickAddQuestion,
        handleChangeFocus,
        onDragEnd,
        onDragStart,
        moveQuestionUp,
        moveQuestionDown,
        SECTION
    } = useQuestions(currentSurvey);

    const renderedQuestions = useMemo(() => (
        currentSurvey?.questions?.map((question, index) => {
            let currentSection = null;
            let sectionQuestions = null;

            if (index === 0) {
                currentSection = null;
            }
            if (question.element_type === SECTION) {
                currentSection = question;
                const questionsAfterSection = currentSurvey.questions.slice(index + 1);
                const nextSectionIndex = questionsAfterSection.findIndex(q => q.element_type === SECTION);
                sectionQuestions = nextSectionIndex === -1 ?
                    questionsAfterSection.length :
                    nextSectionIndex;
            }

            return (!currentSection || currentSection.expanded || question.element_type === SECTION) ? (
                <Draggable key={question.id} draggableId={question.id} index={index}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            className='draggable'
                            id={question.id}
                        >
                            <MemoizedQuestionContainer
                                question={question}
                                moveQuestionUp={moveQuestionUp}
                                moveQuestionDown={moveQuestionDown}
                                key={index}
                                questionInFocus={questionInFocus === question.id}
                                sectionQuestions={sectionQuestions}
                                changeFocus={handleChangeFocus}
                                isDragging={snapshot.isDragging}
                                dragHandle={provided.dragHandleProps}
                                index={index} // Pass the index prop here
                            />
                        </div>
                    )}
                </Draggable>
            ) : (
                <Draggable key={question.id} draggableId={question.id} index={index}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            {...provided.dragHandleProps}
                            className='draggable hidden'
                            id={question.id}
                        />
                    )}
                </Draggable>
            );
        })
    ), [currentSurvey?.questions, questionInFocus]);

    return currentSurvey ? (
        <div className='questions-tab-container'>
            <div className="welcome-data flex-column-center">
                <TextField
                    className='survey-name'
                    label=''
                    placeholder='Survey Title'
                    value={surveyName ? surveyName : ''}
                    onChange={(e) => handleSurveyNameChange(e.target.value)}
                />
            </div>
            {
                currentSurvey?.questions && currentSurvey.questions.length > 0 ?
                    <div className="questions">
                        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onDragStart}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                        className='droppable'
                                    >
                                        {renderedQuestions}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <AddIcon className="add icon add-icon" onClick={() => handleClickAddQuestion(_.last(currentSurvey.questions).id)} />
                    </div> :
                    <div className="create-question flex-column-center" onClick={() => handleClickAddQuestion(null)}>
                        {
                            questionsFetched ?
                                <>
                                    <AddIcon className="icon add-icon" />
                                    <span className='text'>Add Question or Section</span>
                                </> :
                                <CircularProgress className='loader' />
                        }
                    </div>

            }
            <Dialog
                open={openAddQuestionDialog}
                onClose={handleAddQuestionDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={false}
            >
                <QuestionTypesDialog onClose={handleAddQuestionDialogClose} handleAddQuestion={handleAddQuestion} />
            </Dialog>
        </div>
    ) : (<div>no current survey!</div>)
}

export default EditorTabContainer;
