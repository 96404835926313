import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { emailRegEx } from '../utils/regex-utils'
import './invite-user-dialog.scss'

const EMAIL = 'EMAIL';
const NAME = 'NAME';
const SURNAME = 'SURNAME'

function InviteUser(props) {
    const [open, setOpen] = useState(false);
    const [emailValue, setEmailValue] = useState('');
    const [nameValue, setNameValue] = useState('');
    const [surnameValue, setSurnameValue] = useState('')
    const [textError, setTextError] = useState(false)
    const buttonName = props.buttonName || 'Invite team member';

    const handleDialogOpen = () => {
        setOpen(true);
    }
    const handleDialogClose = () => {
        setOpen(false);
    }
    const handleInput = (value, fieldName) => {
        switch (fieldName) {
            case EMAIL:
                setEmailValue(value)
                break;
            case NAME:
                setNameValue(value)
                break;
            case SURNAME:
                setSurnameValue(value)
                break;

        }
    }

    const handleInviteButton = () => {
        if (emailRegEx.test(emailValue) && emailValue !== '') {
            props.handleInvite(emailValue, nameValue, surnameValue)
            setOpen(false)
        } else {
            setTextError(true)
        }
    }

    return (
        <div className='invite-dialog'>
            <Fab variant='extended'
                size='medium'
                color='primary'
                aria-label='add'
                className='invite-button'
                onClick={handleDialogOpen}>
                <AddIcon /> <label className='send-invite-fab'> {buttonName} </label>
            </Fab>

            <Dialog open={open} onClose={handleDialogClose}>
                    <DialogTitle color='secondary' className='dialog-title'> Invite team member to {props.company} </DialogTitle>
                    <DialogContent className='dialog-conent'>
                        <TextField
                            error={textError}
                            helperText={textError ? 'Invalid email format' : ''}
                            className='basic-field'
                            label='User Email'
                            onChange={e => { handleInput(e.target.value, EMAIL) }} />

                        <TextField
                            className='basic-field'
                            label='First Name'
                            onChange={e => { handleInput(e.target.value, NAME) }} />

                        <TextField
                            className='basic-field'
                            label='Last Name'
                            onChange={e => { handleInput(e.target.value, SURNAME) }} />
                        <Fab variant='extended' size='small' aria-label='add' color='primary' onClick={handleInviteButton}> <AddIcon /> <label className='send-invite-fab'>Invite</label> </Fab>
                    </DialogContent>
            </Dialog>
        </div>
    )
}

export default InviteUser