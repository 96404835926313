import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormGroup } from '@material-ui/core';
import UploadListFromQuestion from "../../upload-list-from-question";
import AdvancedSettings from "../../question-advanced-settings";
import { Box } from '@mui/material';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import useSearchChooseQuestion from './../../../../../../hooks/editor/drop-down/use-drop-down';
import './drop-down-question.scss';
import { colorTheme } from '../../../../../../assets/theme';
import { TAB_LISTS } from "../../../../../../assets/constants";
import { useEditContainer } from '../../../../../../hooks/survey/use-edit-container';
import TooltipIcon from '../../../../../../components/tooltip-text/tooltip-icon';

function SearchChooseQuestion({ template, handleAdvancedView, advancedSettings }) {
    const {
        handleListUploaded,
        selectedList,
        mappedLists,
        handleListSelectChange,
        selectedColumn,
        handleColumnSelect,
        mappedColumns,
        itemName,
        handleNameChoose,
        autocompleteOptions,
        templateHasRespondentList
    } = useSearchChooseQuestion(template);

    const { handleTabClick } = useEditContainer();

    const handleHandleTabClick = () => {
        handleTabClick(TAB_LISTS);
    }

    console.log("templateHasRespondentList", templateHasRespondentList);

    return (
        <div className='search-choose-question-container'>
            <div className='list-section'>
                <div className='select-group'>
                    Select list for Answers:
                    <FormControl variant="outlined" style={{ width: '174px' }}>
                        <InputLabel
                            sx={{
                                '&.Mui-focused': {
                                    color: '#1B4972', // Color of the label when the select is focused
                                    top: '0px', // Adjust the label position when focused
                                },
                                '&.MuiInputLabel-shrink': {
                                    top: '0px', // Adjust the label position when shrink
                                },
                                top: '-3px',
                            }}>
                            Select list
                        </InputLabel>
                        <Select
                            labelId='source-option-select-label'
                            id='source-option-select'
                            value={selectedList || (templateHasRespondentList && mappedLists.length < 1 ? 'respondents' : '')}
                            input={<OutlinedInput label="Select List" />}
                            onChange={e => handleListSelectChange(e.target)}
                            sx={{
                                '.MuiSelect-select': {
                                    padding: '13px', // Padding of the select
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#D7D7D7', // Default border color
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1B4972', // Border color on hover
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1B4972', // Border color when focused
                                    borderWidth: '1px', // Optionally, adjust the border width when focused
                                },
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                PaperProps: {
                                    style: {
                                        marginLeft: 0,
                                    },
                                },
                            }}
                        >
                            {mappedLists && mappedLists.length > 0 ? (
                                mappedLists.map((listItem, index) => (
                                    <MenuItem key={index} value={listItem.id}>
                                        {listItem.title}
                                    </MenuItem>
                                ))
                            ) : templateHasRespondentList && mappedLists.length < 1 ? (
                                <MenuItem key={'respondents'} value={'respondents'}>
                                    respondents
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    onClick={(e) => e.preventDefault()}
                                    sx={{
                                        pointerEvents: 'none',
                                        display: 'block',
                                        whiteSpace: 'normal',
                                        textAlign: 'left',
                                        padding: '10px',
                                        '&:hover': {
                                            backgroundColor: 'transparent', // Prevents background color change on hover
                                        }

                                    }}
                                >
                                    <Typography sx={{
                                        color: '#4F4F4F',
                                        pointerEvents: 'none',
                                    }}>
                                        No uploaded lists on this survey.
                                        <br />
                                        Please click on Upload New List button or upload list on
                                        <span
                                            onClick={handleHandleTabClick}

                                            style={{ textDecoration: 'none', color: '#1B4972', marginLeft: '5px', pointerEvents: 'auto', cursor: 'pointer' }}
                                        >
                                            Lists Page
                                        </span>.
                                    </Typography>
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    or
                    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={0} mb={0}>
                        <UploadListFromQuestion onListUploaded={handleListUploaded} />
                    </Box>
                </div>
            </div>

            {selectedList && (
                <Box mt={0} display={'flex'} alignItems={'center'} mb={3}>
                    <Typography mr={2}>Select List attributes that will be displayed in Dropdown:</Typography>

                    <FormControl variant="outlined" style={{ width: '174px' }}>
                        <InputLabel sx={{
                            '&.Mui-focused': {
                                color: '#1B4972', // Color of the label when the select is focused
                                top: '0px', // Adjust the label position when focused
                            },
                            '&.MuiInputLabel-shrink': {
                                top: '0px', // Adjust the label position when shrink
                            },
                            top: '-3px',
                        }} id="demo-multiple-checkbox-label" style={{ whiteSpace: 'nowrap' }}>Select Attributes</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={selectedColumn || []}
                            onChange={e => handleColumnSelect(e.target.value)}
                            input={<OutlinedInput label="Select Attributes" />}
                            renderValue={selected => selected.join(', ')}
                            sx={{
                                '.MuiSelect-select': {
                                    padding: '13px', // Padding of the select
                                },
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#D7D7D7', // Default border color
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1B4972', // Border color on hover
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1B4972', // Border color when focused
                                    borderWidth: '1px', // Optionally, adjust the border width when focused
                                },
                                textTransform: 'capitalize'
                            }}
                        >
                            {mappedColumns && mappedColumns.map((columnItem, index) => (
                                <MenuItem
                                    key={index}
                                    value={columnItem}
                                    sx={{
                                        textTransform: 'capitalize',
                                        marginLeft: '0px',
                                        fontSize: '16px',
                                        '&.Mui-selected': {
                                            color: colorTheme.theme.transform.darkBlueTwo,
                                            backgroundColor: 'transparent'
                                        },
                                        '&:hover': {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                >
                                    <Checkbox
                                        checked={selectedColumn.indexOf(columnItem) > -1}
                                        sx={{
                                            padding: '0px',
                                            '&.Mui-checked': {
                                                color: colorTheme.theme.transform.darkBlueTwo
                                            }
                                        }}
                                    />
                                    <ListItemText
                                        primary={columnItem}
                                        sx={{
                                            textTransform: 'capitalize',
                                            marginLeft: '8px',
                                            fontSize: '16px',
                                            color: selectedColumn.indexOf(columnItem) > -1 ? colorTheme.theme.transform.darkBlueTwo : 'inherit',
                                            '&:hover': {
                                                color: colorTheme.theme.transform.darkBlueTwo // Ensure text color changes on hover
                                            }
                                        }}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box ml={4} display={'flex'} alignItems={'center'}>
                        <Typography mr={2}>Preview:</Typography>
                        <FormControl variant="outlined" style={{ width: '174px', height: '47px' }}>
                            <InputLabel
                                sx={{
                                    '&.Mui-focused': {
                                        color: '#1B4972', // Color of the label when the select is focused
                                        top: '0px', // Adjust the label position when focused
                                    },
                                    '&.MuiInputLabel-shrink': {
                                        top: '0px', // Adjust the label position when shrink
                                    },
                                    top: '-3px', // Adjust the label position when not focused
                                    whiteSpace: 'nowrap', // Prevent text from wrapping
                                    overflow: 'visible', // Ensure the text is not cut off
                                }}
                            >
                                Click to Preview
                            </InputLabel>
                            <Select
                                labelId='column-preview-select-label'
                                id='column-preview-select'
                                value=""
                                input={<OutlinedInput label="Click to Preview" />}
                                sx={{
                                    '.MuiSelect-select': {
                                        padding: '13px', // Padding of the select
                                    },
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#D7D7D7', // Default border color
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1B4972', // Border color on hover
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1B4972', // Border color when focused
                                        borderWidth: '1px', // Optionally, adjust the border width when focused
                                    },
                                }}
                            >
                                {autocompleteOptions.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        <Box display="flex" flexDirection="column">
                                            {selectedColumn.map((field, fieldIndex) => (
                                                <Typography
                                                    key={field}
                                                    display="block"
                                                    sx={{
                                                        color: fieldIndex === 0 ? '#4F4F4F' : '#828282',
                                                        fontSize: fieldIndex === 0 ? '16px' : '14px'
                                                    }}
                                                >
                                                    {option[field]}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </MenuItem>

                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                </Box>
            )}

            {selectedList && selectedColumn.length > 0 && (
                <Box display={'flex'} alignItems={'center'} mb={4}>
                    <Typography mr={1}>Select attribute(s) for dropdown search:</Typography>
                    <Box mr={2} mt={0.5}>
                        <TooltipIcon title={'Attributes for dropdown search'} content={'Selected attribute(s) will be used for searching within the dropdown. You can select multiple attributes to enhance searchability for respondents'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                    </Box>
                    <FormGroup row>
                        {selectedColumn &&
                            selectedColumn.map((columnItem, index) => (
                                <FormControlLabel
                                    style={{ marginLeft: '-2px' }}
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={itemName.includes(columnItem)}
                                            onChange={() => handleNameChoose(columnItem)}
                                            value={columnItem}
                                            sx={{
                                                padding: '0px',
                                                '&.Mui-checked': {
                                                    color: colorTheme.theme.transform.darkBlueTwo
                                                },
                                                marginRight: '6px'
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                color: colorTheme.theme.text.mediumGrey,
                                                textTransform: 'capitalize',


                                            }}
                                        >
                                            {columnItem}
                                        </Typography>
                                    }

                                />
                            ))}
                    </FormGroup>
                </Box>

            )}
            {advancedSettings && <AdvancedSettings setView={handleAdvancedView} template={template} />}
        </div>
    );
}

export default SearchChooseQuestion;
