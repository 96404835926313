import React from 'react';
import { Box } from '@mui/material';
import TitleEditTab from '../../title/title-edit-tab';
import ToggleSwitchValue from '../../form/toogle-switch-value';
import _ from 'lodash';
import { UseSurveySettings } from '../../../hooks/survey/use-survey-settings';

// PostSubmissionSettings component
const PostSubmissionSettings = () => {
    
    // Destructure the properties from UseSurveySettings
    const { allowRespondentsToEdit, setAllowRespondentsToEdit, handleToggleChange } = UseSurveySettings();

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <TitleEditTab title='Post-Submission Settings' marginBottom={0.5} />
            <ToggleSwitchValue
                label='Allow respondents to edit'
                value={allowRespondentsToEdit}
                onChange={(value) => handleToggleChange(setAllowRespondentsToEdit, value, 'edit_after_submit')} 
                description={'Enable this to allow respondents to edit their answers even after submitting the survey.'}
            />
        </Box>
    );
}

export default PostSubmissionSettings;
