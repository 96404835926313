import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';

const OTPInput = ({ length, onChange, positon }) => {
    const [inputs, setInputs] = useState(Array.from({ length }, () => ''));

    const handleChange = (value, index) => {
        const newInputs = [...inputs];
        newInputs[index] = value.slice(0, 1); // Ensure only one character per input
        setInputs(newInputs);
        onChange(newInputs.join(''));

        // Focus next input
        if (value && index < length - 1) {
            document.getElementById(`otp-field-${index + 1}`).focus();
        }
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: positon, gap: 1 }}>
            {inputs.map((value, idx) => (
                <TextField
                    key={idx}
                    id={`otp-field-${idx}`}
                    type="number"
                    variant="outlined"
                    value={value}
                    onChange={(e) => handleChange(e.target.value, idx)}
                    inputProps={{ maxLength: 1, style: { textAlign: 'center', fontSize: '20px' } }}
                    sx={{
                        width: '40px', // Conditionally set the width
                        height: '60px',
                        ml: idx === 3 ? 1 : 0, // Apply additional margin to the fourth input
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#D7D7D7', // Change the outline color
                                border: '1px solid #D7D7D7' // Make the border 1px solid
                            },
                            '&:hover fieldset': {
                                borderColor: '#4F4F4F', // Change the outline color on hover
                                border: '1px solid', // Make the border 1px solid
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#4F4F4F', // Change the outline color on hover
                                border: '1px solid', // Make the border 1px solid
                            },


                        },
                        '&:hover .MuiInputLabel-root': {
                            color: '#4F4F4F',
                        },
                    }}
                />
            ))}
        </Box>
    );
};

export default OTPInput;

