import React from 'react';
import './publish-container.scss';
import { useDispatch, useSelector } from "react-redux";
import { setEditPageActiveTab } from "../../../../redux/actions";
import { Box } from '@mui/material';
import StatusContainer from "../../../../components/survey-edit/publish-tab/status-container";
import EmailContainer from "../../../../components/survey-edit/publish-tab/email-container";
import {
    CLICK_PREVIEW,
    FIX_ERRORS_QUESTIONS,
    FIX_ERRORS_RESPONDENTS,
    TAB_QUESTIONS,
    TAB_SETTINGS,
    TAB_LISTS,
} from "../../../../assets/constants";

// PublishContainer component
function PublishContainer(props) {
    const dispatch = useDispatch(); // Get the dispatcher function from the redux store
    const currentSurvey = useSelector(state => state.currentSurvey); // Get the current survey from the redux store

    // Function to handle the click event for the Fix Errors button
    const handleFixErrorsClick = (type) => {
        if (type === FIX_ERRORS_QUESTIONS) {
            dispatch(setEditPageActiveTab(TAB_QUESTIONS))
        } else if (type === FIX_ERRORS_RESPONDENTS) {
            dispatch(setEditPageActiveTab(TAB_LISTS))
        } else if (type === CLICK_PREVIEW) {
            window.open(`${process.env.REACT_APP_CLIENT_URL}?token=${currentSurvey.surveyAccessToken}&preview=true`, "_blank");
        }
    };

    return (
        <Box className='publish-container'>
            <StatusContainer handleFixErrorsClick={handleFixErrorsClick}/>
            <EmailContainer handleFixErrorsClick={handleFixErrorsClick}/>
        </Box>
    )

}

export default PublishContainer;
