import React from "react";
import './profile-container.scss';
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import { Box, Paper } from '@mui/material';
import ProfileInfo from "../../components/profile/profile-info";
import LicenseInfo from "../../components/profile/licence-info";
import SecurityInfo from "../../components/profile/security-info";
import ActivityInfo from "../../components/profile/activity-info";
import TitleWithIcon from '../../components/title/title-icon';
import { ReactComponent as ProfileIcon } from '../../assets/images/profile/user_avatar.svg';

const ProfileContainer = () => {
    // Define the path segments for the breadcrumbs
    const pathSegments = [
        { name: "Transform", path: "/" },
        { name: "Profile", path: "/profile" },
    ];

    return (
        <Box mt={3} ml='auto' mr='auto' className="profile-container">
            {/* Render the Breadcrumbs component with the pathSegments */}
            <Breadcrumbs pathSegments={pathSegments} />
            <Paper square elevation={0} style={{ borderRadius: 5 }}>
                <Box mt={3.5} mb={3.5} pb={3.5} ml={'auto'} mr={'auto'} width={'94%'}>
                    <Box pt={3.5} pl={1} pb={3.5}>
                        <TitleWithIcon title="My Profile" Icon={ProfileIcon} />
                    </Box>
                    <Box>
                        {/* Render the ProfileInfo component with the user data */}
                        <ProfileInfo
                        />
                        <Box sx={{ borderBottom: '1px solid #ccc', my: 3 }} />
                        {/* Render the LicenseInfo component with the user data */}
                        <LicenseInfo />
                        <Box sx={{ borderBottom: '1px solid #ccc', my: 3 }} />

                        {/* Render the SecurityInfo component with the user data */}
                        <SecurityInfo />
                        <Box sx={{ borderBottom: '1px solid #ccc', my: 3 }} />

                        {/* Render the ActivityInfo component with the user data */}
                        <ActivityInfo
                        />

                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

export default ProfileContainer;
