import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie'; // Import js-cookie for setting cookies
import { Auth } from 'aws-amplify'; // Import AWS Amplify for JWT token generation
// Function to check if a user has access based on their licenses
function hasAccess(user, licenses) {
    return licenses.every(license => user && user[license] === "true");
}

// Function to check if the user has an 'owner' role
function isItOwner(user) {
    return user && user['role'] === 'owner';
}

// Component to protect routes based on user licenses and handle redirection for specific routes
const ProtectedRoute = ({ component: Component, licenses, ...rest }) => {
    // Get the user data from the redux store
    const user = useSelector(state => state.userData);
    const [isReady, setReady] = useState(false);

    // Effect to set isReady to true once the user data is available
    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            setReady(true);
        }
    }, [user]);

    // If the user data is not ready, show a loading spinner or placeholder
    if (!isReady) {
        return <div>Loading...</div>;
    }

    // Render the Route component
    return (
        <Route
            {...rest}
            render={props => {
                // If the path is "/insights" and the user is authenticated, redirect to process.env.REACT_APP_INSIGHTS_DOMAIN_URL
                if (user && rest.path === "/insights") {
                    console.log("Redirecting to Insights app.");

                    const session = Auth.currentSession();
                    const jwtToken = session.getIdToken().getJwtToken();

                    // Only for local setup, comment or remove domain since cookies won't be available due to the way localhost works
                    Cookies.set('idTokenProd', jwtToken, {
                        secure: true,
                        sameSite: 'None',
                        domain: '.transforminsight.com',
                        path: '/',
                    });

                    const user = Auth.currentAuthenticatedUser();
                    const customerName = user.attributes['custom:customer'];
                    const domain = process.env.REACT_APP_INSIGHTS_DOMAIN_URL;
                    const redirectUrl = domain.replace('*', customerName);

                    window.location.href = redirectUrl;
                    return null; // Return null to avoid rendering the current component
                }

                // If user is authenticated and has necessary licenses or is an owner, render the component
                if (user && (isItOwner(user) || (licenses.length > 0 && hasAccess(user, licenses)))) {
                    return <Component {...props} userTemplate={user} />;
                }

                // If not authenticated or no required licenses, redirect to the unauthorized page
                return <Redirect to="/unauthorized" />;
            }}
        />
    );
};

// Export the ProtectedRoute component
export default ProtectedRoute;