import React from 'react';
import { Dialog, DialogContent, DialogActions, Typography, Box } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import PrimaryButton from '../buttons/primary-button';
import HeaderDialog from './header-dialog'; // Ensure this is the correct path
import OTPInput from '../form/totp-input';


const TwoFactorAuthDialog = ({ open, onClose, qrCodeUrl, totpCode, setTotpCode, onVerify, isLoading }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': { width: '575px' }, // Set the dialog width
                BackdropProps: {
                    sx: {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        backdropFilter: 'blur(2.5px)',
                    },
                }
            }}
        >
            <HeaderDialog handleClose={onClose} title="Setup Two-Step Authentication" />
            <DialogContent sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                <Typography variant='body2' width={'99%'} color={colorTheme.theme.text.lightGrey} sx={{ pt: 1, }}>
                    Enable Two-Step Authentication by first downloading an authenticator app, such as Google Authenticator or Microsoft Authenticator, on your mobile device. Then, scan the QR code displayed below with the app and enter the code provided by the app in the field below.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 0 }}>
                    {qrCodeUrl && <img src={qrCodeUrl} alt="QR Code" style={{ marginTop: '15px' }} />}

                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1.3, textAlign: 'center' }}>
                    <Typography width={'99%'} color={colorTheme.theme.text.mediumGrey} sx={{ pt: 1, fontSize: '15px' }}>
                        Verification Code
                    </Typography>
                </Box>

                <OTPInput length={6} onChange={setTotpCode} positon={'center'} />
            </DialogContent>
            <DialogActions sx={{ pt: 4, pb: 2, pr: 2, gap: 1 }}>
                <PrimaryButton onClick={onClose} color="white" hoverColor="#EDF0F4" text="Cancel" hoverText={colorTheme.theme.transform.darkBlue} />
                <PrimaryButton onClick={onVerify} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue}
                    isLoading={isLoading}
                    text="Verify" />
            </DialogActions>
        </Dialog>
    );
};

export default TwoFactorAuthDialog;
