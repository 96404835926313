// question types
export const TEXT_BOX = 'text_box';
export const SINGLE_LINE_TEXT = 'single_line_text';
export const SINGLE_SELECT = 'single_select';
export const MULTI_SELECT = 'multi_select';
export const SEARCH_CHOOSE = 'search_choose';
export const SINGLE_SLIDER_SINGLE_PICKER = 'single_slider_single_picker';
export const MULTI_SLIDER_SINGLE_PICKER = 'multi_slider_single_picker';
export const SINGLE_SLIDER_TWO_PICKERS = 'single_slider_two_pickers';
export const MULTI_SLIDER_TWO_PICKERS = 'multi_slider_two_pickers';
export const PRIORITY_LIST = 'priority_list';
export const SECTION = 'section';
export const USER_SATISFACTION = 'user_satisfaction';

// question sub types
export const SINGLE_SELECT_SUBTYPE_TEXT = 'text';
export const SINGLE_SELECT_SUBTYPE_IMAGE = 'image';
export const SINGLE_SELECT_SUBTYPE_TEXT_BUTTON = 'text_button';

// question items
export const ITEMS_SOURCE_TYPE_LIST = 'LIST';
export const ITEMS_SOURCE_TYPE_QUESTION = 'QUESTION';
export const LIST_RESPONDETS = 'respondents';
export const SECTION_ITEM_IMAGE = 'section_image';
export const SECTION_ITEM_VIDEO = 'section_video';
export const SECTION_ITEM_TEXT = 'section_text';
export const SECTION_ITEM_BOLD_TEXT = 'section_bold_text';

// settings
export const RESPONDENTS_FILE = 'RESPONDENTS_FILE';
export const CUSTOM_LIST_FILE = 'CUSTOM_LIST_FILE';
export const COGNITO_LIST_FILE = 'COGNITO_LIST_FILE';
export const ROW_ID = 'SURVEY_CONST_ROW_ID';
export const ROW_OK = 'ROW OK';
export const STATUS_COLUMN = { title: 'Status', field: 'SURVEY_CONST_STATUS_COLUMN' };
export const ACTIVE_STATUS_COLUMN = { title: 'Survey Progress', field: 'SURVEY_CONST_ACTIVE_STATUS_COLUMN' };
export const ACTIVE_STATUS_COLUMN_TIMESTAMP = { title: 'Status Timestamp', field: 'SURVEY_CONST_ACTIVE_STATUS_COLUMN_TIMESTAMP' };
export const RESPONDENT_STATUS = { // copied from back-end
    NOT_SENT: 'NOT-SENT',
    DO_NOT_SEND: 'DO-NOT-SEND',
    SENT: 'SENT',
    STARTED: 'STARTED',
    REMINDED: 'REMINDED',
    REMINDED_AFTER_STARTED: 'REMINDED-AFTER-STARTED',
    SUBMITTED: 'SUBMITTED',
    EMAIL_BOUNCED: 'EMAIL_BOUNCED',
    SENDING_ERROR: 'SENDING_ERROR'
};

// publish
export const FIX_ERRORS_QUESTIONS = 'FIX_ERRORS_QUESTIONS';
export const FIX_ERRORS_RESPONDENTS = 'FIX_ERRORS_RESPONDENTS';
export const CLICK_PREVIEW = 'CLICK_PREVIEW';
export const SEND_IMMEDIATELY = 'SEND_IMMEDIATELY';

// edit page general
export const TAB_QUESTIONS = 'editor';
export const TAB_STYLE = 'style';
export const TAB_SETTINGS = 'settings';
export const TAB_LISTS = 'lists';
export const TAB_PUBLISH = 'publish';
export const TAB_RESULTS = 'results';

// publish type tabs
export const TAB_EMAIL = 'via email';
export const TAB_ANONYMOUS = 'anonymous';

// sidebar
export const SIDEBAR_TAB_SURVEYS = 'Surveys';
export const SIDEBAR_TAB_TEAM = 'Team';
export const SIDEBAR_TAB_DEFAULT_STYLE = 'Default Style';
export const SIDEBAR_TAB_ORGANIZATION = 'Organization';
export const SIDEBAR_TAB_LANGUAGE = 'Language';
export const SIDEBAR_TAB_BILLING = 'Billing';

// user managment page
export const TAB_TEAM = 'team';
export const TAB_ORGANIZATION = 'organization'

// used radio button types
export const LIST_RADIO = 'list-radio';
export const QUESTION_RADIO = 'question-radio';

// languages
export const ENGLISH = 'English'
export const NORWEGIAN = 'Norwegian'
export const ARABIC = 'Arabic'
export const SWEDISH = 'Swedish'

// source constants
export const REMINDER = 'reminder'
export const INVITATION = 'invitation'

// survey status filter
export const ALL_SURVEYS = 'ALL SURVEYS'
export const DRAFT = 'DRAFT'
export const LIVE = 'LIVE'
export const COMPLETED = 'COMPLETED'
export const SCHEDULED = 'SCHEDULED'

// Industries
export const INDUSTRIES = [
    'Technology',
    'Engineering',
    'Manufacturing',
    'Retail',
    'Healthcare',
    'Finance',
    'Education',
    'Government',
    'Nonprofit/NGO',
    'Consulting',
    'Legal Services',
    'Marketing and Advertising',
    'Hospitality',
    'Real Estate',
    'Transportation and Logistics',
    'Energy',
    'Telecommunications',
    'Agriculture',
    'Consumer Goods',
    'Entertainment',
    'Other',
];

// Survey default welcome note
export const SURVEY_DEFAULT_WELCOME_NOTE_TITLE = `Welcome [Respondent's Name]`;
export const SURVEY_DEFAULT_WELCOME_NOTE = `Thank you for taking part in our survey. We value your input and are committed to understanding your perspectives. Please note, while your responses will influence the overall results, they will remain completely anonymous. Individual responses will not be visible to other participants. We appreciate your honest and thoughtful input.`;
