export function convertToLocalTime(isoString) {
    const date = new Date(isoString);
    return date.toLocaleString();
}

export function formatDate(isoString, time, sentEmails = false) {
    const date = new Date(isoString);

    if (sentEmails) {
        const options = { 
            month: '2-digit', 
            day: '2-digit', 
            year: 'numeric', 
            hour: 'numeric', 
            minute: 'numeric', 
            hour12: true 
        };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    }

    const dateFormatted = date.toLocaleDateString('sr-RS').split('/').join('.');
    if (time) {
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours);
        let period = 'AM';

        if (hours === 0) {
            hours = 12;
        } else if (hours >= 12) {
            period = 'PM';
            if (hours > 12) {
                hours -= 12;
            }
        }

        return `${dateFormatted} ${hours}:${minutes} ${period}`;
    }

    return dateFormatted;
}
