import React, { useState } from 'react';
import { Box, Avatar } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import { ReactComponent as EditImage } from "../../assets/images/actions/edit-avatar.svg";
import { ReactComponent as HoverEditImage } from "../../assets/images/actions/hoverEditIcon.svg";
import useOrganization from '../../hooks/organisation/use-organization';
import TooltipText from '../tooltip-text/tooltip-text';

// OrganizationLogo component
const OrganizationLogo = () => {
    const { companyImageSrc, companyLogoStyle, handleCompanyLogoChange } = useOrganization(); // Destructure companyImageSrc, companyLogoStyle, handleCompanyLogoChange from useOrganization hook

    const [isHovered, setIsHovered] = useState(false);

    return (
        <Box className="company-logo" sx={{ position: 'relative' }}>
            <Avatar sx={companyLogoStyle} src={companyImageSrc} />
            <input
                accept="image/*"
                type="file"
                onChange={handleCompanyLogoChange}
                style={{ display: 'none' }}
                id="company-logo-upload"
            />
            <TooltipText
                name="Edit Organization Image"
                placement="bottom"
                backgroundColor={colorTheme.theme.transform.darkBlueTwo}
                textColor={colorTheme.theme.transform.white}
                margin={'-15px 0px 0px 0px'}
            >
                <label
                    htmlFor="company-logo-upload"
                    style={{
                        position: 'absolute',
                        bottom: 15,
                        right: 35,
                        transform: 'translate(50%, 50%)',
                        padding: '0.5em',
                        cursor: 'pointer',
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        {isHovered ? <HoverEditImage /> : <EditImage />}
                    </div>
                </label>
            </TooltipText>
        </Box>
    );
};

export default OrganizationLogo;
