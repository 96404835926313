import { ReactComponent as ErrorIcon } from '../../assets/images/icons/survey/error-alert-icon.svg';
import { Box, Typography } from '@mui/material';
import { colorTheme } from '../../assets/theme';

// ErrorText component
const ErrorText = ({ error }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center'}}>
            <ErrorIcon />
            <Typography variant="body2" sx={{ color: colorTheme.theme.app.red, fontWeight: '400', ml: '6px' }}>{error}</Typography>
        </Box>
    )
};

export default ErrorText;