// useTwoFactorAuth.js
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import QRCode from 'qrcode';
import { Auth } from 'aws-amplify';
import { updateCognitoUserAction } from '../../redux/actions';
import { fetchUserDetails } from '../../utils/fetch-user';

// Custom hook for managing the state and behavior related to two-factor authentication
const useTwoFactorAuth = (onTwoFactorAuthChange, setIsTwoFactorAuthEnabled) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [openTwoFactorAuthEnabled, setOpenTwoFactorAuthEnabled] = useState(false);
    const [totpSetupDetails, setTotpSetupDetails] = useState('');
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [totpCode, setTotpCode] = useState(''); // State to store the TOTP code entered by the user
    const userData = useSelector(state => state.userData);
    const cognitoUsers = useSelector(state => state.cognitoUsers);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();


    // Effect to generate the QR code for the TOTP setup
    useEffect(() => {
        if (totpSetupDetails) {
            const uri = `otpauth://totp/Transform:${userData?.email}?secret=${totpSetupDetails}&issuer=Transform`;
            QRCode.toDataURL(uri)
                .then(url => {
                    setQrCodeUrl(url);
                })
                .catch(err => {
                    console.error('Error generating QR Code:', err);
                });
        }
    }, [totpSetupDetails, userData?.email]);


    // Function to handle the change in the two-factor authentication switch
    const handleTwoFactorAuthChange = async (event) => {
        const isEnabled = event.target.checked;
        onTwoFactorAuthChange(event);

        try {
            const user = await Auth.currentAuthenticatedUser();
            if (isEnabled) {

                const setupDetails = await Auth.setupTOTP(user);

                setTotpSetupDetails(setupDetails);
                setOpenDialog(true);
            } else {
                await Auth.setPreferredMFA(user, 'NOMFA');
                const attributes = {
                    twoFAEnabled: 'false',
                };
                await dispatch(updateCognitoUserAction(userData.email, attributes, cognitoUsers));
                fetchUserDetails(dispatch);
                enqueueSnackbar('Two-Step Authentication has been disabled.', { variant: 'success' });

            }
        } catch (error) {
            console.error('Error with TOTP setup:', error);
        }
    };

    // Function to close the dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setTotpCode('');
        setIsTwoFactorAuthEnabled(false);

    };


    // Function to verify the TOTP code entered by the user
    const handleVerifyTOTPCode = async () => {
        try {
            setIsLoading(true);
            const user = await Auth.currentAuthenticatedUser();
            await Auth.verifyTotpToken(user, totpCode);
            await Auth.setPreferredMFA(user, 'TOTP');

            const attributes = { twoFAEnabled: 'true' };
            const res = await dispatch(updateCognitoUserAction(userData.email, attributes, cognitoUsers));
            fetchUserDetails(dispatch);

            enqueueSnackbar('Two-Step Authentication has been enabled and will be required for your next login.', { variant: 'success' });
            setOpenTwoFactorAuthEnabled(true);
        } catch (error) {
            console.error('Error during TOTP verification or attribute update:', error);
            enqueueSnackbar('Error during TOTP verification or updating attributes. Please try again.', { variant: 'error' });
        } finally {
            setIsLoading(false);
            handleCloseDialog();
        }
    };

    // Return the state and handlers to be used by the component
    return {
        openDialog, openTwoFactorAuthEnabled, setOpenTwoFactorAuthEnabled,
        totpCode, setTotpCode, handleTwoFactorAuthChange, handleVerifyTOTPCode,
        isLoading, qrCodeUrl, handleCloseDialog
    };
};

export default useTwoFactorAuth;
