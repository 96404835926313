import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Box, Typography } from '@mui/material';
import { Divider } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import ToggleSwitchValue from "../../../../../../components/form/toogle-switch-value";
import CustomTextField from '../../../../../../components/form/questions-text-field';
import CustomNumberTextField from '../../../../../../components/form/question-number-input';
import { colorTheme } from '../../../../../../assets/theme';
import useSearchChooseAdvancedSettings from './../../../../../../hooks/editor/drop-down/use-drop-down-advanced';
import LimitNumberOfAnswers from "../../../../../../components/editor/limit-number-of-answers";
import TooltipIcon from '../../../../../../components/tooltip-text/tooltip-icon';

const SearchChooseAdvancedSettings = (props) => {
    const question = props.template;
    const {
        selectedColumn,
        checkBox,
        allowUserAnswerSwitch,
        inputOption,
        textFields,
        fieldsDisabled,
        handleTextFields,
        handleAnswersSwitch,
        handleCheckbox,
        validationMinCheck,
        validationMaxCheck,
        handleMinValidationCheck,
        handleMaxValidationCheck,
        handleTextInput
    } = useSearchChooseAdvancedSettings(question);

    return (
        <div className='search-choose-advanced-settings'>
            <Box ml={1.5} mt={-1.5}>
                <FormControlLabel
                    control={
                        <ToggleSwitchValue
                            label='Allow respondents to provide their own answer.'
                            value={allowUserAnswerSwitch}
                            onChange={handleAnswersSwitch}
                            description={'Enable this to allow respondents to input their own answer.'}
                        />
                    }
                />
            </Box>
            <Box mt={2.5} display='flex' alignItems='center' className={!allowUserAnswerSwitch ? 'disabled' : ''}>
                <Box component="label" sx={{ marginRight: '12px', textAlign: 'center' }}>Maximum number of added answers:</Box>
                <CustomNumberTextField
                    name='max_number_added_answers'
                    variant='outlined'
                    type='text'
                    size='small'
                    onChange={e => handleTextFields(e.target)}
                    value={textFields.max_number_added_answers || ''}
                />
            </Box>
            <Box mt={2.5} display='flex' alignItems='center' className={!allowUserAnswerSwitch ? 'disabled' : ''}>
                <Typography mr={1} className='text'>Select fields respondents must complete when adding their own answers:</Typography>
                <TooltipIcon title={'Respondents entry fields'} content={'When you allow respondents to add answers, you can control the details they need to provide. Selecting multiple fields will require respondents to fill out each selected field, ensuring comprehensive data collection.'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                <Box mr={2} ></Box>
                {selectedColumn && selectedColumn.map((columnItem, index) =>
                    <FormControl key={index}>
                        <FormGroup>
                            <FormControlLabel
                                style={{ marginLeft: '0px' }}
                                control={
                                    <Checkbox
                                        checked={checkBox[columnItem] || index === 0}
                                        onChange={e => handleCheckbox(e.target)}
                                        name={columnItem}
                                        disabled={fieldsDisabled}
                                        sx={{
                                            padding: '0px',
                                            '&.Mui-checked': {
                                                color: colorTheme.theme.transform.darkBlueTwo
                                            },
                                            marginRight: '6px'
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            color: colorTheme.theme.text.mediumGrey,

                                            textTransform: 'capitalize'
                                        }}
                                    >
                                        {columnItem}
                                    </Typography>
                                }

                            />
                        </FormGroup>
                    </FormControl>)
                }
            </Box>
            <Typography
                className={!allowUserAnswerSwitch ? 'disabled' : ''}
                mb={1.5} mt={3} >Customize Add Answer Button Text</Typography>
            <Box className={!allowUserAnswerSwitch ? 'disabled' : ''}>
                <CustomTextField
                    width='392px'
                    name='item_add_text'
                    placeholder='Enter button text (e.g., “Add your answer”)'
                    variant='outlined'
                    type='text'
                    size='small'
                    value={textFields.item_add_text || ''}
                    onChange={e => handleTextFields(e.target)}
                />
            </Box>
            <Divider
                style={{
                    marginRight: '20px',
                    marginBottom: '20px',
                    marginTop: '32px',
                }} />

            <LimitNumberOfAnswers
                validationMaxCheck={validationMaxCheck}
                validationMinCheck={validationMinCheck}
                inputOption={inputOption}
                handleMinValidationCheck={handleMinValidationCheck}
                handleMaxValidationCheck={handleMaxValidationCheck}
                handleTextInput={handleTextInput}
                questionType={question.element_type}
            />




        </div >
    );
};

export default SearchChooseAdvancedSettings;
