import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

// Function to check if a user has access based on their licenses
function hasAccess(user, licenses) {
    return licenses.every(license => user && user[license] === "true");
}

function isItOwner(user) {
    return user && user['role'] === 'owner';
}

const ProtectedComponent = ({ licenses, children }) => {
    const user = useSelector(state => state.userData);
    const userHasAccess = user && (isItOwner(user) || (licenses.length > 0 && hasAccess(user, licenses)));

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: userHasAccess ? 'auto' : 'none',
                opacity: userHasAccess ? 1 : 0.5,
                height: '100%',  // Adjust the height as needed
            }}
        >
            {children}
        </Box>
    );
};

export default ProtectedComponent;
