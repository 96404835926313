import React from "react";
import TitleWithIcon from "../../../components/title/title-icon";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import { ReactComponent as LanguageIcon } from "../../../assets/images/title/language.svg";
import { Box } from '@mui/material';

// LanguageContainer component
const LanguageContainer = () => {
    // Define the path segments for the breadcrumbs
    const pathSegments = [
        { name: "Transform", path: "/" },
        { name: "Settings", path: "/settings" },
        { name: "Languages", path: "/settings" }
    ];

    return (
        <Box mt={3}>
            {/* Render the Breadcrumbs component with the defined path segments */}
            <Breadcrumbs pathSegments={pathSegments} />
            <Box mt={2.5}>
                <Box className="organization-container">    
                    {/* Render the TitleWithIcon component with the title "Languages" and the LanguageIcon */}
                    <TitleWithIcon title="Languages" Icon={LanguageIcon} />
                </Box>
            </Box>
        </Box>
    );
}

export default LanguageContainer;