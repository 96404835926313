import React from 'react';
import './survey-menu-action.scss';
import { Box } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/images/survey-dashboard/search-icon.svg';
import SurveyMenuActionFilter from './survey-menu-action-filter';
import SurveyMenuActionSort from './survey-menu-action-sort';
import SurveyMenuActionView from './survey-menu-action-view';
import InputSearch from '../input-search/input-search';

// SurveyMenuAction component
const SurveyMenuAction = (props) => {
    const { surveyStatus, handleSurveyStatusChange, selectedSort, handleSortChange, sortType, searchValue, handleSearchChange, view, handleActiveView } = props; // Props

    return (
        <Box className="survey-action flex-center">
            <Box className="survey-action-content flex-center">
                <Box className="search">
                    <InputSearch value={searchValue} placeholder="Search" onChange={handleSearchChange} icon={<SearchIcon />} padding={'8px 16px 8px 6px'} height={'40px'} />
                </Box>
                <SurveyMenuActionFilter surveyStatus={surveyStatus} handleSurveyStatusChange={handleSurveyStatusChange} />
                <SurveyMenuActionSort selectedSort={selectedSort} handleSortChange={handleSortChange} sortType={sortType} />
                <SurveyMenuActionView view={view} handleActiveView={handleActiveView} />
            </Box>
        </Box>
    );
}

export default SurveyMenuAction;