import React from 'react';
import './register.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';
import { useSnackbar } from 'notistack';
import { registerCustomer, resetErrors, resetRegistrationError, resendCustomerEmail } from "../../redux/actions";
import { REGISTRATION_ERROR_COMPANY_EXISTS, REGISTRATION_ERROR_OTHER, REGISTRATION_ERROR_DOMAIN_EXISTS } from "../../assets/action-constants";
import PrimaryButton from '../buttons/primary-button';
import { colorTheme } from '../../assets/theme';
import InputField from '../form/input-field';
import { makeStyles } from '@material-ui/core/styles';
import PhoneInputField from '../form/phone-input-field';
import TooltipIcon from '../tooltip-text/tooltip-icon';

// Constants
const INPUT_COMPANY = 'COMPANY';
const INPUT_EMAIL = 'EMAIL';
const INPUT_PASSWORD = 'PASSWORD';
const INPUT_FIRST_NAME = 'FIRST_NAME';
const INPUT_LAST_NAME = 'LAST_NAME';
const INPUT_NUMBER = "PHONE_NUMBER";
const DOMAIN_NAME = "DOMAIN_NAME";
const DOMAIN_BLACKLIST = ['register', 'admin', 'registaration', 'administration', 'security', 'signin', 'login', 'signup', 'help', 'helpcenter', 'helpdesk', 'support']

const passPolicy = "At least one upper case English letter [A-Z];  At least one lower case English letter: [a-z]; At least one digit, [0-9]; At least one special character, [#?!@$%^&*-] Minimum eight in length (with the anchors);"
const capitalRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/);
const emailRegEx = RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
const numberRegEx = /[^\d+]+/g; // Regular expression to remove all characters except numbers and '+'
const domainRegex = /([^a-zA-Z\d:])/mg;

let subdomain = window.location.host.substring(window.location.host.indexOf('.'))

// Register component
const Register = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const registrationError = useSelector(state => state.registrationError);
    const customerRegistered = useSelector(state => state.customerRegistered);
    const customerDetails = useSelector(state => state.customerDetails);
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(false);
    const [companyError, setCompanyError] = useState({ error: true });
    const [emailError, setEmailError] = useState({ error: true });
    const [passwordError, setPasswordError] = useState({ error: true });
    const [numberError, setNumberError] = useState({ error: true });
    const [firstNameError, setFirstNameError] = useState({ error: true });
    const [lastNameError, setLastNameError] = useState({ error: true });
    const [domainError, setDomainError] = useState({ error: true })
    const [repeatedPass, setRepeatedPass] = useState('');
    const [allChecksPassed, setAllChecksPassed] = useState(false);
    const [repeatedPassError, setRepeatedPassError] = useState();

    // Function to handle the registration error
    useEffect(() => {
        if (registrationError) {
            setLoading(false);
            isCompanyError();
            isDomainError();
            dispatch(resetRegistrationError())
            if (registrationError.type === REGISTRATION_ERROR_OTHER) {
                openSnackbar(registrationError.message)
            }
        }
    }, [registrationError]);

    // Function to handle the registration
    useEffect(() => {
        if (allChecksPassed) {
            setLoading(true);
            console.log('form: ', form)
            dispatch(registerCustomer(form)).then(() => {
                setLoading(false);
            });
            setAllChecksPassed(false)
        }
    }, [allChecksPassed])

    // Function to open the snackbar
    const openSnackbar = (msg) => {
        enqueueSnackbar(msg, {
            variant: 'error',
        });
        dispatch(resetErrors());
    };

    // Function to handle the change in the input fields
    const handleChange = (text, type) => {
        switch (type) {
            case (INPUT_COMPANY):
                let domainPreview = text.replace(/([^a-zA-Z\d:])/mg, '');
                setForm({
                    ...form,
                    customerName: text,
                    domainName: domainPreview
                });
                setDomainError({
                    error: false,
                    errorMessage: ''
                })
                setCompanyError({
                    error: false,
                    errorMessage: ''
                })
                break;
            case (DOMAIN_NAME):
                let domainName = text.replace(domainRegex, '');
                setForm({
                    ...form,
                    domainName: domainName ? domainName : ''
                });
                break;
            case (INPUT_EMAIL):
                setForm({
                    ...form,
                    email: text,
                });
                setEmailError({
                    error: false,
                    errorMessage: ''
                });
                break;
            case (INPUT_NUMBER):
                setForm({
                    ...form,
                    number: text.replace(numberRegEx, ''),
                });
                setNumberError({
                    error: false,
                    errorMessage: ''
                });
                console.log('form: ', form)
                break;
            case (INPUT_PASSWORD):
                if (repeatedPass && text !== repeatedPass) {
                    setRepeatedPassError(true)
                }
                else { setRepeatedPassError(false) }
                if (text.length >= 8) {
                    isPasswordError(text);
                }
                setForm({
                    ...form,
                    password: text,
                });
                setPasswordError({
                    error: false,
                    errorMessage: ''
                });
                break;
            case (INPUT_FIRST_NAME):
                setForm({
                    ...form,
                    firstName: text,
                });
                setFirstNameError({
                    error: false,
                    errorMessage: ''
                });
                break;
            case (INPUT_LAST_NAME):
                setForm({
                    ...form,
                    lastName: text,
                });
                setLastNameError({
                    error: false,
                    errorMessage: ''
                });
                break;
            default:
                return;
        }
    };

    // Function to handle the submit button
    const handleSubmit = () => {
        isCompanyError();
        isDomainError();
        isEmailError();
        isNumberError();
        isNameError();
        isPasswordError(form.password);

        if (!companyError.errorMessage && !domainError.errorMessage && !passwordError.errorMessage && !emailError.errorMessage && !repeatedPassError && !numberError.errorMessage && !firstNameError.errorMessage && !lastNameError.errorMessage) {
            setAllChecksPassed(true)
        }
    };

    // Function to check if the first name and last name are valid
    const isNameError = () => {
        if (!form.firstName || form.firstName === '') {
            setFirstNameError({
                error: true,
                errorMessage: 'Required'
            })
        } else {
            setFirstNameError({
                error: false,
                errorMessage: ''
            })
        }
        if (!form.lastName || form.lastName === '') {
            setLastNameError({
                error: true,
                errorMessage: 'Required'
            })
        } else {
            setLastNameError({
                error: false,
                errorMessage: ''
            })
        }
    };

    // Function to check if the phone number is valid
    const isNumberError = () => {
        console.log('form: ', form)
        if (!form.number || form.number.length < 5) {
            setNumberError({
                error: true,
                errorMessage: 'Required'
            })
        } else {
            setNumberError({
                error: false,
                errorMessage: ''
            })
        }
    };

    // Function to check if the company name is valid
    const isCompanyError = () => {
        if (registrationError && registrationError.type === REGISTRATION_ERROR_COMPANY_EXISTS || DOMAIN_BLACKLIST.includes(form.customerName)) {
            setCompanyError({
                error: true,
                errorMessage: registrationError.message
            })
        } else if (!form.customerName || form.customerName === '') {
            setCompanyError({
                error: true,
                errorMessage: 'Required'
            })
        } else {
            setCompanyError({
                error: false,
                errorMessage: ''
            })
        }
    };

    // Function to check if the domain is valid
    const isDomainError = () => {
        if (registrationError && registrationError.type === REGISTRATION_ERROR_DOMAIN_EXISTS) {
            setDomainError({
                error: true,
                errorMessage: registrationError.message
            })
        }
        else if (!form.domainName || form.domainName === '') {
            setDomainError({
                error: true,
                errorMessage: 'Required'
            })
        }
        else {
            setDomainError({
                error: false,
                errorMessage: ''
            })
        }
    }

    // Function to check if the email is valid
    const isEmailError = () => {
        if (!form.email || form.email === '') {
            setEmailError({
                error: true,
                errorMessage: 'Required'
            })
        } else if (!emailRegEx.test(form.email)) {
            setEmailError({
                error: true,
                errorMessage: 'Invalid email'
            })
        } else {
            setEmailError({
                error: false,
                errorMessage: ''
            })
        }
    };

    // Function to check if the password meets the policy requirements
    const isPasswordError = (password) => {
        if (!password || password === '') {
            setPasswordError({
                error: true,
                errorMessage: 'Required'
            })
        } else if (!capitalRegex.test(password)) {
            setPasswordError({
                error: true,
                errorMessage: 'Your Password does not satisfy the Current Policy Requirements'
            })
        } else {
            setPasswordError({
                error: false,
                errorMessage: ''
            })
        }

        if (!repeatedPass || repeatedPass === '') {
            setRepeatedPassError({
                error: true,
                errorMessage: 'Required'
            })
        }
    };

    // Function to handle the repeat password field
    const handleRepeatPass = (value) => {
        setRepeatedPass(value)
        if (value !== form.password || value === '') {
            setRepeatedPassError({
                error: true,
                errorMessage: 'Mismatch'
            })
            return;
        }
        setRepeatedPassError(false)
    }

    // Function to redirect to the sign in page
    const handleSignInRedirect = () => {
        window.open(`/signin`, '_self')
    }

    const useStylesBootstrap = makeStyles(() => ({ // Custom styles for the tooltip
        tooltip: {
            backgroundColor: 'transparent',
            marginTop: '-5px',
        }
    }));

    const tooltipClass = useStylesBootstrap();  // Get the custom styles for the tooltip

    return (
        <Box className="register-page-content">
            <Typography variant="h4" className="register-page-title">Create an account</Typography>
            <Typography variant="body1" className="register-in-page-description">Already have an account? <span onClick={handleSignInRedirect}>Log in</span></Typography>
            <div className='register-page-container flex-center'>
                {
                    customerRegistered ?
                        <div className="form flex-column-center">
                            <div className='register-success-text flex-column-center'>
                                <h2>Registration submitted!</h2>
                                <h3>Please verify your account.</h3>
                                <label> We have sent you an email with a link to verify your email address and your account. Please go to your mailbox and open the link.</label>
                                <p />
                                <label>If you have not received the verification email, please check your spam folder and if you can not find email, please reach out to us via email at support@transforminsight.com </label>
                            </div>
                        </div> :
                        <div className="form flex-column-center">
                            <div className="form-inner">
                                <form className='form-input' noValidate autoComplete="off">
                                    <InputField type="text" label="Company name" value={form.customerName ? form.customerName : ''} onChange={(e) => handleChange(e.target.value, INPUT_COMPANY)} autoComplete={'new-password'} />
                                    {
                                        companyError.error && companyError.errorMessage && <span>
                                            <Alert severity='error'>{companyError.errorMessage}</Alert>
                                        </span>
                                    }
                                    <Box className="company-domain">
                                        <InputField type="text" label="Company domain" value={form.domainName ? form.domainName : ''} onChange={(e) => handleChange(e.target.value, DOMAIN_NAME)} autoComplete={'new-password'} />
                                        <TooltipIcon title={'What is a subdomain?'} content={"A subdomain is a unique web address that identifies your company's specific space on our platform, like 'yourcompany.transform.com'. It's used for customizing and accessing your company profile directly. Choose a subdomain related to your company name for easy recognition and access."} placement={'bottom-end'} backgroundColor={"#FFFFFF"} strokeColor={colorTheme.theme.text.lightGrey} iconColor={colorTheme.theme.text.lightGrey} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                                    </Box>
                                    {
                                        domainError.error && domainError.errorMessage && <span>
                                            <Alert severity='error'>{domainError.errorMessage}</Alert>
                                        </span>
                                    }
                                    <InputField type="email" label="Email" value={form.email ? form.email : ''} onChange={(e) => handleChange(e.target.value, INPUT_EMAIL)} autoComplete={'new-password'} />
                                    {
                                        emailError.error && emailError.errorMessage && <span>
                                            <Alert className='email-error' severity='error'>{emailError.errorMessage}</Alert>
                                        </span>
                                    }
                                    <PhoneInputField value={form.number ? form.number : ''} onChange={(number) => handleChange(number, INPUT_NUMBER)} />
                                    {
                                        numberError.error && numberError.errorMessage && <span>
                                            <Alert className='number-error' severity='error'>{numberError.errorMessage}</Alert>
                                        </span>
                                    }
                                    <InputField type="text" label="First Name" value={form.firstName ? form.firstName : ''} onChange={(e) => handleChange(e.target.value, INPUT_FIRST_NAME)} autoComplete={'new-password'} />
                                    {
                                        firstNameError.error && firstNameError.errorMessage && <span>
                                            <Alert className='first-name-error' severity='error'>{firstNameError.errorMessage}</Alert>
                                        </span>
                                    }
                                    <InputField type="text" label="Last Name" value={form.lastName ? form.lastName : ''} onChange={(e) => handleChange(e.target.value, INPUT_LAST_NAME)} autoComplete={'new-password'} />
                                    {
                                        lastNameError.error && lastNameError.errorMessage && <span>
                                            <Alert className='last-name-error' severity='error'>{lastNameError.errorMessage}</Alert>
                                        </span>
                                    }
                                    <Tooltip title={passPolicy}>
                                        <Box>
                                            <InputField type="password" label="Password" value={form.password ? form.password : ''} onChange={(e) => handleChange(e.target.value, INPUT_PASSWORD)} autoComplete={'new-password'} />
                                        </Box>
                                    </Tooltip>
                                    {
                                        passwordError.error && passwordError.errorMessage && <span>
                                            <Alert className='password-error' severity='error'>{passwordError.errorMessage}</Alert>
                                        </span>
                                    }
                                    <InputField type="password" label="Repeat password" value={repeatedPass ? repeatedPass : ''} onChange={(e) => handleRepeatPass(e.target.value)} autoComplete={'new-password'} />
                                    {
                                        repeatedPassError && (
                                            repeatedPassError.errorMessage === 'Required' ?
                                                <span>
                                                    <Alert className='repeat-pass-error' severity='error'>{repeatedPassError.errorMessage}</Alert>
                                                </span>
                                                :
                                                repeatedPassError.errorMessage == 'Mismatch' &&
                                                <span>
                                                    <Alert className='repeat-pass-error' severity='error'>Password confirmation does not match the original</Alert>
                                                </span>
                                        )
                                    }
                                </form>
                            </div>
                            <div className='submit-button'>
                                {
                                    loading ?
                                        <CircularProgress /> :
                                        <PrimaryButton onClick={handleSubmit} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="Create account" />
                                }
                            </div>
                        </div>
                }
            </div>
            <Typography variant="body1" className="register-in-page-bottom">
                Protected by reCAPTCHA and subject to the Google <span>Privacy Policy</span> and <span>Terms of Service</span>.
            </Typography>
        </Box>
    )
}

export default Register;