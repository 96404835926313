import axios from 'axios';

import userDetails from '../assets/admin-user-template';
import { REGISTRATION_ERROR_COMPANY_EXISTS, REGISTRATION_ERROR_OTHER, REGISTRATION_ERROR_DOMAIN_EXISTS } from "../assets/action-constants";
import { transformDbLists, transformListToDbList, transformDbRespondents, transformDbSurveys } from "./data-transformer";
import { ROW_ID } from "../assets/constants";

axios.defaults.baseURL = process.env.REACT_APP_DB_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
const xApiKey = process.env.REACT_APP_X_API_KEY;

export function getUserCredentials(company) {
  return axios({
    method: 'get',
    url: '/customer-registration/' + company,
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('user credentials res: ', res);
    if (res && res.data) {
      return {
        ...res.data.Items[0],
      };
    }
    return null;
  }, err => {
    console.log('error when fetching credentials: ', err);
  })
}

export function getUserDetailsDb() {
  // todo getDetails with token from db
  return Promise.resolve(userDetails);
}

export function getSurveysDb() {

  return axios({

    method: 'get',
    url: '/admin/surveys',

  }).then(res => {
    console.log('res: ', res);
    return transformDbSurveys(res.data);
  }, err => {
    console.log('error when getting surveys: ', err);
    throw err;
  })
}

export function getSurveyDb(surveyId) {
  console.log('getting from DB', surveyId)
  return axios({
    method: 'get',
    url: '/admin/survey/get',
    params: {
      surveyId: surveyId,
    }
  }).then(res => {
    console.log('res: ', res);
    return res.data;
  }, err => {
    console.log('error when getting surveys: ', err);
    throw err;
  })
}

export function getOrganizationSurveysDb() {
  return axios({
    method: 'get',
    url: '/admin/organization-surveys',
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('res: ', res);
    return transformDbSurveys(res.data);
  }, err => {
    console.log('error when getting organization surveys: ', err);
    throw err;
  })
}


export function registerCustomerDb(customer) {
  return axios({
    method: 'post',
    url: '/customer-registration',
    headers: {
      'x-api-key': xApiKey,
    },
    data: {
      ...customer
    }
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when registering customer: ', err);
    if (err.response.status === 409) {
      if (err.response.data.errorCode === 1) {
        return { type: REGISTRATION_ERROR_COMPANY_EXISTS, message: err.response.data.message };
      }
      if (err.response.data.errorCode === 2) {
        return { type: REGISTRATION_ERROR_DOMAIN_EXISTS, message: err.response.data.message }
      }
    } else {
      return { type: REGISTRATION_ERROR_OTHER, message: 'Error with registration' };
    }
  })
}

export function resendCustomerEmailDb(customerData) {
  return axios({
    method: 'put',
    url: '/customer-registration',
    headers: {
      'x-api-key': xApiKey,
    },
    data: customerData,
  }).then(res => {
    console.log('resend request response: ', res);
    return res;
  }, err => {
    console.log('error when resending survey: ', err);
    throw err;
  })
}

export function verifyCustomerDb(customerData) {
  return axios({
    method: 'put',
    url: '/customer-registration',
    headers: {
      'x-api-key': xApiKey,
    },
    data: customerData,
  }).then(res => {
    console.log('resend request response: ', res);
    return res;
  }, err => {
    console.log('error when resending survey: ', err);
    throw err;
  })
}

export function startSurveyDb(survey) {
  return axios({
    method: 'post',
    url: '/admin/survey/' + survey.id + '/start',
    data: survey,
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when starting survey: ', err);
    throw err;
  })
}

export function copySurveyDb(surveyId) {
  return axios({
    method: 'post',
    url: '/admin/survey/copy',
    data: { surveyId: surveyId }
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when copying survey: ', err);
    throw err;
  })
}

export function assignSurveyDb(surveyId, userData, email, attributes, surveyName, access, callerData) {
  let userAttributes = attributes || {};
  let invitedUsersData = userData || [];
  return axios({
    method: 'post',
    url: '/admin/survey/assign',
    data: {
      surveyId: surveyId,
      invitedUsers: invitedUsersData,
      attributes: userAttributes,
      email: email,
      surveyName: surveyName,
      access: access,
      callerData: callerData
    },
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('assign users res: ', res);
    return res;
  }, err => {
    console.log('error when assigning users to  survey: ', err);
    throw err;
  })
}

export function updateSurveyDb(survey) {
  return axios({
    method: 'post',
    url: '/admin/survey/' + survey.id,
    data: survey,
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when updating survey: ', err);
    throw err;
  })
}

export function publishSurveyDb(surveyId) {
  return axios({
    method: 'post',
    url: '/admin/survey/' + surveyId + '/publish',
    // data: {endDate: endDate},
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when publishing survey: ', err);
    throw err;
  })
}

export function scheduleSurveyDb(survey) {
  return axios({
    method: 'post',
    url: '/admin/survey/' + survey.id + '/schedule',
    data: survey,
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when scheduling survey: ', err);
    throw err;
  })
}

export function closeSurveyDb(survey) {
  return axios({
    method: 'post',
    url: '/admin/survey/' + survey.id + '/close',
    data: { surveyId: survey.id },
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when closing survey: ', err);
    throw err;
  })
}

export function saveList(surveyId, listId, list, title) {
  let dataObject = { title: title, details: transformListToDbList(list) }
  return axios({
    method: 'post',
    url: '/admin/survey/' + surveyId + '/lists/' + listId,
    data: dataObject
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when saving list: ', err);
    throw err;
  })
}

export function getLists(surveyId) {
  return axios({
    method: 'get',
    url: '/admin/survey/' + surveyId + '/lists/',
  }).then(res => {
    console.log('res: ', res);
    return transformDbLists(res.data);
  }, err => {
    console.log('error when getting lists: ', err);
    throw err;
  })
}

export function postSurveyQuestion(surveyId, question) {
  return axios({
    method: 'post',
    url: '/admin/questions',
    data: {
      templateId: surveyId,
      question: question
    }
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when posting question: ', err);
    throw err;
  })
}

export function postTemplate(details, questions) {
  return axios({
    method: 'post',
    url: '/admin/template',
    data: {
      details: details,
      questions: questions
    }
  }).then(res => {
    console.log('Response:', res);
    return res;
  }, err => {
    console.log('Error when posting template:', err);
    throw err;
  });
}


export function getSurveyQuestion(surveyId) {
  return axios({
    method: 'get',
    url: '/admin/questions',
    params: {
      templateId: surveyId,
    }
  }).then(res => {
    console.log('res survey questions: ', res);
    return res;
  }, err => {
    console.log('error when getting questions: ', err);
    throw err;
  })
}

export function deleteSurveyQuestion(surveyId, questionId) {
  return axios({
    method: 'delete',
    url: '/admin/questions/' + questionId,
    params: {
      templateId: surveyId,
    }
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when getting questions: ', err);
    throw err;
  })
}

export function getRespondentsDb(surveyId) {
  return axios({
    method: 'get',
    url: '/admin/survey/' + surveyId + '/respondents',
  }).then(res => {
    console.log('res: ', res);
    return transformDbRespondents(res.data);
  }, err => {
    console.log('error when getting respondents: ', err);
    throw err;
  })
}

export function updateRespondents(surveyId, respondents, method) {
  return axios({
    method: method,
    url: '/admin/survey/' + surveyId + '/respondents',
    data: respondents,
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when updating respondents: ', err);
    throw err;
  })
}

export function addColumnToRespondentsLists(surveyId, column, method) {
  return axios({
    method: method,
    url: '/admin/survey/' + surveyId + '/respondents',
    data: {
      column: column
    }
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when adding column to respondents: ', err);
    throw err;
  })
}

export function deleteRespondentsDb(surveyId, respondents) {
  return axios({
    method: 'delete',
    url: '/admin/survey/' + surveyId + '/respondents',
    data: respondents.map(r => { return { id: r[ROW_ID], details: { ...r, tableData: undefined } } }),
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when delete respondents: ', err);
    throw err;
  })
}

export function deleteList(surveyId, listId) {
  return axios({
    method: 'delete',
    url: '/admin/survey/' + surveyId + '/lists/' + listId
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when delete respondents: ', err);
    throw err;
  })
}

export function deleteSurveyDb(surveyId, createdBy) {
  return axios({
    method: 'delete',
    url: '/admin/survey/delete',
    data: {
      surveyId: surveyId,
      createdBy: createdBy
    }
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when deleting survey: ', err);
    throw err;
  })
}

export function getS3CredentialsDb(fileName, fileType) {
  return axios({
    method: 'get',
    url: '/admin/credentials',
    params: {
      fileName,
      fileType
    }
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when fetching s3 credentials: ', err);
    throw err;
  })
}
export function downloadSurvey(surveyId, templateId) {
  return axios({
    method: 'post',
    url: '/admin/survey/download',
    data: {
      surveyId: surveyId,
      templateId: templateId
    }
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when fetching downloading survey: ', err);
    throw err;
  })
}

export function sendSurveyReminders(surveyId, reminder) {
  return axios({
    method: 'post',
    url: 'admin/survey/reminder',
    data: {
      surveyId: surveyId,
      reminder: reminder
    }
  }).then(res => {
    console.log('res: ', res);
    return res;
  }, err => {
    console.log('error when trying to send reminder: ', err);
    throw err;
  })
}
export function listCognitoUsers(token) {
  token = localStorage.getItem('token');
  axios.defaults.headers.common.Authorization = token
  return axios({
    method: 'get',
    url: 'admin/user',
    headers: { 'x-api-key': xApiKey, }
  }).then(res => {
    console.log('res listing cognito users ', res)
    return res.data.Users;
  }, err => {
    console.log('error when trying to list users from cognito pool: ', err);
    throw err;
  })
}

export function addCognitoUser(email, name, surname, surveyLicense, surveyAccessLevel, insightsLicense, role, callerData) {
  console.log('add user data: ', email, name, surname, surveyLicense, surveyAccessLevel, insightsLicense, role, callerData)
  console.log('caller data: ', callerData)
  return axios({
    method: 'post',
    url: 'admin/user',
    data: {
      email: email,
      attributes: {
        firstName: name,
        lastName: surname,
        surveyLicense: surveyLicense,
        surveyAccessLevel: surveyAccessLevel,
        insightsLicense: insightsLicense,
        role: role
      },
      callerData: callerData
    },
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('res adding new cognito user ', res)
    return res.data.User;
  }, err => {
    console.log('error when trying to add user into cognito pool: ', err);
    throw err;
  })
}

export function getUsersOnSurvey(surveyId) {
  return axios({
    method: 'post',
    url: 'admin/survey/details',
    data: {
      surveyId: surveyId
    }
  }).then(res => {
    console.log('list assigned users: ', res)
    return res.data.Items
  }, err => {
    console.log('error listing assigned users ', err);
    throw err;
  })
}

export function deleteCogintoUser(email) {
  return axios({
    method: 'delete',
    url: 'admin/user',
    data: {
      email: email,
    },
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('res deleting cognito user ', res)
    return res.data;
  }, err => {
    console.log('error when trying to delete user from cognito pool: ', err);
    throw err;
  })
}

export function uploadFileToS3(presignedPostData, file) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    let filePath = '';
    Object.keys(presignedPostData.fields).forEach(key => {
      if (key === 'key') {
        filePath = presignedPostData.fields[key]
      }
      formData.append(key, presignedPostData.fields[key]);
    });

    formData.append("acl", "public-read");
    // Actual file has to be appended last.
    formData.append("file", file);
    console.log(formData)
    const xhr = new XMLHttpRequest();
    xhr.open("POST", presignedPostData.url, true);
    xhr.send(formData);
    xhr.onload = function (data) {
      this.status === 204 ? resolve(presignedPostData.url + '/' + filePath) : reject(this.responseText);
    };
  });
}

// UPDATE USER

export function updateCognitoUser(email, attributes) {
  return axios({
    method: 'put',
    url: 'admin/user',
    data: {
      email: email,
      attributes: attributes
    },
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('Response updating user: ', res);
    return res.data;
  }).catch(err => {
    console.log('Error when trying to update user from cognito pool: ', err);
    throw err;
  });
}

export function getOrganizationData() {
  return axios({
    method: 'get',
    url: 'admin/organization/details',
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('Response getting organization data: ', res);
    return res.data;
  }).catch(err => {
    console.log('Error when trying to get organization data: ', err);
    throw err;
  });
}

export function postOrganizationData(details) {
  const data = {
    details: details
  };
  return axios({
    method: 'post',
    url: 'admin/organization/details',
    data: data,
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('Response posting organization data: ', res);
    return res.data;
  }).catch(err => {
    console.log('Error when trying to post organization data: ', err);
    throw err;
  });
}

export function getOrganizationDefaultStyleData() {
  return axios({
    method: 'get',
    url: 'admin/organization/default-style',
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('Response getting organization default style data: ', res);
    return res.data;
  }
  ).catch(err => {
    console.log('Error when trying to get organization default style data: ', err);
    throw err;
  });
}

export function postOrganizationDefaultStyleData(details) {
  const data = {
    defaultStyleDetails: details
  };
  return axios({
    method: 'post',
    url: 'admin/organization/default-style',
    data: data,
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('Response posting organization default style data: ', res);
    return res.data;
  }).catch(err => {
    console.log('Error when trying to post organization default style data: ', err);
    throw err;
  });
}

export function deleteFileFromS3(fileUrl) {
  const data = {
    fileUrl: fileUrl
  };
  return axios({
    method: 'delete',
    url: 'admin/storage',
    data: data,
    headers: {
      'x-api-key': xApiKey,
    }
  }).then(res => {
    console.log('Response deleting file from S3: ', res);
    return res.data;
  }).catch(err => {
    console.log('Error when trying to delete file from S3: ', err);
    throw err;
  });
}