export default  {
	"invitationTemplate": "invitation-template.html",
	"language": "en",
	"started": "2024-04-09T18:42:25.805Z",
	"colors": {
		"highlight": "#FEBE10",
		"fontLight": "#fff",
		"lightHighlight": "rgba(233, 173, 193, 0.86)",
		"backgroundMedium": "#666",
		"fontDark": "#333",
		"main": "#0C255C",
		"backgroundLight": "#EDEDED",
		"value": "defaultTransform"
	},
	"startImmediately": true,
	"createdBy": "john_user_id",
	"domainName": "development",
	"statusTimestamp": null,
	"name": "BASE TEMPLATE",
	"status": "DRAFT"
}
	

