import React, { useState } from 'react';
import { Box, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { ReactComponent as SortIcon } from '../../assets/images/actions/icon-sort.svg';
import TextButton from '../buttons/text-button';
import { colorTheme } from '../../assets/theme';
import CustomCheckboxLabel from '../form/custom-checkbox-label';

// Style for the component using makeStyles
const useStyles = makeStyles(() => ({
    menuItem: {
        color: colorTheme.theme.text.lightGrey,
        fontSize: '16px',

        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

// SortMenu component
const SortMenu = ({ sortOrder, onSortOrderChange }) => {
    const classes = useStyles(); // Get styles from useStyles
    const [anchorEl, setAnchorEl] = useState(null); // State for anchor element
    const [elementWidth, setElementWidth] = useState('auto'); // State for element width
    const open = Boolean(anchorEl); // Boolean state for anchor element

    // Function to handle click event
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setElementWidth(event.currentTarget.clientWidth);
    };

    // Function to handle close event
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Function to handle sort change
    const handleSortChange = (order) => {
        onSortOrderChange(order);
        handleClose();
    };

    return (
        <Box>
            <TextButton name='Sort By' onClick={handleClick} size='small' fontSize='14px' width='auto' margin='auto auto 0 auto' borderRadius='23px' Icon={SortIcon} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{
                    style: {
                        borderRadius: '4px',
                        boxShadow: '0px 1px 2.5px 0px #7B7D8982',
                        width: elementWidth,
                        marginTop: '45px',
                    },
                }}
            >
                <MenuItem className={classes.menuItem} TouchRippleProps={{ style: { display: 'none' } }}>
                    <CustomCheckboxLabel
                        label='A-Z'
                        checked={sortOrder === 'asc'}
                        onChange={() => handleSortChange('asc')}
                        colorChange={false}
                    />
                </MenuItem>
                <MenuItem className={classes.menuItem} TouchRippleProps={{ style: { display: 'none' } }}>
                    <CustomCheckboxLabel
                        label='Z-A'
                        checked={sortOrder === 'desc'}
                        onChange={() => handleSortChange('desc')}
                        colorChange={false}
                    />
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default SortMenu;
