import { useState, useCallback, useMemo } from 'react';

// Custom hook to enhance table functionality
export function useTeamTable(rows, headCells) {
    // State variables
    const [selected, setSelected] = useState([]); // Selected rows
    const [page, setPage] = useState(0); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
    const [searchInput, setSearchInput] = useState(''); // Search input
    const [isAddDialogOpen, setAddDialogOpen] = useState(false);
    const [isEditDialogOpen, setEditDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [searchMade, setSearchMade] = useState(false);
    const [selectedRow, setSelectedRow] = useState({}); // Selected row for editing or deleting
    const [statusFilter, setStatusFilter] = useState('');
    const [roleFilter, setRoleFilter] = useState('');

    // Handler to update the status filter when it changes in the dropdown
    const handleStatusFilterChange = (newStatus) => {
        setStatusFilter(newStatus);
        setPage(0); // Reset page to 0 when filter changes
    };

    // Handler to update the role filter when it changes in the dropdown
    const handleRoleFilterChange = (newRole) => {
        setRoleFilter(newRole);
        setPage(0); // Reset page to 0 when filter changes
    };

    // Handler to select all rows
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    // Handler to select a row
    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    // Handler to change page
    const handleChangePage = (event, newPage) => setPage(newPage);

    // Handler to change rows per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Function to check if a row is selected
    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Search functionality
    const handleSearchChange = useCallback((value) => {
        setSearchInput(value);
        setPage(0); // Reset page to 0 when search changes
        const searchMadeNow = value.length > 0;
        if (searchMade !== searchMadeNow) {
            setSearchMade(searchMadeNow);
        }
    }, [searchMade]);

    // Function to toggle dialog add user visibility
    const toggleAddDialog = () => {
        setAddDialogOpen(!isAddDialogOpen);
    };
    // Function to toggle edit user dialog visibility
    const toggleEditDialog = (row) => {
        setSelectedRow(row);
        setEditDialogOpen(!isEditDialogOpen);
    };
    // Function to toggle delete user dialog visibility
    const toggleDeleteDialog = (row) => {
        setSelectedRow(row);
        setDeleteDialogOpen(!isDeleteDialogOpen);
    };

    // Filter rows based on search, status, and role
    const filteredRows = useMemo(() => rows.filter(row => {
        const searchTextMatch = row.user.toLowerCase().includes(searchInput.toLowerCase());
        const statusMatch = statusFilter === '' || row.status === statusFilter;

        let roleMatch = roleFilter === ''; // If no role filter is set, all rows should match.
        if (!roleMatch && roleFilter) {
            if (roleFilter === 'Both') { // Special case for 'Both'
                roleMatch = row.role.includes('Survey Admin') && row.role.includes('Insights Admin');
            } else {
                roleMatch = row.role.includes(roleFilter);
            }
        }

        return searchTextMatch && statusMatch && roleMatch;
    }), [rows, searchInput, statusFilter, roleFilter]);

    // Paginate the filtered rows
    const paginatedRows = useMemo(() => {
        const start = page * rowsPerPage;
        const end = start + rowsPerPage;
        return filteredRows.slice(start, end);
    }, [filteredRows, page, rowsPerPage]);

    // Calculate the number of empty rows
    const emptyRows = rowsPerPage - paginatedRows.length;

    // Return the state and handlers
    return {
        handleSelectAllClick,
        handleClick,
        handleChangePage,
        handleChangeRowsPerPage,
        isSelected,
        handleSearchChange,
        toggleAddDialog,
        toggleEditDialog,
        toggleDeleteDialog,
        selectedRow,
        isEditDialogOpen,
        isAddDialogOpen,
        isDeleteDialogOpen,
        searchInput,
        paginatedRows,
        filteredRows,
        selected,
        page,
        rowsPerPage,
        emptyRows,
        searchMade,
        statusFilter,
        roleFilter,
        handleStatusFilterChange,
        handleRoleFilterChange,
        setStatusFilter,
        setRoleFilter,
    };
}
