import React from "react";
import './resources-card.scss';
import { Typography, Link } from '@material-ui/core';
import { ReactComponent as KnowledgeIcon } from "../../assets/images/dashboard/knowledge-icon.svg";
import { ReactComponent as ContactIcon } from "../../assets/images/dashboard/contact-icon.svg";

const ResourceCard = () => {
    return (
        <div className="resources-card">
            <Typography variant="h3">Resources</Typography>
            <div className="resources-items">
                <div className="resource-item">
                    <div className="resource-icon">
                        <KnowledgeIcon/>
                    </div>
                    <div className="resource-description">
                        <Typography variant="h4">Knowledge center</Typography>
                        <Link href="#">https://help.transforminsights.com</Link>
                    </div>
                </div>
                <div className="resource-item">
                    <div className="resource-icon">
                        <ContactIcon/>
                    </div>
                    <div className="resource-description">
                        <Typography variant="h4">Contact us</Typography>
                        <Link href="#">support@transform.com.sa</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResourceCard;