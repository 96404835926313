import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { ROW_ID, STATUS_COLUMN } from "../../assets/constants";
import { getRowsPerPageOptions } from '../../utils/rows-options';
import { v4 as uuidv4 } from 'uuid';
import { emailRegEx } from "../../utils/regex-utils";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import _, { set } from "lodash";

// Custom hook to manage lists table
export const useListsTable = (props) => {
    const { enqueueSnackbar } = useSnackbar(); // Snackbar
    const currentSurvey = useSelector(state => state.currentSurvey); // Get current survey
    const userData = useSelector(state => state.userData); // Get user data
    const [state, setState] = useState({
        columns: [],
        data: [],
        columnsOrder: [],
        selectedColumns: [],
    }); // Table state
    const [title, setTitle] = useState(""); // Table title  
    const delayedUpdateTitle = useCallback(_.debounce((title, lists) => props.updateTitle(title, lists), 800), []); // Delayed update title
    let tableRef = React.createRef(); // Table reference
    const [searchQuery, setSearchQuery] = useState(''); // Search query state
    const [sortOrder, setSortOrder] = useState('asc'); // Sort order state
    const [sortBy, setSortBy] = useState(null); // Sort by column state
    const rowsPerPageOptionsNumbers = getRowsPerPageOptions(state.filteredData ? state.filteredData.length : 0); // Rows per page options
    const [deletingRow, setDeletingRow] = useState(null); // New state for tracking row deletion
    const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page state
    const [page, setPage] = useState(0); // Page state
    const [editingRow, setEditingRow] = useState(null); // New state for tracking row editing
    const [newRespondent, setNewRespondent] = useState({}); // New respondent state
    const [addingRow, setAddingRow] = useState(null); // New state for tracking row addition
    const [addingColumn, setAddingColumn] = useState(null); // New state for tracking column addition
    const [newColumnName, setNewColumnName] = useState(''); // New column names state
    const newColumnRef = useRef(null); // New column reference

    // Function to open a snackbar
    const openSnackbar = (msg) => {
        enqueueSnackbar(msg, {
            variant: 'error',
        });
    };

    // Update title when the props change
    useEffect(() => {
        if (props.title) {
            setTitle(props.title);
        }
    }, [props.title]);

    // Function to handle title change
    const handleTitleChange = (title) => {
        setTitle(title);
        if (title != "") {
            delayedUpdateTitle(title, currentSurvey.lists);
        }
    };

    // Function to filter data based on search query
    const filterData = (data, query) => {
        if (!query) {
            return data;
        }
        return data.filter(row =>
            Object.values(row).some(value =>
                String(value).toLowerCase().includes(query.toLowerCase())
            )
        );
    };

    // Function to get the default sort column
    const getDefaultSortColumn = (columns) => {
        if (columns.length == 0) return;
        const nameColumn = columns.find(col => col.field.toLowerCase() === 'name');
        return state.columns && state.columns.length > 0 && nameColumn ? nameColumn.field : columns[0].field;
    };

    // Function to apply sorting
    const applySorting = (data, order, column) => {
        const sortColumn = column || sortBy;
        if (!sortColumn) return data;

        return _.orderBy(data, [sortColumn], [order]);
    };

    // Update data when the props change
    useEffect(() => {
        if (props.data && props.columns) {
            if (tableRef && tableRef.current && tableRef.current.dataManager) {
                tableRef.current.dataManager.orderBy = -1;
                tableRef.current.dataManager.orderDirection = "";
            }
            let columns = _.cloneDeep(props.columns).filter(col => col.field !== STATUS_COLUMN.field);
            let statusColumnIndex = columns.findIndex(col => col.field === STATUS_COLUMN.field);
            if (statusColumnIndex !== -1) {
                columns[statusColumnIndex].defaultSort = 'asc';
            }
            let data = _.cloneDeep(props.data).map(row => {
                let { [STATUS_COLUMN.field]: status, ...rest } = row;
                return rest;
            });

            // Add table data to the rows for table actions
            const datawithTableData = data.map((row, index) => {
                return {
                    ...row,
                    tableData: { id: index }
                };
            });

            const filteredData = filterData(datawithTableData, searchQuery);
            const defaultSortColumn = getDefaultSortColumn(columns);
            const sortedData = applySorting(filteredData, sortOrder, defaultSortColumn);

            setSortBy(defaultSortColumn);

            setState(prevState => ({
                ...prevState,
                data: datawithTableData,
                filteredData: sortedData,
                columns: prepareColumns(columns),
                columnsOrder: columns.map(col => col.field),
                selectedColumns: columns.map(col => col.field),
            }));

            // If there is only one row in the table, initiate edit
            if (props.manuallyUploaded && (!props.manuallyUploadedListId || props.manuallyUploadedListId == props.listId)) {
                initiateEdit(sortedData[0]);
            } else {
                setEditingRow(null);
            }
        }
    }, [props.data, props.columns, searchQuery, sortOrder]);

    // Function to handle sort order change
    const handleSortOrderChange = (order) => {
        setSortOrder(order);
    };

    // Function to handle column order change
    const handleColumnOrderChange = (newOrder) => {
        setState(prevState => ({
            ...prevState,
            columnsOrder: newOrder,
            columns: newOrder.map(order => prevState.columns.find(col => col.field === order)),
        }));
    };

    // Function to handle column selection change
    const handleColumnSelectionChange = (selectedColumns) => {
        setState(prevState => ({
            ...prevState,
            selectedColumns: selectedColumns,
        }));
    };

    // Table icons
    const tableIcons = {
        Add: forwardRef((props, ref) => <PersonAddIcon {...props} ref={ref} />)
    };

    // Function to update data
    const updateData = (data, updatedRecord) => {
        props.updateData({ columns: state.columns, data: data }, updatedRecord);
    };

    // Function to handle row add
    const handleRowAdd = (newData) => {
        // Check if email is valid
        if (newData.email && !emailRegEx.test(newData.email)) {
            openSnackbar('Please enter a valid email address for respondent');
        }
        else {
            setAddingRow(null);
            return new Promise(resolve => {
                if (props.data) {
                    const data = [...state.data];
                    let { [STATUS_COLUMN.field]: status, ...rest } = newData;
                    data.push({
                        ...rest,
                        [ROW_ID]: rest[ROW_ID] ? rest[ROW_ID] : uuidv4()
                    });
                    updateData(data, rest);
                }
                resolve();
            });
        }
    };

    // Function to handle row update
    const handleRowUpdate = (newData, oldData) => {
        return new Promise(resolve => {
            if (props.data) {
                const data = [...state.data];
                let { [STATUS_COLUMN.field]: status, ...rest } = newData;
                data[data.indexOf(oldData)] = rest;
                updateData(data, rest);
            }
            resolve();
        });
    };

    // Function to handle row delete
    const handleRowDelete = (oldData) => {
        if (oldData.Email === userData.email) {
            openSnackbar('You can not delete yourself from workspace');
            return Promise.reject();
        }
        if (oldData.Role === 'owner') {
            openSnackbar('You can not delete owner of the workspace');
            return Promise.reject();
        }
        const deleteRowIndex = state.data.findIndex(row => row[ROW_ID] === oldData[ROW_ID]); //oldData.tableData.id;
        return new Promise(resolve => {
            resolve();
            if (props.data) {
                const data = [...state.data];
                data.splice(deleteRowIndex, 1);
                if (props.deleteData) {
                    props.deleteData([oldData], _.cloneDeep({ columns: state.columns, data: data }));
                } else {
                    updateData(data);
                }
            }
        });
    };

    // Function to handle multi row delete
    const handleMultiRowDelete = (deletedData) => {
        const deleteRowIndexes = deletedData.map(d => d.tableData.id);
        return new Promise(resolve => {
            resolve();
            if (props.data) {
                const data = [...state.data];
                _.remove(data, (value, index) => deleteRowIndexes.includes(index));
                if (props.deleteData) {
                    props.deleteData(deletedData, _.cloneDeep({ columns: state.columns, data: data }));
                } else {
                    updateData(data);
                }
            }
        });
    };

    // Function to handle selected rows
    const handleSelectedRows = (selectedRows) => {
        if (selectedRows) {
            props.selectedRows(selectedRows);
        }
    };

    // Function to prepare columns
    const prepareColumns = (columns) => {
        return columns.filter(col => col.title !== 'tableData');
    };

    // Function to initiate edit row
    const initiateEdit = (row) => {
        setEditingRow(row);
        setNewRespondent(row);
    };

    // Function to confirm edit
    const confirmEdit = () => {
        handleRowUpdate(newRespondent, editingRow).then(() => setEditingRow(null));
    };

    // Function to cancel edit
    const cancelEdit = () => {
        setEditingRow(null);
    };

    // Function to handle new respondent change
    const handleNewRespondentChange = (id, value) => {
        setNewRespondent(prev => ({
            ...prev,
            [id]: value,
        }));
    };

    // Handler to change page
    const handleChangePage = (event, newPage) => setPage(newPage);

    // Handler to change rows per page
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Function to handle delete row
    const initiateDelete = (row) => {
        setDeletingRow(row);
    };

    // Function to confirm delete
    const confirmDelete = () => {
        handleRowDelete(deletingRow).then(() => setDeletingRow(null));
    };

    // Function to cancel delete
    const cancelDelete = () => {
        setDeletingRow(null);
    };

    // Handle search query change
    const handleSearchQueryChange = (query) => {
        setSearchQuery(query);
        setPage(0);
    };

    // Function to start adding row
    const startAddingRow = () => {
        setAddingColumn(false);
        const emptyRow = state.columns.reduce((acc, col) => {
            acc[col.field] = '';
            return acc;
        }, {});
        setAddingRow(emptyRow);
    };

    // Function to cancel adding row
    const cancelAddingRow = () => {
        setAddingRow(null);
    };

    // Function to confirm adding row
    const confirmAddingRow = () => {
        handleRowAdd(addingRow);
    };

    // Function to start adding column
    const startAddingColumn = () => {
        setAddingRow(null);
        setAddingColumn(true);

        // Scroll to the new column (5Oms delay because the column is not rendered yet)
        setTimeout(() => {
            newColumnRef.current.scrollIntoView({ behavior: 'smooth', inline: 'end', block: 'nearest' });
        }, 50);
    };

    // Function to cancel adding column
    function cancelAddingColumn() {
        setAddingColumn(false);
        setNewColumnName('');
    }

    // Function to save column
    function saveColumn() {
        // Check if the column name is emptys
        if (!newColumnName) {
            openSnackbar('Please enter a column name');
            return;
        }

        // Check if the column name already exists
        if (state.columns.find(col => col.field.toLowerCase() === newColumnName.toLowerCase())) {
            openSnackbar('Column name already exists');
            return;
        }

        props.saveColumn(newColumnName);
        setAddingColumn(false);
        setNewColumnName('');

        // Scroll to the new column (1000ms delay because the column is not rendered yet)
        setTimeout(() => {
            newColumnRef.current.scrollIntoView({ behavior: 'smooth', inline: 'end', block: 'nearest' });
        }, 1000);
    }

    // Return the functions and states
    return {
        state,
        title,
        tableRef,
        tableIcons,
        searchQuery,
        rowsPerPageOptionsNumbers,
        deletingRow,
        rowsPerPage,
        page,
        editingRow,
        newRespondent,
        sortOrder,
        addingRow,
        addingColumn,
        newColumnName,
        newColumnRef,
        setNewColumnName,
        startAddingColumn,
        cancelAddingColumn,
        saveColumn,
        setAddingRow,
        handleRowUpdate,
        handleRowDelete,
        handleMultiRowDelete,
        handleSelectedRows,
        prepareColumns,
        initiateEdit,
        confirmEdit,
        cancelEdit,
        handleNewRespondentChange,
        handleChangePage,
        handleChangeRowsPerPage,
        initiateDelete,
        confirmDelete,
        cancelDelete,
        handleSearchQueryChange,
        handleColumnOrderChange,
        handleColumnSelectionChange,
        handleSortOrderChange,
        handleTitleChange,
        startAddingRow,
        cancelAddingRow,
        confirmAddingRow
    };
};
