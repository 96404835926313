import React from 'react';
import { Button } from '@mui/material';
import { colorTheme } from '../../assets/theme';

const SecondaryButton = ({ onClick, color, hoverColor, text, arrow }) => (
    <Button
        variant="contained"
        onClick={onClick}
        sx={{
            textTransform: 'uppercase',
            bgcolor: color,
            color: color === colorTheme.theme.transform.darkBlueTwo ? 'white' : colorTheme.theme.transform.darkBlueTwo,
            padding: '6px 24px',
            letterSpacing: '0.4px',
            border: color === 'white' ? '1.5px solid #1B4972' : 'none',
            borderRadius: '20px',
            lineHeight: '24px',
            boxShadow: 'inset 0 0 0 1.5px #0C255C',
            '&:hover': {
                bgcolor: hoverColor,
                boxShadow: 'inset 0 0 0 1.5px #0C255C',
            },
            height: '40px',
            minWidth: 0,
            width: 'auto',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            gap: '6px',
            alignItems: 'center',
        }}
    >
        {arrow}
        {text}
    </Button>
);



export default SecondaryButton;