import React from "react";
import { Box, Typography } from "@mui/material";
import { Divider } from "@material-ui/core";
import './single-slider-question.scss';
import ToggleSwitchValue from "../../../../../../components/form/toogle-switch-value";
import CustomTextField from "../../../../../../components/form/questions-text-field";
import CustomNumberTextField from "../../../../../../components/form/question-number-input";
import { colorTheme } from '../../../../../../assets/theme';
import useSingleSliderAdvancedSettings from './../../../../../../hooks/editor/single-slider/use-single-slider-advanced-settings';
import FormControlLabel from '@mui/material/FormControlLabel';
import TooltipIcon from '../../../../../../components/tooltip-text/tooltip-icon';

const SingleSliderAdvancedSettings = (props) => {
    const {
        sliderText,
        switchText,
        enableDefaultPosition,
        displayDefaultValue,
        handleSliderText,
        handleTextSwitch,
        handleDefaultValue,
        handleEnableDefaultPosition,
        defaultSliderPosValidation,
        MULTI_SLIDER_FLAG
    } = useSingleSliderAdvancedSettings(props);

    return (
        <div className='single-slider-advanced-settings'>
            <Box ml={'13px'} mt={-1.5}>
                <FormControlLabel
                    control={
                        <ToggleSwitchValue
                            label='Add text description'
                            value={switchText}
                            onChange={handleTextSwitch}
                            description={'Enable this to add descriptive text for the minimum and maximum slider values.'}
                        />
                    }
                />
            </Box>
            <Box mt={2.5} sx={{ display: 'flex', gap: '24px' }}
                className={!switchText ? 'disabled' : ''}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography mr={'12px'} component="label" className='min-max-label'>Text For Minimum Value:</Typography>
                    <CustomTextField
                        disabled={!switchText}
                        placeholder='Type here'
                        name={props.from !== MULTI_SLIDER_FLAG ? 'item_min_text' : 'items_min_text'}
                        value={props.from !== MULTI_SLIDER_FLAG ? sliderText.item_min_text : sliderText.items_min_text || ''}
                        onChange={handleSliderText}
                        sx={{ width: '243px' }}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography mr={'12px'} component="label" className='min-max-label'>Text For Maximum Value:</Typography>
                    <CustomTextField
                        disabled={!switchText}
                        placeholder='Type here'
                        name={props.from !== MULTI_SLIDER_FLAG ? 'item_max_text' : 'items_max_text'}
                        value={props.from !== MULTI_SLIDER_FLAG ? sliderText.item_max_text : sliderText.items_max_text || ''}
                        onChange={handleSliderText}
                        sx={{ width: '243px' }}
                    />
                </Box>
            </Box>
            <Divider
                style={{
                    marginLeft: '0px',
                    marginRight: '0px',
                    marginBottom: '20px',
                    marginTop: '32px',

                }} />

            <Box ml={'12px'}>
                <FormControlLabel
                    control={
                        <ToggleSwitchValue
                            label='Set Default Slider Position'
                            value={enableDefaultPosition}
                            onChange={handleEnableDefaultPosition}
                            description={'Set the initial position of the slider when respondents first view the question. This value should be within the minimum and maximum range of the slider and aligned with slider increments'}
                        />
                    }
                />
            </Box>
            <Box
                className={!enableDefaultPosition ? 'disabled' : ''}
                sx={{ display: 'flex', alignItems: 'center', gap: '12px', mt: 2 }}>
                <Typography component="label">Enter default position</Typography>
                <CustomNumberTextField
                    name='default_position'
                    value={displayDefaultValue()}
                    onChange={e => handleDefaultValue(e.target.value)}
                    error={defaultSliderPosValidation()}
                    sx={{ width: '40px' }}
                    disabled={!enableDefaultPosition}
                />
                <TooltipIcon title={'Slider Default Position'} content={'This value should be within the minimum and maximum range of the slider and aligned with slider increments'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
            </Box>
            {defaultSliderPosValidation() && enableDefaultPosition && (
                <Typography variant="caption" color="error" mt={1}>
                    *Default value must be within boundaries and aligned with increment steps
                </Typography>
            )}
        </div>
    );
};

export default SingleSliderAdvancedSettings;
