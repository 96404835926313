// themeColors array with color presets
const themeColors = [
    {
        value: 'defaultTransform',
        main: '#0C255C',
        highlight: '#FEBE10',
        "backgroundLight": "#EDEDED",
        "backgroundMedium": "#666",
        "fontDark": "#333",
        "fontLight": "#fff",
        "lightHighlight": "rgba(233, 173, 193, 0.86)",
    },
    {
        value: 'blueOrange',
        main: '#053987',
        highlight: '#FF7A00',
        "backgroundLight": "#EDEDED",
        "backgroundMedium": "#666",
        "fontDark": "#333",
        "fontLight": "#fff",
        "lightHighlight": "rgba(233, 173, 193, 0.86)",
    },
    {
        value: 'greenYellow',
        main: '#016C36',
        highlight: '#FAB700',
        "backgroundLight": "#EDEDED",
        "backgroundMedium": "#666",
        "fontDark": "#333",
        "fontLight": "#fff",
        "lightHighlight": "rgba(233, 173, 193, 0.86)",
    },
    {
        value: 'purpleGreen',
        main: '#470073',
        highlight: '#016C36',
        "backgroundLight": "#EDEDED",
        "backgroundMedium": "#666",
        "fontDark": "#333",
        "fontLight": "#fff",
        "lightHighlight": "rgba(233, 173, 193, 0.86)",
    },
    {
        value: 'orangeBlue',
        main: '#F07300',
        highlight: '#015DB2',
        "backgroundLight": "#EDEDED",
        "backgroundMedium": "#666",
        "fontDark": "#333",
        "fontLight": "#fff",
        "lightHighlight": "rgba(233, 173, 193, 0.86)",
    },
    {
        value: 'yellowPurple',
        main: '#FFB800',
        highlight: '#872FF6',
        "backgroundLight": "#EDEDED",
        "backgroundMedium": "#666",
        "fontDark": "#333",
        "fontLight": "#fff",
        "lightHighlight": "rgba(233, 173, 193, 0.86)",
    },
    {
        value: 'pinkBlue',
        main: '#FF7883',
        highlight: '#439DCF',
        "backgroundLight": "#EDEDED",
        "backgroundMedium": "#666",
        "fontDark": "#333",
        "fontLight": "#fff",
        "lightHighlight": "rgba(233, 173, 193, 0.86)",
    },
    {
        value: 'lilacBlue',
        main: '#BF0073',
        highlight: '#1B4972',
        "backgroundLight": "#EDEDED",
        "backgroundMedium": "#666",
        "fontDark": "#333",
        "fontLight": "#fff",
        "lightHighlight": "rgba(233, 173, 193, 0.86)",
    },
    {
        value: 'purpleLilac',
        main: '#4C1FAC',
        highlight: '#A472FF',
        "backgroundLight": "#EDEDED",
        "backgroundMedium": "#666",
        "fontDark": "#333",
        "fontLight": "#fff",
        "lightHighlight": "rgba(233, 173, 193, 0.86)",
    },
    {
        value: 'blackRed',
        main: '#231F20',
        highlight: '#D00032',
        "backgroundLight": "#EDEDED",
        "backgroundMedium": "#666",
        "fontDark": "#333",
        "fontLight": "#fff",
        "lightHighlight": "rgba(233, 173, 193, 0.86)",
    },
];

export default themeColors;