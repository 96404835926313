import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PrimaryButton from '../components/buttons/primary-button';
import { colorTheme } from '../assets/theme';

function NotFoundPage() {

    const goBack = () => {
        window.history.back();
    }

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Full viewport height
                backgroundColor: 'background.default', // Use theme's default background
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                <Typography variant="h1">
                    Not Found Page
                </Typography>
                <PrimaryButton onClick={goBack} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text={'Go Back'}

                />
            </Box>
        </Box>

    );
}

export default NotFoundPage;
