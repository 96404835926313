import React, { useState } from 'react';
import { Typography, Box } from "@mui/material";
import OTPInput from "../../components/form/totp-input";
import PrimaryButton from "../buttons/primary-button";
import { colorTheme } from "../../assets/theme";
import SecondaryButton from "../buttons/secondary-button";

const MFAComponent = ({ handleVerifyMfa, handleBack, setMfaCode, mfaCode, ArrowRight, loading }) => {

    return (

        <div className='post-confirmation-code-container form-container'>
            <Typography variant="body1" sx={{ lineHeight: '19px' }} className="sign-in-page-description">Please enter the verification code from your authenticator app to continue. </Typography>
            <Box className='form-inputs'>
                <Box >
                    <Typography variant="body1" ml={8.7} mb={2} sx={{ textAlign: 'left' }}>Verification Code</Typography>
                    <OTPInput
                        length={6}
                        onChange={setMfaCode}
                        positon={'center'}
                    />
                </Box>
            </Box>
            <Box className='bottom-buttons'>
                <SecondaryButton
                    onClick={handleBack} color="#FFFFFF" hoverColor="#EDF0F4" text="Back"
                    arrow={<ArrowRight />}
                />

                <PrimaryButton
                    onClick={() => handleVerifyMfa(mfaCode)}
                    color={colorTheme.theme.transform.darkBlueTwo}
                    hoverColor={colorTheme.theme.transform.darkBlue}
                    text="Verify Code"
                    isLoading={loading}
                />
            </Box>
        </div>
    );
};

export default MFAComponent;
