import React from 'react';
import TextField from '@mui/material/TextField';

const CustomNumberTextField = ({
    value,
    onChange,
    name,
    variant = "outlined",
    size = "small",
    error = false,
    inputProps = {},
    InputProps = {},
    disabled = false,
    sx = {}
}) => {
    return (
        <TextField
            value={value}
            onChange={onChange}
            name={name}
            variant={variant}
            size={size}
            type="text"
            error={error}
            inputProps={inputProps}
            InputProps={{
                ...InputProps,
                style: {
                    height: '32px',
                    textAlign: 'center', // Center align the text
                    ...InputProps.style
                }
            }}
            disabled={disabled}
            sx={{
                width: '40px',
                backgroundColor: '#FFFFFF',
                '& .MuiInputLabel-root': {
                    fontStyle: 'normal', // Remove italic style
                    color: '#828282',    // Set label color to #828282
                },
                '& .MuiInputLabel-root.Mui-focused': {
                    color: '#828282',    // Ensure the label color remains #828282 when focused
                },
                '& .MuiInputBase-input': {
                    color: 'black', // Set input text color to black
                    height: '32px',
                    padding: '0px !important', // Adjust padding to fit height requirement
                    boxSizing: 'border-box',
                    fontSize: '15px', // Set input font size
                    textAlign: 'center', // Center align the text
                },
                '& .MuiInputAdornment-root': {
                    marginRight: '0px' // Adjust margin for input adornment
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: '#D7D7D7', // Default border color
                    },
                    '&:hover fieldset': {
                        borderColor: '#D7D7D7', // Border color on hover
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#D7D7D7', // Border color when focused
                    },
                    '&.MuiInputBase-multiline': {
                        padding: '0',        // Adjust padding for multiline input
                    },
                    '&:hover fieldset': {
                        borderColor: '#4F4F4F', // Change the outline color on hover
                        border: '1px solid #4F4F4F', // Make the border 1px solid
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#1B4972', // Change the outline color when focused
                        borderWidth: 1,
                    },
                },
                ...sx
            }}
        />
    );
};

export default CustomNumberTextField;
