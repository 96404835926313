import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';

export const useIpFetch = () => {
    const [ipAddress, setIpAddress] = useState('Loading...');
    const [error, setError] = useState('');
    const location = useLocation();  // Hook from React Router


    useEffect(() => {
        const fetchIp = async () => {
            console.log('Fetching IP address... from useIpFetch.js');
            // Check if the current pathname is '/profile' before fetching
            if (location.pathname === '/profile') {
                try {
                    const response = await axios.get('https://jsonip.com');
                    console.log('IP address response:', response.data);
                    setIpAddress(response.data.ip);
                } catch (error) {
                    console.error('Error fetching IP address:', error);
                    setError('Error fetching IP address');
                    setIpAddress('No IP Available'); // Fallback message
                }
            } else {
                // Optionally reset IP address and error when not on '/profile'
                setIpAddress('Navigate to /profile to see IP address');
                setError('');
            }
        };

        fetchIp();
    }, [location.pathname]); // Add location.pathname as a dependency


    return { ipAddress, error };
};
