import React from 'react';
import { TableCell, TableHead, TableRow, Checkbox } from '@mui/material';
import CustomSelect from './../form/custom-select';
import { colorTheme } from '../../assets/theme';

function TableHeadComponent({ headCells, statusFilter, onStatusFilterChange, roleFilter, onRoleFilterChange, teamRoleOptions }) {
    return (
        <TableHead
            sx={{
                backgroundColor: colorTheme.theme.colors.teamTableHeaderBackground,
                borderRadius: '4px 4px 0 0',
                borderBottom: 'none !important'
            }}
        >
            <TableRow sx={{ height: '56px', '& > *': { padding: '6px 0px' } }}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sx={{
                            border: 'none !important',
                            pl: '28px !important',
                            width: headCell.width,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            boxSizing: 'border-box',
                            maxWidth: headCell.width,
                            minWidth: headCell.width,
                        }}
                    >
                        {headCell.id === 'status' ? (
                            <CustomSelect
                                label="Invitation Status"
                                options={[
                                    { value: '', label: 'All' },
                                    { value: 'Accepted', label: 'Accepted' },
                                    { value: 'Pending', label: 'Pending' }
                                ]}
                                value={statusFilter}
                                onChange={(e) => onStatusFilterChange(e.target.value)}
                            />
                        ) : headCell.id === 'role' ? (
                            <CustomSelect
                                label="Team Role"
                                options={teamRoleOptions}
                                value={roleFilter}
                                onChange={(e) => onRoleFilterChange(e.target.value)}
                            />
                        ) : headCell.label}
                    </TableCell>
                ))}
                <TableCell sx={{ borderTopRightRadius: '4px', border: 'none !important' }} align="right"></TableCell>
            </TableRow>
        </TableHead>
    );
}

export default TableHeadComponent;
