import React from "react";
import { Box } from '@mui/material';
import { ReactComponent as ListsIcon } from '../../../../assets/images/title/lists.svg';
import TitleWithIcon from '../../../../components/title/title-icon';
import RespondentsList from "../../../../components/survey-edit/lists-tab/respondents-list";
import AdditionalLists from "../../../../components/survey-edit/lists-tab/additional-lists";

// ListsContainer component
const ListsContainer = () => {
    return (
        <Box className='settings-container'> 
            <TitleWithIcon title='Lists' Icon={ListsIcon} />  
            <Box mt={5}>
                <RespondentsList />
            </Box>
            <Box mt={8}>
                <AdditionalLists />
            </Box>
        </Box>
    );
}

export default ListsContainer;