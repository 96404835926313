import React, { useState } from 'react';
import './organization-container.scss';
import { Box, Typography } from '@mui/material';
import TitleWithIcon from "../../../components/title/title-icon";
import Breadcrumbs from "./../../../components/breadcrumbs/breadcrumbs";
import { ReactComponent as OrganizationIcon } from "../../../assets/images/title/organization.svg";
import OrganizationLogo from '../../../components/organization/organization-logo';
import OrganizationEdit from '../../../components/organization/organization-edit';
import OrganizationView from '../../../components/organization/organization-view';
import useOrganization from '../../../hooks/organisation/use-organization';

// OrganizationContainer component
const OrganizationContainer = () => {
    const { pathSegments, editMode, onEdit, completeCompanyProfile } = useOrganization(); // Destructure the pathSegments, editMode, and onEdit from the useOrganization hook

    return (
        <Box mt={3}>
            {/* Render the Breadcrumbs component with the defined path segments */}
            <Breadcrumbs pathSegments={pathSegments} />
            <Box mt={2.5}>
                <Box className="organization-container">
                    <TitleWithIcon title="Organization" Icon={OrganizationIcon} />
                    <Box className="organization-info">
                        <OrganizationLogo />
                        {
                            !editMode && <OrganizationView onEditClick={onEdit} />
                        }
                        {
                            editMode && <OrganizationEdit onEditClick={onEdit} />
                        }
                    </Box>
                    {
                        !editMode && !completeCompanyProfile ? <Typography variant="body2" className='organization-complete-info'>Complete Your Company Profile. Click 'Edit' to add more details.</Typography> : null
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default OrganizationContainer;