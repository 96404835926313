import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { ReactComponent as SaveIcon } from "./../../assets/images/actions/icon-save-blue.svg";
import { colorTheme } from '../../assets/theme';
import HeaderDialog from './header-dialog';
import PrimaryButton from '../buttons/primary-button'
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { setSaveDefaultStyleMessage } from "../../redux/actions";
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

// SaveDefaultStyle component for dialog
const SaveDefaultStyle = ({ open, handleClose, handleSaveDefaultStyleDetails, isLoading }) => {
    const dispatch = useDispatch();
    const [checkboxMessage, setCheckboxMessage] = useState(false); // Set the checkbox message to false

    const handleChange = (event) => {
        setCheckboxMessage(event.target.checked);
        dispatch(setSaveDefaultStyleMessage(event.target.checked));
    }

    return (
        <Dialog open={open} onClose={handleClose}
            sx={{ '& .MuiDialog-paper': { width: '550px' } }} // Set the dialog width
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog Icon={SaveIcon} handleClose={handleClose} title={`Apply style changes`} />
            <DialogContent
                sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                <DialogContentText
                    variant='body2'
                    sx={{ color: colorTheme.theme.text.darkGrey, lineHeight: "24px", pt: 2 }}>
                    Style changes will only affect surveys created after these changes are saved. Existing surveys will retain their current style.
                </DialogContentText>
                <Box display="flex" alignItems="center" gap={1} mt={1.5}>
                    <FormControlLabel control={<Checkbox checked={checkboxMessage}
                        onChange={handleChange} sx={{
                            padding: '0px',
                            '&.Mui-checked': {
                                color: colorTheme.theme.transform.darkBlueTwo,
                            },
                        }} />} label={
                            <Typography sx={{ fontSize: '14px', color: colorTheme.theme.text.mediumGrey, marginLeft: '6px' }}>
                                Don’t show this message again
                            </Typography>
                        } sx={{ marginLeft: '0px'}}/>
                </Box>
            </DialogContent>
            <DialogActions sx={{ pt: 2, pb: 2, pr: 2 }}>
                <PrimaryButton onClick={handleClose} color="white" hoverColor="#EDF0F4" text="Cancel" hoverText={colorTheme.theme.transform.darkBlue} />
                <PrimaryButton onClick={handleSaveDefaultStyleDetails} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="Continue" isLoading={isLoading}/>
            </DialogActions>
        </Dialog>
    );
};

export default SaveDefaultStyle;