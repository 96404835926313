import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _, { set } from 'lodash';
import { updateQuestion, updateSurveyEditTime } from "../../redux/actions";
import { SECTION } from '../../assets/constants';

const ACTION_TITLE_EDIT = 'ACTION_TITLE_EDIT';
const ACTION_SUBTITLE_EDIT = 'ACTION_SUBTITLE_EDIT';

// Custom hook to manage question title and subtitle
export const useQuestionTitle = (props) => {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);
    const [questionTitle, setQuestionTitle] = useState(props.question.question_text || '');
    const [questionSubtitle, setQuestionSubtitle] = useState(props.question.question_explanation_string || '');
    const currentTimestamp = new Date().toISOString();

    // Single debounced function to update the question
    const delayedUpdate = useCallback(_.debounce((title, subtitle) => {
        let newQuestion = _.cloneDeep(props.question);
        newQuestion.question_text = title;
        newQuestion.question_explanation_string = subtitle;
        dispatch(updateQuestion(currentSurvey.id, newQuestion));
    }, 500), [dispatch, currentSurvey.id, props.question]);


    // Function to handle changes in title and subtitle
    const handleTitleChange = (value, type) => {
        if (type === ACTION_TITLE_EDIT && value !== questionTitle) {
            setQuestionTitle(value);
            delayedUpdate(value, questionSubtitle);
        } else if (type === ACTION_SUBTITLE_EDIT && value !== questionSubtitle) {
            setQuestionSubtitle(value);
            delayedUpdate(questionTitle, value);

        }

        dispatch(updateSurveyEditTime(currentTimestamp));

    };

    return {
        questionTitle,
        questionSubtitle,
        handleTitleChange,
        ACTION_TITLE_EDIT,
        ACTION_SUBTITLE_EDIT,
        SECTION
    };
};
