import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Radio, RadioGroup, Checkbox, Box } from "@mui/material";
import AdvancedSettings from '../../question-advanced-settings';
import { useTextBoxQuestion } from '../../../../../../hooks/editor/text-box/use-text-box';
import CustomTextField from '../../../../../../components/form/questions-text-field';
import './text-box-question.scss';

// This component is used to render the text box question in the survey editor.
function TextBoxQuestion({ template, advancedSettings, handleAdvancedView }) {
    // text box question hook
    const {
        useMaxCharacters,
        optionChecked,
        customOption,
        handleUseMaxCharactersChange,
        handleRadioChange,
        handleTextFieldChange,
        characterItems,
        CUSTOM_OPTION
    } = useTextBoxQuestion(template);

    return (
        <div className='text-box-question-container'>
            <FormControlLabel
                control={
                    // Checkbox for limiting the number of characters for the answer
                    <Checkbox
                        checked={useMaxCharacters}
                        onChange={handleUseMaxCharactersChange}
                        name='use-max-characters'
                        sx={{
                            paddingLeft: '9px',
                            paddingRight: '8px',
                            paddingTop: '0px !important',
                            paddingBottom: '0px !important',
                            color: '#D7D7D7', // Default color
                            '&.Mui-checked': {
                                color: '#1c4972', // Color when checked
                            },
                            '&:hover': {
                                backgroundColor: 'transparent', // Remove hover effect
                            },
                        }}
                    />
                }
                label="Limit number of characters for answer"
            />
            <Box mb={3} >
                <FormControl component="fieldset" className='options' disabled={!useMaxCharacters}>
                    {/*  Radio group for selecting the number of characters */}
                    <RadioGroup name="text-select" value={optionChecked} onChange={e => handleRadioChange(e.target.value)}
                        sx={{
                            '& .MuiRadio-root': {
                                color: '#D7D7D7', // Default color
                                '&.Mui-checked': {
                                    color: '#1c4972', // Color when checked
                                },
                                '&:hover': {
                                    backgroundColor: 'transparent', // Remove hover effect
                                },
                            },
                        }}
                        row={true}>
                        {
                            characterItems.map((item, index) => {
                                return (
                                    <FormControlLabel key={index} value={item.text} control={<Radio color='primary' />}
                                        label={item.text} />
                                )
                            })
                        }
                        {/* Custom number of characters */}
                        <FormControlLabel
                            className={!useMaxCharacters ? 'disabled' : ''}
                            value={CUSTOM_OPTION}
                            control={<Radio color='primary' />}
                            label={
                                <CustomTextField
                                    placeholder='Enter custom number of characters'
                                    value={customOption}
                                    onChange={e => handleTextFieldChange(e.target.value)}
                                    onClick={() => handleRadioChange(CUSTOM_OPTION)}
                                    width='277px'
                                    type="number"
                                />

                            }
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            {/*  Advanced settings */}
            {advancedSettings && <AdvancedSettings setView={handleAdvancedView} template={template} />}
        </div>
    );
}

export default TextBoxQuestion;
