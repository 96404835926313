import React, { useState } from "react";
import './survey-menu-action-sort.scss';
import { Box } from '@mui/material';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/survey-dashboard/arrow-up-icon.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/survey-dashboard/arrow-down-icon.svg';
import { ReactComponent as SortIcon } from '../../assets/images/survey-dashboard/sort-icon.svg';
import { ReactComponent as SelectedIcon } from '../../assets/images/survey-dashboard/selected-icon.svg';
import { colorTheme } from "../../assets/theme";

// Constants to store the survey table columns for sorting
const surveyTableColumns = [
    { title: 'Alphabetical', field: 'name' },
    { title: 'Date Created', field: 'started' },
    { title: 'Last Edited', field: 'lastEditedTime' },
];

// Custom styles for the component
const useStyles = makeStyles(() => ({
    paper: {
        marginTop: '10px',
        boxShadow: '0px 1px 2.5px 0px #7B7D8982',
        '& .MuiList-padding': {
            padding: '5px 0px'
        },
    },
    root: {
        minWidth: '20px',
    },
    primary: {
        color: colorTheme.theme.text.lightGrey,
    },
    selected: {
        color: colorTheme.theme.transform.darkBlueTwo,
    },
    menuItem: {
        padding: '3px 30px 3px 12px',
        '&:hover': {
            backgroundColor: colorTheme.theme.app.lightestGrey,
        },
    }
}));

// SurveyMenuActionSort component
const SurveyMenuActionSort = ({ selectedSort, handleSortChange, sortType }) => {
    const [anchorEl, setAnchorEl] = useState(null); // State to store the anchor element of the menu
    const classes = useStyles(); // Get the custom styles for the component

    // Function to handle the click event of the menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Function to handle the close event of the menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box className="sort">
            <IconButton
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClick}
                className="sort-button"
            >
                Sort by
                <SortIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                classes={{ paper: classes.paper }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {
                    surveyTableColumns.map((col, index) => {
                        return (
                            <MenuItem key={index} onClick={() => { handleSortChange(col.field, sortType); handleClose(); }} classes={{ root: classes.menuItem }}>
                                <ListItemIcon classes={{ root: classes.root }}>
                                    {
                                        selectedSort === col.field &&
                                        <SelectedIcon />
                                    }
                                </ListItemIcon>
                                <ListItemText
                                    primary={col.title}
                                    classes={{ primary: selectedSort === col.field ? `${classes.primary} ${classes.selected}` : classes.primary }} />
                            </MenuItem>
                        );
                    })
                }
            </Menu>
            {
                sortType === 'asc' &&
                <IconButton className="sort-icon" onClick={() => { handleSortChange(selectedSort, 'desc') }} >
                    <ArrowUpIcon />
                </IconButton>
            }
            {
                sortType === 'desc' &&
                <IconButton className="sort-icon" onClick={() => { handleSortChange(selectedSort, 'asc') }} >
                    <ArrowDownIcon />
                </IconButton>
            }
        </Box>
    )
};

export default SurveyMenuActionSort;