import { useEffect } from "react";
import { useSnackbar } from 'notistack';
import { RESPONDENTS_FILE, ROW_ID } from "../../assets/constants";
import { v4 as uuidv4 } from 'uuid';
import XLSX from "xlsx";

const MAX_FILE_SIZE = 10; // in MB
const MAX_ROWS = 10000;
const MAX_COLUMNS = 100; 

// useUploadList hook
export const useUploadList = (props) => {
    const { enqueueSnackbar } = useSnackbar(); // Snackbar notification

    useEffect(() => {}, []); 

    // Function to open snackbar
    const openSnackbar = (msg) => {
        enqueueSnackbar(msg, {
            variant: 'error',
        });
    };

    // Function to handle file upload
    const onFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.size > MAX_FILE_SIZE * 1000000) {
            openSnackbar('Maximum file size exceeded, max file size is ' + MAX_FILE_SIZE + ' MB');
            return;
        }
        if (file) {
            let reader = new FileReader();
            try {
                reader.onload = function (e) {
                    let data = e.target.result;
                    const dataParse = fileToJson(data);
                    const { isValid, validationMessage } = props.type === RESPONDENTS_FILE
                        ? validateRespondentsFile(dataParse)
                        : validateCustomListFile(dataParse);

                    if (isValid) {
                        const transformedData = fileArraysToObjects(dataParse);
                        if(props.type === RESPONDENTS_FILE) {
                            props.handleUploadedData(transformedData, null, false);
                        }else{
                            props.handleUploadedData(transformedData, null, null, false, null);
                        }
                    } else {
                        openSnackbar(validationMessage);
                    }
                };
            } catch (e) {
                openSnackbar('Error with file upload');
            }
            reader.readAsBinaryString(file);
        }
    };

    // Function to convert file to JSON
    const fileToJson = (data) => {
        try {
            let readData = XLSX.read(data, { type: 'binary' });
            const respondentsData = readData.Sheets[readData.SheetNames[0]];
            return XLSX.utils.sheet_to_json(respondentsData, { header: 1 });
        } catch (e) {
            openSnackbar('Error when reading xlsx file');
        }
    };

    // Function to convert file arrays to objects
    const fileArraysToObjects = (data) => {
        const header = data[0];
        const dataRows = data.slice(1);
        return {
            columns: header.map(col => ({ title: col, field: col })),
            data: dataRows.map(r => {
                let dataObj = { [ROW_ID]: uuidv4() };
                header.forEach((col, ix) => dataObj[col] = r[ix]);
                return dataObj;
            })
        };
    };

    // Function to check duplicates
    const checkDuplicates = (data) => {
        const lowerCased = data.map(item => item.toString().toLowerCase());
        const set = new Set(lowerCased);
        return lowerCased.length !== set.size;
    };

    // Function to validate respondents file
    const validateRespondentsFile = (data) => {
        let isValid = false;
        let validationMessage = '';
        const columnNames = data[0];
        if (!columnNames) {
            validationMessage = 'Header missing in file!';
        } else if (checkDuplicates(columnNames)) {
            validationMessage = 'Respondent list should not have 2 columns with same name. Please check columns names before you try to upload';
        } else if (data.length <= 1) {
            validationMessage = 'File should contain at least one row of data!';
        } else if (data.length > MAX_ROWS) {
            validationMessage = 'File should contain at most ' + MAX_ROWS + ' rows of data!';
        } else if (columnNames.length > MAX_COLUMNS) {
            validationMessage = 'File should contain at most ' + MAX_COLUMNS + ' columns!';
        } else if (!columnNames.map(col => col.toLowerCase()).includes('email')) {
            validationMessage = 'Email column missing in file!';
        } else if (!columnNames.map(col => col.toLowerCase()).includes('name')) {
            validationMessage = 'Name column missing in file!';
        } else {
            isValid = true;
        }
        return { isValid, validationMessage };
    };

    // Function to validate custom list file
    const validateCustomListFile = (data) => {
        let isValid = false;
        let validationMessage = '';
        const columnNames = data[0];
        if (!columnNames) {
            validationMessage = 'Header missing in file!';
        } else if (checkDuplicates(columnNames)) {
            validationMessage = 'Custom list should not have 2 columns with same name. Please check columns names before you try to upload';
        } else {
            isValid = true;
        }
        return { isValid, validationMessage };
    };

    // Function to handle delete
    const handleDelete = (listId) => {
        props.handleDelete(listId);
    };

    // Return functions
    return {
        onFileUpload,
        handleDelete,
        openSnackbar
    };
};
