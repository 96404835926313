// useTeammates.js
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";


// Custom hook for managing the state and behavior related to teammates
const useMyTeammates = () => {
    // State for whether the list of teammates is empty, the number of teammates, and the first and second user
    const [isEmpty, setIsEmpty] = useState(true);
    const [numberOfTeammates, setNumberOfTeammates] = useState(0);
    const [firstUser, setFirstUser] = useState(null);
    const [secondUser, setSecondUser] = useState(null);

    // Get the list of cognito users from the redux store
    const cognitoUsers = useSelector(state => state.cognitoUsers);


    // Effect to update state when cognitoUsers changes
    useEffect(() => {
        if (cognitoUsers) {
            console.log('MY TEAMMATES:', cognitoUsers);
            setIsEmpty(false);
            setNumberOfTeammates(cognitoUsers.length);
            setFirstUser(cognitoUsers[0]);
            setSecondUser(cognitoUsers[1]);
        }
        console.log('MY TEAMMATES:', cognitoUsers);
    }, [cognitoUsers]);

    // Return the state and attribute objects for use in the component
    return { isEmpty, numberOfTeammates, firstUser, secondUser };
};

export default useMyTeammates;