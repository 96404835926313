import React from "react";
import './dashboard-container.scss';
import Typography from "@material-ui/core/Typography";
import AppCard from "../../components/app-card/app-card";
import ResourcesCard from "../../components/resources-card/resources-card";
import MyTeammates from "../../components/my-teammates/my-teammates";
import ProtectedComponent from "../../helper/protected-component";
import { ReactComponent as DashboardIcon } from "../../assets/images/dashboard/home-page-icon.svg";
import { ReactComponent as SurveyLogo } from "../../assets/images/dashboard/survey-logo.svg";
import { ReactComponent as InsightsLogo } from "../../assets/images/dashboard/insights-logo.svg";
import { Auth } from 'aws-amplify'; // Import AWS Amplify for JWT token generation
import Cookies from 'js-cookie'; // Import js-cookie for setting cookies

const DashboardContainer = () => {

    // Function to handle JWT token and redirect for Insights
    const handleInsightsClick = async () => {
        console.log("Redirecting to Insights...");
        try {
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            // Only for local setup, comment or remove domain since cookies won't be available due to the way localhost works
            Cookies.set('idTokenProd', jwtToken, {
                secure: true,
                sameSite: 'None',
                domain: '.transforminsight.com',
                path: '/',
            });

            const user = await Auth.currentAuthenticatedUser();
            const customerName = user.attributes['custom:customer'];
            const domain = process.env.REACT_APP_INSIGHTS_DOMAIN_URL;
            const redirectUrl = domain.replace('*', customerName);

            // Redirect to the Insights app
            window.location.href = redirectUrl;
        } catch (error) {
            console.error("Error fetching JWT token:", error);
        }
    };



    // Function to handle Survey card click
    const handleSurveyClick = () => {
        window.location.href = '/survey'; // Redirect to the Survey page
    };

    return (
        <div className="dashboard-container">
            <div className="dashboard-header">
                <div>
                    <DashboardIcon />
                </div>
                <Typography variant="h2">Transform Overview</Typography>
            </div>
            <div className="dashboard-cards">
                <ProtectedComponent licenses={['surveyLicense']}>
                    <AppCard
                        title="Survey"
                        icon={<SurveyLogo />}
                        description="Create, publish and analyze surveys to gather valuable information."
                        onClick={handleSurveyClick} // Ensure the Survey card has the click handler
                    />
                </ProtectedComponent>

                <AppCard
                    title="Insights"
                    icon={<InsightsLogo />}
                    description="Insights derived from the surveys and additional digital data sources."
                    onClick={handleInsightsClick} // Pass the Insights click handler
                />

                <ResourcesCard />
                <ProtectedComponent licenses={[]}>
                    <MyTeammates />
                </ProtectedComponent>
            </div>
        </div>
    );
}

export default DashboardContainer;
