import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { updateQuestion } from "../../../redux/actions";

const isStringDigits = (value) => /^\d+$/.test(value);

const useSearchChooseAdvancedSettings = (question) => {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);

    const initialSwitches = {
        text_in_button_switch: question.text_in_button_switch ?? false,
        text_in_response_switch: question.text_in_response_switch ?? false
    };

    const initialTextFields = {
        item_add_text: question.item_add_text || '',
        response_text_field: question.response_text_field || '',
        max_number_added_answers: question.max_number_added_answers?.toString() || 3
    };

    const initialInputOption = {
        items_min_number: question.items_min_number?.toString() || '1',
        items_max_number: question.items_max_number?.toString() || '3'
    };

    const initialCheckBox = question.required_fields?.reduce((acc, field) => {
        acc[field] = true;
        return acc;
    }, {}) || {};

    const [selectedColumn, setSelectedColumn] = useState(question.item_fields || []);
    const [checkBox, setCheckBox] = useState(initialCheckBox);
    const [allowUserAnswerSwitch, setAllowUserAnswerSwitch] = useState(!!question.item_other_allowed);
    const [maxNumSwitch, setMaxNumSwitch] = useState(!!question.max_number_added_answers);
    const [numberOfAllowedAnswers, setNumberOfAllowedAnswers] = useState(question.max_number_added_answers || 3);
    const [switches, setSwitches] = useState(initialSwitches);
    const [validationMinCheck, setValidationMinCheck] = useState(!!question.validation_min);
    const [validationMaxCheck, setValidationMaxCheck] = useState(!!question.validation_max);
    const [validationOptionChecked, setValidationOptionChecked] = useState(!!question.min_max);
    const [inputOption, setInputOption] = useState(initialInputOption);
    const [textFields, setTextFields] = useState(initialTextFields);
    const [fieldsDisabled, setFieldsDisabled] = useState(!question.item_other_allowed);
    const [fieldsDisabledMinMax, setFieldsDisabledMinMax] = useState(!question.min_max);
    const [items_max, setItemsMax] = useState(question.items_max_number || 3);
    const [items_min, setItemsMin] = useState(question.items_min_number || 1);

    const delayedUpdate = useCallback(_.debounce((newQuestion) => {
        dispatch(updateQuestion(currentSurvey.id, newQuestion));
    }, 500), [dispatch, currentSurvey.id]);

    const updateSearchChooseQuestionAdvancedSettings = (value) => {
        let newQuestion = _.cloneDeep(question);
        const newObject = Object.assign(newQuestion, value);
        delayedUpdate(newObject);
    };

    const handleSwitches = ({ name, checked }) => {
        setSwitches(prevState => ({
            ...prevState,
            [name]: checked
        }));
        updateSearchChooseQuestionAdvancedSettings({ [name]: checked });
    };

    const handleTextFields = ({ name, value }) => {
        if (name === 'max_number_added_answers' && validationOptionChecked && Number(value) > inputOption.items_max_number) {
            value = inputOption.items_max_number.toString(); // Limit value to items_max_number
        }
        setTextFields(prevState => ({
            ...prevState,
            [name]: value
        }));
        updateSearchChooseQuestionAdvancedSettings({ [name]: value });
    };

    const handleAnswersSwitch = (checked) => {
        let updateObject = checked ? { item_other_allowed: true, max_number_added_answers: Number(numberOfAllowedAnswers) } : { item_other_allowed: false, max_number_added_answers: undefined };
        updateSearchChooseQuestionAdvancedSettings(updateObject);
        setAllowUserAnswerSwitch(checked);
        setFieldsDisabled(!checked);
    };

    const handleCheckbox = ({ checked, name }) => {
        let temp = { ...checkBox };
        temp[name] = checked;

        setCheckBox(temp);

        // Always include the first checkbox in required fields
        const firstCheckboxKey = Object.keys(temp)[0];
        temp[firstCheckboxKey] = true;

        let updateObject = _.pickBy(temp, (value) => value);
        updateSearchChooseQuestionAdvancedSettings({ required_fields: Object.keys(updateObject) });
    };


    const handleMinValidationCheck = (checked) => {
        const minNumber = isStringDigits(inputOption.items_min_number) ? Number(inputOption.items_min_number) : 1;

        setValidationMinCheck(checked);
        let updateObject = { 'items_min_number': minNumber, 'validation_min': checked, 'validation_max': validationMaxCheck, 'items_max_number': items_max };
        setInputOption(prevState => ({
            ...prevState, items_min_number: minNumber
        }));
        setItemsMin(minNumber);

        updateSearchChooseQuestionAdvancedSettings(updateObject);
    };

    const handleMaxValidationCheck = (checked) => {
        const maxNumber = isStringDigits(inputOption.items_max_number) ? Number(inputOption.items_max_number) : 3;

        setValidationMaxCheck(checked);
        let updateObject = { 'items_max_number': maxNumber, 'validation_max': checked, 'validation_min': validationMinCheck, 'items_min_number': items_min };
        setInputOption(prevState => ({
            ...prevState, items_max_number: maxNumber
        }));

        setItemsMax(maxNumber);
        updateSearchChooseQuestionAdvancedSettings(updateObject);
    };

    const handleTextInput = (evt) => {
        const { name, value } = evt.target;
        setInputOption(prevState => ({
            ...prevState, [name]: value
        }));
        if (isStringDigits(value)) {
            updateSearchChooseQuestionAdvancedSettings({ [name]: Number(value) });
        }
    };

    return {
        selectedColumn,
        checkBox,
        allowUserAnswerSwitch,
        maxNumSwitch,
        numberOfAllowedAnswers,
        switches,
        validationOptionChecked,
        inputOption,
        textFields,
        fieldsDisabled,
        fieldsDisabledMinMax,
        setAllowUserAnswerSwitch,
        setFieldsDisabled,
        handleSwitches,
        handleTextFields,
        handleAnswersSwitch,
        handleCheckbox,
        handleTextInput,
        isStringDigits,
        handleMinValidationCheck,
        handleMaxValidationCheck,
        validationMinCheck,
        validationMaxCheck,
    };
};

export default useSearchChooseAdvancedSettings;
