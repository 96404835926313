import React from "react";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import TeamTable from './../../../components/team-table/team-table';
import useTeam from "./../../../hooks/team-survey/use-team";
import { Box } from '@mui/material';
import './../../settings-page/teammates-tab/teammates-container.scss';

// DefaultStyleContainer component to render the default style tab
const TeamContainer = () => {

    const { teamData, handleDelete, handleEdit, handleAddUser, pathSegments, setInsightsLicense, headCells,
        setSurveyLicense, setRole, teamRoleOptions, licenseColors, borderColors, textColors } = useTeam();


    return (
        <Box mt={3} className="teammates-container">
            {/* Render the Breadcrumbs component with the defined path segments */}
            <Breadcrumbs pathSegments={pathSegments} />
            <Box mt={2.5}>
            </Box>

            {/* Render the TeamTable component with the defined rows, headers, and handlers */}
            <TeamTable title="Team" rows={teamData} headCells={headCells} handleDelete={handleDelete} handleEdit={handleEdit} handleAddUser={handleAddUser}
                setInsightsLicense={setInsightsLicense} setSurveyLicense={setSurveyLicense} setRole={setRole} colSpan={8} width={'100%'}
                teamRoleOptions={teamRoleOptions} licenseColors={licenseColors} borderColors={borderColors} textColors={textColors} isTeammates={false}
            />
        </Box>
    );
}

export default TeamContainer;