import React from 'react';
import { ReactComponent as TextBoxIcon } from "../../src/assets/images/editor/single-text-box.svg";
import { ReactComponent as SingleLineTextIcon } from "../../src/assets/images/editor/single-line-text.svg";
import { ReactComponent as SingleSelectIcon } from "../../src/assets/images/editor/select-one.svg";
import { ReactComponent as MultiSelectIcon } from "../../src/assets/images/editor/select-many.svg";
import { ReactComponent as MultiSelectImageIcon } from "../../src/assets/images/editor/select-many-images.svg";
import { ReactComponent as DropDownMenuIcon } from "../../src/assets/images/editor/dro-down-menu.svg";
import { ReactComponent as SingleSelectImageIcon } from "../../src/assets/images/editor/select-one-image.svg";
import { ReactComponent as SingleSliderIcon } from "../../src/assets/images/editor/single-slider.svg";
import { ReactComponent as MultiSliderIcon } from "../../src/assets/images/editor/multi-slider.svg";
// import { ReactComponent as DivaderIcon } from "../../src/assets/images/editor/divader.svg";
import { ReactComponent as SectionIcon } from "../../src/assets/images/editor/section.svg";

const questionTypes = [
    [
        {
            label: 'Text',
            questions: [
                {
                    value: 'text_box',
                    label: 'Text Box',
                    icon: <TextBoxIcon className='icon' />
                },
                {
                    value: 'single_line_text',
                    label: 'Single Line Text',
                    icon: <SingleLineTextIcon className='icon' />
                },
            ]
        },
        {
            label: 'Sliders',
            questions: [
                {
                    value: 'single_slider_single_picker',
                    label: 'Single Slider',
                    icon: <SingleSliderIcon className='icon' />
                },
                {
                    value: 'multi_slider_single_picker',
                    label: 'Multi Slider',
                    icon: <MultiSliderIcon className='icon' />
                },
            ]
        },
    ],
    [
        {
            label: 'Single Choice',
            questions: [
                {
                    value: 'single_select',
                    label: 'Select One',
                    style_type: 'text',
                    icon: <SingleSelectIcon className='icon' />
                },
                {
                    value: 'single_select',
                    label: 'Select One Image',
                    style_type: 'image',
                    icon: <SingleSelectImageIcon className='icon' />
                },
            ]
        },
        {
            label: 'Multiple Choice',
            questions: [
                {
                    value: 'multi_select',
                    label: 'Select Many',
                    style_type: 'text',
                    icon: <MultiSelectIcon className='icon' />
                },
                {
                    value: 'multi_select',
                    label: 'Select Many Images',
                    style_type: 'image',
                    icon: <MultiSelectImageIcon className='icon' />
                },
                {
                    value: 'search_choose',
                    label: 'Drop-down Menu',
                    icon: <DropDownMenuIcon className='icon' />
                },
            ]
        },
    ],
    [
        {
            label: 'Other Elements',
            questions: [
                {
                    value: 'section',
                    label: 'Section',
                    icon: <SectionIcon className='icon' />
                },
                // {
                //     value: 'divider',
                //     label: 'Divider',
                //     icon: <DivaderIcon className='icon' />
                // }
            ]
        },
    ],
];

export default questionTypes;
