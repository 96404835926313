import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { updateQuestion } from "../../../redux/actions";

const MULTI_SLIDER_FLAG = 'multi-slider';

const useSingleSliderAdvancedSettings = (props) => {
    const question = props.template;
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);
    const [defaultValue, setDefaultValue] = useState(question.item_default_value || '1');
    const [sliderText, setSliderText] = useState({
        item_min_text: question.item_min_text || '',
        item_max_text: question.item_max_text || ''
    });
    const [multiSelectDefault, setMultiSelectDefault] = useState(question.items_default_value || '1');
    const [switchText, setSwitchText] = useState(question.description_switch || false);
    const [enableDefaultPosition, setEnableDefaultPosition] = useState(question.enable_default_position || false);


    const delayedUpdate = useCallback(_.debounce(question =>
        dispatch(updateQuestion(currentSurvey.id, question)), 300),
        [dispatch, currentSurvey.id]);

    const defaultSliderPosValidation = () => {
        let value;
        if (props.from === MULTI_SLIDER_FLAG) {
            value = question.items_default_value;
            return (value < question.items_min || value > question.items_max) || value % question.items_increment > 0;
        } else {
            value = question.item_default_value;
            return (value < question.item_min || value > question.item_max) || value % question.item_increment > 0;
        }
    };

    const updateSingleSliderAdvancedQuestion = (value) => {
        const newQuestion = {
            ...question,
            ...value
        };
        delayedUpdate(newQuestion);
    };

    const handleSliderText = (event) => {
        const { name, value } = event.target;
        setSliderText(prevState => ({
            ...prevState,
            [name]: value
        }));
        updateSingleSliderAdvancedQuestion({ [name]: value });
    };

    const handleTextSwitch = (checked) => {
        let updateObject;
        if (props.from !== MULTI_SLIDER_FLAG) {
            updateObject = checked ? { description_switch: true, item_min_text: sliderText.item_min_text, item_max_text: sliderText.item_max_text } : { description_switch: false, item_min_text: '', item_max_text: '' };

        } else {
            updateObject = checked ? { description_switch: true, items_min_text: sliderText.item_min_text, items_max_text: sliderText.item_max_text } : { description_switch: false, items_min_text: '', items_max_text: '' };

        }
        updateSingleSliderAdvancedQuestion(updateObject);
        setSwitchText(checked);
    };

    const handleDefaultValue = (value) => {
        if (props.from === MULTI_SLIDER_FLAG) {
            setMultiSelectDefault(Number(value));
            updateSingleSliderAdvancedQuestion({ items_default_value: Number(value) });
        } else {
            setDefaultValue(Number(value));
            updateSingleSliderAdvancedQuestion({ item_default_value: Number(value) });
        }
    };

    const displayDefaultValue = () => {
        return (props.from === MULTI_SLIDER_FLAG) ? multiSelectDefault : defaultValue;
    };

    const handleEnableDefaultPosition = (checked) => {
        setEnableDefaultPosition(checked);
        updateSingleSliderAdvancedQuestion({ enable_default_position: checked });
    };

    return {
        defaultValue,
        multiSelectDefault,
        sliderText,
        switchText,
        enableDefaultPosition,
        displayDefaultValue,
        handleSliderText,
        handleTextSwitch,
        handleDefaultValue,
        handleEnableDefaultPosition,
        defaultSliderPosValidation,
        MULTI_SLIDER_FLAG
    };
};

export default useSingleSliderAdvancedSettings;
