import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Box } from '@mui/material';
import { ReactComponent as MoreIcon } from "../../assets/images/survey-dashboard/more-icon.svg";
import IconButton from '@material-ui/core/IconButton';
import SurveyStatus from '../../assets/survey-status';
import { convertToLocalTime, formatDate } from '../../utils/time';
import * as moment from 'moment';
import { colorTheme } from '../../assets/theme';
import { useSelector } from 'react-redux';
import styled from '@mui/material/styles/styled';

const StyledIconButton = styled(IconButton)({
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
    '& svg path': {
        transition: 'fill 0.3s ease',
    },
    '&:hover': {
        backgroundColor: colorTheme.theme.app.lightestGrey,
        'svg path': {
            fill: colorTheme.theme.transform.darkBlue,
        }
    },
});

// TableRow component
function SurveyTableRowComponent({ row, headCells, handleMenuClick }) {
    const cognitoUsers = useSelector(state => state.cognitoUsers); // Get the cognito users from the redux store

    // Get moment from now
    const getMoment = (isoString) => {
        if (!isoString) {
            return 'Invalid date';
        }
        const date = moment(isoString);
        return date.isValid() ? date.fromNow() : 'Invalid date';
    };

    // Find and return Give name and surname of the user
    const findUserInCognitoUsers = (id) => {
        if (cognitoUsers) {
            let user = cognitoUsers.find(user => user.Username === id);
            if (user) {
                const givenNameAttr = user.Attributes.find(attr => attr.Name === 'given_name');
                const familyNameAttr = user.Attributes.find(attr => attr.Name === 'family_name');

                const givenName = givenNameAttr ? givenNameAttr.Value : '';
                const familyName = familyNameAttr ? familyNameAttr.Value : '';

                if (givenName || familyName) {
                    return `${givenName} ${familyName}`.trim();
                } else {
                    return 'User has not set a name';
                }
            }
        }
        return 'Not found';
    }

    return (
        // Render a table row
        <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.id}
            sx={{
                height: '56px',
                '& > *': {
                    pt: '0 !important', // Set the padding top to 0
                    pb: '0 !important', // Set the padding bottom to 0
                    pl: '0px',          // Set the padding left to 0
                    pr: '0px',         // Set the padding right to 16px
                },
            }}
        >
            {headCells.map((headCell) => (
                <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    sx={{
                        pl: '7px !important',
                        color: colorTheme.theme.text.darkGrey,
                        width: headCell.width,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        boxSizing: 'border-box',
                        maxWidth: headCell.width,
                        minWidth: headCell.width,
                        ...(headCell.id === 'name' && {
                            pl: '24px !important',
                        }),
                    }}
                >
                    {headCell.id === 'status' ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <SurveyStatus status={row.status} class="status-icon" opacity={true} />
                        </Box>
                    ) :
                        headCell.id === 'created' ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', color: colorTheme.theme.text.mediumGrey }}>
                                <>{getMoment(row.started)}<br /> <span style={{ fontSize: '13px', color: colorTheme.theme.text.lightGrey }}>By: {findUserInCognitoUsers(row.createdBy)}</span></>
                            </Box>
                        ) : headCell.id === 'edited' ? (
                            // Handle status by rendering the status label
                            <Box sx={{ color: colorTheme.theme.text.mediumGrey }}>
                                <>{getMoment(row.lastEditedTime)}<br /> <span style={{ fontSize: '13px', color: colorTheme.theme.text.lightGrey }}>By: {findUserInCognitoUsers(row.editedBy)}</span></>
                            </Box>
                        ) : headCell.id === 'published' ? (
                            // Handle license(s) by rendering LicenceButton for each license
                            <Box sx={{ display: 'flex', flexDirection: 'column', color: colorTheme.theme.text.mediumGrey }}>
                                {
                                    row.status === 'DRAFT' ? 'Not published' :
                                        row.status === 'SCHEDULED' ? <>{formatDate(row.startDate, true)} <br /> <span style={{ fontSize: '13px', color: colorTheme.theme.text.lightGrey }}>By: {findUserInCognitoUsers(row.publishedBy)}</span></> :
                                            (row.status === 'COMPLETED' || row.status === 'LIVE') ? <>{getMoment(row.publishedTime)} <br /> <span style={{ fontSize: '13px', color: colorTheme.theme.text.lightGrey }}>By: {findUserInCognitoUsers(row.publishedBy)}</span></> : null
                                }
                            </Box>
                        ) : (
                            row[headCell.id]
                        )}
                </TableCell>
            ))}

            <TableCell
                sx={{ pr: '16px' }}
                align="right"
            >
                <StyledIconButton style={{ width: '28px', height: '28px' }} className="options-icon" onClick={e => handleMenuClick(e, row.id)}>
                    <MoreIcon aria-controls="customized-menu" aria-haspopup="true" />
                </StyledIconButton>
            </TableCell>
        </TableRow>
    );
}

SurveyTableRowComponent.propTypes = {
    row: PropTypes.object.isRequired,
};

export default SurveyTableRowComponent;