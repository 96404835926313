import React from 'react';
import './create-new-survey.scss';
import { Box, DialogContent, Dialog, Typography } from '@mui/material';
import Fab from '@mui/material/Fab';
import HeaderDialog from './header-dialog';
import { createEmptySurvey } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BlankSurveyIcon } from '../../assets/images/survey-dashboard/blank-survey-icon.svg';
import { ReactComponent as TemplateSurveyIcon } from '../../assets/images/survey-dashboard/template-survey-icon.svg';
import { lastActivity } from '../../utils/last-activity';

// Constants to store the action types
const ACTION_START_SURVEY_BLANK = 'ACTION_START_SURVEY_BLANK';
const ACTION_START_SURVEY_TEMPLATE = 'ACTION_START_SURVEY_TEMPLATE';

// CreateNewSurveyDialog component
const CreateNewSurveyDialog = ({ open, handleClose, setOpenModal }) => {
    const history = useHistory(); // History hook to navigate
    const dispatch = useDispatch(); // Dispatch hook to dispatch actions
    const cognitoUsers = useSelector(state => state.cognitoUsers); // Get the cognito users from the redux store
    const userData = useSelector(state => state.userData); // Get the user data from the redux store
    const userTemplate = useSelector(state => state.userTemplate); // Get the user template from the redux store
    const userTemplateID = userTemplate.id; // Get the user template ID from the user template
    const organizationDefaultStyleData = useSelector(state => state.organizationDefaultStyleData); // Get the organization default style data from the redux store
    const organizationData = useSelector(state => state.organizationData); // Get the organization data from the state 

    // Function to handle the action click
    const handleActionClick = (action) => {
        if (action === ACTION_START_SURVEY_BLANK && userTemplateID) {
            lastActivity(userData.email, cognitoUsers, dispatch);
            console.log('Creating empty survey with user template ID:', userTemplateID);
            dispatch(createEmptySurvey(organizationDefaultStyleData, organizationData.companyName));
            history.push(`/survey/edit`);
        }
        if (action === ACTION_START_SURVEY_TEMPLATE) {
            lastActivity(userData.email, cognitoUsers, dispatch);
            setOpenModal();
            handleClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className='create-new-survey-dialog'
        >
            <HeaderDialog handleClose={handleClose} title={'Create new survey'} />
            <DialogContent>
                <Box className="create-new-survey-dialog-types">
                    <CreateNewSurveyType Icon={BlankSurveyIcon} title={'BLANK'} description={'Build your own survey starting from a blank sheet.'} onClick={() => handleActionClick(ACTION_START_SURVEY_BLANK)} />
                    <CreateNewSurveyType Icon={TemplateSurveyIcon} title={'TEMPLATE'} description={'Select a pre-designed layout to quickly customize your survey.'} onClick={() => handleActionClick(ACTION_START_SURVEY_TEMPLATE)} />
                </Box>
            </DialogContent>
        </Dialog>
    )
};

// CreateNewSurveyType component
const CreateNewSurveyType = ({ Icon, title, description, onClick }) => {
    return (
        <Fab className="create-new-survey-dialog-type" onClick={onClick}>
            <Icon />
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body2">{description}</Typography>
        </Fab>
    )
}

export default CreateNewSurveyDialog;