import React, { useRef, useState } from "react";
import { ReactComponent as UploadIcon } from '../../assets/images/actions/upload.svg';
import { useUploadList } from '../../hooks/survey/use-upload-list';
import { Box } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import { RESPONDENTS_FILE } from "../../assets/constants";
import AddButton from "../buttons/add-button";
import UploadedNewRespondentsList from "../dialogs/uploaded-new-respondents-list";

// UploadList component
function UploadList(props) {

    const {
        onFileUpload,
    } = useUploadList(props); // Get file upload and delete functions from custom hook

    const [open, setOpen] = useState(false); // State to control dialog open/close
    const [file, setFile] = useState(null); // State to store file
    const uploadId = 'upload-input-' + props.id; // Generate unique upload id
    const inputRef = useRef(); // Create a ref for the input element

    // Function to handle file upload
    const handleFileUpload = (e) => {
        if (e.target.files.length && props.type === RESPONDENTS_FILE && props.listId != null) {
            setOpen(true);
            setFile(e);
        }else{
            onFileUpload(e);
        }
    };

    // Function to confirm file upload
    const handleConfirm = () => {
        setOpen(false);
        onFileUpload(file);
    }

    // Function to close the dialog
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Box>
            <UploadedNewRespondentsList open={open} handleClose={handleClose} handleConfirm={handleConfirm} text={"Uploading a new list will replace the current list and all associated data will be lost. Please confirm that you want to proceed with uploading a new list."} />
            <input
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleFileUpload}
                onClick={(e) => e.target.value = ''}
                className='upload-input'
                id={uploadId}
                ref={inputRef}
                hidden={true}
            />
            <AddButton
                Icon={UploadIcon} text={props.text} onClick={() => inputRef.current.click()} hoverColor={colorTheme.theme.transform.darkBlue}
            />
        </Box>
    );
}

export default UploadList;
