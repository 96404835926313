import React from 'react';
import { Box } from '@mui/material';
import CustomNumberTextField from './../form/question-number-input';
import ToggleSwitchValue from './../form/toogle-switch-value';
import { colorTheme } from '../../assets/theme';
import { Typography } from '@mui/material';
import TooltipIcon from '../tooltip-text/tooltip-icon';

const isStringDigits = (value) => /^\d+$/.test(value);

const LimitNumberOfAnswers = ({ validationMaxCheck, validationMinCheck, inputOption, handleMinValidationCheck,
    handleMaxValidationCheck, handleTextInput, questionType }) => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: '#4F4F4F' }}>Limit number of answers</Typography>
                <Typography sx={{ color: '#828282', fontSize: '12px', marginTop: '0px' }}>Enable to set minimum and maximum limits for how many answers respondents can select.</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '16px' }}>
                    <Box className='minimum-number-of-responses' sx={{ display: 'flex', alignItems: 'center' }}>
                        <ToggleSwitchValue
                            label='Minimum number of answers'
                            value={validationMinCheck}
                            onChange={handleMinValidationCheck}
                        />
                        <Box className={!validationMinCheck ? 'disabled' : ''} sx={{ display: 'flex', alignItems: 'center' }}>
                            <CustomNumberTextField
                                name='items_min_number'
                                value={inputOption.items_min_number}
                                onChange={handleTextInput}
                                error={inputOption.items_min_number !== '' && !isStringDigits(inputOption.items_min_number)}
                                disabled={!validationMinCheck}
                                sx={{ marginRight: '8px', marginLeft: '-4px' }}
                            />
                            <TooltipIcon title={'Minimum number of answers'} content={'Minimum number of answers'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                        </Box>
                    </Box>

                    {/* Conditionally render the maximum number of responses box */}
                    {questionType !== 'single_line_text' && (
                        <Box className='maximum-number-of-responses' sx={{ display: 'flex', alignItems: 'center' }}>
                            <ToggleSwitchValue
                                label='Maximum number of answers'
                                value={validationMaxCheck}
                                onChange={handleMaxValidationCheck}
                            />
                            <Box className={!validationMaxCheck ? 'disabled' : ''} sx={{ display: 'flex', alignItems: 'center' }}>
                                <CustomNumberTextField
                                    name='items_max_number'
                                    value={inputOption.items_max_number}
                                    onChange={handleTextInput}
                                    error={inputOption.items_max_number !== '' && !isStringDigits(inputOption.items_max_number)}
                                    disabled={!validationMaxCheck}
                                    sx={{ marginRight: '8px', marginLeft: '-8px' }}
                                />
                                <TooltipIcon title={'Maximum number of answers'} content={'Maximum number of answers'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default LimitNumberOfAnswers;
