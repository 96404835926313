import { updateCognitoUserAction } from "../redux/actions";

const attributes = {
    lastActive: new Date().toISOString()

};
// Dispatch an action to update the user in Cognito

export const lastActivity = async (email, cognitoUsers, dispach) => {
    const res = await dispach(updateCognitoUserAction(email, attributes, cognitoUsers));
    console.log('Response:', res);
}