import { MULTI_SLIDER_SINGLE_PICKER } from '../assets/constants';
import { updateQuestion } from "../redux/actions";
import store from '../redux/store';

export const updateRelationshipQuestions = (surveyId, sourceId, fieldName, questionsFetched) => {
    questionsFetched.forEach(question => {
        if (question.element_type === MULTI_SLIDER_SINGLE_PICKER && question.items_input === sourceId) {
            question['items_name_field'] = fieldName;
            store.dispatch(updateQuestion(surveyId, question))
        }
    })
} 