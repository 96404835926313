import React, { useState } from 'react';
import { Box, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { ReactComponent as ExportIcon } from '../../assets/images/actions/icon-export.svg';
import { colorTheme } from '../../assets/theme';
import TextButton from '../buttons/text-button';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Papa from 'papaparse';

// Style for the component using makeStyles
const useStyles = makeStyles(() => ({
    menuItem: {
        color: colorTheme.theme.text.lightGrey,
        fontSize: '16px',

        '&:hover': {
            backgroundColor: colorTheme.theme.app.lightestGrey,
        },
    },
}));

// ExportMenu component
const ExportMenu = ({ state, title }) => {
    const classes = useStyles(); // Get styles from useStyles
    const [anchorEl, setAnchorEl] = useState(null); // State for anchor element
    const [elementWidth, setElementWidth] = useState('auto'); // State for element width
    const open = Boolean(anchorEl); // Boolean state for anchor element

    // Extract columns, data, and selectedColumns from state
    const { columns, filteredData, selectedColumns } = state;

    // Filter columns based on selectedColumns and remove 'SURVEY_CONST_ROW_ID'
    const filteredColumns = columns.filter(column => column.field !== 'SURVEY_CONST_ROW_ID' && selectedColumns.includes(column.field));

    // Function to handle click event
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setElementWidth(event.currentTarget.clientWidth);
    };

    // Function to handle close event
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Function to export CSV
    const exportCsv = () => {
        const csvData = Papa.unparse({
            fields: filteredColumns.map(column => column.title),
            data: filteredData.map(row => filteredColumns.map(column => row[column.field]))
        });

        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) { // Feature detection
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${title}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    // Function to export PDF
    const exportPdf = () => {
        const doc = new jsPDF();
        doc.autoTable({
            head: [filteredColumns.map(column => column.title)],
            body: filteredData.map(row => filteredColumns.map(column => row[column.field]))
        });
        doc.save(`${title}.pdf`);
    };

    return (
        <Box>
            <TextButton name='Export' onClick={handleClick} size='small' fontSize='14px' width='auto' margin='auto auto 0 auto' borderRadius='23px' Icon={ExportIcon} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{
                    style: {
                        borderRadius: '4px',
                        boxShadow: '0px 1px 2.5px 0px #7B7D8982',
                        width: elementWidth,
                        marginTop: '45px',
                    },
                }}
            >
                <MenuItem onClick={exportCsv} className={classes.menuItem}>
                    CSV
                </MenuItem>
                <MenuItem onClick={exportPdf} className={classes.menuItem}>
                    PDF
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default ExportMenu;
