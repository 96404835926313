export const mapMenuItems = (currentSurvey, mappedList, listType) => {

    if (listType === 'custom') {
        //Handle custom lists
        currentSurvey.lists.forEach((listItem) => {
            mappedList.push({ title: listItem.title, id: listItem.id, columns: listItem.columns, data: listItem.data })
        })
    }
    else {
        //Handle respondents list
        if (currentSurvey.respondents.columns.length !== 0) {
            mappedList.unshift({ title: 'respondents', id: currentSurvey.id, columns: currentSurvey.respondents.columns, data: currentSurvey.respondents.data })
        }
    }
    return mappedList
}

export const getListName = (listId, mappedLists) => {
    if (listId && mappedLists) {
        let resultList = mappedLists.find(({ id }) => id === listId)
        return resultList !== undefined ? resultList.title : undefined;
    }
}

export const getListId = (name, lists, currentSurveyId) => {
    if (name && lists) {
        let resultList = lists.find(({ title }) => title === name)
        console.log('result list, ', resultList)
        if (resultList !== undefined) {
            return resultList.id
        } else {
            return currentSurveyId
        }
    }
}