import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { colorTheme } from '../../assets/theme';

// CustomCheckboxLabel component
const CustomCheckboxLabel = ({ value, label, checked, onChange, colorChange = true, cursor = 'pointer' }) => {
    return (
        <FormControlLabel
            className='radio'
            labelPlacement="end"
            value={value}
            control={
                <Checkbox
                    checked={checked}
                    onChange={onChange}
                    style={{ padding: '0px', marginTop: '0px', width: '14px', height: '14px' }}
                    sx={{
                        color: checked ? colorTheme.theme.transform.darkBlueTwo : colorTheme.theme.text.lightGrey,
                        '&.Mui-checked': {
                            color: colorTheme.theme.transform.darkBlueTwo,
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: '18.5px', // Set the checkbox icon size to 14px
                        },
                    }}
                />
            }
            sx={{
                margin: '0px',
                gap: '10px',
                color: checked ? colorTheme.theme.transform.darkBlueTwo : colorTheme.theme.text.lightGrey,
                '& .MuiFormControlLabel-label': {
                    fontSize: '16px',
                    cursor: cursor,
                },
            }}
            label={label}
        />
    );
};

export default CustomCheckboxLabel;
