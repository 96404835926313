import React from 'react';
import './status-container.scss';
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from '@mui/material';
import { colorTheme } from '../../../assets/theme';
import { ReactComponent as ElementsIcon } from '../../../assets/images/icons/survey/elements-icon.svg';
import { ReactComponent as RespondentsIcon } from '../../../assets/images/icons/survey/respondents-icon.svg';
import { ReactComponent as RespondentsErrorIcon } from '../../../assets/images/icons/survey/respondents-error-icon.svg';
import { ReactComponent as ErrorAlertIcon } from '../../../assets/images/icons/survey/error-alert-icon.svg';
import { ReactComponent as DateIcon } from '../../../assets/images/icons/survey/date-icon.svg';
import Fab from "@material-ui/core/Fab";
import TooltipText from '../../tooltip-text/tooltip-text';
import {
    CLICK_PREVIEW,
    FIX_ERRORS_QUESTIONS,
    FIX_ERRORS_RESPONDENTS,
} from "../../../assets/constants";
import * as moment from 'moment';

// StatusContainer component
function StatusContainer(props) {
    const dispatch = useDispatch(); // Get the dispatcher function from the redux store 
    const questionErrors = useSelector(state => state.questionErrors); // Get question errors from the redux store
    const respondentErrors = useSelector(state => state.respondentErrors); // Get respondent errors from the redux store
    const currentSurvey = useSelector(state => state.currentSurvey); // Get the current survey from the redux store

    return (
        <Box>
            <Typography variant="h6" sx={{ color: colorTheme.theme.text.mediumGrey, fontSize: '16px', fontWeight: '400' }} mb={2}>Status and Validation</Typography>
            <Box className="status-container flex-row-center">
                <Box className="flex-row-center" sx={{ gap: '40px' }}>
                    <ElementsIcon className="elements-icon" />
                    <Box className="flex-row-center" sx={{ gap: '20px' }}>
                        {currentSurvey && currentSurvey.questions && (
                            <>
                                <Box className="sections flex-column-center">
                                    <Typography variant='h4' sx={{ fontSize: '48px', fontFamily: 'Rubik', fontWeight: '500', color: colorTheme.theme.text.darkGrey, lineHeight: '56px' }}>
                                        {currentSurvey.questions.filter(q => q.element_type === "section").length}
                                    </Typography>
                                    <Typography variant='body2' sx={{ fontSize: '12px', color: colorTheme.theme.text.lightGrey, textTransform: 'uppercase' }}>
                                        sections
                                    </Typography>
                                </Box>
                                <Box className="flex-column-center">
                                    <Typography variant='h4' sx={{ fontSize: '48px', fontFamily: 'Rubik', fontWeight: '500', color: colorTheme.theme.text.darkGrey, lineHeight: '56px' }}>
                                        {currentSurvey.questions.filter(q => q.element_type !== "section").length}
                                    </Typography>
                                    <Typography variant='body2' sx={{ fontSize: '12px', color: colorTheme.theme.text.lightGrey, textTransform: 'uppercase' }}>
                                        questions
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Box>

                    {/* {
                    !currentSurvey.questions || currentSurvey.questions.length === 0 ?
                        <span className="flex-column-center">
                            <Fab variant="extended" className="error" size="small">
                                <ErrorOutlineOutlinedIcon /> no questions
                            </Fab>
                            <span className="link" onClick={() => props.handleFixErrorsClick(FIX_ERRORS_QUESTIONS)}>add questions</span>
                        </span> :
                        (
                            questionErrors ?
                                <span className="flex-column-center">
                                    <Fab variant="extended" className="error" size="small">
                                        <ErrorOutlineOutlinedIcon /> {questionErrors} errors
                                    </Fab>
                                    <span className="link" onClick={() => props.handleFixErrorsClick(FIX_ERRORS_QUESTIONS)}>fix errors</span>
                                </span> :
                                <span className="flex-column-center">
                                    <Fab variant="extended" className="no-errors" size="small">
                                        <CheckCircleOutlineOutlinedIcon /> no errors
                                    </Fab>
                                    <span className="link" onClick={() => props.handleFixErrorsClick(CLICK_PREVIEW)}>preview survey</span>
                                </span>
                        )
                } */}
                </Box>
                <Box className="flex-row-center" sx={{ gap: '40px' }}>
                    {
                        respondentErrors ?
                            <RespondentsErrorIcon className="respondents-icon" /> :
                            <RespondentsIcon className="respondents-icon" />
                    }
                    <Box className="flex-row-center" sx={{ gap: '20px' }}>
                        <Box className="questions flex-column-center">
                            <Typography variant='h4' sx={{ fontSize: '48px', fontFamily: 'Rubik', fontWeight: '500', color: colorTheme.theme.text.darkGrey, lineHeight: '56px' }}>{currentSurvey.respondents ? currentSurvey.respondents.data.length : 0}</Typography>
                            <Typography variant='body2' sx={{ fontSize: '12px', color: colorTheme.theme.text.lightGrey, textTransform: 'uppercase' }}>respondents</Typography>
                            {
                                respondentErrors > 0 &&
                                <TooltipText name="Resolve Errors" backgroundColor={'white'} textColor={colorTheme.theme.text.mediumGrey} margin={'5px 0px 0px 0px'} boxShadow={'0px 2px 8px 0px #7B7D8942;'} arrow={false} >
                                    <Fab variant="extended" className="error-button" size="small" onClick={() => props.handleFixErrorsClick(FIX_ERRORS_RESPONDENTS)}>
                                        <ErrorAlertIcon className="error-icon" />
                                        {respondentErrors} ERRORS
                                    </Fab>
                                </TooltipText>
                                // <Typography variant='body2' className='manage' sx={{ fontSize: '12px', color: colorTheme.theme.transform.darkBlueTwo, textTransform: 'uppercase', fontWeight: '500', cursor: 'pointer', transition: '0.3s color ease-in-out' }} onClick={() => props.handleFixErrorsClick(FIX_ERRORS_RESPONDENTS)}>Manage list</Typography>
                            }
                        </Box>
                    </Box>
                    {/* {
                    (currentSurvey.respondents && currentSurvey.respondents.data.length > 0) ?
                        (
                            respondentErrors ?
                                <span className="flex-column-center">
                                    <Fab variant="extended" className="error" size="small">
                                        <ErrorOutlineOutlinedIcon /> {respondentErrors} errors
                                    </Fab>
                                    <span className="link" onClick={() => props.handleFixErrorsClick(FIX_ERRORS_RESPONDENTS)}>fix errors</span>
                                </span> :
                                <span className="flex-column-center">
                                    <Fab variant="extended" className="no-errors" size="small">
                                        <CheckCircleOutlineOutlinedIcon /> no errors
                                    </Fab>
                                    <span className="link" onClick={() => props.handleFixErrorsClick(FIX_ERRORS_RESPONDENTS)}>respondents list</span>
                                </span>
                        ) :
                        <span className="flex-column-center">
                            <Fab variant="extended" className="error" size="small">
                                <ErrorOutlineOutlinedIcon /> no respondents
                            </Fab>
                            <span className="link" onClick={() => props.handleFixErrorsClick(FIX_ERRORS_RESPONDENTS)}>add respondents</span>
                        </span>
                } */}
                </Box>
                <Box className="flex-row-center" sx={{ gap: '16px' }}>
                    <DateIcon />
                    {/* <Box className="status flex-column-center">
                    <span className="text">survey status:</span>
                    <SurveyStatus status={currentSurvey.status} />
                </Box> */}
                    <Box className="publish-info" sx={{
                        display: 'grid',
                        gridTemplateColumns: 'max-content 1fr',
                        columnGap: '8px',
                        rowGap: '16px',
                        alignItems: 'center'
                    }}>
                        <StatusInfo name="Status" value={currentSurvey.status} />
                        <StatusInfo
                            name={currentSurvey.status === "DRAFT" ? "Starts" : "Start"}
                            value={
                                currentSurvey.status === "DRAFT"
                                    ? (currentSurvey.startDate
                                        ? moment(currentSurvey.startDate).format('DD/MM/YYYY | hh:mm A')
                                        : 'DD/MM/YY | 00:00 AM')
                                    : currentSurvey.status === "SCHEDULED"
                                        ? (currentSurvey.startDate
                                            ? moment(currentSurvey.startDate.replace("#", "")).format('DD/MM/YYYY | hh:mm A')
                                            : 'DD/MM/YY | 00:00 AM')
                                        : (currentSurvey.publishedTime
                                            ? moment(currentSurvey.publishedTime.replace("#", "")).format('DD/MM/YYYY | hh:mm A')
                                            : 'DD/MM/YY | 00:00 AM')
                            }
                        />
                        <StatusInfo
                            name={currentSurvey.status === "DRAFT" ? "Ends" : "End"}
                            value={currentSurvey.status === "COMPLETED"
                                ? (currentSurvey.statusTimestamp
                                    ? moment(currentSurvey.statusTimestamp.replace("#", "")).format('DD/MM/YYYY | hh:mm A')
                                    : 'DD/MM/YY | 00:00 AM')
                                : (currentSurvey.endDate
                                    ? moment(currentSurvey.endDate).format('DD/MM/YYYY | hh:mm A')
                                    : 'DD/MM/YY | 00:00 AM')
                            }
                        />

                    </Box>
                    {/* <Box className="info">
                    <span className="start">Starts: {
                        currentSurvey.startImmediately ? 'immediately' :
                            (currentSurvey.startDate ? moment(currentSurvey.startDate).format('DD/MM/YY') : 'DD/MM/YY')
                    }</span>
                    <span className="ends">Ends: {currentSurvey.endDate ?
                        moment(currentSurvey.endDate).format('DD/MM/YY')
                        : 'DD/MM/YY'}</span>
                </Box> */}
                </Box>
            </Box>
        </Box>
    )

}

function StatusInfo({ name, value }) {
    return (
        <>
            <Typography variant='body2' sx={{ color: colorTheme.theme.text.lightGrey, lineHeight: 1 }}>{name}:</Typography>
            <Typography variant='body2' sx={{ fontWeight: '700', color: colorTheme.theme.text.mediumGrey, lineHeight: 1 }}>{value}</Typography>
        </>
    )
}

export default StatusContainer;
