import React from 'react';
import ListsTable from "../../../components/lists-table/lists-table"
import UploadList from '../../lists-table/upload-list';
import CreateListManually from '../../lists-table/create-list-manually';
import TitleWithIcon from '../../title/title-icon';
import { useRespondentsList } from '../../../hooks/survey/use-respondents-list';
import { RESPONDENTS_FILE } from "../../../assets/constants";
import { Box, Typography, Link } from '@mui/material';
import { ReactComponent as RespondentsIcon } from '../../../assets/images/title/respondents.svg';
import { ReactComponent as EmptyRespondentsIcon } from "../../../assets/images/table/empty-respondents.svg";
import { colorTheme } from '../../../assets/theme';

// RespondentsList component
function RespondentsList() {

    const {
        manuallyUploaded,
        respondentList,
        respondentErrors,
        saveColumn,
        handleDataUpdate,
        handleDataDelete,
        handleUploadedData,
        handleDelete
    } = useRespondentsList(); // Get respondents list and functions from respondents hook

    return (
        <Box>
            <TitleWithIcon title='Manage Respondents' Icon={RespondentsIcon} fontSize={'18px'} />
            <Box display={"flex"} justifyContent={"space-between"} alignItems={'flex-end'}>
                <Typography variant="body2" sx={{ color: colorTheme.theme.text.lightGrey }} mt={1}>
                    Create your list manually or upload your list of respondents here. Please ensure the list includes the required attributes: <br />
                    Name and Email. Please use an Excel file format (.xlsx or .xls) or CSV (.csv) to ensure proper data processing.
                </Typography>
               <Box display={"flex"} gap={2}> 
                    <CreateListManually type={RESPONDENTS_FILE} handleUploadedData={handleUploadedData} listId={respondentList && respondentList.data.length > 0 ? 'respondent-list' : null} />
                    <UploadList listId={respondentList && respondentList.data.length > 0 ? 'respondent-list' : null}
                        type={RESPONDENTS_FILE} handleUploadedData={handleUploadedData} id='respondents'
                        handleDelete={handleDelete} errorCount={respondentErrors} text="Upload list"
                    />
               </Box>
            </Box>
            <Box mt={3}>
            {
                <ListsTable data={respondentList ? respondentList.data : []}
                    columns={respondentList ? respondentList.columns : []}
                    updateData={handleDataUpdate}
                    deleteData={handleDataDelete}
                    title='Respondents'
                    type={RESPONDENTS_FILE}
                    handleDelete={handleDelete}
                    emptyTableTitle={'Hey, it looks like you haven’t added any respondents yet.'}
                    emptyTableDescription={'Upload your respondent list to begin distributing your surveys and collecting valuable feedback.'}
                    EmptyTableIcon={EmptyRespondentsIcon}
                    paginationText={'Respondents per page:'}
                    errorCount={manuallyUploaded ? 0 : respondentErrors}
                    saveColumn={saveColumn}
                    manuallyUploaded={manuallyUploaded}
                />
            }
            </Box>
            <Typography variant="body2" sx={{ color: colorTheme.theme.text.mediumGrey }} mt={2}>
                Not sure how to format your list? Download our <Link href="#" sx={{ color: colorTheme.theme.transform.darkBlue, textDecoration: 'none', fontWeight: '500' }}>Excel template</Link> to get started
            </Typography>
        </Box>
    );
}

export default RespondentsList;
