import React, { useState, useRef } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { ReactComponent as SearchIcon } from "./../../assets/images/actions/icon-search.svg";
import { ReactComponent as ClearIcon } from "./../../assets/images/table/x.svg";

const SearchBar = ({ text, onChange, width }) => {
    const [value, setValue] = useState('');
    const inputRef = useRef(null);

    const handleChange = (event) => {
        setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    const handleClear = () => {
        setValue('');
        if (onChange) {
            onChange({ target: { value: '' } });
        }
    };

    return (
        <TextField
            ref={inputRef}
            sx={{
                width: width,
                background: "white",
                borderRadius: '4px',
                position: 'relative',  // Ensuring the TextField container is positioned relatively
                '& .MuiOutlinedInput-root': {
                    height: '40px',
                    '& fieldset': {
                    },
                    '&:hover fieldset': {
                        borderColor: '#4F4F4F',
                        border: '1px solid'
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#1B4972',
                        borderWidth: 1,
                    },
                },
                '& .MuiInputBase-input': {
                    height: '20px',
                    fontSize: '14px',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                    color: '#1B4972',
                },
                '& input:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 1000px transparent inset',
                    'box-shadow': '0 0 0 1000px transparent inset',
                    'background-clip': 'content-box',
                },
            }}
            variant="outlined"
            placeholder={text}
            value={value}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {value && (
                            <IconButton style={{ position: 'absolute', right: 5, top: '50%', transform: 'translateY(-50%)' }}>
                                <ClearIcon onClick={handleClear} style={{ cursor: 'pointer' }} />
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchBar;
