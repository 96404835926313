import React, { useEffect, useState } from 'react';
import { MenuItem } from '@material-ui/core'
import { Select } from '@mui/material';
import { indexOf } from 'lodash';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { MULTI_SELECT, SEARCH_CHOOSE, SINGLE_SELECT } from '../../../../assets/constants';

function ListPreviousQuestions(props) {
    const [selectedQuestion, setSelectedQuestion] = useState('');

    useEffect(() => {
        if (props.sourceQuestion) {
            setSelectedQuestion(props.sourceQuestion);
        }
    }, [props])

    let index = props.questions.findIndex(q => q.id === props.questionId)
    const menuItems = props.questions.filter(q => (q.element_type === SEARCH_CHOOSE || q.element_type === MULTI_SELECT || q.element_type === SINGLE_SELECT) && indexOf(q) < index).map(q => ({ text: q.question_text, id: q.id }))

    const handleQuestionSelect = ({ value }) => {
        const questionDetails = props.questions.find(q => q.id === value)
        if (questionDetails.element_type === SEARCH_CHOOSE) {
            props.setItemsNameField(questionDetails.items_name_field);
        }
        if (questionDetails.element_type === MULTI_SELECT && questionDetails.items) {
            props.setItemsNameField('item_text');
        }
        props.setSelectedQuestion(value);
        setSelectedQuestion(value)
    }

    return (

        <FormControl variant="outlined" style={{ width: '243px' }}>
            <InputLabel
                sx={{
                    '&.Mui-focused': {
                        color: '#1B4972', // Color of the label when the select is focused
                        top: '0px', // Adjust the label position when focused
                    },
                    '&.MuiInputLabel-shrink': {
                        top: '0px', // Adjust the label position when shrink
                    },
                    top: '-3px',
                }}>
                Select from which question
            </InputLabel>
            <Select
                labelId='source-option-select-label'
                id='source-option-select'
                value={selectedQuestion || ''}
                input={<OutlinedInput label="Select from which question" />}
                onChange={e => handleQuestionSelect(e.target)}
                sx={{
                    '.MuiSelect-select': {
                        padding: '13px', // Padding of the select
                        backgroundColor: '#FFFFFF',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D7D7D7', // Default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1B4972', // Border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1B4972', // Border color when focused
                        borderWidth: '1px', // Optionally, adjust the border width when focused
                    },
                }}
            >
                {
                    menuItems.map((item) => <MenuItem key={item.id} value={item.id}> {item.text} </MenuItem>)
                }
            </Select>
        </FormControl>
    )
}
export default ListPreviousQuestions