import React, { Component, useCallback, useEffect, useState } from 'react';
import './text-editor.scss';
import { EditorState, Modifier, ContentState, convertToRaw } from 'draft-js';
import { useDispatch, useSelector } from "react-redux";
import { Editor } from 'react-draft-wysiwyg';
import { Box } from '@mui/material';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import Fab from "@material-ui/core/Fab";
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { updateCurrentSurvey } from "../../../redux/actions";
import { getS3Credentials, uploadFile } from "../../../redux/actions";
import {
    REMINDER,
    INVITATION,
    SECTION,
} from "../../../assets/constants";
import _, { reject } from "lodash";

const uuid = require('uuid'); // For generating unique id

// CustomOption component for adding custom options to the toolbar
class CustomOption extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        editorState: PropTypes.object,
        buttonText: PropTypes.string,
        outputText: PropTypes.string,
        reminder: PropTypes.bool
    };

    addOption = () => {
        const { editorState, onChange, outputText } = this.props;
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            outputText,
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };

    render() {
        const { buttonText } = this.props;
        return (
            <Box onClick={this.addOption} className="button-wrapper">
                <Fab variant="extended" size="small">
                    {buttonText}
                </Fab>
            </Box>
        );
    }
}

// Function to create editor state from HTML string
const editorStateFromHTML = (htmlString) => {
    const blocksFromHtml = htmlToDraft(htmlString);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
};

// TextEditor component
const TextEditor = (props) => {
    const dispatch = useDispatch(); // Get dispatch function from redux
    const {sectionQuestion} = props // Get sectionQuestion from props
    const currentSurvey = useSelector(state => state.currentSurvey); // Get current survey from redux
    const source = props.source; // Get source from props
    const [editorState, setEditorState] = useState(''); // State for editorState
    const [editorHTML, setEditorHTML] = useState(null); // State for editorHTML

    // Function to update current survey with updated survey data after debounce
    const delayedUpdate = useCallback(_.debounce(updatedSurvey => {
        dispatch(updateCurrentSurvey(updatedSurvey))
    }, 300), []);

    // Set initial state for editorState based on source
    useEffect(() => {
        if (source === REMINDER) {
            setEditorState(currentSurvey.lastReminder ? editorStateFromHTML(currentSurvey.lastReminder) :
                EditorState.createEmpty())
        } else if (source === INVITATION) {
            setEditorState(currentSurvey.invitationEmailText ?
                editorStateFromHTML(currentSurvey.invitationEmailText) :
                EditorState.createEmpty())
        } else if (source === SECTION) {
            setEditorState(sectionQuestion.section_description ? 
                editorStateFromHTML(sectionQuestion.section_description) :
                EditorState.createEmpty())
        }
    }, [])

    // Function to handle editor change
    const handleEditorChange = (state) => {
        setEditorState(state);
        const raw = convertToRaw(state.getCurrentContent());
        const html = draftToHtml(raw)
        setEditorHTML(html);

        if (source === INVITATION) {
            if (editorHTML !== html) {
                delayedUpdate({ ...currentSurvey, invitationEmailText: html });
            }
        } else if (source === REMINDER) {
            delayedUpdate({ ...currentSurvey, lastReminder: html });
            props.handleReminderText(html)
        } else if (source === SECTION) {
            props.handleSectionText(html)
        }
    };
    
    // Function to get URL for image upload
    const getUrl = (file) => new Promise((resolve, reject) => {
        const imageFile = file.name + uuid.v1()
        try {
            dispatch(getS3Credentials(imageFile, file.type)).then((res) => {
                resolve(res.payload.uploadData)
            })
        } catch (error) {
            console.log('Error on upload ' + error)
            reject()
        }
    })

    // Function to get image callback
    const imageCallBack = file => {
        return getUrl(file)
            .then((res) => {
                const link = `${res.url}/${res.fields.key}`
                return dispatch(uploadFile(res, file)).then(() => {
                    return new Promise((resolve, reject) => {
                        resolve({ data: { link: link } })
                    })
                })
            })
    }

    return (
        <Box className="text-editor-container">
            <Editor
                defaultEditorState={null}
                editorState={editorState}
                onEditorStateChange={handleEditorChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                    options: ['inline', 'fontSize', 'list', 'textAlign', 'link', 'image', 'embedded'],
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                    },
                    image: { uploadEnabled: true, uploadCallback: imageCallBack},
                    list: {
                        inDropdown: false,
                        options: ['unordered', 'ordered'],
                    },
                    fontSize: {
                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                    },
                    textAlign: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['left', 'center', 'right']
                    },
                }}
                toolbarCustomButtons={
                    source !== SECTION ? 
                    [
                        <CustomOption buttonText="[Add Name]" outputText="{{Name}}" />,
                        <CustomOption buttonText="[Add Survey Link]" outputText="{{{SurveyLink}}}" />
                    ] : []}
            />
        </Box>
    )
};

export default TextEditor;
