import React from 'react';
import { Dialog, DialogContent, DialogActions, Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Typography } from '@mui/material';
import { ReactComponent as Icon } from "./../../assets/images/actions/upload.svg";
import { colorTheme } from '../../assets/theme';
import { getRowsPerPageOptions } from '../../utils/rows-options';
import HeaderDialog from './header-dialog';
import PrimaryButton from '../buttons/primary-button';
import SearchBar from '../form/search-bar';

export default function TableDialog({
    open,
    handleClose,
    handleAction,
    listName,
    columns,
    data,
    searchQuery,
    onSearchChange,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
    totalRows
}) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{ '& .MuiDialog-paper': { width: '467px', } }} // Set the dialog width
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog Icon={Icon} handleClose={handleClose} title={'List Preview and Confirmation'} />
            <DialogContent sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: '500',
                            color: '#4F4F4F',
                            width: '215px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {listName}
                    </Typography>
                    <SearchBar
                        text="Search"
                        onChange={onSearchChange}
                        width="213px"
                    />
                </Box>
                <TableContainer component={Paper} sx={{ borderRadius: '4px', boxShadow: 'none', border: '1px solid #D7D7D7' }}>
                    <Table sx={{ width: '100%', margin: 'auto', boxShadow: 'none', tableLayout: 'fixed' }}>
                        <TableHead sx={{ backgroundColor: '#EDF0F4' }}>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell key={index}>{column.title}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {columns.map((column, colIndex) => (
                                        <TableCell key={colIndex}>{row[column.field]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <TablePagination
                        sx={{
                            pt: 5,
                            mr: 0,
                            '& .MuiTablePagination-toolbar': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                            },
                            '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                                mr: -1,
                            },
                            '& .MuiTablePagination-input': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                                mr: 1.7,
                            },
                            '& .MuiTablePagination-actions': {
                                '& button': {
                                    margin: '0px',
                                    padding: '8px', // Adjust padding to ensure the button is more square
                                    transform: 'scale(0.9)',
                                    minWidth: 'auto', // Remove any minimum width restrictions
                                    width: '25px', // Equal width and height to form a square
                                    height: '25px', // Set height equal to width
                                    '& svg': {
                                        marginLeft: '0px', // Adjust if needed
                                        marginRight: '0px', // Adjust if needed
                                    },
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Set the background color on hover
                                        borderRadius: '50%', // Make the hover background rounded
                                        transform: 'scale(1.1)', // Optionally scale a bit on hover
                                    }
                                }
                            }
                        }}
                        component="div"
                        count={totalRows}
                        page={page}
                        onPageChange={onPageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={onRowsPerPageChange}
                        rowsPerPageOptions={getRowsPerPageOptions(data.length)}
                        labelRowsPerPage="Users per page:"
                    />
                </TableContainer>
            </DialogContent>
            <DialogActions sx={{ pt: 2, pb: 2, pr: 2 }}>
                <PrimaryButton onClick={handleClose} color="white" hoverColor="#EDF0F4" text="Cancel" hoverText={colorTheme.theme.transform.darkBlue} />
                <PrimaryButton onClick={handleAction} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="Save" />
            </DialogActions>
        </Dialog>
    );
}
