import React from "react";
import { styled } from '@mui/system';
import { IconButton } from '@mui/material';
import { colorTheme } from "../../assets/theme";

// StyledIconButton component
const StyledIconButton = styled(IconButton)({
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
    '& svg path': {
        transition: 'fill 0.3s ease',
    },
    '&:hover': {
        backgroundColor: colorTheme.theme.app.lightestGrey,
        'svg path': {
            fill: colorTheme.theme.transform.darkBlue,
        }
    },
});

// ListsTableActions component
const ListsTableActions = ({ firstAction, secondAction, FirstIcon, SecondIcon }) => {
    return (
        <>
            <StyledIconButton onClick={firstAction}>
                <FirstIcon />
            </StyledIconButton>
            <StyledIconButton onClick={secondAction}>
                <SecondIcon />
            </StyledIconButton>
        </>
    )
};

export default ListsTableActions;