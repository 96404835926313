import React from "react";
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import TeamTable from './../../../components/team-table/team-table';
import { Box } from '@mui/material';
import './teammates-container.scss';
import useTeammates from "../../../hooks/teammates/use-teammates";

// Define the headers for the table

// TeamMatesContainer component
const TeamMatesContainer = () => {
    // Use the useTeammates hook to get teammates functionality
    const { teamData, handleDelete, handleEdit, handleAddUser, pathSegments, setInsightsLicense, headCells,
        setSurveyLicense, setRole, teamRoleOptions, licenseColors, textColors, isLoading } = useTeammates();
    return (
        <Box className="teammates-container" mt={3}>
            {/* Render the Breadcrumbs component with the defined path segments */}
            <Breadcrumbs pathSegments={pathSegments} />
            <Box mt={2.5}>
            </Box>
            <Box mt={1.5}>
                {/* Render the TeamTable component with the defined rows, headers, and handlers */}
                <TeamTable title="Teammates" rows={teamData} headCells={headCells} handleDelete={handleDelete} handleEdit={handleEdit} handleAddUser={handleAddUser}
                    setInsightsLicense={setInsightsLicense} setSurveyLicense={setSurveyLicense} setRole={setRole} colSpan={7} width={'100%'} teamRoleOptions={teamRoleOptions}
                    licenseColors={licenseColors} textColors={textColors} isTeammates={true} isLoading={isLoading}
                />
            </Box>
        </Box>
    );
}

export default TeamMatesContainer;