import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import './question-container.scss';
import {
    TEXT_BOX,
    SINGLE_SLIDER_SINGLE_PICKER,
    SECTION,
    MULTI_SLIDER_SINGLE_PICKER,
    SINGLE_LINE_TEXT,
    SINGLE_SELECT,
    MULTI_SELECT,
    SEARCH_CHOOSE,
} from "../../../../assets/constants";
import { ReactComponent as ExpandIcon } from "../../../../assets/images/question-container/unfold-more.svg";
import { ReactComponent as CollapseIcon } from "../../../../assets/images/question-container/unfold-less.svg";
import { ReactComponent as DragHandleIcon } from "../../../../assets/images/question-container/drag-handle.svg";
import { ReactComponent as MoveDownIcon } from "../../../../assets/images/question-container/move-down.svg";
import { ReactComponent as MoveUpIcon } from "../../../../assets/images/question-container/move-up.svg";
import QuestionBasicSettings from "./question-footer";
import TextBoxQuestion from "./questions/text-box-question/text-box-question";
import SingleLineTextQuestion from "./questions/single-line-text-question/single-line-text-question";
import MultiSelectQuestion from "./questions/multi-select-question/multi-select-question";
import QuestionTitle from "./question-title";
import Section from "./questions/section/section";
import SingleSelectQuestion from "./questions/single-select-question/single-select-question";
import SingleSliderQuestion from "./questions/single-slider-question/single-slider-question";
import MultiSliderQuestion from "./questions/multi-slider-question/multi-slider-question";
import SearchChooseQuestion from "./questions/drop-down-question/drop-down-question";
import { updateQuestion } from "../../../../redux/actions";
import { colorTheme } from "../../../../assets/theme";
import TooltipText from '../../../../components/tooltip-text/tooltip-text';
import styled from "@mui/material/styles/styled";
import { IconButton } from '@mui/material';

const StyledIconButton = styled(IconButton)({
    backgroundColor: 'transparent',
    transition: 'background-color 0.3s ease',
    padding: '2px',
    '& svg path': {
        transition: 'fill 0.3s ease',
    },
    '&:hover': {
        backgroundColor: colorTheme.theme.app.lightestGrey,
        'svg path': {
            fill: colorTheme.theme.transform.darkBlue,
        }
    },
});

const renderQuestion = (question, handleAdvancedView, advancedSettings) => {
    switch (question.element_type) {
        case SINGLE_SLIDER_SINGLE_PICKER:
            return <SingleSliderQuestion template={question} handleAdvancedView={handleAdvancedView} advancedSettings={advancedSettings} />;
        case MULTI_SLIDER_SINGLE_PICKER:
            return <MultiSliderQuestion template={question} handleAdvancedView={handleAdvancedView} advancedSettings={advancedSettings} />;
        case TEXT_BOX:
            return <TextBoxQuestion template={question} handleAdvancedView={handleAdvancedView} advancedSettings={advancedSettings} />;
        case SINGLE_LINE_TEXT:
            return <SingleLineTextQuestion template={question} handleAdvancedView={handleAdvancedView} advancedSettings={advancedSettings} />;
        case SINGLE_SELECT:
            return <SingleSelectQuestion template={question} handleAdvancedView={handleAdvancedView} advancedSettings={advancedSettings} />;
        case SEARCH_CHOOSE:
            return <SearchChooseQuestion template={question} handleAdvancedView={handleAdvancedView} advancedSettings={advancedSettings} />;
        case MULTI_SELECT:
            return <MultiSelectQuestion template={question} handleAdvancedView={handleAdvancedView} advancedSettings={advancedSettings} />;
        case SECTION:
            return <Section template={question} handleAdvancedView={handleAdvancedView} advancedSettings={advancedSettings} />;
        default:
            return <div>unknown question type!</div>;
    }
};

const notNull = (value) => {
    return value !== null && value !== undefined;
};

function QuestionContainer(props) {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);
    const [questionExpanded, setQuestionExpanded] = useState(false);
    const [advancedSettings, setAdvancedSettings] = useState(false);

    const handleAdvancedView = () => {
        setAdvancedSettings(!advancedSettings);
    };

    const delayedUpdate = useCallback(_.debounce(question =>
        dispatch(updateQuestion(currentSurvey.id, question)), 600),
        []);

    useEffect(() => {
        if (notNull(props.question.expanded) && props.question.expanded) {
            setQuestionExpanded(props.question.expanded);
        }
    }, [props.question]);

    useEffect(() => {
        if (props.isDragging && questionExpanded) {
            handleExpandQuestion();
        }
    }, [props.isDragging]);

    const handleExpandQuestion = () => {
        let newQuestion = _.cloneDeep(props.question);
        newQuestion.expanded = !questionExpanded;
        delayedUpdate(newQuestion);
        setQuestionExpanded(!questionExpanded);
    };

    const moveQuestionUp = () => {
        props.moveQuestionUp(props.question.id);
    };

    const moveQuestionDown = () => {
        props.moveQuestionDown(props.question.id);
    };

    const classes = `${props.questionInFocus ? 'focus' : ''} ${props.question.element_type === SECTION ? 'section' : ''}`;

    return (
        <div className={`question-container ${classes}`}
            onClick={() => { props.changeFocus(props.question.id) }}>
            <div className="question">

                <QuestionTitle index={props.index} questionExpanded={questionExpanded} question={props.question} />

                {
                    questionExpanded ?
                        <div className="question-expanded">
                            <TooltipText name="Move Up" placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 0px'}>
                                <StyledIconButton className="move-up-icon" onClick={moveQuestionUp}>
                                    <MoveUpIcon />
                                </StyledIconButton>
                            </TooltipText>
                            <TooltipText name="Move Down" placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 0px'}>
                                <StyledIconButton className="move-down-icon" onClick={moveQuestionDown}>
                                    <MoveDownIcon />
                                </StyledIconButton>
                            </TooltipText>
                            <TooltipText name="Collapse" placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 0px'}>
                                <StyledIconButton className="expand icon" onClick={handleExpandQuestion}>
                                    <CollapseIcon />
                                </StyledIconButton>
                            </TooltipText>
                            {renderQuestion(props.question, handleAdvancedView, advancedSettings)}
                        </div> :
                        <div className="question-collapsed">
                            <TooltipText name="Move Up" placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 0px'}>
                                <StyledIconButton className="move-up-icon" onClick={moveQuestionUp}>
                                    <MoveUpIcon />
                                </StyledIconButton>
                            </TooltipText>
                            <TooltipText name="Move Down" placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 0px'}>
                                <StyledIconButton className="move-down-icon" onClick={moveQuestionDown}>
                                    <MoveDownIcon />
                                </StyledIconButton>
                            </TooltipText>
                            <TooltipText name="Expand" placement="bottom" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.transform.white} margin={'9px 0px 0px 0px'}>
                                <StyledIconButton className="expand icon" onClick={handleExpandQuestion}>
                                    <ExpandIcon />
                                </StyledIconButton>
                            </TooltipText>
                        </div>
                }
            </div>
            <span {...props.dragHandle}><DragHandleIcon className="drag-handle" /></span>
            {
                questionExpanded && props.questionInFocus && !props.isDragging &&
                <QuestionBasicSettings question={props.question} handleAdvancedView={handleAdvancedView} />
            }
        </div>
    );
}

export default QuestionContainer;
