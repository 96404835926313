import { updateCognitoUserAction } from "../redux/actions";

const date = new Date();
const formattedDate = date.toLocaleDateString('de-DE');

const attributes = {
    lastPwdChanged: formattedDate
};

// Dispatch an action to update the user in Cognito
export const passwordChanged = async (email, cognitoUsers, dispatch) => {
    const res = await dispatch(updateCognitoUserAction(email, attributes, cognitoUsers));
    console.log('Response:', res);
}