import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';

import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ReactComponent as CollaboratorIcon } from "../../../../assets/images/actions/upload.svg";
import UploadNewListAttributes from '../../../../components/dialogs/upload-new-list-attributes';
import UploadNewList from '../../../../components/dialogs/upload-new-list';
import TableDialog from '../../../../components/dialogs/table-dialog';
import { ROW_ID } from "../../../../assets/constants";
import XLSX from "xlsx";
import { updateCustomList } from '../../../../redux/actions';
const uuid = require('uuid');

const MAX_FILE_SIZE = 10; // in MB

function UploadListFromQuestion({ onListUploaded }) {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [open, setOpen] = useState(true);
    const [file, setFile] = useState({});
    const [fileName, setFileName] = useState('');
    const [checkBoxState, setCheckBoxState] = useState({});
    const [newList, setNewList] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { enqueueSnackbar } = useSnackbar();
    const buttonId = 'upload-list-' + uuid.v4();
    
    const openSnackbar = (msg) => {
        enqueueSnackbar(msg, {
            variant: 'error',
        });
    };

    useEffect(() => {
        if (Object.keys(newList).length !== 0) {
            dispatch(updateCustomList(currentSurvey.id, newList));
            onListUploaded(newList); // Notify parent component of the new list
        }
    }, [newList]);

    useEffect(() => {
        setOpen(true)
    }, [file])

    useEffect(() => {
        if (Object.keys(newList).length !== 0) {
            dispatch(updateCustomList(currentSurvey.id, newList))
            onListUploaded(newList); // Notify parent component of the new list
        }
    }, [newList]);

    useEffect(() => {
        if (previewOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [previewOpen]);

    const handleClose = () => {
        setOpen(false);
    }

    const handleNext = () => {
        setOpen(false)
        setPreviewOpen(true)
    }

    const handlePreviewClose = () => {
        setPreviewOpen(false);
    }

    const handleCheckbox = ({ name, checked }) => {
        setCheckBoxState({
            ...checkBoxState,
            [name]: checked
        })
    }

    const handleSave = (filteredColumns) => {
        const list = {
            id: uuid.v4(),
            data: file.data,
            columns: filteredColumns,
            title: fileName,
            file: file
        }
        if (currentSurvey) {
            enqueueSnackbar("Successfully saved", {
                variant: 'success',
            })
            setNewList(list)
        }
        handlePreviewClose();
    }

    const filterOutColumns = (data, filterValue) => {
        let filtered = [];
        data.forEach(dataItem => {
            if (filterValue.includes(dataItem.title)) {
                filtered.push(dataItem)
            }
        })
        return filtered;
    }

    const validateColumns = (columns, listName) => {
        if (columns && columns.length > 1) {
            return (
                <UploadNewListAttributes
                    open={open}
                    handleClose={handleClose}
                    columns={columns}
                    handleAction={handleNext}
                    listName={fileName}
                    handleCheckbox={handleCheckbox}
                    checkBoxState={checkBoxState}
                />
            )
        } else {
            return (
                <UploadNewList
                    open={open}
                    handleClose={handleClose}
                    columns={columns}
                    handleAction={handleSave}
                    listName={fileName}
                />
            )
        }
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const previewColumns = () => {
        let selectedColumns = _.pickBy(checkBoxState, function (value, key) { return value });
        let filteredColumns = filterOutColumns(file.columns, Object.keys(selectedColumns));

        // Make sure file.data is an array
        const filteredData = Array.isArray(file.data) ? file.data.filter((row) => {
            return Object.values(row).some((value) =>
                value.toString().toLowerCase().includes(searchQuery.toLowerCase())
            );
        }) : [];

        return (
            <TableDialog
                open={previewOpen}
                handleClose={handlePreviewClose}
                handleAction={() => handleSave(filteredColumns)}
                listName={fileName}
                columns={filteredColumns}
                data={filteredData}
                searchQuery={searchQuery}
                onSearchChange={handleSearchChange}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                totalRows={filteredData.length}
            />
        )
    }


    const onFileUpload = (event) => {
        const file = event.target.files[0];
        setFileName(file.name);

        if (file && file.size > MAX_FILE_SIZE * 1000000) {
            openSnackbar('Maximum file size exceeded, max file size is ' + MAX_FILE_SIZE + ' MB');
            return;
        }
        if (file) {
            let reader = new FileReader();
            try {
                reader.onload = function (e) {
                    let data = e.target.result;
                    const dataParse = fileToJson(data);

                    const transformedData = fileArraysToObjects(dataParse);
                    setFile(transformedData);
                    let tmpObj = {}

                    transformedData.columns.forEach(column => {
                        if (typeof column.title === 'string') {
                            tmpObj[column.title] = false;
                        }
                    })
                    setCheckBoxState(tmpObj);
                }
            } catch (e) {
                openSnackbar('Error with file upload');
            }
            reader.readAsBinaryString(file);
        }
    }

    const fileToJson = (data) => {
        try {
            let readData = XLSX.read(data, { type: 'binary' });
            const respondentsData = readData.Sheets[readData.SheetNames[0]];
            return XLSX.utils.sheet_to_json(respondentsData, { header: 1 });
        } catch (e) {
            openSnackbar('error when reading xlsx file');
        }
    };

    const fileArraysToObjects = (data) => {
        const header = data[0];
        const dataRows = data.slice(1);
        return {
            columns: header.map(col => { return { title: col, field: col } }),
            data: dataRows.map(r => {
                let dataObj = {
                    [ROW_ID]: uuid.v4()
                };
                header.forEach((col, ix) => dataObj[col] = r[ix]);
                return dataObj;
            })
        }
    };

    return (
        <div className='upload-button'>
            {Object.keys(file).length !== 0 && validateColumns(file.columns, fileName)}
            <input
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={e => onFileUpload(e)}
                onClick={(e) => e.target.value = ''}
                className='upload-input'
                id={buttonId}
                hidden={true}
            />
            <label htmlFor={buttonId}>
                <Button component="span"
                    className='add-option-hover'
                    style={{
                        borderRadius: '30px',
                        color: '#1e4675',
                        border: '1.5px solid #1e4675',
                        height: '30px',

                    }}
                    startIcon={<CollaboratorIcon />}
                >
                    <span>Upload new list</span>
                </Button>
            </label>
            {previewOpen && previewColumns()}
        </div>
    )
}

export default UploadListFromQuestion;
