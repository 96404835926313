// Import necessary hooks and functions from React, Redux, lodash, and local files
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { updateQuestion } from "../../../redux/actions";

// Custom hook for managing advanced settings of a text box component
const useTextBoxAdvancedSettings = (question) => {
    // Hook to dispatch actions to the Redux store
    const dispatch = useDispatch();
    // Accessing the current survey state from the Redux store
    const currentSurvey = useSelector(state => state.currentSurvey);

    // State for managing the switch that toggles the presence of text in the response field
    const [textInResponseFieldSwitch, setTextInResponseFieldSwitch] = useState(question.item_placeholder || false);
    // State for storing the actual text present in the response field
    const [textInResponseField, setTextInResponseField] = useState(question.item_placeholder_text || "");


    // Custom hook to delay the dispatch of the updateQuestion action using lodash's debounce function
    const delayedUpdate = useCallback(_.debounce(updatedQuestion =>
        dispatch(updateQuestion(currentSurvey.id, updatedQuestion)), 300), [dispatch, currentSurvey.id]);

    // Function to update the advanced settings of the text box
    const updateTextBoxAdvancedSettings = (value) => {
        // Creates a deep clone of the question object to avoid mutating the original object
        let newQuestion = _.cloneDeep(question);
        // Merges the new values into the cloned question object
        const newObject = Object.assign(newQuestion, value);
        // Calls the delayed update function with the updated question object
        delayedUpdate(newObject);
    };

    // Function to toggle the state of the textInResponseFieldSwitch
    const handleResponseFieldSwitch = () => {
        setTextInResponseFieldSwitch(prevState => !prevState);
        const updateSwitch = { item_placeholder: !textInResponseFieldSwitch, item_placeholder_text: textInResponseField };
        updateTextBoxAdvancedSettings(updateSwitch);
    }
    // Function to handle changes to the text in the response field and update the state and question object accordingly
    const handleTextInResponseField = (inputValue) => {
        const updateTextInResponse = { item_placeholder_text: inputValue, item_placeholder: true };
        updateTextBoxAdvancedSettings(updateTextInResponse);
        setTextInResponseField(inputValue);
    };

    // Returns the states and handlers to be used by the component
    return {
        textInResponseFieldSwitch,
        textInResponseField,
        handleResponseFieldSwitch,
        handleTextInResponseField
    };
};

// Export the custom hook for use in other components
export default useTextBoxAdvancedSettings;