import React from "react";
import { ReactComponent as CreateIcon } from '../../assets/images/actions/icon-create.svg';
import { useCreateList } from '../../hooks/survey/use-create-list';
import { Box } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import AddButton from "../buttons/add-button";
import AddNewList from "../dialogs/add-new-list";
import UploadedNewRespondentsList from "../dialogs/uploaded-new-respondents-list";

// UploadList component
function CreateListManually(props) {

    const { 
        listName, 
        attributes, 
        addNewListDialogOpened, 
        warningDialogOpened, 
        setListName, 
        setAddNewListDialogOpened, 
        addAttributes, 
        checkAttribute, 
        handleConfirm, 
        handleCancel, 
        handleCloseWarningDialog, 
        handleConfirmWarningDialog } = useCreateList(props);

    return (
        <Box>
            <UploadedNewRespondentsList open={warningDialogOpened} handleClose={handleCloseWarningDialog} handleConfirm={handleConfirmWarningDialog} text={"Creating a new list will replace the current list and all associated data will be lost. Please confirm that you want to proceed with creating a new list."} />
            <AddNewList open={addNewListDialogOpened} handleClose={handleCancel} handleConfirm={handleConfirm} type={props.type} attributes={attributes} addAttributes={addAttributes} checkAttribute={checkAttribute} listName={listName} setListName={setListName} />
            <AddButton
                Icon={CreateIcon} text="Create list" onClick={setAddNewListDialogOpened} hoverColor={colorTheme.theme.transform.darkBlue}
            />
        </Box>
    );
}

export default CreateListManually;
