import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import _ from 'lodash';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import CancelIcon from '@material-ui/icons/Cancel';
import * as serviceWorker from './serviceWorker';
import './index.css';
import store from './redux/store';
import App from "./App";
import LandingPage from "./landing-page";
import RegisterPage from "./pages/register-page";
import SigninPage from "./pages/signin-page";
import { createRootVariables } from './assets/theme'; // Import the createRootVariables function from the theme file
import { getCreds } from './utils/index';
let userData;

createRootVariables(); // Call the createRootVariables function to set the root variables

// Check if the user has credentials
getCreds().then((hasCredentials) => {

    // If the user does not have credentials, render the LandingPage component
    if (!hasCredentials) {
        ReactDOM.render(
            <Provider store={store}>
                <LandingPage />
            </Provider>,
            document.getElementById('root')
        );
    } else {
        // If the user has credentials, render the App component
        const notistackRef = React.createRef();
        const onClickDismiss = key => () => {
            notistackRef.current.closeSnackbar(key);
        };

        ReactDOM.render(

            // Wrap the App component in the Provider and SnackbarProvider components
            <Provider store={store}>
                <SnackbarProvider
                    hideIconVariant={true}
                    ref={notistackRef}
                    action={(key) => (
                        <CancelIcon onClick={onClickDismiss(key)} className="close-snackbar-icon" />
                    )}
                >
                    {/* BrowserRouter and Switch components to handle routing */}
                    <BrowserRouter basename="/">
                        <Switch>
                            {/*  Route components for signin */}
                            <Route exact path="/signin">
                                <SigninPage userData={userData} />
                            </Route>
                            {/* Route component for register */}
                            <Route exact path="/register">
                                <RegisterPage />
                            </Route>
                            {/* Route component for the home page */}
                            <Route path="/" >
                                <App />
                            </Route>
                        </Switch>
                    </BrowserRouter>
                </SnackbarProvider>
            </Provider>,
            document.getElementById('root')
        );
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
