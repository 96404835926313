import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getQuestions, getCustomLists, getRespondents, setEditPageActiveTab, setActiveEditSurveyTab } from "../../redux/actions";
import { TAB_PUBLISH, TAB_QUESTIONS, TAB_SETTINGS, TAB_STYLE, TAB_RESULTS, TAB_LISTS } from "../../assets/constants";
import { ReactComponent as QuestionsIcon } from "../../assets/images/menu/survey/editor.svg";
import { ReactComponent as StyleIcon } from "../../assets/images/menu/survey/style-icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/menu/survey/settings-icon.svg";
import { ReactComponent as PublishIcon } from "../../assets/images/menu/survey/publish-icon.svg";
import { ReactComponent as ListsIcon } from "../../assets/images/menu/survey/lists-icon.svg";
import { ReactComponent as ResultsIcon } from "../../assets/images/menu/survey/results-icon.svg";
import EditorTabContainer from '../../pages/survey-page/edit-tab/questions-tab/editor-tab-container';
import SettingsContainer from "../../pages/survey-page/edit-tab/settings-tab/settings-container";
import StyleContainer from "../../pages/survey-page/edit-tab/style-tab/style-container";
import PublishContainer from "../../pages/survey-page/edit-tab/publish-tab/publish-container";
import ListsContainer from '../../pages/survey-page/edit-tab/lists-tab/lists-container';
import ResultsContainer from '../../pages/survey-page/edit-tab/results-tab/results-container';

// Tabs data
const tabs = [
    {
        type: TAB_QUESTIONS,
        icon: <QuestionsIcon className='tab-icon' />,
    },
    {
        type: TAB_STYLE,
        icon: <StyleIcon className='tab-icon' />,
    },
    {
        type: TAB_SETTINGS,
        icon: <SettingsIcon className='tab-icon' />,
    },
    {
        type: TAB_LISTS,
        icon: <ListsIcon className='tab-icon stroke' />,
    },
    {
        type: TAB_PUBLISH,
        icon: <PublishIcon className='tab-icon' />,
    },
    {
        type: TAB_RESULTS,
        icon: <ResultsIcon className='tab-icon' />,
    }
];

// Custom hook for EditContainer component
export function useEditContainer() {
    const dispatch = useDispatch(); // Dispatch function from Redux
    const currentSurvey = useSelector(state => state.currentSurvey); // Current survey data
    const questionsFetched = useSelector(state => state.questionsFetched); // Questions fetched flag
    const listsFetched = useSelector(state => state.listsFetched); // Custom lists fetched flag
    const respondentsFetched = useSelector(state => state.respondentsFetched); // Respondents fetched flag
    const editPageActiveTab = useSelector(state => state.editPageActiveTab); // Active tab from Redux
    const [activeTab, setActiveTab] = useState(editPageActiveTab ? editPageActiveTab : TAB_QUESTIONS); // Active tab state
    const [dividerStyle, setDividerStyle] = useState({ width: 0, left: 0 }); // Divider style state
    const tabsRef = useRef(null); // Tabs reference

    // Fetch questions, custom lists, and respondents on component mount
    useEffect(() => {
        dispatch(setActiveEditSurveyTab(true));
        if (currentSurvey) {
            if (!questionsFetched) {
                dispatch(getQuestions(currentSurvey.id));
            }
            if (!listsFetched) {
                dispatch(getCustomLists(currentSurvey.id));
            }
            if (!respondentsFetched) {
                dispatch(getRespondents(currentSurvey.id));
            }
        }

        return () => {
            dispatch(setActiveEditSurveyTab(false));
        }
    }, []);

    // Set active tab from Redux when editPageActiveTab changes in Redux
    useEffect(() => {
        if (editPageActiveTab && editPageActiveTab !== activeTab) {
            setActiveTab(editPageActiveTab);
        }
    }, [editPageActiveTab]);

    // Update divider style on active tab change
    useEffect(() => {
        const updateDividerStyle = () => {
            // Get active tab element and set divider style based on it
            if (tabsRef.current) {
                const activeTabElement = tabsRef.current.querySelector('.tab.active');
                if (activeTabElement) {
                    const { offsetWidth, offsetLeft } = activeTabElement;
                    setDividerStyle({ width: offsetWidth, left: offsetLeft });
                }
            }
        };

        updateDividerStyle();
    }, [activeTab]);

    // Function to handle tab click
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        dispatch(setEditPageActiveTab(tab));
    };

    // Function to render tab content
    const renderTabContent = () => {
        switch (activeTab) {
            case TAB_QUESTIONS:
                return <EditorTabContainer />;
            case TAB_SETTINGS:
                return <SettingsContainer />;
            case TAB_STYLE:
                return <StyleContainer />;
            case TAB_PUBLISH:
                return <PublishContainer />;
            case TAB_LISTS:
                return <ListsContainer />;
            case TAB_RESULTS:
                return <ResultsContainer />;
            default:
                return null;
        }
    };

    // Function to handle preview click
    const handlePreviewClick = () => {
        // let domainPart = window.location.href.split('.')[0]
        // let companydomain = domainPart.substring(domainPart.lastIndexOf('/') + 1)
        window.open(`http://${process.env.REACT_APP_CLIENT_URL}?token=${currentSurvey.surveyAccessToken}&preview=true`, "_blank");
    };

    // Function to handle add collaborator click
    const handleAddColaboratorClick = () => {
        window.alert('Add Collaborator');
    };

    // Return the state and handlers
    return {
        tabs,
        activeTab,
        dividerStyle,
        tabsRef,
        handleTabClick,
        renderTabContent,
        handlePreviewClick,
        handleAddColaboratorClick
    }
};