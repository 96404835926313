import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { updateQuestion, cloneQuestion, removeQuestion, updateSurveyEditTime } from '../../redux/actions';
import { v4 as uuidv4 } from 'uuid';
import {
    SECTION_ITEM_BOLD_TEXT,
    SECTION_ITEM_IMAGE,
    SECTION_ITEM_TEXT,
    SECTION_ITEM_VIDEO
} from '../../assets/constants';

// Constants for settings actions
const SETTINGS_ACTIONS = {
    INSIGHTS: 'SETTINGS_ACTION_INSIGHTS',
    REQUIRED: 'SETTINGS_ACTION_REQUIRED',
    PAGE_BREAK: 'SETTINGS_ACTION_PAGE_BREAK',
    DUPLICATE: 'SETTINGS_ACTION_DUPLICATE',
    DELETE: 'SETTINGS_ACTION_DELETE',
    HIDDEN: 'SETTINGS_ACTION_HIDDEN'
};

const SECTION_ACTIONS = {
    ADD_IMAGE: 'SECTION_ACTION_ADD_IMAGE',
    ADD_VIDEO: 'SECTION_ACTION_ADD_VIDEO',
    ADD_DESCRIPTION: 'SECTION_ACTION_ADD_DESCRIPTION',
    ADD_BOLD_TEXT: 'SECTION_ACTION_ADD_BOLD_TEXT'
};

const notNull = (value) => value !== null && value !== undefined;

const useQuestionSettings = (question) => {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);
    const currentTimestamp = new Date().toISOString();
    // State variables
    const [requiredChecked, setRequiredChecked] = useState(false);
    const [pageBreakChecked, setPageBreakChecked] = useState(false);
    const [hiddenChecked, setHiddenChecked] = useState(false);
    const [firedAction, setFiredAction] = useState({
        SECTION_ITEM_TEXT: false,
        SECTION_ITEM_IMAGE: false,
        SECTION_ITEM_VIDEO: false
    });
    const [loading, setLoading] = useState(false);

    // Effect to synchronize component state with question prop
    useEffect(() => {
        if (notNull(question.required)) setRequiredChecked(question.required);
        if (notNull(question.page_break)) setPageBreakChecked(question.page_break);
        if (notNull(question.hidden)) setHiddenChecked(question.hidden);
    }, [question]);

    // Function to handle different settings changes
    const handleSettingsChange = useCallback(async (type) => {
        let newQuestion = _.cloneDeep(question);

        const updateAndDispatch = (field, value) => {
            newQuestion[field] = value;
            dispatch(updateQuestion(currentSurvey.id, newQuestion));
            dispatch(updateSurveyEditTime(currentTimestamp));
        };

        const addSectionItem = (itemType, uniqueCheck = false) => {
            if (uniqueCheck && firedAction[itemType]) return;

            const sectionItem = {
                item_id: uuidv4(),
                item_text: '',
                item_type: itemType,
                ...(itemType === SECTION_ITEM_IMAGE || itemType === SECTION_ITEM_VIDEO) && { item_url: '' }
            };

            newQuestion.items.push(sectionItem);
            setFiredAction(prevState => ({ ...prevState, [itemType]: true }));
            dispatch(updateQuestion(currentSurvey.id, newQuestion));
            dispatch(updateSurveyEditTime(currentTimestamp));
        };

        try {
            setLoading(true);

            switch (type) {
                case SETTINGS_ACTIONS.REQUIRED:
                    setRequiredChecked(!requiredChecked);
                    updateAndDispatch('required', !requiredChecked);
                    dispatch(updateSurveyEditTime(currentTimestamp));
                    break;
                case SETTINGS_ACTIONS.PAGE_BREAK:
                    setPageBreakChecked(!pageBreakChecked);
                    updateAndDispatch('page_break', !pageBreakChecked);
                    dispatch(updateSurveyEditTime(currentTimestamp));
                    break;
                case SETTINGS_ACTIONS.DUPLICATE:
                    await dispatch(cloneQuestion(currentSurvey.id, question));
                    dispatch(updateSurveyEditTime(currentTimestamp));
                    break;
                case SETTINGS_ACTIONS.DELETE:
                    await dispatch(removeQuestion(currentSurvey.id, question));
                    dispatch(updateSurveyEditTime(currentTimestamp));
                    break;
                case SETTINGS_ACTIONS.HIDDEN:
                    setHiddenChecked(!hiddenChecked);
                    updateAndDispatch('hidden', !hiddenChecked);
                    dispatch(updateSurveyEditTime(currentTimestamp));
                    break;
                case SECTION_ACTIONS.ADD_DESCRIPTION:
                    addSectionItem(SECTION_ITEM_TEXT, true);
                    dispatch(updateSurveyEditTime(currentTimestamp));
                    break;
                case SECTION_ACTIONS.ADD_BOLD_TEXT:
                    addSectionItem(SECTION_ITEM_BOLD_TEXT);
                    dispatch(updateSurveyEditTime(currentTimestamp));
                    break;
                case SECTION_ACTIONS.ADD_IMAGE:
                    addSectionItem(SECTION_ITEM_IMAGE);
                    dispatch(updateSurveyEditTime(currentTimestamp));
                    break;
                case SECTION_ACTIONS.ADD_VIDEO:
                    addSectionItem(SECTION_ITEM_VIDEO);
                    dispatch(updateSurveyEditTime(currentTimestamp));
                    break;
                default:
                    console.log('Unknown action type!');
            }
        } finally {
            setLoading(false);
        }
    }, [question, requiredChecked, pageBreakChecked, hiddenChecked, firedAction, dispatch, currentSurvey.id]);

    return {
        requiredChecked,
        pageBreakChecked,
        hiddenChecked,
        firedAction,
        handleSettingsChange,
        loading
    };
};

export default useQuestionSettings;
