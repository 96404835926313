import React from "react";
import { Box, Typography } from "@mui/material";
import "./branding-panel.scss";
import { ReactComponent as TransformLogo } from "../../assets/images/login-register/transform-logo.svg";
import cover from "../../assets/images/login-register/cover.png";

// BrandingPanel component
const BrandingPanel = () => {
    return(
        <Box className="branding-panel">
            <img src={cover} alt="branding-panel-cover" className="branding-panel-cover" />
            <Box className="branding-panel-content">
                <TransformLogo className="branding-panel-logo" />
                <Typography variant="body2" className="branding-panel-text">Transform grew out of a desire to create a consulting firm that could make an impact by showing how leaders in small and large organizations can transform themselves and the complex systems they are part of. We realized that in this fast-paced and results-oriented world, human courage and compassion have been largely forgotten, yet they are the necessary ingredients to create the transformations that we want for ourselves, our teams, our organizations, and entire communities.</Typography>
            </Box>
        </Box>
    )
};

export default BrandingPanel;
