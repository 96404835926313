import React from "react";
import './organization.scss';
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import ValueInfo from "./value-label";
import { ReactComponent as EditIcon } from "../../assets/images/actions/edit.svg";
import { colorTheme } from "../../assets/theme";
import PrimaryButton from "../buttons/primary-button";

// OrganizationView component
const OrganizationView = ({ onEditClick }) => {
    const organizationData = useSelector(state => state.organizationData); // Get the organization data from the state

    return (
        <Box className="organization">
            <Box className="organization-column">
                <ValueInfo label="Company Name" value={organizationData.companyName} labelMarginBottom="8px" valueFontSize="20px" valueFontWeight="700" valueLineHeight="20px" />
                <ValueInfo label="Industry" value={organizationData.industry} />
                <ValueInfo label="Founded" value={organizationData.founded} />
                <ValueInfo label="Number of employees" value={organizationData.numberOfEmployees} />
            </Box>
            <Box className="organization-column">
                <ValueInfo label="Website" value={organizationData.website} />
                <ValueInfo label="Street address" value={organizationData.streetAddress} />
                <ValueInfo label="City" value={organizationData.city} />
                <ValueInfo label="Country" value={organizationData.country} />
            </Box>
            <Box sx={{ position: { sm: 'absolute' }, right: 40, top: 80, display: 'flex', gap: '1rem' }}>
            <PrimaryButton
                    color={colorTheme.theme.transform.darkBlueTwo}
                    Icon={EditIcon}
                    hoverColor={colorTheme.theme.transform.darkBlue}
                    onClick={onEditClick} text="Edit"
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        transform: 'translate(50%, -50%)',
                        padding: '0.5em',
                    
                    }} />
            </Box>
        </Box>
    )
};

export default OrganizationView;