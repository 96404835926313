import React from "react";
import './survey-tile.scss';
import { useSelector } from "react-redux";
import * as moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as MoreIcon } from "../../assets/images/survey-dashboard/more-icon.svg";
import SurveyStatus from "../../assets/survey-status";
import { convertToLocalTime, formatDate } from "../../utils/time";

// SurveyTile component
const SurveyTile = ({ survey, index, handleMenuClick }) => {
    const cognitoUsers = useSelector(state => state.cognitoUsers); // Get cognito users from redux

    // Get moment from now
    const getMoment = (isoString) => {
        if (!isoString) {
            return 'Invalid date';
        }
        const date = moment(isoString);
        return date.isValid() ? date.fromNow() : 'Invalid date';
    }

    // Find and return  givenName and familyName of the user
    const findUserInCognitoUsers = (id) => {
        if (cognitoUsers) {
            let user = cognitoUsers.find(user => user.Username === id);
            if (user) {
                const givenNameAttr = user.Attributes.find(attr => attr.Name === 'given_name');
                const familyNameAttr = user.Attributes.find(attr => attr.Name === 'family_name');
                const givenName = givenNameAttr ? givenNameAttr.Value : '';
                const familyName = familyNameAttr ? familyNameAttr.Value : '';

                if (givenName || familyName) {
                    return `${givenName} ${familyName}`.trim();
                } else {
                    return 'User has not set a name';
                }
            }
        }
        return 'Not found';
    }

    return(
        <div className="survey-tile flex-column-center">
        <div className="survey-preview" style={{ background: `linear-gradient(110.41deg, ${survey.colors.main} 4.1%, ${survey.colors.highlight} 97.4%)` }}>
            <SurveyStatus status={survey.status} class="status-icon" />
            <img src={survey.companyLogo}/>
        </div>
        <div className="details">
            <div className="title">{survey.name ? survey.name : 'untitled'}</div>
            <div className="info">
                {
                    survey.status === 'COMPLETED' ? <>Completed: {formatDate(survey.statusTimestamp.substring(1))}</> :
                        survey.status === 'LIVE' ? <>Published: {getMoment(survey.publishedTime)},<br />by: {findUserInCognitoUsers(survey.publishedBy)}</> :
                            survey.status === 'DRAFT' ? <>Edited: {getMoment(survey.lastEditedTime)},<br />by: {findUserInCognitoUsers(survey.editedBy)}</> :
                                survey.status === 'SCHEDULED' ? <>Set for: {formatDate(survey.startDate, true)},<br />by: {findUserInCognitoUsers(survey.publishedBy)}</> : ""
                }
            </div>
            <IconButton className="options-icon" onClick={e => handleMenuClick(e, survey.id)}>
                <MoreIcon aria-controls="customized-menu" aria-haspopup="true" />
            </IconButton>
        </div>
    </div>
    )
}

export default SurveyTile;