import React, { useState } from 'react';
import './app-menu.scss';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setSidebarActivePage } from '../redux/actions';
import { Box, Menu, MenuItem, ListItemIcon, Typography } from '@material-ui/core';
import ProtectedComponent from '../helper/protected-component';
import { ReactComponent as SurveyLogo } from "../assets/images/menu/header/survey-logo.svg";
import { ReactComponent as InsightsLogo } from "../assets/images/menu/header/insights-logo.svg";
import { ReactComponent as GridLogo } from "../assets/images/menu/header/header-grid-logo.svg";
import { SIDEBAR_TAB_SURVEYS } from '../assets/constants';
import { Auth } from 'aws-amplify'; // Import AWS Amplify for JWT token generation
import Cookies from 'js-cookie'; // Import js-cookie for setting cookies

// AppMenu component to render the app menu in the header
function AppMenu() {
    const dispatch = useDispatch(); // Get the dispatch function from react-redux
    const history = useHistory(); // Get the history object from the useHistory hook
    const [anchorEl, setAnchorEl] = useState(null);  // State to store the anchor element of the menu
    const open = !!anchorEl; // Boolean value to check if the menu is open or not

    // Function to handle the click event of the menu
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Function to handle the close event of the menu
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Function to handle the click event of the menu items
    const handleAppMenuClick = async (type) => {
        switch (type) {
            case 'survey':
                history.push('/survey');
                dispatch(setSidebarActivePage(SIDEBAR_TAB_SURVEYS));
                break;
            case 'insights':
                // Generate JWT token and redirect to the external Insights app
                try {
                    const session = await Auth.currentSession();
                    const jwtToken = session.getIdToken().getJwtToken();

                    // Only for local setup, comment or remove domain since cookies won't be available due to the way localhost works
                    Cookies.set('idTokenProd', jwtToken, {
                        secure: true,
                        sameSite: 'None',
                        domain: '.transforminsight.com',
                        path: '/',
                    });

                    const user = await Auth.currentAuthenticatedUser();
                    const customerName = user.attributes['custom:customer'];
                    const domain = process.env.REACT_APP_INSIGHTS_DOMAIN_URL;
                    const redirectUrl = domain.replace('*', customerName);

                    window.location.href = redirectUrl;
                } catch (error) {
                    console.error("Error fetching JWT token:", error);
                }
                break;
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} className={`avatar ${open ? ' active' : ''}`}>
                <GridLogo onClick={handleClick} className="grid-logo user-settings-logo" />
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                getContentAnchorEl={null}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                className='app-menu'
            >
                <Typography variant='h5' style={{ color: "#B0B0B0", fontFamily: 'Roboto', fontWeight: 300, paddingInline: 20, paddingTop: 3, paddingBottom: 12, fontSize: 14 }}>My Apps</Typography>
                <ProtectedComponent licenses={['surveyLicense']}>
                    <MenuItem onClick={() => handleAppMenuClick('survey')}>
                        <ListItemIcon>
                            <SurveyLogo />
                        </ListItemIcon>
                        Survey
                    </MenuItem>
                </ProtectedComponent>
                <ProtectedComponent licenses={['insightsLicense']}>
                    <MenuItem onClick={() => handleAppMenuClick('insights')}>
                        <ListItemIcon>
                            <InsightsLogo />
                        </ListItemIcon>
                        Insights
                    </MenuItem>
                </ProtectedComponent>
            </Menu>
        </React.Fragment>
    );
}

export default AppMenu;
