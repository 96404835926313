import React from 'react';
import { Dialog, DialogContent, DialogActions, Box, FormControl, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { ReactComponent as Icon } from "./../../assets/images/actions/upload.svg";
import { colorTheme } from '../../assets/theme';
import HeaderDialog from './header-dialog';
import PrimaryButton from '../buttons/primary-button';
import { useSnackbar } from 'notistack';

export default function UploadNewListAttributes({ open, handleClose, handleAction, listName, columns, handleCheckbox, checkBoxState }) {
    const { enqueueSnackbar } = useSnackbar();

    function handleSave() {
        const selectedColumns = columns.filter(column => checkBoxState[column.title]);
        if (selectedColumns.length === 0) {
            enqueueSnackbar('Please select at least one column to continue.', { variant: 'error' });
            return;
        }
        handleAction();
        handleClose();
    }

    const isNextDisabled = columns.every(column => !checkBoxState[column.title]);

    return (
        <Dialog open={open} onClose={handleClose}
            sx={{ '& .MuiDialog-paper': { width: '391px' } }} // Set the dialog width
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog Icon={Icon} handleClose={handleClose} title={'Upload new list'} />
            <DialogContent
                sx={{ pl: 2, pr: 2, pt: 0, pb: 1 }}>
                <Typography
                    variant='body2'
                    sx={{ color: colorTheme.theme.text.lightGrey, lineHeight: "24px", pt: 2 }}>
                    Upload list: <span style={{ fontWeight: '500', color: '#4F4F4F' }}>{listName}</span>
                </Typography>
                <Typography
                    variant='body2'
                    sx={{ color: colorTheme.theme.text.lightGrey, lineHeight: "24px", pt: 1.5 }}>
                    Select attributes to include:
                </Typography>
                <Box>
                    {columns.map((columnItem, index) =>
                        <FormControl key={index}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkBoxState[columnItem.title] || false}
                                            onChange={e => handleCheckbox(e.target)}
                                            name={columnItem.title.toString() || ''}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colorTheme.theme.transform.darkBlueTwo,
                                                },
                                            }}
                                        />
                                    }
                                    label={columnItem.title}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            textTransform: 'capitalize',

                                        },
                                    }}
                                />
                            </FormGroup>
                        </FormControl>
                    )
                    }
                </Box>
            </DialogContent>
            <DialogActions sx={{ pt: 1, pb: 2, pr: 2 }}>
                <PrimaryButton onClick={handleClose} color="white" hoverColor="#EDF0F4" text="Cancel" hoverText={colorTheme.theme.transform.darkBlue} />
                <PrimaryButton onClick={handleSave}
                    color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="Continue"
                    opacity={isNextDisabled ? '0.5' : '1'}
                />
            </DialogActions>
        </Dialog>
    );
}
