import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Auth } from 'aws-amplify'
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import axios from 'axios'
import { fetchUserDetails } from '../utils/fetch-user';
import { getUserDetails, resetErrors, storeUserData, listCognitoUsersAction } from "../redux/actions";
import { determineUserRole } from '../utils/role';
import { getOrganizationDataAction, getOrganizationDefaultStyleDataAction } from '../redux/actions';
import { convertUnixToLocalTime } from '../../src/utils/unix-to-local-time';

export function useApp() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const userTemplate = useSelector(state => state.userTemplate);
    const error = useSelector(state => state.error);
    const cognitoUsers = useSelector(state => state.cognitoUsers);

    // use media query hook
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const [token, setToken] = useState('');
    const isFirstRun = useRef(true);

    console.log(userTemplate)

    useEffect(() => {
        // todo this works for amplify app but probably not for prod
        if (window.location.pathname.includes('signin')) {
            history.push("/");
        }
        manageAccessToken(true)
        const interval = setInterval(() => {
            manageAccessToken(false);
        }, 5 * 60 * 1000)

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (token && isFirstRun.current) {
            dispatch(getUserDetails());
            isFirstRun.current = false;
        }
    }, [token]);

    useEffect(() => {
        if (userTemplate) {
            console.log('user template fetched, template: ', userTemplate);
        }
    }, [userTemplate]);

    useEffect(() => {
        console.log('is mobile: ', isMobile);
    }, [isMobile]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, {
                variant: 'error',
            });
            dispatch(resetErrors());
        }
    }, [error]);

    useEffect(() => {

        if (token && !cognitoUsers) {
            // authToken is available, now safe to fetch protected data
            console.log('some');
            localStorage.setItem('token', token);
            fetchUserDetails(dispatch);
            dispatch(listCognitoUsersAction(token));
            dispatch(getOrganizationDataAction()); // Fetch organization data when token is available
            dispatch(getOrganizationDefaultStyleDataAction()); // Fetch organization default style data when token is available
        }
    }, [token]);

    const setAccessToken = token => {
        axios.defaults.headers.common.Authorization = token || null
    }

    const manageAccessToken = (firstLoad) => {
        Auth.currentSession()
            .then(async (res) => {
                if (firstLoad) {
                    console.log('current session: ', res);
                    const userData = {
                        username: res.idToken.payload['cognito:username'],
                        email: res.idToken.payload['email'],
                        firstName: res.idToken.payload['given_name'],
                        lastName: res.idToken.payload['family_name'],
                        role: res.idToken.payload['custom:role'],
                        surveyLicense: res.idToken.payload['custom:surveyLicense'],
                        insightsLicense: res.idToken.payload['custom:insightsLicense'],
                        authTime: res.idToken.payload['auth_time'],
                        phone_number: res.idToken.payload['phone_number'],
                        lastPwdChanged: res.idToken.payload['custom:lastPwdChanged'],
                        picture: res.idToken.payload['picture'],
                        twoFAEnabled: res.idToken.payload['custom:twoFAEnabled'],
                        authTimeFormatted: convertUnixToLocalTime(res.idToken.payload['auth_time']),
                    };

                    // Determine role based on attributes
                    const computedRole = determineUserRole(userData);

                    dispatch(storeUserData({
                        ...userData,
                        computedRole, // Store the computed role directly
                    }));

                    // Store relevant user information in local storage
                    localStorage.setItem('userData', JSON.stringify(userData));
                    localStorage.setItem('token', res.idToken.jwtToken);

                }
                console.log('current session: ', res);
                setToken(res.idToken.jwtToken)
                setAccessToken(res.idToken.jwtToken);
            })
            .catch((e) => {
                console.log('currentSession error: ', e);
                history.replace('/signin')
            });
    }

    return {
        userTemplate,

    }


}