import React from 'react';
import { ReactComponent as DraftIcon } from '../assets/images/survey-status/draft-icon.svg';
import { ReactComponent as LiveIcon } from '../assets/images/survey-status/live-icon.svg';
import { ReactComponent as CompletedIcon } from '../assets/images/survey-status/completed-icon.svg';
import { ReactComponent as ScheduledIcon } from '../assets/images/survey-status/scheduled-icon.svg';
import { colorTheme } from './../assets/theme'; // Import the colorTheme object from the theme.js file

import './survey-status.scss';

const surveyStatusLabels = [
    {
        value: 'DRAFT',
        style: {
            backgroundColor: colorTheme.theme.survey.statusDraft,
            color: colorTheme.theme.survey.statusText,
        },
        styleOpacity: {
            backgroundColor: colorTheme.theme.survey.statusDraftOpacity,
            color: colorTheme.theme.survey.statusDraft,
            boxShadow: 'inset 0px 0px 0px 0.5px ' + colorTheme.theme.survey.statusDraft,
        },
        icon: <DraftIcon />,
        class: 'survey-status draft'
    },
    {
        value: 'LIVE',
        style: {
            backgroundColor: colorTheme.theme.survey.statusLive,
            color: colorTheme.theme.survey.statusText,
        },
        styleOpacity: {
            backgroundColor: colorTheme.theme.survey.statusLiveOpacity,
            color: colorTheme.theme.survey.statusLive,
            boxShadow: 'inset 0px 0px 0px 0.5px ' + colorTheme.theme.survey.statusLive,
        },
        icon: <LiveIcon className="icon"/>,
        class: 'survey-status live'
    },
    {
        value: 'COMPLETED',
        style: {
            backgroundColor: colorTheme.theme.survey.statusCompleted,
            color: colorTheme.theme.survey.statusText,
        },
        styleOpacity: {
            backgroundColor: colorTheme.theme.survey.statusCompletedOpacity,
            color: colorTheme.theme.survey.statusCompleted,
            boxShadow: 'inset 0px 0px 0px 0.5px ' + colorTheme.theme.survey.statusCompleted,
        },
        icon: <CompletedIcon className="icon"/>,
        class: 'survey-status completed'
    },
    {
        value: 'SCHEDULED',
        style: {
            backgroundColor: colorTheme.theme.survey.statusScheduled,
            color: colorTheme.theme.survey.statusText,
        },
        styleOpacity: {
            backgroundColor: colorTheme.theme.survey.statusScheduledOpacity,
            color: colorTheme.theme.survey.statusScheduled,
            boxShadow: 'inset 0px 0px 0px 0.5px ' + colorTheme.theme.survey.statusScheduled,
        },
        icon: <ScheduledIcon />,
        class: 'survey-status scheduled'
    }
];

function SurveyStatus(props) {
    const { status, opacity = false } = props;
    const details = surveyStatusLabels.find(d => d.value === status);

    return details ? (
        <span className={`survey-status-label flex-row-center ${details.class} ${props.class ? props.class : ''}${opacity ? ' opacity' : ''}`} style={opacity ? details.styleOpacity : details.style}>
            <span className="icon">{details.icon}</span>
            {details.value}
        </span>
    ) : (
        <span className={`survey-status-label flex-row-center ${props.class ? props.class : ''}`}>
            <span className="icon"><DraftIcon/></span>
            UNKNOWN
        </span>
    )

}

export default SurveyStatus;
