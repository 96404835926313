import React, { useEffect, useState } from "react";
import './sidebar.scss';
import TooltipText from "../../components/tooltip-text/tooltip-text";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as SurveyIcon } from "../../assets/images/menu/sidebar/survey-icon.svg";
import { ReactComponent as StyleIcon } from "../../assets/images/menu/sidebar/style-icon.svg";
import { ReactComponent as TeamIcon } from "../../assets/images/menu/sidebar/team-icon.svg";
import { ReactComponent as OrganizationIcon } from "../../assets/images/menu/sidebar/organization-icon.svg";
import { ReactComponent as LanguageIcon } from "../../assets/images/menu/sidebar/language-icon.svg";
import { ReactComponent as BillingIcon } from "../../assets/images/menu/sidebar/billing-icon.svg";
import { SIDEBAR_TAB_SURVEYS, SIDEBAR_TAB_TEAM, SIDEBAR_TAB_DEFAULT_STYLE, SIDEBAR_TAB_ORGANIZATION, SIDEBAR_TAB_LANGUAGE, SIDEBAR_TAB_BILLING } from "../../assets/constants";
import { setCurrentSurvey, setSidebarActivePage } from "../../redux/actions";
import { colorTheme } from "../../assets/theme";

// Create an array of objects for the settings tabs
const settingsTab = [
    {
        type: SIDEBAR_TAB_TEAM,
        icon: <TeamIcon className='tab-icon' />,
    },
    {
        type: SIDEBAR_TAB_ORGANIZATION,
        icon: <OrganizationIcon className='tab-icon' />,
    },
    {
        type: SIDEBAR_TAB_LANGUAGE,
        icon: <LanguageIcon className='tab-icon' />,
    },
    {
        type: SIDEBAR_TAB_BILLING,
        icon: <BillingIcon className='tab-icon' />,
    }
]

// Create an array of objects for the survey tabs
const surveysTab = [
    {
        type: SIDEBAR_TAB_SURVEYS,
        icon: <SurveyIcon className='tab-icon' />,
    },
    {
        type: SIDEBAR_TAB_TEAM,
        icon: <TeamIcon className='tab-icon' />,
    },
    {
        type: SIDEBAR_TAB_DEFAULT_STYLE,
        icon: <StyleIcon className='tab-icon' />,
    },
]

const Sidebar = ({ displaySurveyTabs, onTabClick }) => {
    const dispatch = useDispatch(); // Get the dispatch function from the react-redux
    const tabs = displaySurveyTabs ? surveysTab : settingsTab; // If displaySurveyTabs is true, display the surveyTab, otherwise display settingsTab
    const [activeTab, setActiveTab] = useState(tabs[0].type); // Set the active tab to the first tab in the array
    const sidebarActivePage = useSelector(state => state.sidebarActivePage); // Get the sidebarActivePage from the redux store
    const currentSurvey = useSelector(state => state.currentSurvey); // Get the currentSurvey from the redux store

    // Function to handle the tab click
    const handleTabClick = (tab) => {
        dispatch(setSidebarActivePage(tab));
    };

    // useEffect hook to set the active tab based on the sidebarActivePage
    useEffect(() => {
        if (sidebarActivePage) {
            // If the displaySurveyTabs is true, set the currentSurvey to null and push the history state to '/survey'
            if (sidebarActivePage.page === SIDEBAR_TAB_SURVEYS) {
                if (displaySurveyTabs === true) {
                    if (currentSurvey) {
                        dispatch(setCurrentSurvey(null));
                        window.history.pushState({}, null, '/survey');
                    }
                }
            }
            setActiveTab(sidebarActivePage.page);
            onTabClick(sidebarActivePage.page);
        }
    }, [sidebarActivePage]);

    // On first render, set the active tab to the first tab in the array
    useEffect(() => {
        onTabClick(tabs[0].type);
        setActiveTab(tabs[0].type);
    }, []);

    return <div className="sidebar">
        {tabs.map((tab) => {
            return <TooltipText placement="right" name={tab.type} key={tab.type} backgroundColor={colorTheme.theme.transform.yellow} textColor={colorTheme.theme.transform.black} margin={'0px 0px 0px -10px'}>
                <div className={`sidebar-tab ${activeTab === tab.type ? 'active' : ''}`} onClick={() => handleTabClick(tab.type)}>
                    {tab.icon}
                </div>
            </TooltipText>
        })}
    </div>
};

export default Sidebar;