import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as SurveyLogo } from "../../assets/images/menu/header/survey-logo.svg";
import { setSidebarActivePage } from '../../redux/actions/index.js';
import { SIDEBAR_TAB_TEAM } from '../../assets/constants.js';
import { setCurrentSurvey } from '../../redux/actions/index.js';
import Box from '@material-ui/core/Box';

// This hook is used in Header component
const useHeader = () => {
    const dispatch = useDispatch(); // Get the dispatch function from the react-redux
    const history = useHistory(); // Get the history object from the react-router-dom
    const userData = useSelector(state => state.userData); // Get the userData from the redux store
    const [currentPath, setCurrentPath] = useState(history.location.pathname); // State to store the current path
    const currentSurvey = useSelector(state => state.currentSurvey); // Get the currentSurvey from the redux store
    const [surveyPage, setSurveyPage] = useState(false); // State to store the survey page status   
    const activeEditPageTab = useSelector(state => state.activeEditSurveyTab); // Get the activeEditSurveyTab from the redux store
    const ACTION_SETTINGS = 'ACTION_SETTINGS'; // Constant to store the action settings

    // Function to handle the click event of the logo
    const handleLogoClick = () => {
        history.push(`/`);
    };

    // useEffect hook to listen to the history changes
    useEffect(() => {
        const unlisten = history.listen((location) => {
            setCurrentPath(location.pathname);
            setSurveyPage(location.pathname === '/survey' || location.pathname === '/survey/edit');
        });
        return () => {
            unlisten();
        };
    }, [history]);

    // Function to render the logo based on the current path
    const renderLogo = () => {
        switch (currentPath) {
            case '/survey':
            case '/survey/edit':
                return <Box className="app-name" onClick={() => handleAppMenuClick('survey')}><SurveyLogo className="logo" /><h2>Survey</h2></Box>;
            default:
                return null;
        }
    };

    // Function to handle the click event of the action
    const handleActionClick = (action) => {
        switch (action) {
            case ACTION_SETTINGS:
                history.push(`/settings`);
                dispatch(setSidebarActivePage(SIDEBAR_TAB_TEAM));
                break;
            default:
                break;
        }
    };

    // Function to handle the click event of the app menu
    const handleAppMenuClick = (app) => {
        history.push(`/${app}`);
        // If the app is survey, set the currentSurvey to null and set the sidebarActivePage to 'Surveys'
        if (app === 'survey') {
            if (currentSurvey) {
                dispatch(setCurrentSurvey(null));;
            }
            dispatch(setSidebarActivePage('Surveys'));
        }
    };

    // Return the functions and states to be used in the Header component
    return {
        handleLogoClick,
        renderLogo,
        handleActionClick,
        handleAppMenuClick,
        currentSurvey,
        surveyPage,
        activeEditPageTab,
        userData,
        ACTION_SETTINGS
    };
};

export default useHeader;