import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { updateQuestion } from "../../../redux/actions";

// This function is used to check if the value is a string of digits.
const isStringDigits = (value) => {
    return /^\d+$/.test(value);
};

// This function is used to check if the value is an error.
const isError = (value) => {
    return value !== '' && !isStringDigits(value);
};

// This hook is used to handle the single line text question in the survey editor.
const useSingleLineQuestion = (template) => {
    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);
    const [inputOption, setInputOption] = useState({
        initial_number_of_fields: '3',
        max_characters: '30',
        items_min_number: '1',
        items_max_number: '3'
    });

    // This function is used to update the single line question.
    const delayedUpdate = useCallback(_.debounce(question =>
        dispatch(updateQuestion(currentSurvey.id, question)), 1000), [dispatch, currentSurvey.id]);

    // This function is used to handle the text input.
    useEffect(() => {
        if (template.initial_number_of_fields) {
            setInputOption(prevState => ({
                ...prevState, initial_number_of_fields: template.initial_number_of_fields
            }));
        }
        if (template.max_characters) {
            setInputOption(prevState => ({
                ...prevState, max_characters: template.max_characters
            }));
        }
        if (template.items_min_number) {
            setInputOption(prevState => ({
                ...prevState, items_min_number: template.items_min_number
            }));
        }
        if (template.items_max_number) {
            setInputOption(prevState => ({
                ...prevState, items_max_number: template.items_max_number
            }));
        }
    }, [template]);

    // This function is used to handle the text input.
    useEffect(() => {
        let updateObject = {};
        if (!template.max_characters) {
            updateObject['max_characters'] = 30;
        }
        if (!template.initial_number_of_fields) {
            updateObject['initial_number_of_fields'] = 3;
        }
        updateSingleLineQuestion(updateObject);
    }, []);

    // This function is used to handle the text input.
    const handleTextInput = (evt) => {
        let { name, value } = evt.target;
        setInputOption(prevState => ({
            ...prevState, [name]: value
        }));
        if (isStringDigits(value)) {
            value = Number(value);
            updateSingleLineQuestion({ [name]: value });
        }
    };

    // This function is used to update the single line question.
    const updateSingleLineQuestion = (value) => {
        const newQuestion = _.cloneDeep(template);
        const newObject = Object.assign(newQuestion, value);
        delayedUpdate(newObject);
    };

    return {
        inputOption,
        handleTextInput,
        isError,
    };
};

export default useSingleLineQuestion;
