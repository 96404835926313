import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';
import Header from "./layout/header";
import NotFoundPage from "./pages/not-found-page";
import NotAuthorized from './pages/not-authorized-page';
import UserManagementContainer from "./users-management-page/user-management-container";
import SettingsContainer from "./pages/settings-page/settings-container"; 
import ProfileContainer from './pages/profile-page/profile-container';
import SurveyContainer from './pages/survey-page/survey-container'; 
import { colorTheme } from './assets/theme';
import DashboardContainer from './pages/dashboard-page/dashboard-container';
import InsightsContainer from './pages/insights-page/insights-container';
import { useApp } from './hooks/use-app'; 
import ProtectedRoute from './helper/protected-route'; 
import { Auth } from 'aws-amplify';

const Logout = () => {
    const history = useHistory();

    useEffect(() => {
        try{
            const cookies = document.cookie.split(";");
            cookies.forEach(cookie => {
                const cookieName = cookie.split("=")[0].trim();
                // Set the cookie to expire in the past and specify the domain
                document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
            });
        } catch (error){
            console.log('Failed to delete cookies:', error);
        }
        // Clear local storage
        localStorage.clear();

        // Redirect to the sign-in page
        history.push('/signin');
    }, [history]);

    return null; // This component doesn't render anything
};

function App() {
    // Get the userTemplate from the useApp hook
    const { userTemplate } = useApp();

    return (
        // Render the app container with the color theme
        <div className="app-container">
            <MuiThemeProvider theme={colorTheme}>
                <Header />
                <div className="main-container">
                    <Switch>
                        {/* Route for the home page */}
                        <Route exact path="/" render={() =>
                            userTemplate ? (
                                <DashboardContainer />
                            ) : (
                                <div className='loading-screen'>Loading...</div>
                            )
                        } />

                        {/* Routes for different pages */}
                        <ProtectedRoute 
                            exact 
                            path="/settings" 
                            component={SettingsContainer} 
                            licenses={[]} 
                            userTemplate={userTemplate} 
                        />
                        <Route exact path="/profile" component={ProfileContainer} />
                        
                        {/* Protected routes that require licenses */}
                        <ProtectedRoute 
                            exact 
                            path="/survey" 
                            component={SurveyContainer} 
                            licenses={['surveyLicense']} 
                            userTemplate={userTemplate} 
                        />
                        <ProtectedRoute 
                            exact 
                            path="/survey/edit" 
                            component={SurveyContainer} 
                            licenses={['surveyLicense']} 
                            userTemplate={userTemplate} 
                        />
                        <ProtectedRoute 
                            exact 
                            path="/insights" 
                            component={InsightsContainer} 
                            licenses={['insightsLicense']} 
                            userTemplate={userTemplate} 
                        />

                        {/* Route for unauthorized users */}
                        <Route exact path="/unauthorized" component={NotAuthorized} />

                        {/* New Logout Route */}
                        <Route exact path="/logout" component={Logout} />

                        {/* Route for 404 page */}
                        <Route component={NotFoundPage} />
                    </Switch>
                </div>
            </MuiThemeProvider>
        </div>
    );
}

export default App;
