import React from "react";
import './custom-table.scss';
import PropTypes from 'prop-types';
import { Box, Paper, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import CustomTableEmptyRow from './custom-table-empty-row';
import { useCustomTable } from '../../hooks/table/use-custom-table';
import CustomTableRow from './custom-table-row';
import CustomTableHead from './custom-table-head';
import { getRowsPerPageOptions } from '../../utils/rows-options';

// Table component
const CustomTable = ({ rows, emptyTableFirstParagraph, emptyTableSecondParagraph }) => {
    const rowsPerPageOptionsNumbers = getRowsPerPageOptions(rows.length); // Get the rows per page options based on the length of the rows

    // Define the headers for the table
    const headCells = [
        { id: 'subject', numeric: false, disablePadding: true, label: 'Subject', width: '40%' },
        { id: 'groups', numeric: false, disablePadding: false, label: 'To', width: '40%' },
        { id: 'sentTime', numeric: false, disablePadding: false, label: 'Sent', width: '30%' },
    ];

    const {
        handleChangePage,
        handleChangeRowsPerPage,
        page,
        rowsPerPage,
    } = useCustomTable(rows, headCells); // Destructure handleChangePage, handleChangeRowsPerPage, page, rowsPerPage from the useTable hook

    return (
        <Box
            className="team-table-container"
            sx={{ width: '100%', background: "white", borderRadius: '5px'}}>
            <Paper
                className='team-table-paper'
                sx={{ width: '100%', mb: 0, boxShadow: 'none', padding: '0px', boxSizing: 'border-box' }}>
                <TableContainer sx={{ 
                    width: '100%',
                    overflowX: 'auto', 
                    border: '1px solid #E0E0E0',
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    borderBottomLeftRadius: rows.length > 0 ? 'none' : '4px',
                    borderBottomRightRadius: rows.length > 0 ? 'none' : '4px'
                    }}>
                    <Table
                        className='team-table'
                        sx={{ width: '100%', margin: 'auto', boxShadow: 'none' }}
                        aria-labelledby="tableTitle"
                    >
                        {/* Table head component */}
                        <CustomTableHead
                            rowCount={rows.length}
                            headCells={headCells}
                        />

                        {/* Table body */}
                        <TableBody>
                            {rows.length > 0 ? (
                                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    return (
                                        <CustomTableRow
                                            key={index}
                                            row={row}
                                            rowId={index}
                                            headCells={headCells} />
                                    );
                                })
                            ) : (
                                // Empty table row
                                <CustomTableEmptyRow firstParagraph={emptyTableFirstParagraph} secondParagraph={emptyTableSecondParagraph}/>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Table pagination */}
                {rows.length > 0 && (
                    <TablePagination
                        sx={{
                            pt: 5,
                            mr: 0,
                            border: '1px solid #E0E0E0',
                            width: '100%',
                            borderBottomLeftRadius: '4px',
                            borderBottomRightRadius: '4px',
                            borderTop: 'none',
                            '& .MuiTablePagination-toolbar': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                                minHeight: '50px',
                                paddingRight: '50px',
                            },
                            '& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                                mr: -1,
                            },
                            '& .MuiTablePagination-input': {
                                fontSize: '12px',
                                color: '#9A9A9A',
                                mr: 1.7,
                            },
                            '& .MuiTablePagination-select': {
                                paddingLeft: '4px !important',
                                minWidth: 'auto !important',
                            },
                            '& .MuiTablePagination-actions': {
                                '& button': {
                                    margin: '0px',
                                    padding: '8px', // Adjust padding to ensure the button is more square
                                    transform: 'scale(0.9)',
                                    minWidth: 'auto', // Remove any minimum width restrictions
                                    width: '25px', // Equal width and height to form a square
                                    height: '25px', // Set height equal to width
                                    '& svg': {
                                        marginLeft: '0px', // Adjust if needed
                                        marginRight: '0px', // Adjust if needed
                                    },
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // Set the background color on hover
                                        borderRadius: '50%', // Make the hover background rounded
                                        transform: 'scale(1.1)', // Optionally scale a bit on hover
                                    }
                                }
                            }
                        }}
                        rowsPerPageOptions={rowsPerPageOptionsNumbers}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Items per page"
                    />
                )}
            </Paper>
        </Box>
    );
};

// Define the prop types for the Table component
CustomTable.propTypes = {
    rows: PropTypes.array.isRequired,
};

export default CustomTable;