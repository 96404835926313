import React from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from "../../assets/images/title/arrow.svg";
import { colorTheme } from '../../assets/theme';
import './breadcrumbs.scss';

//  Breadcrumbs component
const Breadcrumbs = ({ pathSegments = [] }) => {
    return (
        <Box display="flex" alignItems="center" className="breadcrumbsContainer">
            {/* Map over each segment in the path */}
            {pathSegments.map((segment, index) => (
                // Use a fragment to group the elements for each segment
                <React.Fragment key={index}>
                    {/* If the segment has an icon, render it */}
                    {segment.icon && <segment.icon />}
                    {/* If the segment has a path and it's not the last segment, render a link */}
                    {segment.path && index < pathSegments.length - 1 ? (
                        <Link to={segment.path} className="linkStyle">
                            {/* Use Material UI's Link component for the actual text of the link */}
                            <MuiLink component="span" variant="body1" className="linkStyle" style={{ color: colorTheme.theme.colors.gray }}>
                                {segment.name}
                            </MuiLink>
                            {/* Render an arrow icon after the link */}
                            <ArrowIcon className="arrowIcon" />
                        </Link>
                    ) : (
                        // If the segment doesn't have a path or it's the last segment, render plain text
                        <Typography variant="p" component="span" variantMapping={{ body1: 'span' }}>
                            {segment.name}
                            {/* If it's not the last segment, render an arrow icon after the text */}
                            {index < pathSegments.length - 1 && <ArrowIcon className="arrowIcon" />}
                        </Typography>
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default Breadcrumbs;