import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, ListSubheader, Divider } from '@mui/material';

const SelectDialog = ({ label, options, width, value, onChange }) => {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    const groupedOptions = options.reduce((groups, option) => {
        const group = option.group ? option.group : '';
        if (!groups[group]) groups[group] = [];
        groups[group].push(option);
        return groups;
    }, {});

    return (
        <FormControl fullWidth sx={{ width }}>
            <InputLabel id={`${label}-select-label`} sx={{
                '&.Mui-focused': {
                    color: '#1B4972', // Color of the label when the select is focused
                    top: '0px', // Adjust the label position when focused
                },
                '&.MuiInputLabel-shrink': {
                    top: '0px', // Adjust the label position when shrink
                },
                top: '-3px',
            }}>
                {label}
            </InputLabel>
            <Select
                labelId={`${label}-select-label`}
                id={`${label}-select`}
                label={label}
                value={value}
                onChange={handleChange}
                sx={{

                    '.MuiSelect-select': {
                        padding: '13px', // Padding of the select

                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D7D7D7', // Default border color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1B4972', // Border color on hover
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#1B4972', // Border color when focused
                        borderWidth: '1px', // Optionally, adjust the border width when focused
                    },
                }}
            >
                {Object.entries(groupedOptions).map(([group, options], groupIndex) => (
                    [
                        ...(groupIndex !== 0 ? [<Divider key={`divider-${group}`} />] : []),
                        <ListSubheader key={`header-${group}`} component="div" disableSticky sx={{
                            color: '#828282', // Color of the subheader
                            fontWeight: '400', // Font weight of the subheader
                        }}>
                            {group}
                        </ListSubheader>,
                        ...options.map(option => (
                            <MenuItem key={`item-${option.value}`} value={option.value} sx={{
                                '&:hover': {
                                    backgroundColor: '#EEF0F4', // Background color on hover for the menu item
                                }
                            }}>
                                {option.label}
                            </MenuItem>
                        )),
                    ]
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectDialog;