import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as DemoTemplateIcon } from '../../../assets/images/survey-dashboard/blank-survey-icon.svg';
import { ReactComponent as BaseTemplateIcon } from '../../../assets/images/survey-dashboard/base_template.svg';
import { ReactComponent as CollaborationNetworkIcon } from '../../../assets/images/survey-dashboard/collaboration-network-icon.svg';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import HeaderDialog from './../../../components/dialogs/header-dialog';
import './../../../components/dialogs/create-new-survey.scss';
import { createFromTemplate } from "../../../redux/actions";
import { Box, Typography, Fab } from '@mui/material';

import TemplateDetails from '../../../assets/template-details';
import CollaborationTemplateDetails from '../../../assets/collaboration-network-template-details';
import BaseTemplate from '../../../assets/base-template';
import DemoTemplate from '../../../assets/base-template-no';
import CollaborationNetworkTemplate from '../../../assets/collaboration-network-template';


const ACTION_START_SURVEY_BASE = 'ACTION_START_SURVEY_BASE';
const ACTION_START_SURVEY_DEMO = 'ACTION_START_SURVEY_DEMO';
const ACTION_START_SURVEY_COLLABORATION_NETWORK = 'ACTION_START_SURVEY_COLLABORATION_NETWORK';

const TemplateView = ({ open, handleClose }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const organizationDefaultStyleData = useSelector(state => state.organizationDefaultStyleData); // Get the organization default style data from the redux store

    const handleActionClick = (action) => {
        setLoading(true);
        if (action === ACTION_START_SURVEY_DEMO) {
            dispatch(createFromTemplate(organizationDefaultStyleData, TemplateDetails, DemoTemplate)).then(() => {
                setLoading(false);
                history.push(`/survey/edit`);
            });
        }

        if (action === ACTION_START_SURVEY_BASE) {
            dispatch(createFromTemplate(organizationDefaultStyleData, TemplateDetails, BaseTemplate)).then(() => {
                setLoading(false);
                history.push(`/survey/edit`);
            });
        }

        if (action === ACTION_START_SURVEY_COLLABORATION_NETWORK) {
            dispatch(createFromTemplate(organizationDefaultStyleData, CollaborationTemplateDetails, CollaborationNetworkTemplate)).then(() => {
                setLoading(false);
                history.push(`/survey/edit`);
            });
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className='create-new-survey-dialog'
        >
            <HeaderDialog handleClose={handleClose} title={'Create new template'} />
            <DialogContent sx={{ width: '572px', boxSizing: 'border-box' }}>
                {loading &&
                    <Box width={'100%'} height={338} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Typography mb={3} className='loading-text'>Creating your survey ...</Typography>
                        <CircularProgress className='loader' />
                    </Box>
                }

                {!loading &&
                    <Box className="create-new-survey-dialog-types">
                        {/* <CreateNewSurveyType
                            Icon={DemoTemplateIcon}
                            title={'DEMO'}
                            description={'Build your own survey starting from a blank sheet.'}
                            onClick={() => handleActionClick(ACTION_START_SURVEY_DEMO)}
                        />
                        <CreateNewSurveyType
                            Icon={BaseTemplateIcon}
                            title={'BASE'}
                            description={'Select a pre-designed layout to quickly customize your survey.'}
                            onClick={() => handleActionClick(ACTION_START_SURVEY_BASE)}
                        /> */}
                         <CreateNewSurveyType
                            Icon={CollaborationNetworkIcon}
                            title={'COLLABORATION NETWORK'}
                            description={'Build your own survey starting from a blank sheet.'}
                            onClick={() => handleActionClick(ACTION_START_SURVEY_COLLABORATION_NETWORK)}
                        />
                    </Box>
                }
            </DialogContent>
        </Dialog>
    );
};

const CreateNewSurveyType = ({ Icon, title, description, onClick }) => {
    return (
        <Fab className="create-new-survey-dialog-type" onClick={onClick}>
            <Icon className="survey-tile-icon" />
            <Typography variant="h3" className="survey-tile-title">{title}</Typography>
            <Typography variant="body2" className="survey-tile-description">{description}</Typography>
        </Fab>
    );
};

export default TemplateView;
