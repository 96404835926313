import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import JoditEditor from "jodit-react";
import _ from "lodash";
import { Box } from '@mui/material';
import AdvancedSettings from '../../question-advanced-settings';

import { updateQuestion } from "../../../../../../redux/actions";
import './section.scss';

function Section({ template, handleAdvancedView, advancedSettings }) {

    const dispatch = useDispatch();
    const currentSurvey = useSelector(state => state.currentSurvey);

    const editor = useRef(null)
    const [content, setContent] = useState('')


    useEffect(() => {
        if (template.section_description) {
            setContent(template.section_description)
        }
    }, [template]);

    useEffect(() => {
        if (content) {
            updateSection({ section_description: content })
        }
    }, [content])

    const delayedUpdate = useCallback(_.debounce(question =>
        dispatch(updateQuestion(currentSurvey.id, question)), 800),
        []);
    const updateSection = (value) => {
        let newQuestion = _.cloneDeep(template)
        const newObject = Object.assign(newQuestion, value)
        delayedUpdate(newObject);
    }


    const config = {
        readonly: false,
        height: 300,
        allowResizeX: false,
        uploader: {
            url: 'https://xdsoft.net/jodit/finder/?action=fileUpload',
        },
        license: '%C8D1K-3J5F3-A0PMN-SLADZ%',
        buttons: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'strikethrough', '|', 'brush', 'font', 'fontsize', 'paragraph', '|', 'ul', 'ol', '|', 'indent', 'outdent', 'align', '|', 'file', 'image', 'video', '|', 'hr', 'table', 'link', 'symbol']
    }
    return (
        <><Box className='section-container'>
            <div className='section-container-flex-column-center'>
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)} />
            </div>

        </Box><Box className='section-container'>
                {advancedSettings && <AdvancedSettings setView={handleAdvancedView} template={template} />}
            </Box></>

    );
}

export default Section;
