import React from 'react';
import { Box } from '@mui/system';
import './register-page.scss';
import BrandingPanel from '../components/branding-panel/branding-panel';
import Register from '../components/register/register';

function RegisterPage() {
    return (
        <Box className="register-page">
            <BrandingPanel />
            <Register />
        </Box>
    );
}

export default RegisterPage;
