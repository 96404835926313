import React from 'react';
import { Typography, Alert, Box } from "@mui/material";
import OTPInput from "../../components/form/totp-input";
import PrimaryButton from "../buttons/primary-button";
import SecondaryButton from "../buttons/secondary-button";
import InputField from "../form/input-field";


const VerificationCodeComponent = ({
    form,
    handleChange,
    generalAuthError,
    forgotPassSubmit,
    handleForgotClick,
    colorTheme,
    NEW_PASSWORD,
    NEW_PASSWORD_CONFIRMATION,
    ArrowRight,
    isLoading

}) => {

    const handleCodeChange = (code) => {
        console.log('code', code);
        form.confirmation_code = code;
        handleChange(code, 'CONFIRMATION_CODE');
    }


    return (
        <div className='post-confirmation-code-container form-container'>
            <Typography mb={0} variant="body1" className="sign-in-page-description">
                Please enter the verification code sent to your email then set your new password.
            </Typography>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'left'}
                mb={2} mt={0}>
                <Typography variant="body1" mb={2} sx={{ textAlign: 'left' }}>Verification Code</Typography>
                <OTPInput
                    position={'left'}
                    length={6}
                    onChange={(code) => handleCodeChange(code)}
                />
            </Box>
            <Box textAlign={'left'} mb={0}>
                <Typography mb={1} variant="body1" sx={{ fontSize: '15px' }}>NEW PASSWORD</Typography>
                <Typography mb={1.5} variant="body2"
                    sx={{ lineHeight: '19px', color: '#7D7D7D', fontSize: '14px' }}
                >Create a new password that's at least 8 characters long and includes a mix of letters, numbers, and symbols for enhanced security.</Typography>
            </Box>
            <Box className='form-inputs'>
                <InputField
                    type="password"
                    label="New Password"
                    value={form.new_password}
                    onChange={(e) => handleChange(e.target.value, NEW_PASSWORD)}
                    autoComplete='new-password'
                />
                <InputField
                    type="password"
                    label="Repeat New Password"
                    value={form.new_password_confirmation}
                    onChange={(e) => handleChange(e.target.value, NEW_PASSWORD_CONFIRMATION)}
                    autoComplete='new-password'
                />
                {generalAuthError && (
                    <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                        {generalAuthError}
                    </Alert>
                )}
            </Box>
            <Box className='bottom-buttons'>
                <SecondaryButton
                    onClick={handleForgotClick}
                    color="#FFFFFF"
                    hoverColor="#EDF0F4"
                    text="Back"
                    arrow={<ArrowRight />}
                />
                <PrimaryButton
                    onClick={forgotPassSubmit}
                    color={colorTheme.theme.transform.darkBlueTwo}
                    hoverColor={colorTheme.theme.transform.darkBlue}
                    text="Change password"
                    isLoading={isLoading}
                />
            </Box>
        </div>
    );
}

export default VerificationCodeComponent;
