import React, { useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import { Box, Typography } from "@mui/material";
import { styled } from '@mui/system';
import { ReactComponent as PenIcon } from "../../assets/images/actions/icon-pen.svg";
import { colorTheme } from "../../assets/theme";
import TooltipText from "../tooltip-text/tooltip-text";
import InputField from "../form/input-field";
import ColorPicker from "./color-picker";

// StyledIconButton component
const StyledIconButton = styled(IconButton)({
    padding: '7px',
    '& svg path': {
        transition: 'fill 0.3s ease',
    },
    '&:hover svg path': {
        fill: colorTheme.theme.transform.darkBlue,
    },
});

// ColorBox component
const ColorBox = ({ name, marginTop, value, handleChange }) => {
    const [colorPickerOpened, setColorPickerOpened] = useState(false); // State for color picker

    return (
        <Box className="color-box" mt={marginTop} display="flex" alignItems="center" gap={1.5} style={{ position: "relative" }}>
            <Box width={32} height={32} borderRadius={18} backgroundColor={value} />
            <Typography variant="h5" sx={{ lineHeight: '24px', fontSize: '16px', fontWeight: '500', color: colorPickerOpened ? colorTheme.theme.transform.darkBlueTwo : colorTheme.theme.text.darkGrey }}>{name}</Typography>
            <InputField onChange={handleChange} value={value} label="Founded" textFieldWidth="200px" inputPadding="10px 12px" lineHeight="20px" inputfontSize="16px" boxMarginBottom="0px" labelFontSize="12px" labelMarginBottom="8px" inputMarginLeft="auto" hideLabel={true} />
            <TooltipText name="Color Picker" backgroundColor={colorTheme.theme.transform.darkBlueTwo} textColor={colorTheme.theme.colors.white} margin={'5px 0px 0px 0px'}>
                <StyledIconButton onClick={() => setColorPickerOpened(!colorPickerOpened)} className="color-picker-button">
                    <PenIcon />
                </StyledIconButton>
            </TooltipText>
            <ColorPicker color={value} opened={colorPickerOpened} setColorPickerOpened={setColorPickerOpened} handleChange={handleChange} />
        </Box>
    )
}

export default ColorBox;
