import React from 'react';
import { Box, Typography, TableRow, TableCell } from '@mui/material';

// ListsTableEmptyRow component
const ListsTableEmptyRow = (props) => {

    const EmptyTableIcon = props.EmptyTableIcon; // Empty table icon
    
    return (
        <TableRow sx={{ borderBottom: "none", borderTop: "none" }}>
            <TableCell colSpan={6} sx={{ borderBottom: "none", borderTop: "none" }}>
                <Box textAlign="center" sx={{ width: '100%', pt: 5, pb: 5 }}>
                    <EmptyTableIcon style={{ margin: 'auto', display: 'block' }} />
                    <Typography sx={{ mt: 2 }} varianty="body1" >{props.emptyTableTitle}</Typography>
                    <Typography sx={{ mt: 1 }} variant="body2">{props.emptyTableDescription}</Typography>
                </Box>
            </TableCell>
        </TableRow>
    )
};

export default ListsTableEmptyRow;