import React from 'react';
import './survey-menu-action-filter.scss';
import { Box } from '@mui/material';
import SurveyFilterButton from '../survey-filter-button/survey-filter-button';
import { ReactComponent as DraftIcon } from '../../assets/images/survey-status/draft-icon.svg';
import { ReactComponent as LiveIcon } from '../../assets/images/survey-status/live-icon.svg';
import { ReactComponent as CompletedIcon } from '../../assets/images/survey-status/completed-icon.svg';
import { ReactComponent as ScheduledIcon } from '../../assets/images/survey-status/scheduled-icon.svg';
import { ALL_SURVEYS, DRAFT, LIVE, COMPLETED, SCHEDULED } from '../../assets/constants';

// Array to store the survey status
const AllSurveyStatus = [
    {
        status: ALL_SURVEYS,
        filter: '',
    },
    {
        status: DRAFT,
        filter: DRAFT,
        icon: <DraftIcon className="icon"/>,
    },
    {
        status: LIVE, 
        filter: LIVE,
        icon: <LiveIcon className="icon"/>,
    },
    {
        status: COMPLETED,
        filter: COMPLETED,
        icon: <CompletedIcon className="icon"/>,
    },
    {
        status: SCHEDULED,
        filter: SCHEDULED,
        icon: <ScheduledIcon className="icon"/>,
    },
];

// SurveyMenuActionFilter component
const SurveyMenuActionFilter = (props) => {
    const { surveyStatus, handleSurveyStatusChange } = props; // Props

    return (
        <Box className="filter">
            {
                AllSurveyStatus.map((status, index) => (
                    <SurveyFilterButton key={index} active={surveyStatus} status={status.status} filter={status.filter} icon={status.icon} onClick={handleSurveyStatusChange} />
                ))
            }
        </Box>
    );
}

export default SurveyMenuActionFilter;