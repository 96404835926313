import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { updateCustomList, deleteCustomList } from "../../redux/actions";
import { STATUS_COLUMN, ROW_ID, ROW_OK } from "../../assets/constants";
import _ from "lodash";

const uuid = require('uuid'); // Generate unique IDs

// Constants
const ROW_NULL = 'ROW EMPTY';

// Function to check if a row is null
const isNull = (row) => {
    let isEmpty = true;
    _.forOwn(row, (v, k) => {
        if (v) {
            isEmpty = false;
        }
    });
    return _.isEmpty(row) || isEmpty;
};

// Custom hook to manage custom lists
export const useAdditionalLists = () => {
    const dispatch = useDispatch(); // Dispatch actions
    const currentSurvey = useSelector(state => state.currentSurvey); // Get current survey
    const [customLists, setCustomLists] = useState([]); // Custom lists
    const [manuallyUploaded, setManuallyUploaded] = useState(false); // Manually uploaded
    const [manuallyUploadedListId, setManuallyUploadedListId] = useState(null); // Manually uploaded list ID

    // Update custom lists when the current survey changes
    useEffect(() => {
        if (currentSurvey && currentSurvey.lists && !_.isEqual(currentSurvey.lists, customLists)) {
            setCustomLists(currentSurvey.lists);
        }
    }, [currentSurvey]); // + customLists

    // Function to validate a list
    const validateList = (respondentsData, listId, file, listName) => {
        let header = _.cloneDeep(respondentsData.columns);
        if (!header.find(col => col.field === STATUS_COLUMN.field)) {
            header = [STATUS_COLUMN, ...header];
        }
        const data = _.cloneDeep(respondentsData.data);
        let dataErrors = 0;
        data.forEach(d => {
            if (isNull(d)) {
                d[STATUS_COLUMN.field] = ROW_NULL;
                dataErrors++;
            } else {
                if (!d[ROW_ID]) {
                    d[ROW_ID] = uuid.v4();
                }
                d[STATUS_COLUMN.field] = ROW_OK;
            }
        });
        updateList({ columns: header, data: data }, listId, file, dataErrors, listName);
    };

    // Function to update a list
    const updateList = (listData, listId, file, dataErrors, listName) => {
        const { columns, data } = listData;
        let lists = _.cloneDeep(customLists);
        let listIndex = lists.findIndex(l => l.id === listId);
        if (listIndex !== -1) {
            lists[listIndex].data = data;
            lists[listIndex].columns = columns;
            lists[listIndex].dataErrors = dataErrors;
            if (currentSurvey) {
                dispatch(updateCustomList(currentSurvey.id, lists[listIndex]));
            }
        } else {
            const list = {
                id: listId,
                data: data,
                columns: columns,
                title: listName ? listName : 'Custom untitled',
                file: file,
                dataErrors: dataErrors,
            };
            lists.push(list);
            if (currentSurvey) {
                dispatch(updateCustomList(currentSurvey.id, list));
            }
        }
        setCustomLists(lists);
    };

    // Function to handle data update
    const handleDataUpdate = (newData, listId) => {
        validateList(newData, listId, null, null);
        setManuallyUploaded(false);
    };

    // Function to handle uploaded data
    const handleUploadedData = (data, listId, file, manual = false, listName) => {
        if (!listId) {
            const newID = uuid.v4();
            validateList(data, newID, file, listName);

            if (manual && !manuallyUploaded) {
                setManuallyUploaded(true);
                setManuallyUploadedListId(newID);
            }else{
                setManuallyUploaded(false);
            }
        } else {
            const list = customLists.find(l => l.id === listId);
            validateList(
                {
                    columns: _.uniqBy([...list.columns, ...data.columns], col => col.field),
                    data: [...list.data, ...data.data],
                },
                listId, null, null);
        }
    };

    // Function to handle title update
    const handleTitleUpdate = (title, listId, customLists) => {
        let lists = _.cloneDeep(customLists);
        let listIndex = lists.findIndex(l => l.id === listId);
        if (listIndex !== -1) {
            lists[listIndex].title = title;
            if (currentSurvey) {
                dispatch(updateCustomList(currentSurvey.id, lists[listIndex]));
            }
        }
        setCustomLists(lists);
    };

    // Function to handle delete
    const handleDelete = (listId) => {
        let lists = _.cloneDeep(customLists);
        _.remove(lists, l => l.id === listId);
        setCustomLists(lists);
        dispatch(deleteCustomList(currentSurvey.id, listId));
    };

    // Function to save new additional list column
    const saveColumn = (newColumnName, listId) => {
        const newColumn = {
            title: newColumnName,
            field: newColumnName,
        };
        
        let lists = _.cloneDeep(customLists);
        let listIndex = lists.findIndex(l => l.id === listId);
        if (listIndex !== -1) {
            lists[listIndex].columns.push(newColumn);
            lists[listIndex].data.forEach(row => {
                row[newColumnName] = '';
            });
            if (currentSurvey) {
                dispatch(updateCustomList(currentSurvey.id, lists[listIndex]));
            }
        }
    };

    // Return custom lists and functions
    return {
        manuallyUploaded,
        customLists,
        manuallyUploadedListId,
        saveColumn,
        handleDataUpdate,
        handleUploadedData,
        handleTitleUpdate,
        handleDelete,
    };
};
