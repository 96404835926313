
import { colorTheme } from '../../assets/theme';
import DefaultCompanyImage from '../../assets/images/actions/default-organization.svg';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getS3Credentials, uploadFile } from "../../redux/actions";
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { updateOrganizationDataAction, deleteFileFromS3Action } from '../../redux/actions';
import { lastActivity } from '../../utils/last-activity';

// This hook used in the Organization page
const useOrganization = () => {
    const dispatch = useDispatch(); // Use dispatch from Redux
    const { enqueueSnackbar } = useSnackbar(); // Use enqueueSnackbar from notistack
    const organizationData = useSelector(state => state.organizationData); // Access organization data from Redux store.
    const [companyImage, setCompanyImage] = useState(organizationData?.picture || DefaultCompanyImage); // useState for the company image
    const [editMode, setEditMode] = useState(false); // useState for the edit mode
    const [companyLogoFile, setCompanyLogoFile] = useState(null); // useState for the company logo file
    const [fetchingCredentials, setFetchingCredentials] = useState(false); // useState for fetching credentials
    const [fileName, setFileName] = useState(null); // useState for the file name
    const [oldCompanyLogo, setOldCompanyLogo] = useState(null); // useState for the old company logo
    const uploadData = useSelector(state => state.uploadData); // Access upload data from Redux store.
    const cognitoUsers = useSelector(state => state.cognitoUsers); // Get the cognito users from the redux store
    const userData = useSelector(state => state.userData); // Get the user data from the redux store
    const [completeCompanyProfile, setCompleteCompanyProfile] = useState(false); // useState for the complete company profile

    useEffect(() => {
        if (organizationData && organizationData.city && organizationData.companyName && organizationData.country && organizationData.founded && organizationData.industry && organizationData.numberOfEmployees && organizationData.picture && organizationData.streetAddress && organizationData.website) {
            setCompleteCompanyProfile(true);
            console.log('Organization data true');
        } else{
            setCompleteCompanyProfile(false);
            console.log('Organization data false');
        }
    }, [organizationData]);

    // Maximum file size for profile picture.
    const MAX_FILE_SIZE = 5; // in MB

    // Define the company image source
    const companyImageSrc = companyImage;

    // Define the company logo style
    const companyLogoStyle = {
        width: { xs: 100, sm: 174 },
        height: { xs: 100, sm: 174 },
        border: `3px solid ${colorTheme.theme.transform.darkBlueTwo}`,
    };

    // Function to handle the edit mode
    const onEdit = () => {
        setEditMode(!editMode);
    }

    // Function to update the organization data with the new picture
    const updateOrganizationDataPicture = (attributes) => {

        // New organization data with the updated attributes
        let newOrganizationData = {
            ...organizationData,
            ...attributes
        };

        // Update the organization data with the new picture
        dispatch(updateOrganizationDataAction(newOrganizationData))
        .then(() => {
            console.log('Organization data updated successfully');
            enqueueSnackbar('Organization details data updated successfully', { variant: 'success' });
        })
 
        // Delete the old company logo from S3 if it different from the new picture
        if (oldCompanyLogo && oldCompanyLogo != attributes.picture) {
            dispatch(deleteFileFromS3Action(oldCompanyLogo));
            setOldCompanyLogo(null);
        }
    };

    // UseEffect to upload the file
    useEffect(() => {
        if(fetchingCredentials && uploadData && companyLogoFile && organizationData) {
            console.log('Upload data:', uploadData);
            dispatch(uploadFile(uploadData, companyLogoFile, fileName))
            .then(() => {
                const attributes = {
                    picture: `${uploadData.url}/${uploadData.fields.key}`
                };

                updateOrganizationDataPicture(attributes); // Update the organization data with the new picture
                lastActivity(userData.email, cognitoUsers, dispatch); // Update the last activity
            })
            setFetchingCredentials(false);
        }
    }, [fetchingCredentials, uploadData, companyLogoFile]);

    // Function to handle company logo changes
    const handleCompanyLogoChange = (event) => {
        if (event.target.files.length > 0) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setCompanyImage(e.target.result); // Update company logo with the selected file.
            };
            reader.readAsDataURL(event.target.files[0]);

            // Get the file
            const file = event.target.files[0];

            // Create a new file object with the file name + timestamp + file extension
            const newFile = new File([file], file.name.substring(0, file.name.lastIndexOf('.')) + '-' + Date.now() + file.name.substring(file.name.lastIndexOf('.')), { type: file.type });

            if (newFile.type && newFile.name, newFile.type.size > MAX_FILE_SIZE * 1000000) {
                enqueueSnackbar('File size exceeds the maximum limit of 5MB', { variant: 'error' });
                return;
            }

            if(file) {
                setCompanyLogoFile(newFile);
                setFileName(newFile.name);
                dispatch(getS3Credentials(newFile.name, newFile.type)).then(() => {
                    setFetchingCredentials(true);
                });
                setOldCompanyLogo(organizationData.picture);
            }
        }
    };

    // Define the path segments for the breadcrumbs
    const pathSegments = [
        { name: "Transform", path: "/" },
        { name: "Settings", path: "/settings" },
        { name: "Organization", path: "/settings" }
    ];

    return {
        completeCompanyProfile,
        companyImage,
        setCompanyImage,
        companyImageSrc,
        companyLogoStyle,
        handleCompanyLogoChange,
        pathSegments,
        editMode,
        onEdit
    };
};

export default useOrganization;