import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { ACTIVE_STATUS_COLUMN, RESPONDENT_STATUS, TAB_ANONYMOUS, TAB_EMAIL } from "../../assets/constants";
import { ReactComponent as EmailIcon } from '../../assets/images/icons/survey/email-icon.svg';
import { ReactComponent as LinkIcon } from '../../assets/images/icons/survey/link-icon.svg';
import { useValidateRespondnts } from './validate-respondents';
import date from 'date-and-time';
import axios from 'axios'
import {
    updateCurrentSurvey,
    publishSurvey,
    scheduleSurvey,
    closeSurvey,
    resetErrors,
    getRespondents,
    downloadSurveyData,
} from "../../redux/actions";
import _ from "lodash";

const publishTypeTabs = [
    {
        type: TAB_EMAIL,
        icon: <EmailIcon className='tab-icon' />,
    },
    {
        type: TAB_ANONYMOUS,
        icon: <LinkIcon className='tab-icon' />,
    },
];

// Constants
const INPUT_COMPANY = 'INPUT_COMPANY';
const INPUT_SUBJECT = 'INPUT_SUBJECT';

// Menu tabs
let MENU_TABS = ['Publishing', 'Invitation email', 'Reminder email', 'Download'];

// Custom hook to handle the email container
export function useEmailContainer() {
    const dispatch = useDispatch(); // Get the dispatcher function from the redux store
    const { enqueueSnackbar } = useSnackbar(); // Get the snackbar function from the snackbar hook 
    const surveyPublished = useSelector(state => state.surveyPublished); // Get the surveyPublished state from the redux store
    const publishingError = useSelector(state => state.publishingError); // Get the publishingError state from the redux store
    const questionErrors = useSelector(state => state.questionErrors); // Get the questionErrors state from the redux store
    const respondentErrors = useSelector(state => state.respondentErrors); // Get the respondentErrors state from the redux store
    const currentSurvey = useSelector(state => state.currentSurvey); // Get the currentSurvey state from the redux store
    const downloadUrl = useSelector(state => state.downloadUrl); // Get the downloadUrl state from the redux store
    const userTemplate = useSelector(state => state.userTemplate); // Get the userTemplate state from the redux store
    const cognitoUsers = useSelector(state => state.cognitoUsers); // Get cognito users from redux
    const [dividerStyle, setDividerStyle] = useState({ width: 0, left: 0 }); // Divider style state
    const tabsRef = useRef(null); // Tabs reference
    const userData = useSelector(state => state.userData); // Get the user state from the redux store

    useValidateRespondnts(currentSurvey); // Custom hook to validate respondents

    // State for the invitation from name 
    const [invitationFromName, setInvitationFromName] = useState({
        value: '',
        error: false,
        errorMessage: '',
    });

    // State for the invitation subject
    const [invitationSubject, setInvitationSubject] = useState({
        value: '',
        error: false,
        errorMessage: '',
    });

    const [publishingSurvey, setPublishingSurvey] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [activeTab, setActiveTab] = useState(MENU_TABS[0]);
    const [urlFetched, setUrlFetched] = useState(false);
    const [anonymousSwitch, setAnonymousSwitch] = useState(false);
    const [copiedLink, setCopiedLink] = useState('');
    const [anonymousLink, setAnonymousLink] = useState('');
    const [embeddableSwitch, setEmbeddableSwitch] = useState(false);
    const [embeddableLink, setEmbeddableLink] = useState('<div style="padding: 56.25% 0 0 0; position: relative; height: 3700px;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 3700px;" src="${window.location.host}/?token=${currentSurvey.surveyAccessToken}&anonymous=true&embedded=true" frameborder="0"></iframe></div>');
    const [activePublishTypeTab, setActivePublishTypeTab] = useState(publishTypeTabs[0].type);

    // State for the respondent status
    const [respondentStatus, setRespondentStatus] = useState({
        [RESPONDENT_STATUS.NOT_SENT]: '',
        [RESPONDENT_STATUS.SENT]: '',
        [RESPONDENT_STATUS.STARTED]: '',
        [RESPONDENT_STATUS.SUBMITTED]: '',
        [RESPONDENT_STATUS.EMAIL_BOUNCED]: '',
    });

    // Function to debounce the update of the current survey
    const delayedUpdate = useCallback(_.debounce(updatedSurvey => {
        dispatch(updateCurrentSurvey(updatedSurvey))}
    , 300), []);

    // useEffect hook to update the invitation from name and invitation subject when the current survey changes
    useEffect(() => {
        if (currentSurvey && currentSurvey.invitationFromName && currentSurvey.invitationFromName !== invitationFromName) {
            setInvitationFromName({ ...invitationFromName, value: currentSurvey.invitationFromName });
        }
        if (currentSurvey && currentSurvey.invitationSubject && currentSurvey.invitationSubject !== invitationSubject) {
            setInvitationSubject({ ...invitationSubject, value: currentSurvey.invitationSubject });
        }
        if (currentSurvey && currentSurvey.embeddable === true) {
            setEmbeddableSwitch(true)
            // setEmbeddableLink(`${window.location.host}/?token=${currentSurvey.surveyAccessToken}&anonymous=true`)
        }
        if (currentSurvey && currentSurvey.anonynmous === true) {
            setAnonymousSwitch(true)
            setAnonymousLink(`${window.location.host}/?token=${currentSurvey.surveyAccessToken}&anonymous=true&embedded=true`)
        }

        // Initial state for the anonymous switch
        handleAnonymmousSwitch(true);
    }, []);

    // useEffect hook to update the publishing survey state when the survey is published
    useEffect(() => {
        if (surveyPublished) {
            setPublishingSurvey(false);
        }
    }, [surveyPublished]);

    // useEffect hook to show an error message when there is an error publishing the survey
    useEffect(() => {
        if (publishingError && !surveyPublished) {
            openSnackbar("Error when publishing survey");
            setPublishingSurvey(false);
        }
    }, [publishingError]);

    // useEffect hook to update the respondent status when the current survey changes
    useEffect(() => {
        if (currentSurvey && currentSurvey.respondents && currentSurvey.respondents.data) {
            const respondentsStatusList = currentSurvey.respondents.data.map(r => r[ACTIVE_STATUS_COLUMN.field]);
            setRespondentStatus({
                [RESPONDENT_STATUS.NOT_SENT]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.NOT_SENT).length,
                [RESPONDENT_STATUS.SENT]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.SENT).length,
                [RESPONDENT_STATUS.STARTED]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.STARTED).length + respondentsStatusList.filter(s => s === RESPONDENT_STATUS.REMINDED_AFTER_STARTED).length,
                [RESPONDENT_STATUS.SUBMITTED]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.SUBMITTED).length,
                [RESPONDENT_STATUS.EMAIL_BOUNCED]: respondentsStatusList.filter(s => s === RESPONDENT_STATUS.EMAIL_BOUNCED).length,
            })
        }

        if (currentSurvey.status === 'LIVE') {
            MENU_TABS[0] = 'Publishing' // 'Survey status'
            setActivePublishTypeTab(publishTypeTabs[0].type)
        } else {
            MENU_TABS[0] = 'Publishing'
        }

    }, [currentSurvey]);

    // Function to show a snackbar with an error message
    const openSnackbar = (msg) => {
        enqueueSnackbar(msg, {
            variant: 'error',
        });
        dispatch(resetErrors());
    };

    // Function to validate the recurring survey
    const validateRecurringSurvey = () => {
        if (currentSurvey.recurring === true) {
            return currentSurvey.recurringInterval && (currentSurvey.recurringDay || currentSurvey.recurringRate)
        }
        return true
    }

    // Function to handle the change of the invitation from name and invitation subject
    const handleChange = (value, inputType) => {
        console.log('value', value)
        if (inputType === INPUT_COMPANY) {
            delayedUpdate({ ...currentSurvey, invitationFromName: value });
            validateInput(value, INPUT_COMPANY);
        } else if (inputType === INPUT_SUBJECT) {
            delayedUpdate({ ...currentSurvey, invitationSubject: value });
            validateInput(value, INPUT_SUBJECT);
        }
    };

    // Function to validate the input
    const validateInput = (value, inputType) => {
        if (!value || value === '') {
            if (inputType === INPUT_COMPANY) {
                setInvitationFromName({ ...invitationFromName, value: value, error: true, errorMessage: 'required' });
            } else if (inputType === INPUT_SUBJECT) {
                setInvitationSubject({ ...invitationSubject, value: value, error: true, errorMessage: 'required' });
            }
            return false;
        } else {
            if (inputType === INPUT_COMPANY) {
                setInvitationFromName({ ...invitationFromName, value: value, error: false, errorMessage: '' });
            } else if (inputType === INPUT_SUBJECT) {
                setInvitationSubject({ ...invitationSubject, value: value, error: false, errorMessage: '' });
            }
        }
        return true;
    };

    // Function to validate the invitation email
    const validateInvitationEmail = () => {
        if (anonymousSwitch === false && embeddableSwitch === false) {
            return currentSurvey.invitationEmailText
                && currentSurvey.invitationEmailText.includes("{{{SurveyLink}}}");
        } return true
    };

    // Function to validate the dates
    const validateDates = () => {
        if (currentSurvey.startImmediately) { return true }

        else if (currentSurvey.endDate && (date.subtract(new Date(currentSurvey.startDate), new Date(currentSurvey.endDate)).toMinutes() >= 0)) {
            return false
        }
        else if (date.subtract(new Date(currentSurvey.startDate), new Date(Date.now())).toMinutes() < 0) {
            return false
        }
        return true
    };


    // Function to handle the survey launch click
    const handleSurveyLaunchClick = () => {
        if (anonymousSwitch === false && embeddableSwitch === false) {
            const fromNameValid = validateInput(invitationFromName.value, INPUT_COMPANY);
            const subjectValid = validateInput(invitationSubject.value, INPUT_SUBJECT);
            const recurringValid = validateRecurringSurvey()
            const questionsValid = currentSurvey.questions && currentSurvey.questions.length > 0 && !questionErrors;
            const respondentsValid = currentSurvey.respondents && currentSurvey.respondents.data.length && !respondentErrors;
            const invitationEmailValid = validateInvitationEmail();
            const datesValid = validateDates();

            console.log('fromNameValid', fromNameValid)
            console.log('subjectValid', subjectValid)
            console.log('questionsValid', questionsValid)
            console.log('respondentsValid', respondentsValid)
            console.log('invitationEmailValid', invitationEmailValid)
            console.log('datesValid', datesValid)
            console.log('recurringValid', recurringValid)

            if (fromNameValid && subjectValid && questionsValid && respondentsValid && invitationEmailValid && datesValid && recurringValid) {
                setPublishingSurvey(true);
                if (!currentSurvey.startImmediately) {
                    dispatch(scheduleSurvey(currentSurvey));
                } else if (currentSurvey.startImmediately && !currentSurvey.endDate) {
                    dispatch(publishSurvey(currentSurvey.id, currentSurvey.endDate, findUsernameWithEmail(userData.email)));
                } else {
                    // schedule end but send invites right away
                    dispatch(publishSurvey(currentSurvey.id, currentSurvey.endDate, findUsernameWithEmail(userData.email)));
                }
            } else if (!invitationEmailValid) {
                openSnackbar('Invitation email must contain survey link!')
            } else if (!datesValid) {
                openSnackbar('Survey start or end date is invalid!')
            } else if (!recurringValid) {
                openSnackbar('If survey is recurring you must populate all the required fields')
            } else {
                openSnackbar('survey invalid, fix errors')
            }
        }
        else {
            setPublishingSurvey(true);
            if (!currentSurvey.startImmediately) {
                dispatch(scheduleSurvey(currentSurvey));
            } else if (currentSurvey.startImmediately && !currentSurvey.endDate) {
                dispatch(publishSurvey(currentSurvey.id, currentSurvey.endDate, findUsernameWithEmail(userData.email)));
            } else {
                // schedule end but send invites right away
                dispatch(publishSurvey(currentSurvey.id,  currentSurvey.endDate, findUsernameWithEmail(userData.email)));
            }
        }
    };

    // Function to handle the menu tab click
    const handleMenuTabClick = (tab) => {
        setActiveTab(tab);
    };

    // Function to handle the publish type tab click
    const handlePublishTypeTabClick = (tab) => {
        setActivePublishTypeTab(tab);
        console.log('tab', tab)
    };

    // Function to handle the open dialog click
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    // Function to handle the close dialog
    const handleCloseDialog = (shouldCloseSurvey) => {
        setOpenDialog(false);
        if (shouldCloseSurvey) {
            dispatch(closeSurvey(currentSurvey));
        }
    };

    // Function to handle the refresh click
    const handleRefreshClick = () => {
        dispatch(getRespondents(currentSurvey.id));
    };

    // Function to handle the download button click
    const handleDownloadButton = () => {
        dispatch(downloadSurveyData(currentSurvey.id, currentSurvey.id));
        setUrlFetched(true);
    }

    // Function to handle the anonymmous switch
    const handleAnonymmousSwitch = (checked) => {
        if (checked) {
            setAnonymousSwitch(true);
            setAnonymousLink(`${window.location.host}/?token=${currentSurvey.surveyAccessToken}&anonymous=true`)
            delayedUpdate({ ...currentSurvey, anonynmous: true })
        }
        else {
            if (!embeddableSwitch) {
                setAnonymousSwitch(false);
                setAnonymousLink(``)
                setCopiedLink('')
                delayedUpdate({ ...currentSurvey, anonynmous: false })
            }
        }
    }

    // Function to handle the embeddable switch
    const handleEmbeddableSwitch = (checked) => {
        if (checked) {
            setAnonymousSwitch(true);
            setEmbeddableSwitch(true);
            //setAnonymousLink(`${window.location.host}/?token=${currentSurvey.surveyAccessToken}&anonymous=true`);
            //setEmbeddableLink(`<div style="padding: 56.25% 0 0 0; position: relative; height: 3700px;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 3700px;" src="${window.location.host}/?token=${currentSurvey.surveyAccessToken}&anonymous=true&embedded=true" frameborder="0"></iframe></div>`)
            delayedUpdate({ ...currentSurvey, embeddable: true, anonynmous: true })
        }
        else {
            setAnonymousSwitch(false);
            setEmbeddableSwitch(false);
            setCopiedLink('')
            delayedUpdate({ ...currentSurvey, embeddable: false, anonynmous: false })
        }
    }

    // Function to handle the copy button
    const handleCopyButton = (link) => {
        if (link) {
            navigator.clipboard.writeText(link)
            if (link.includes('embedded')) {
                setCopiedLink('embedded')
            } else {
                setCopiedLink('anonymous')
            }
        }
    }

    // Function to extract the content
    const extractContent = (s) => {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    };

    // Update divider style on active publish type tab change
    useEffect(() => {
        const updateDividerStyle = () => {
            // Get active tab element and set divider style based on it
            if (tabsRef.current) {
                console.log('tabsRef.current', tabsRef.current)
                const activeTabElement = tabsRef.current.querySelector('.publish-type-tab.active');
                if (activeTabElement) {
                    const { offsetWidth, offsetLeft } = activeTabElement;
                    setDividerStyle({ width: offsetWidth, left: offsetLeft });
                    console.log('activeTabElement', activeTabElement)
                }
            }
        };
        updateDividerStyle();
    }, [activePublishTypeTab]);

    // Find username with email
    function findUsernameWithEmail(email) {
        if (cognitoUsers) {
            let user = cognitoUsers.find(user => user.Attributes.find(attr => attr.Value === email));
            if (user) {
                return user.Username;
            }
        }
        return 'Not found';
    }
    
    // Find and return  givenName and familyName of the user
    const findUserInCognitoUsers = (id) => {
        if (cognitoUsers) {
            let user = cognitoUsers.find(user => user.Username === id);
            if (user) {
                const givenNameAttr = user.Attributes.find(attr => attr.Name === 'given_name');
                const familyNameAttr = user.Attributes.find(attr => attr.Name === 'family_name');
                const givenName = givenNameAttr ? givenNameAttr.Value : '';
                const familyName = familyNameAttr ? familyNameAttr.Value : '';

                if (givenName || familyName) {
                    return `${givenName} ${familyName}`.trim();
                } else {
                    return 'User has not set a name';
                }
            }
        }
        return 'Not found';
    }

    return {
        currentSurvey,
        invitationFromName,
        invitationSubject,
        respondentStatus,
        publishingSurvey,
        openDialog,
        activeTab,
        urlFetched,
        anonymousSwitch,
        copiedLink,
        anonymousLink,
        embeddableSwitch,
        embeddableLink,
        dividerStyle,
        publishTypeTabs,
        activePublishTypeTab,
        tabsRef,
        handleCopyButton,
        handleAnonymmousSwitch,
        handleEmbeddableSwitch,
        handleSurveyLaunchClick,
        handleMenuTabClick,
        handleClickOpenDialog,
        handleCloseDialog,
        handleRefreshClick,
        handleDownloadButton,
        handleChange,
        extractContent,
        handlePublishTypeTabClick,
        findUserInCognitoUsers
    };
};