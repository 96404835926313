import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateGeneralSettings } from '../../redux/actions/index';
import _ from 'lodash';

// List of languages
const languages = [
    { value: 'en', label: 'English' },
    { value: 'no', label: 'Norwegian' },
    { value: 'ar', label: 'Arabic' },
    { value: 'sv', label: 'Swedish' }
];

// Custom hook to enhance survey settings functionality
export function UseSurveySettings() {
    const dispatch = useDispatch(); // Dispatch
    const currentSurvey = useSelector(state => state.currentSurvey); // Current survey
    const isFirstRender = useRef(true); // Ref to track first render

    // State variables
    const [confirmationNote, setConfirmationNote] = useState(false); // State to store confirmation note
    const [questionNumeration, setQuestionNumeration] = useState(false); // State to store question numeration
    const [progressBar, setProgressBar] = useState(false); // State to store progress bar
    const [language, setLanguage] = useState(languages[0].value); // State to store language
    const [userCustom, setUserCustom] = useState(false);  // State to store user custom
    const [confirmNameTitle, setConfirmNameTitle] = useState(''); // State to store confirm name title
    const [confirmNameText, setConfirmNameText] = useState(''); // State to store confirm name text 
    const [allowRespondentsToEdit, setAllowRespondentsToEdit] = useState(false); // State to store allow respondents to edit
    const [focusedField, setFocusedField] = useState(''); // State to store currently focused field

    // Handle initial state from currentSurvey
    useEffect(() => {
        if (isFirstRender.current && currentSurvey) {
            setConfirmationNote(currentSurvey.confirmationNote ?? true);
            setQuestionNumeration(currentSurvey.questionNumeration ?? true);
            setProgressBar(currentSurvey.progressBar ?? true);
            setLanguage(currentSurvey.language ?? languages[0].value);
            setUserCustom(currentSurvey.userCustom ?? false);
            setConfirmNameTitle(currentSurvey.confirmNameTitle ?? '');
            setConfirmNameText(currentSurvey.confirmNameText ?? '');
            setAllowRespondentsToEdit(currentSurvey.edit_after_submit ?? false);
            isFirstRender.current = false;
        }
    }, [currentSurvey]);

    // Throttle function to update general settings
    const delayedUpdate = useCallback(_.throttle(updateObject =>
        dispatch(updateGeneralSettings(updateObject)), 1000),
        [dispatch]);

    // Function to handle user custom change
    const handleUserCustomChange = (event, defaultTitle, defaultText) => {
        const value = event.target.value === 'custom';
        setUserCustom(value);
        delayedUpdate({ userCustom: value });

        if(!value) {
            setConfirmNameTitle(defaultTitle);
            setConfirmNameText(defaultText);
            delayedUpdate({ confirmNameTitle: defaultTitle, confirmNameText: defaultText });
        }
    };

    // Function to handle language change
    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
        delayedUpdate({ language: event.target.value });
    };

    // Function to handle toggle change
    const handleToggleChange = (setter, value, field) => {
        setter(value);

        // Check if field is confirmationNote and value is false to set userCustom to false
        if (field === 'confirmationNote' && !value) {
            setUserCustom(false);
            delayedUpdate({ [field]: value, userCustom: false });
        } else {
            delayedUpdate({ [field]: value });
        }
    };

    // Function to handle confirm name title change
    const handleConfirmNameTitleChange = (event) => {
        setConfirmNameTitle(event.target.value);
        delayedUpdate({ confirmNameTitle: event.target.value });
    };

    // Function to handle confirm name text change
    const handleConfirmNameTextChange = (event) => {
        setConfirmNameText(event.target.value);
        delayedUpdate({ confirmNameText: event.target.value });
    };

    // Function to handle tag click
    const handleTagClick = (tag) => {
        if (focusedField === 'title') {
            if (!confirmNameTitle.includes(tag)) {
                setConfirmNameTitle(prevTitle => `${prevTitle} ${tag}`.trim());
                delayedUpdate({ confirmNameTitle: `${confirmNameTitle} ${tag}`.trim() });
            }
        } else if (focusedField === 'text') {
            if (!confirmNameText.includes(tag)) {
                setConfirmNameText(prevText => `${prevText} ${tag}`.trim());
                delayedUpdate({ confirmNameText: `${confirmNameText} ${tag}`.trim() });
            }
        }
    };

    // Function to handle field focus
    const handleFieldFocus = (field) => {
        setFocusedField(field);
    };

    // Return the properties to be used in the components
    return {
        languages,
        confirmationNote, setConfirmationNote,
        questionNumeration, setQuestionNumeration,
        progressBar, setProgressBar,
        language, setLanguage,
        userCustom, setUserCustom,
        confirmNameTitle, setConfirmNameTitle,
        confirmNameText, setConfirmNameText,
        allowRespondentsToEdit, setAllowRespondentsToEdit,
        handleUserCustomChange,
        handleLanguageChange,
        handleToggleChange,
        handleConfirmNameTitleChange,
        handleConfirmNameTextChange,
        handleTagClick,
        handleFieldFocus,
    };
}
