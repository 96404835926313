import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@mui/material';
import { ReactComponent as Icon } from '../../assets/images/login-register/tooltip-icon.svg';

const TooltipIcon = ({ title, content, placement, backgroundColor, strokeColor, iconColor, backgroundHover, strokeHover, iconHover }) => {

    const useStylesBootstrap = makeStyles(() => ({ 
        tooltip: {
            backgroundColor: 'transparent',
            marginTop: '5px',
        },
        icon: {
            '& path': {
                fill: iconColor,
                transition: 'fill 0.3s ease',
            },
            '& circle': {
                fill: backgroundColor,
                stroke: strokeColor,
                transition: 'fill 0.3s ease, stroke 0.3s ease',
            },
            '&:hover': {
                '& path': {
                    fill: iconHover,
                },
                '& circle': {
                    fill: backgroundHover,
                    stroke: strokeHover,
                },
            }
        },
        tooltipText: {
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 1px 2.5px 0px #7B7D8982',
            borderRadius: '4px',
            padding: '10px 12px',
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            '& h3': {
                color: 'var(--text-mediumGrey)',
                fontSize: '12px',
                fontWeight: '500',
            },
            '& p': {
                color: 'var(--text-lightGrey)',
                fontSize: '12px',
                lineHeight: '20px',
            }
        }
    }));

    const classes = useStylesBootstrap();

    return (
        <Tooltip title={
            <Box className={classes.tooltipText}>
                {
                    title && <Typography variant="h3">{title}</Typography>
                }
                <Typography variant="body1">{content}</Typography>
            </Box>
        }
            classes={{ tooltip: classes.tooltip }}
            placement={placement}>
            <Icon className={classes.icon}/>
        </Tooltip>
    );
};

export default TooltipIcon;
