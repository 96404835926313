// Import necessary libraries and components
import React from "react";
import './my-teammates.scss';
import { Typography } from '@material-ui/core';
import List from '@mui/material/List';
import { useHistory } from 'react-router-dom';
import { ReactComponent as PlusIcon } from "../../assets/images/dashboard/plus-icon.svg";
import TeammateListItem from "./teammates-list";
import useMyTeammates from "../../hooks/teammates/use-my-teammates";
import { setSidebarActivePage } from '../../redux/actions';
import { SIDEBAR_TAB_TEAM } from '../../assets/constants';
import { useDispatch } from 'react-redux';

// Define a constant for the settings action
const ACTION_SETTINGS = 'ACTION_SETTINGS';

// Define a Button component
const Button = ({ children, onClick }) => {
    return (
        <button className="add-teammates-button" onClick={onClick}>
            <span className="add-teammates-button-icon">
                <PlusIcon />
            </span>
            <p>{children}</p>
        </button>
    );
}

// Define the MyTeammates component
const MyTeammates = () => {
    // Use the useMyTeammates hook to get teammate data
    const { isEmpty, numberOfTeammates, firstUser, secondUser } = useMyTeammates();

    // Get the history object and dispatch function
    const history = useHistory();
    const dispatch = useDispatch();

    // Define a function to handle action clicks
    const handleActionClick = (action) => {
        switch (action) {
            case ACTION_SETTINGS:
                // If the action is settings, navigate to the settings page and set the active sidebar page
                history.push(`/settings`);
                dispatch(setSidebarActivePage(SIDEBAR_TAB_TEAM));
                break;
            default:
                break;
        }
    };

    // Define a function to handle manage clicks
    const handleManageClick = () => {
        handleActionClick(ACTION_SETTINGS);
    };

    // Render the component
    return (
        <div className="my-teammates">
            <div className="my-teammates-header">
                <Typography variant="h3">My Teammates</Typography>
                {
                    // If there are teammates, display the number of teammates
                    !isEmpty && <Typography variant="h4">{numberOfTeammates}</Typography>
                }
            </div>
            {
                // If there are no teammates, display the Add Teammates button
                // Otherwise, display the list of teammates and the Manage button
                isEmpty ? <Button onClick={handleManageClick} >Add Teammates</Button>
                    : <>
                        <List>
                            <TeammateListItem user={firstUser} />
                            <TeammateListItem user={secondUser} />
                        </List>
                        <button className="manage-button" onClick={handleManageClick}>Manage</button>
                    </>
            }
        </div>
    );
}

// Export the MyTeammates component
export default MyTeammates;