import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { convertUnixToLocalTime } from '../../utils/unix-to-local-time';
import { useIpFetch } from '../../utils/get-ip';
import { useLocation } from 'react-router-dom';



export function useActivityInfo() {
    const userData = useSelector(state => state.userData); // Retrieve user data from Redux store
    const { ipAddress, error } = useIpFetch();
    const location = useLocation();  // Hook from React Router

    const [authTime, setAuthTime] = useState('Loading...'); // State for storing formatted authentication time

    useEffect(() => {
        // Updates authTime whenever userData changes
        if (userData && userData.authTime) {
            const formattedTime = convertUnixToLocalTime(userData.authTime);
            setAuthTime(formattedTime);
        }

        console.log('userData:', userData)

        if (location.pathname === '/profile') {
            console.log('Fetching IP address...')

        }



    }, [userData, location.pathname]);

    return {
        authTime, // Authenticated time formatted
        ipAddress: ipAddress || 'No IP recorded' // IP address or default message
    };
}
