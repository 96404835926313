import React from 'react';
import { TableRow, TableCell, Typography, Box } from '@mui/material';

// Generic EmptyStateRow component
function EmptyStateRow({ icon: Icon, mainMessage, secondaryMessages, colSpan }) {
    return (
        <TableRow sx={{ borderBottom: "none", borderTop: "none" }}>
            <TableCell colSpan={colSpan} sx={{ borderBottom: "none", borderTop: "none" }}>
                <Box textAlign="center" sx={{ width: '100%', pt: 5, pb: 5 }}>
                    <Icon style={{ margin: 'auto', display: 'block' }} />
                    <Typography sx={{ mt: 2 }} variant="body1">{mainMessage}</Typography>
                    {secondaryMessages.map((msg, index) => (
                        <Typography key={index} sx={{ mt: index === 0 ? 1 : 0.5, color: '#4F4F4F' }} variant="body2">{msg}</Typography>
                    ))}
                </Box>
            </TableCell>
        </TableRow>
    );
}

export default EmptyStateRow;
