import React from "react";
import { Typography } from '@mui/material';
import { colorTheme } from '../../assets/theme';

// TitleEditTab component
const TitleEditTab = ({ title, marginBottom }) => {
  return (
    <Typography variant="h6" sx={{fontSize: '18px', fontWeight: 500, lineHeight: '24px', letterSpacing: '0.15px', color: colorTheme.theme.transform.black}} mb={marginBottom}>{title}</Typography>
  );
};

export default TitleEditTab;