import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Grid, Link } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import TooltipIcon from '../tooltip-text/tooltip-icon';

const LicenseInfo = () => {
    const userData = useSelector(state => state.userData);
    // Convert string 'true'/'false' to boolean true/false
    const [insightsLicense, setInsightsLicense] = useState(userData?.insightsLicense);
    const [surveyLicense, setSurveyLicense] = useState(userData?.surveyLicense);

    useEffect(() => {
        setInsightsLicense(userData?.insightsLicense);
        setSurveyLicense(userData?.surveyLicense);
        console.log('userData:', userData);
    }
        , [userData]);


    return (
        <Box width={'55%'} sx={{
            p: 1, flexGrow: 1,
            '@media (max-width:1200px)': {
                width: '80%'
            }
        }}>
            <Typography mb={3} variant="h6" gutterBottom>Your Licenses</Typography>
            <Grid container spacing={8}>
                {surveyLicense === 'true' && (
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                                SURVEY
                                <TooltipIcon content={'Survey License Information'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                            </Typography>
                        </Box>
                        <Typography
                            mb={2}
                            color={colorTheme.theme.transform.darkBlueTwo}
                            variant="body2" gutterBottom>Super Baseline license type 01</Typography>
                        <Typography variant="body2"
                            color={colorTheme.theme.text.lightGrey}
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam metus nibh, lacinia quis ligula vitae, varius consectetur metus.
                        </Typography>
                    </Grid>
                )}
                {insightsLicense === 'true' && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                            INSIGHTS
                            <TooltipIcon content={'Insights License Information'} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                        </Typography>
                        <Typography
                            mb={2}

                            color={colorTheme.theme.transform.darkBlueTwo}
                            variant="body2" gutterBottom>Super Insights license type 01</Typography>
                        <Typography variant="body2"
                            color={colorTheme.theme.text.lightGrey}
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam metus nibh, lacinia quis ligula vitae, varius consectetur metus.
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Typography variant="body2" sx={{ mt: 3 }}>
                For any additional information contact <Link
                    href="mailto:saler@partnersite.com"
                    sx={{
                        color: colorTheme.theme.transform.darkBlueTwo,
                        textDecoration: 'none' // This removes the underline
                    }}
                >
                    saler@partnersite.com
                </Link>.
            </Typography>
        </Box>
    );
};

export default LicenseInfo;
