import React from "react";
import { Box } from '@mui/material';
import './single-line-text-question.scss';
import LimitNumberOfAnswers from "../../../../../../components/editor/limit-number-of-answers";
import useSingleLineAdvancedSettings from './../../../../../../hooks/editor/single-line-text/use-single-line-advanced-settings';

const SingleLineAdvancedSettings = (props) => {
    const question = props.template;

    const {
        validationMinCheck,
        validationMaxCheck,
        inputOption,
        handleMinValidationCheck,
        handleMaxValidationCheck,
        handleTextInput

    } = useSingleLineAdvancedSettings(question);

    return (
        <Box
            ml={3}>
            <LimitNumberOfAnswers
                validationMaxCheck={validationMaxCheck}
                validationMinCheck={validationMinCheck}
                inputOption={inputOption}
                handleMinValidationCheck={handleMinValidationCheck}
                handleMaxValidationCheck={handleMaxValidationCheck}
                handleTextInput={handleTextInput}
                questionType={question.element_type}
            />
        </Box>
    );
};

export default SingleLineAdvancedSettings;
