import React, { useState } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import InputField from '../form/input-field';
import PrimaryButton from '../buttons/primary-button';
import { colorTheme } from '../../assets/theme';
import { ReactComponent as SaveIcon } from "./../../assets/images/actions/icon-save.svg";
import usePasswordManagement from '../../hooks/profile/use-change-password';
import ForgotPassword from '../dialogs/forgot-password';
import ResetPassword from '../dialogs/reset-password';

const EditPassword = ({ onSave, onCancel }) => {

    const {
        passwordStrength,
        passwordMessage,
        handleNewPasswordChange,
        setRepeatNewPassword,
        setCurrentPassword,
        handleChangePassword,
        getPasswordStrengthColor,
        passwordStrengthPercent,
        error,
        repeatNewPasswordChange,
        isLoading
    } = usePasswordManagement(onCancel);

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [email, setEmail] = useState('');


    const handleSaveChanges = () => {
        setOpen(false);
    }



    return (

        <><ResetPassword
            handleChangePassword={handleChangePassword}
            setCurrentPassword={setCurrentPassword}
            setRepeatNewPassword={setRepeatNewPassword}
            handleNewPasswordChange={handleNewPasswordChange}
            passwordStrengthPercent={passwordStrengthPercent}
            passwordStrength={passwordStrength}
            passwordMessage={passwordMessage}
            getPasswordStrengthColor={getPasswordStrengthColor}
            open={resetPassword}
            onCancel={onCancel}
            handleClose={() => setResetPassword(false)}
            title="Reset your password"
            buttonText='Reset password'
            email={email}
            handleSaveChanges={handleSaveChanges}
            textFieldWidth='460px' /><

                ><ForgotPassword
                    open={open}
                    setResetPassword={setResetPassword}
                    handleClose={handleClose}
                    title="Forgot your password?"
                    buttonText='Get confirmation code'
                    email={email}
                    setEmail={setEmail}
                    handleSaveChanges={handleSaveChanges}
                    textFieldWidth='460px' />

                <Box sx={{
                    display: 'inline',
                    gap: 3,
                    ml: 2,
                    mt: 3,
                    width: '100%',
                    '@media (max-width:1200px)': {
                        flexDirection: 'column',
                        alignItems: 'start',
                        ml: 2,
                    }
                }}>
                    <Box width={'45%'}>
                        <Typography variant="body1" fontWeight={500} gutterBottom>Change Password</Typography>
                        <Typography variant="caption" sx={{ color: colorTheme.theme.colors.grayDarker }}>Password must contain minimum of  8 letters and 3 different classes of characters. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        gap: 3,
                        ml: 0,
                        mt: 2,
                        width: '100%',
                        justifyContent: 'flex-start',
                        '@media (max-width:1200px)': {
                            flexDirection: 'column',
                            alignItems: 'start',
                            ml: 0,
                        }
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 2,
                            width: '100%',
                            '@media (max-width:1200px)': {
                                flexDirection: 'column',
                                alignItems: 'start',
                                ml: 0,
                            }
                        }}>
                            <Box sx={{
                                display: 'flex',
                                textAlign: 'center',
                                gap: 3,
                                alignItems: 'center',
                            }}
                            >
                                <InputField
                                    type="password"
                                    label="Current password"
                                    textFieldWidth={'300px'}
                                    onChange={(event) => setCurrentPassword(event.target.value)}
                                    sx={{
                                        '@media (max-width:1200px)': {
                                            width: '100%',
                                            alignItems: 'start',
                                        }
                                    }} />
                                <Typography variant="body2"
                                    onClick={() => setOpen(true)}
                                    color={colorTheme.theme.transform.darkBlueTwo}
                                    fontWeight={400} gutterBottom
                                    ml={-0.5}
                                    sx=
                                    {{
                                        padding: '4px 8px',
                                        borderRadius: '30px',
                                        transition: 'color 0.3s ease-in-out, background-color 0.3s ease-in-out',
                                        '&:hover': {
                                            color: colorTheme.theme.transform.darkBlue,
                                            backgroundColor: '#0C255C12',
                                        }
                                    }}

                                >Forgot your password?</Typography>
                            </Box>
                            <Box sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 2,
                                width: '100%',
                                '@media (max-width:1200px)': {
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    ml: 0,
                                }
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 3,
                                    alignItems: 'center',
                                    mt: 1,
                                    '@media (max-width:1200px)': {
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        ml: 0,
                                    }
                                }}>
                                    <InputField
                                        type="password"
                                        label="New password"
                                        error={passwordMessage === "Not a valid password."}
                                        textFieldWidth={'300px'}
                                        onChange={handleNewPasswordChange}
                                        sx={{
                                            '@media (max-width:1200px)': {
                                                width: '100%',
                                                alignItems: 'start',
                                            }
                                        }} />
                                    <InputField
                                        type="password"
                                        error={error === "Passwords do not match."}
                                        label="Repeat New password"
                                        textFieldWidth={'300px'}
                                        onChange={repeatNewPasswordChange}
                                        sx={{
                                            '@media (max-width:1200px)': {
                                                width: '100%',
                                            }
                                        }} />

                                    <PrimaryButton onClick={onCancel} color="white" hoverColor="#EDF0F4" text="Cancel" hoverText={colorTheme.theme.transform.darkBlue} />
                                    <PrimaryButton
                                        text="Save"
                                        isLoading={isLoading}
                                        Icon={SaveIcon}
                                        color={colorTheme.theme.transform.darkBlueTwo}
                                        hoverColor={colorTheme.theme.transform.darkBlue}
                                        onClick={handleChangePassword} />
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    width: '622px',
                                    '@media (max-width:1200px)': {
                                        width: '100%',
                                        alignItems: 'centers',
                                    }
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        width: '300px',
                                        '@media (max-width:1200px)': {
                                            width: '100%',
                                        }
                                    }}>

                                        <LinearProgress
                                            variant="determinate"
                                            value={passwordStrengthPercent}
                                            sx={{
                                                mt: -1,
                                                width: '100%',
                                                height: '6px',
                                                borderRadius: '5px',
                                                backgroundColor: '#D7D7D7',
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: getPasswordStrengthColor(passwordStrength),
                                                },
                                                '@media (max-width:1200px)': {
                                                    display: 'none',
                                                }
                                            }} />

                                        <Typography sx={{
                                            fontSize: '12px !important', textAlign: 'right', mt: 0, color: getPasswordStrengthColor(passwordStrength),
                                            '@media (max-width:1200px)': {
                                                display: 'none',
                                            }
                                        }}>
                                            {passwordMessage}
                                        </Typography>
                                    </Box>

                                    <Box sx={{
                                        display: 'flex',
                                        width: '330px',
                                        gap: 2,
                                        mt: -1.5,
                                        justifyContent: 'flex-end',
                                        '@media (max-width:1200px)': {
                                            display: 'none',
                                        }
                                    }}>

                                        <Typography color="#E23D32" sx={{ mt: 0, fontSize: '12px', visibility: error ? 'visible' : 'hidden' }}>
                                            {error}
                                        </Typography>
                                    </Box>


                                </Box>


                            </Box>
                        </Box>
                    </Box>
                </Box></></>
    );
};

export default EditPassword;
