import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { passwordChanged } from '../../utils/pwd-change';
import { useSelector } from 'react-redux';
import { fetchUserDetails } from '../../utils/fetch-user';
import { useDispatch } from 'react-redux';
import { validatePassword } from '../../utils/pwd-validate';



const usePasswordManagement = (onCancel) => {
    const [newPassword, setNewPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordMessage, setPasswordMessage] = useState('Password Strength');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [error, setError] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const cognitoUsers = useSelector(state => state.cognitoUsers);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);


    const calculatePasswordStrength = (password) => {
        let strength = 0;
        const uppercaseCriteria = /[A-Z]/.test(password);
        const specialCharCriteria = /[^A-Za-z0-9]/.test(password);

        if (password.length >= 8) strength += 1;
        if (uppercaseCriteria) strength += 1;
        if (specialCharCriteria) strength += 1;

        if (strength === 0) {
            setPasswordMessage('Weak');
        } else if (strength === 1 || strength === 2) {
            setPasswordMessage('Medium');
        } else if (strength === 3) {
            setPasswordMessage('Strong');
        }

        return strength; // Now, 0 to 3
    };



    const handleChangePassword = async () => {
        setIsLoading(true);
        if (newPassword !== repeatNewPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log('User:', user);
            console.log('Current Password:', currentPassword);
            console.log('New Password, Repeat Password:', newPassword, repeatNewPassword);
            const res = await Auth.changePassword(user, currentPassword, newPassword);
            const email = user.attributes.email;
            await passwordChanged(email, cognitoUsers, dispatch);
            console.log('Password changed successfully:', res);
            onCancel();
            enqueueSnackbar('Password changed successfully!', { variant: 'success' });
            fetchUserDetails(dispatch);
            setIsLoading(false);
        } catch (error) {
            setError(error.message);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const repeatNewPasswordChange = (event) => {
        setRepeatNewPassword(event.target.value);
        if (newPassword !== event.target.value && event.target.value !== '') {
            setError("Passwords do not match.");
            return;
        }
        else {
            console.log('Passwords match');
            setError('');
        }
    }


    const handleNewPasswordChange = (event) => {

        const password = event.target.value;
        setNewPassword(password);
        if (password.length === 0) {
            setPasswordMessage('Password Strength');
        }
        else if (!validatePassword(password, setPasswordMessage)) {
            setPasswordStrength(0);

        } else {
            const strength = calculatePasswordStrength(password);
            setPasswordStrength(strength);
            updatePasswordMessage(strength);
        }
    };

    const updatePasswordMessage = (strength) => {
        switch (strength) {
            case 0:
            case 1:
                setPasswordMessage('Weak');
                break;
            case 2:
                setPasswordMessage('Medium');
                break;
            case 3:
                setPasswordMessage('Strong');
                break;
            default:
                setPasswordMessage('Password Strength');
        }
    };

    const getPasswordStrengthColor = (strength) => {
        if (passwordMessage === 'Password Strength') return '#D7D7D7';
        if (passwordMessage === 'Not a valid password') return '#E23D32';
        if (strength === 0 || strength === 1) return '#E23D32';
        if (strength === 2) return '#F87F3B';
        if (strength === 3) return '#00A707';
        return '#D7D7D7';
    };

    const passwordStrengthPercent = (passwordStrength / 3) * 100;

    return {
        newPassword,
        repeatNewPassword,
        currentPassword,
        passwordStrength,
        passwordMessage,
        error,
        handleNewPasswordChange,
        setRepeatNewPassword,
        setCurrentPassword,
        handleChangePassword,
        getPasswordStrengthColor,
        passwordStrengthPercent,
        setError,
        repeatNewPasswordChange,
        isLoading
    };
};

export default usePasswordManagement;
