/**
 * Function that return is string has only digits
 * @param value
 * @returns {boolean}
 */
export const isStringDigits = (value) => {
    return /^-?\d*\.{0,1}\d+$/.test(value);
};
/**
 * Function checks is string is not empty and have only string digits
 * @param value
 * @returns {boolean}
 */
export const isError = (value) => {
    return value !== '' && !isStringDigits(value);
};

export const handleRemoveButton = (id, list, listSetter) => {
    let filteredData = list.filter(item => item.item_id !== id)
    listSetter(filteredData);
    return filteredData;
};

export const mapSurveyQuestions = (questionList) => {
    let mappedList = [];
    questionList.forEach((questionItem) => {
        if (questionItem.question_text) {
            mappedList.push({ id: questionItem.id, text: questionItem.question_text })
        }
    })
    return mappedList
}

export const getQuestionId = (questions, questionId) => {
    return questions.find(q => q.id === questionId)
}