import React from "react";
import './app-card.scss'; // Import your styles

const AppCard = ({ title, icon, description, onClick }) => {
    const handleClick = (e) => {
        e.stopPropagation(); // Prevent event bubbling
        if (onClick) {
            onClick(); // Call the passed onClick function if it exists
        }
    };

    return (
        <div className="app-card" onClick={handleClick} role="button" tabIndex={0} style={{ cursor: 'pointer' }}>
            <div className="app-card-header">
                <div className="app-card-icon">
                    {icon}
                </div>
                <div className="app-card-description">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
}

export default AppCard;
