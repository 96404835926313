import React from 'react';
import { Box, Typography } from '@mui/material';
import { useActivityInfo } from './../../hooks/profile/use-profile-activity';

const ActivityInfo = () => {
    // Using the hook to manage the state and side effects related to activity info
    const { authTime, ipAddress } = useActivityInfo();

    return (
        <Box sx={{ p: 1 }}>
            <Typography mb={3} variant="h6" gutterBottom>Activity</Typography>
            <Typography fontWeight={500} variant="body1" gutterBottom>Last sign-in:</Typography>
            <Typography mb={2} variant="body2" gutterBottom>{authTime}</Typography>
            <Typography fontWeight={500} variant="body1" gutterBottom>Last sign-in IP:</Typography>
            <Typography variant="body2" gutterBottom>{ipAddress}</Typography>
        </Box>
    );
};

export default ActivityInfo;
