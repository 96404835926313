import React from "react";
import { Box, Typography } from '@mui/material';
import { colorTheme } from "../../assets/theme";
import TooltipIcon from "../tooltip-text/tooltip-icon";

// TitleTooltip component
const TitleTooltip = ({ title, tooltipText, marginBottom }) => {
    return (
        <Box display="flex" alignItems="center" gap={1.5} mb={marginBottom}>
            <Typography variant="h5" sx={{ lineHeight: '24px', fontSize: '18px', fontWeight: '500', color: colorTheme.theme.text.darkGrey }}>{title}</Typography>
            <TooltipIcon content={tooltipText} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
        </Box>
    );
}

export default TitleTooltip;