import React from 'react';
import { Dialog, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { ReactComponent as Icon } from "./../../assets/images/actions/icon-delete.svg";
import { colorTheme } from '../../assets/theme';
import HeaderDialog from './header-dialog';
import PrimaryButton from '../buttons/primary-button';

// DeleteSurveyDialog component
export default function DeleteSurveyDialog({ open, handleClose, handleAction, isLoading, surveyToDelete }) {
    const surveyName = surveyToDelete?.name; // Get the survey name

    console.log(surveyToDelete);

    // Handle the delete action
    function handleDelete() {
        handleAction();
    }

    return (
        <Dialog open={open} onClose={handleClose}
            sx={{ '& .MuiDialog-paper': { width: '550px' } }} // Set the dialog width
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog Icon={Icon} handleClose={handleClose} title={`Confirm Deletion`} />
            <DialogContent
                sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                <DialogContentText
                    variant='body2'
                    sx={{ color: colorTheme.theme.text.darkGrey, lineHeight: "24px", pt: 2 }}>
                    This action will permanently delete the survey <span style={{ fontWeight: 500 }}>'{surveyName ? surveyName : "untitled"}'</span> and all related resources, including respondent data. Are you sure you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pt: 2, pb: 2, pr: 2 }}>
                <PrimaryButton onClick={handleClose} color="white" hoverColor="#EDF0F4" text="Cancel" hoverText={colorTheme.theme.transform.darkBlue} />
                <PrimaryButton onClick={handleDelete}
                    isLoading={isLoading}
                    color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="DELETE" />
            </DialogActions>
        </Dialog>
    );
}
