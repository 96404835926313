// utils/userUtils.js
import { storeUserData, listCognitoUsersAction } from '../redux/actions';
import { Auth } from 'aws-amplify';

// Assuming determineUserRole and determineUserLicense are functions you've defined elsewhere
import { determineUserRole, determineUserLicense } from './role';


// This function fetches user details from AWS Cognito and dispatches an action to store the user data in the Redux store.
// Optional enqueueSnackbar for showing notifications on the UI
export const fetchUserDetails = async (dispatch, enqueueSnackbar = null) => {
    try {
        // Fetch the current authenticated user from AWS Cognito. The bypassCache option forces Cognito to retrieve the latest user data.
        const userDetails = await Auth.currentAuthenticatedUser({ bypassCache: true });

        // Extract the user attributes from the user details.
        const userAttributes = userDetails.attributes || {};
        console.log('User attributes:', userAttributes);
        console.log('User details:', userDetails);

        // Create a user object with the relevant attributes.
        let user = {
            email: userAttributes.email,
            firstName: userAttributes.given_name,
            lastName: userAttributes.family_name,
            role: userAttributes['custom:role'],
            surveyLicense: userAttributes['custom:surveyLicense'],
            insightsLicense: userAttributes['custom:insightsLicense'],
            surveyAccessLevel: userAttributes['custom:surveyAccessLevel'],
            authTime: userDetails.signInUserSession.idToken.payload.auth_time,
            phone_number: userAttributes.phone_number,
            lastPwdChanged: userAttributes['custom:lastPwdChanged'],
            picture: userAttributes.picture,
            twoFAEnabled: userAttributes['custom:twoFAEnabled'],

        };

        // Determine the user's role and license based on the user data.
        const computedRole = determineUserRole(user);
        const licences = determineUserLicense(user);

        // Dispatch an action to store the user data in the Redux store.
        dispatch(storeUserData({
            ...user,
            computedRole,
            licences
        }));

        console.log('Fetched updated user details: ', userDetails);
        dispatch(listCognitoUsersAction());
    } catch (error) {
        // Log the error
        console.error('Error fetching user details from Cognito: ', error);

        // Optionally show a snackbar if the function is available
        if (enqueueSnackbar) {
            enqueueSnackbar('Failed to fetch updated user details.', { variant: 'error' });
        }
    }
};
