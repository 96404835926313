import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import DefaultAvatar from '../../assets/images/actions/default-avatar.svg';
import { updateCognitoUserAction, getS3Credentials, uploadFile, deleteFileFromS3Action } from "../../redux/actions";
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { fetchUserDetails } from '../../utils/fetch-user';


// Custom hook to handle profile information.
export function useProfileInfo() {
    const userData = useSelector(state => state.userData); // Access user data from Redux store.
    const [userAvatar, setUserAvatar] = useState(userData?.picture || DefaultAvatar);
    const [phoneNumber, setPhoneNumber] = useState(userData?.phone_number);
    const [role, setRole] = useState(null);
    const [isDefaultAvatar, setIsDefaultAvatar] = useState(userAvatar === DefaultAvatar);
    const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false);
    const [hasPHoneNumber, setHasPhoneNumber] = useState(false);
    const [fetchingCredentials, setFetchingCredentials] = useState(false);
    const [oldProfileAvatar, setOldProfileAvatar] = useState(null);
    const cognitoUsers = useSelector(state => state.cognitoUsers);
    const uploadData = useSelector(state => state.uploadData);
    const dispatch = useDispatch();
    const [profileFile, setprofileFile] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [fileName, setFileName] = useState(userData?.username?.substring(0, 13));
    const [editMode, setEditMode] = useState(false);
    const [cacheBust, setCacheBust] = useState(Date.now());

    // Maximum file size for profile picture.
    const MAX_FILE_SIZE = 5; // in MB

    // Role descriptions for different roles.
    const roleDescriptions = {
        Owner: "Has full control over the entire platform, including access to all workspace and dashboard settings, and all apps. Can manage other users' roles and has the authority to modify all settings.",
        'Survey Admin': "Responsible for managing the Survey app. Has the authority to create, edit, and delete surveys, manage survey settings, and view all survey data.",
        'Insights Admin': "Responsible for managing the Insights app. Has the authority to access and analyze all insights data, manage settings within the Insights app, and modify report configurations.",
        'Survey & Insights Admin': "Combines the roles of Survey Admin and Insights Admin. Has full access to both the Survey and Insights apps, including their settings, data management, and user permissions within these apps."
    };

    // Role description state.
    const [roleDescription, setRoleDescription] = useState('No specific information available for this role.')

    // Function to handle phone number changes.
    const handlePhoneNumberChange = (newPhoneNumber) => {
        console.log("Updated Phone Number:", newPhoneNumber); // For debugging
        setPhoneNumber(newPhoneNumber);
    }

    // Function to get the role description based on the role.
    const getRoleDescription = () => {
        console.log(roleDescriptions[role]);
        return roleDescriptions[role] || "No specific information available for this role.";
    }

    // Update isDefaultAvatar state when userAvatar changes.
    useEffect(() => {
        setIsDefaultAvatar(userAvatar === DefaultAvatar);
    }, [userAvatar]);

    // Update role description when role changes.
    useEffect(() => {
        setRoleDescription(getRoleDescription());
    }, [role]);

    // Update user avatar when userData changes.
    useEffect(() => {
        if (userData?.picture) {

            setUserAvatar(`${userData.picture}?${cacheBust}`);
        } else {
            setUserAvatar(DefaultAvatar);
        }
    }, [userData, userData?.picture]);

    // Update states based on userData changes.
    useEffect(() => {
        setPhoneNumber(userData?.phone_number);
        if (!userData?.phone_number) {
            setShowPhoneNumberInput(false); // Hide input field if phone number exists
            setHasPhoneNumber(false);
        }
        setRole(userData?.computedRole);

    }, [userData]);


    // Update user avatar when fetchingCredentials is true.
    const updateAvatar = async (email, attributes, cognitoUsers) => {
        console.log('Update avatar:', email, attributes, cognitoUsers);
        const res = await dispatch(updateCognitoUserAction(email, attributes, cognitoUsers));
        console.log('Update avatar response:', res);

        // Delete the old profile avatar from S3 if it is different from the new profile avatar
        if (oldProfileAvatar && oldProfileAvatar != attributes.picture) {
            dispatch(deleteFileFromS3Action(oldProfileAvatar));
            setOldProfileAvatar(null);
        }

        fetchUserDetails(dispatch);
    }

    // Update user avatar when fetchingCredentials is true.
    useEffect(() => {
        if (fetchingCredentials && uploadData && profileFile && userData) {
            // setFileName(userData?.username?.substring(0, 13));
            console.log('Upload data:', uploadData);
            dispatch(uploadFile(uploadData, profileFile, fileName));
            let attributes = {
                picture: uploadData.url + "/" + uploadData.fields.key
            };

            updateAvatar(userData.email, attributes, cognitoUsers);
            setFetchingCredentials(false);
            enqueueSnackbar('Profile picture updated successfully', { variant: 'success' });

        }
    }
        , [fetchingCredentials, uploadData, profileFile, userData]);


    // Function to handle avatar changes.
    const handleAvatarChange = (event) => {
        if (event.target.files.length > 0) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setUserAvatar(e.target.result); // Update avatar with the selected file.
            };
            reader.readAsDataURL(event.target.files[0]);
            setIsDefaultAvatar(false);
            const file = event.target.files[0];

            // Create a new file object with the file name + timestamp + file extension
            const newFile = new File([file], file.name.substring(0, file.name.lastIndexOf('.')) + '-' + Date.now() + file.name.substring(file.name.lastIndexOf('.')), { type: file.type });

            if (newFile.type && newFile.name, newFile.type.size > MAX_FILE_SIZE * 1000000) {
                enqueueSnackbar('File size exceeds the maximum limit of 5MB', { variant: 'error' });
                return;
            }

            if (file) {
                setprofileFile(newFile);
                setFileName(newFile.name);
                dispatch(getS3Credentials(newFile.name, newFile.type)).then(() => {
                    setFetchingCredentials(true);
                });
                setOldProfileAvatar(userData.picture);
            }

        }
    };

    // Function to toggle phone number input field.
    const togglePhoneNumberInput = (value) => {
        setShowPhoneNumberInput(value);

    };

    // Function to change phone number.
    const changePhoneNumber = async () => {
        console.log('Edit user:', userData.email);
        console.log('New', phoneNumber);

        // Constructing the attributes object with only the new phone number
        const attributes = {
            phoneNumber: phoneNumber // Ensure this key matches the expected key in your backend
        };

        // Dispatch the updateCognitoUserAction with the new phone number
        try {
            const res = await dispatch(updateCognitoUserAction(userData.email, attributes, cognitoUsers));
            enqueueSnackbar('User updated successfully', { variant: 'success' });
            fetchUserDetails(dispatch, enqueueSnackbar);
            setShowPhoneNumberInput(false);
            console.log('New phone number:', phoneNumber);


        } catch (error) {
            console.error('Failed to update user:', error);
            enqueueSnackbar('Failed to update user', { variant: 'error' });
        }
    };

    // Function to handle the edit button click.
    const onEdit = () => {
        setEditMode(true);
    };

    // Function to handle the cancel button click.
    const onCancel = () => {
        setEditMode(false);

    };

    // Return the required states and functions.
    return {
        userData, userAvatar, phoneNumber, role, handleAvatarChange, isDefaultAvatar, togglePhoneNumberInput,
        showPhoneNumberInput, hasPHoneNumber, setHasPhoneNumber, setPhoneNumber, changePhoneNumber, roleDescriptions, roleDescription, handlePhoneNumberChange, onEdit, onCancel, editMode
    };
}
