import React from 'react';
import { Dialog, DialogContent, DialogActions, Typography } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import PrimaryButton from '../buttons/primary-button';
import HeaderDialog from './header-dialog'; // Ensure this is the correct path


const TwoFactorAuthEnabled = ({ open, onClose, }) => {


    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDialog-paper': { width: '575px' }, // Set the dialog width
                BackdropProps: {
                    sx: {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        backdropFilter: 'blur(2.5px)',
                    },
                }
            }}
        >
            <HeaderDialog handleClose={onClose} title="Two-Step Authentication Enabled" />
            <DialogContent sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                <Typography variant='body2' width={'99%'} color={colorTheme.theme.text.lightGrey} sx={{ pt: 1, }}>
                    Your account is now secured with Two-Step Authentication. Remember to keep your authenticator app secure at all times. Do not delete the app without ensuring you have access to backup codes or a recovery method. This is crucial to maintain access to your account, especially if your device is lost or changed.
                </Typography>
                <Typography variant='body2' width={'99%'} color={colorTheme.theme.text.lightGrey} sx={{ pt: 2, }}>
                    Should you lose access to your authenticator app and not have backup codes, there are still ways to recover your account. For more details on how to manage your authenticator app, what to do in case of device loss, or how to recover your account,
                    <span style={{ color: colorTheme.theme.transform.darkBlueTwo }}> Security Help Section.
                    </span>
                </Typography>
            </DialogContent>
            <DialogActions sx={{ pt: 2, pb: 2, pr: 2 }}>
                <PrimaryButton onClick={onClose} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text="GOT IT" />
            </DialogActions>
        </Dialog>
    );
};

export default TwoFactorAuthEnabled
