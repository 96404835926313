import React, { useState } from 'react';
import Sidebar from '../../layout/sidebar/sidebar';
import TeamContainer from './teammates-tab/teammates-container';
import OrganizationContainer from './organization-tab/organization-container';
import LanguageContainer from './language-tab/language-container';
import BillingContainer from './billing-tab/billing-container';
import './settings-container.scss';
import { SIDEBAR_TAB_TEAM, SIDEBAR_TAB_ORGANIZATION, SIDEBAR_TAB_LANGUAGE, SIDEBAR_TAB_BILLING } from "../../assets/constants";

// SettingsContainer component to render the settings page
const SettingsContainer = () => {
    const [activeTab, setActiveTab] = useState(SIDEBAR_TAB_TEAM); // State to store the active tab, default value is SIDEBAR_TAB_TEAM

    // Function to handle the click event of the tab
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // Function to render the content based on the active tab
    const renderContent = () => {
        switch (activeTab) {
            case SIDEBAR_TAB_TEAM:
                return <TeamContainer />;
            case SIDEBAR_TAB_ORGANIZATION:
                return <OrganizationContainer />;
            case SIDEBAR_TAB_LANGUAGE:
                return <LanguageContainer />;
            case SIDEBAR_TAB_BILLING:
                return <BillingContainer />;
            default:
                return null; // Add a default case to handle unknown tab values
        }
    };

    return (
        <>
            <Sidebar displaySurveyTabs={false} onTabClick={handleTabClick} />
            <div className='container-settings' >
                {
                    renderContent() // Render the content based on the active tab
                }
            </div>
        </>
    );
};

export default SettingsContainer;