import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { colorTheme } from '../../../assets/theme';
import Tooltip from "@material-ui/core/Tooltip";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import ClassicSelect from '../../form/classic-select';
import ReadOnlyInputField from '../../form/input-read-only';
import InputField from '../../form/input-field';
import InputTextarea from '../../form/input-textarea';
import TextButton from '../../buttons/text-button';
import TooltipIcon from '../../tooltip-text/tooltip-icon';
import TitleEditTab from '../../title/title-edit-tab';
import ToggleSwitchValue from '../../form/toogle-switch-value';
import CustomRadioLabel from '../../form/custom-radio-label';
import { UseSurveySettings } from '../../../hooks/survey/use-survey-settings';
import { SURVEY_DEFAULT_WELCOME_NOTE, SURVEY_DEFAULT_WELCOME_NOTE_TITLE } from '../../../assets/constants';
import _ from 'lodash';

// Styles for the tooltip
const useStylesBootstrap = makeStyles(() => ({
    tooltip: {
        backgroundColor: 'transparent',
        marginTop: '0px',
    },
    tooltipText: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 2.5px 0px #7B7D8982',
        borderRadius: '4px',
        padding: '10px 12px',
        display: 'flex',
        flexDirection: 'column',
        transform: 'translateX(100%)',
        gap: '2px',
        '& h3': {
            color: 'var(--text-mediumGrey)',
            fontSize: '12px',
            fontWeight: '500',
        },
        '& p': {
            color: 'var(--text-lightGrey)',
            fontSize: '12px',
            lineHeight: '20px',
        }
    }
}));

// DisplayConfigurator component
const DisplayConfigurator = () => {
    const classes = useStylesBootstrap();
    
    // Destructure the properties from UseSurveySettings
    const { 
        languages,
        confirmationNote, 
        questionNumeration, 
        progressBar, 
        language, 
        userCustom, 
        confirmNameTitle, 
        confirmNameText, 
        setConfirmationNote,
        setQuestionNumeration,
        setProgressBar,
        handleToggleChange, 
        handleUserCustomChange, 
        handleLanguageChange, 
        handleConfirmNameTitleChange, 
        handleConfirmNameTextChange, 
        handleTagClick,
        handleFieldFocus
        } = UseSurveySettings();

    return (
        <Box display="flex" flexDirection="column" mt={5} gap={2}>
            <TitleEditTab title='Survey Display Configuration' marginBottom={0.5} />
            <Box display="flex" flexDirection="column">
            <Typography variant="body1" sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '16px', color: colorTheme.theme.transform.black, marginBottom: '4px' }}>{'Welcome note'}</Typography>
                <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: 400, lineHeight: '16px', color: colorTheme.theme.text.lightGrey }}>{'Prompt respondents with a welcome message at the start of the survey to confirm their understanding of participation terms and data gathering practices.'}</Typography>
            </Box>
            {
                confirmationNote &&
                <Box ml={2} width={550}>
                    <FormControl component="fieldset" sx={{ width: '100%' }}>
                        <RadioGroup name="welcome-note" value={userCustom ? 'custom' : 'default'} style={{ display: 'flex', gap: '16px' }} onChange={(e) => handleUserCustomChange(e, SURVEY_DEFAULT_WELCOME_NOTE_TITLE, SURVEY_DEFAULT_WELCOME_NOTE)}>
                            <CustomRadioLabel value={'default'} label={'Use default welcome note'} checked={!userCustom} />
                            {
                                !userCustom &&
                                <Box mt={1} mb={0.5} display={'flex'} flexDirection="column" gap={2}>
                                    <ReadOnlyInputField email={SURVEY_DEFAULT_WELCOME_NOTE_TITLE} textFieldWidth={'100%'} greyColor={colorTheme.theme.text.lightGrey} label={'Title'} inputFontSize='12px' inputPadding="11.5px 10px" />
                                    <InputTextarea textareaText={SURVEY_DEFAULT_WELCOME_NOTE} textareaWidth={'100%'} greyColor={colorTheme.theme.text.lightGrey} textareaFontSize='12px' textareaPadding="11.5px 10px" textareaReadOnly={true} />
                                </Box>
                            }
                            <CustomRadioLabel value={'custom'} label={'Create custom welcome note'} checked={userCustom} />
                            {
                                userCustom &&
                                <Box mt={0.5} mb={0.5} display={'flex'} flexDirection="column" gap={2}>
                                    <Box display={'flex'} alignItems="center" gap={1.25}>
                                        <Typography variant="body1" sx={{ color: colorTheme.theme.transform.black, fontSize: '14px' }}>Tags: </Typography>
                                        <Box display={'flex'} gap={0.5} alignItems={'center'}>
                                            <TextButton name="[Respondent's Name]" onClick={() => handleTagClick("[Respondent's Name]")} size={'small'} fontSize='14px' width='fit-content' borderRadius='4px' />
                                            <TextButton name="[Company Name]" onClick={() => handleTagClick("[Company Name]")} size={'small'} fontSize='14px' width='fit-content' borderRadius='4px' />
                                        </Box>
                                        <TooltipIcon title={'What are Tags?'} content={'Tags are placeholders used in survey welcome messages that automatically insert personalized details like a respondent’s name. When a survey is accessed, each tag is replaced with specific information for each respondent, making your welcome message more personalized and engaging.'} placement={'bottom-start'} backgroundColor={"#FFFFFF"} strokeColor={colorTheme.theme.text.lightGrey} iconColor={colorTheme.theme.text.lightGrey} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                                    </Box>
                                    <InputField 
                                        value={confirmNameTitle} 
                                        onChange={handleConfirmNameTitleChange} 
                                        onFocus={() => handleFieldFocus('title')}
                                        textFieldWidth={'100%'} 
                                        greyColor={colorTheme.theme.transform.black} 
                                        label={'Title'} 
                                        fontSize='12px' 
                                        inputPadding="12px 10px" 
                                        lineHeight={'16px'} 
                                        labelPosition='14.5px, 14px' 
                                        labelFontSize='14px'
                                    />
                                    <InputTextarea 
                                        textareaText={confirmNameText} 
                                        onChange={handleConfirmNameTextChange} 
                                        onFocus={() => handleFieldFocus('text')}
                                        textareaWidth={'100%'} 
                                        greyColor={colorTheme.theme.transform.black} 
                                        textareaFontSize='12px' 
                                        textareaPadding="11.5px 10px"
                                        placeholder={'Type your welcome note here...'}
                                    />
                                    <Tooltip title={ <Box className={classes.tooltipText}><WelcomeNoteGuidesInfo /></Box> } placement={'bottom-end'} classes={{ tooltip: classes.tooltip }}>
                                        <Box width="fit-content"><TextButton name='What to include in my welcome note?' onClick={() => { }} size={'small'} fontSize='12px' width='fit-content' margin='-10px 0 0 0' /></Box>
                                    </Tooltip>
                                </Box>
                            }
                        </RadioGroup>
                    </FormControl>
                </Box>
            }
            <ToggleSwitchValue 
                label='Question numeration' 
                value={questionNumeration} 
                onChange={(value) => handleToggleChange(setQuestionNumeration, value, 'questionNumeration')} 
                description={'Toggle this to number your survey questions sequentially (1, 2, 3, etc.).'} 
            />
            <ToggleSwitchValue 
                label='Progress bar' 
                value={progressBar} 
                onChange={(value) => handleToggleChange(setProgressBar, value, 'progressBar')} 
                description={'Enable this to display a progress bar during the survey, letting respondents see how much of the survey they have completed.'} 
            />
            <Box display={'flex'} flexDirection="column" gap={1.5}>
                <Typography variant="body1" sx={{ color: colorTheme.theme.transform.black }}>Change navigation language</Typography>
                <ClassicSelect 
                    label="Select 1" 
                    value={language} 
                    list={languages} 
                    onChange={handleLanguageChange} 
                    isLabelList={true} 
                    selectPadding='14px 12px' 
                    selectWidth='125px' 
                    selectFontSize='16px' 
                    selectLineHeight='20px' 
                    selectColor={colorTheme.theme.text.lightGrey} 
                />
            </Box>
        </Box>
    );
}

// WelcomeNoteGuidesInfo component for Tooltip
const WelcomeNoteGuidesInfo = () => {
    return (
        <>
            <Typography variant="h3" sx={{ marginBottom: '2px', color: colorTheme.theme.text.mediumGrey, lineHeight: '20px' }}>Welcome note guides</Typography>
            <Typography variant="body1" sx={{ color: colorTheme.theme.text.lightGrey, lineHeight: '20px' }}>Include a brief greeting, state the purpose of the survey, and set expectations about how long it will take. Mention any privacy assurances or participation incentives. Keep it concise to engage respondents without overwhelming them.</Typography>
        </>
    )
}

export default DisplayConfigurator;
