import React from 'react';
import { TextField, IconButton } from '@mui/material';
import { ReactComponent as CopyIcon } from '../../assets/images/actions/icon-copy.svg';
import { colorTheme } from '../../assets/theme';

// InputTextarea component
const InputTextarea = ({ textareaText, textareaWidth, greyColor, textareaFontSize = '1rem', textareaPadding = '16.5px 14px', textareaReadOnly, onChange, placeholder, onFocus = () => { } }) => {

    // Function to copy the text to clipboard
    const handleCopy = () => {
        navigator.clipboard.writeText(textareaText);
    };
    
    return (
        <TextField
            id="textarea"
            value={textareaText || ''}
            fullWidth
            variant="outlined"
            multiline={true}
            onChange={onChange}
            rows={5}
            placeholder={placeholder}
            onFocus={onFocus}
            sx={{
                width: textareaWidth, // Conditionally set the width
                '.MuiOutlinedInput-root': {
                    padding: '0px',
                    color: 'red',
                    '&.Mui-disabled': {
                        '& textarea': {
                            color: greyColor,
                            WebkitTextFillColor: greyColor,
                        }
                    },
                    'textarea': {
                        padding: textareaPadding,
                        fontSize: textareaFontSize,
                        lineHeight: '20px',

                        '&::placeholder': {
                            color: colorTheme.theme.text.lightGrey,
                            opacity: 1,
                        },
                    },
                    '&.Mui-focused fieldset': {
                        borderColor:  '#1B4972', // Keep red or theme color when focused
                        borderWidth: 1,
                    },
                },
                '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                    color: greyColor, // Adjust label color when disabled/read-only
                },
            }}
            InputProps={{
                readOnly: textareaReadOnly,
                style: { color: greyColor },
                endAdornment: textareaReadOnly && (
                    <IconButton onClick={handleCopy} sx={{ 
                        position: 'absolute', 
                        bottom: '2px', 
                        right: '2px',
                        '& svg path': {
                            transition: '0.3s fill ease-in-out'
                        },
                        '&:hover': {
                            backgroundColor: '#EEF0F4',

                            '& svg path': {
                                fill: colorTheme.theme.transform.darkBlue
                            }
                        }, 
                        }}>
                        <CopyIcon style={{ fill: greyColor }} />
                    </IconButton>
                ),
            }}
            InputLabelProps={{
                shrink: true,
                style: { color: greyColor },
            }}
            disabled={textareaReadOnly}
        />
    );
};

export default InputTextarea;
