import React from 'react';
import './style-container.scss';
import { useSelector } from "react-redux";
import { Box } from '@mui/material';
import ThemePreview from "../../../../components/theme-preview/theme-preview";
import themeColors from '../../../../assets/theme-colors';
import useSurveyStyleSettings from '../../../../hooks/survey/use-survey-style-settings';
import StyleEditBox from '../../../../components/style-edit-box/style-edit-box';
import { ReactComponent as StyleIcon } from "../../../../assets/images/title/style-2.svg";

// StyleContainer component to render the style tab
function StyleContainer() {
    const currentSurvey = useSelector(state => state.currentSurvey); // Get the current survey from the Redux store

    const {
        themeColor,
        inputFileRef,
        handleThemeColorChange,
        handleColorInput,
        onFileUpload,
    } = useSurveyStyleSettings(currentSurvey, themeColors); // Use the useSurveyStyleSettings hook to get the survey style settings

    return (
        <Box className='style-container'>
            <StyleEditBox 
            title={'Style'}
            Icon={StyleIcon}
            surveyStyle={true}
            themeColors={themeColors} 
            localDefaultStyleData={themeColor} 
            saveChangesButtonVisible={false} 
            handleDefaultStyleChange={handleColorInput}
            handleColorPresetChange={handleThemeColorChange}
            openedSaveDialog={null}
            toggleSaveDialog={null}
            handleSaveDefaultStyleDetails={null}
            handleDefaultStyleLogoChange={null}
            file={null}
            handleSetFile={onFileUpload}
            inputFileRef={inputFileRef}
            isLoading={false}
            />
            <ThemePreview themeColor={themeColor} companyLogo={currentSurvey ? currentSurvey.companyLogo : null} />
        </Box>
    )
}

export default StyleContainer;