import React, { useMemo } from 'react';
import { ListItem, ListItemAvatar, ListItemText, Avatar, Tooltip } from '@mui/material';
import LicenceButton from "../buttons/licence-button";
import { useTeammateAttributes } from './../../hooks/teammates/use-teammates-list';
import { ReactComponent as AvatarIcon } from "./../../assets/images/table/avatar-table.svg";

const TeammateListItem = React.memo(({ user }) => {
    const licenseColors = useMemo(() => ({
        'Survey Admin': '#FFBD6F',   // Yellowish color for "Survey"
        'Insights Admin': '#FAE36E',  // Blue color for "Insights"
        'Owner': '#ABD78A',           // Red color for "Owner"
        'Pending': '#D8D8D8', // Gray color for "Awaiting Info"
    }), []);

    // Destructure the licenses and name from the useTeammateAttributes hook
    const { licenses, name } = useTeammateAttributes(user);

    // Get the user's picture attribute
    const userAvatar = user?.Attributes?.find(attr => attr.Name === 'picture')?.Value;

    // Function to truncate name if it exceeds a certain length
    const truncateName = (name, maxLength) => {
        if (name.length > maxLength) {
            return `${name.substring(0, maxLength)}...`;
        }
        return name;
    };

    const maxNameLength = 13; // Adjust this value as needed

    return (
        <ListItem>
            <ListItemAvatar>
                {userAvatar ? (
                    <Avatar
                        alt="User Avatar"
                        src={userAvatar}
                        sx={{ width: 24, height: 24, mr: '8px' }}
                    />
                ) : (
                    <AvatarIcon
                        style={{
                            width: 28,
                            height: 28,
                            marginRight: '9px',
                            flexShrink: 0
                        }}
                    />
                )}
            </ListItemAvatar>
            <Tooltip title={name} arrow>
                <ListItemText primary={truncateName(name, maxNameLength)} />
            </Tooltip>
            <div className="apps">
                {/* licences button */}
                {Array.isArray(licenses) && licenses.map((license, index) => (
                    <LicenceButton key={index} text={license}
                        backgroundColor={name === 'Awaiting Info...' ? licenseColors['Pending'] : licenseColors[license]} />
                ))}
            </div>
        </ListItem>
    );
});

export default TeammateListItem;
