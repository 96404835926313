import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { updateSurveyColors, updateSurveyLogo, getS3Credentials, uploadFile } from '../../redux/actions';
import { hexValidator } from "../../utils/styleUtils";
import _ from 'lodash';

// useSurveyStyleSettings hook to handle the survey style settings
const useSurveyStyleSettings = (currentSurvey, themeColors) => {
    const dispatch = useDispatch(); // Get the dispatch function from the Redux store
    const { enqueueSnackbar } = useSnackbar(); // Get the enqueueSnackbar function from the notistack
    const uploadData = useSelector(state => state.uploadData); // Get the upload data from the Redux store
    const logoUrl = useSelector(state => state.logoUrl); // Get the logo URL from the Redux store
    const [logoFile, setLogoFile] = useState(null); // State for the logo file
    const [fetchingCredentials, setFetchingCredentials] = useState(false); // State for fetching credentials
    const [themeColor, setThemeColor] = useState(currentSurvey ? currentSurvey.colors : themeColors[0]); // State for the theme color
    const inputFileRef = useRef(null); // Create a ref for the input file

    // Update theme color when currentSurvey changes
    useEffect(() => {
        if (currentSurvey && currentSurvey.colors && !_.isEqual(currentSurvey.colors, themeColor)) {
            setThemeColor(currentSurvey.colors);
        }
    }, []);

    // Upload file when uploadData and fetchingCredentials are true
    useEffect(() => {
        if (uploadData && fetchingCredentials && logoFile) {
            dispatch(uploadFile(uploadData, logoFile, currentSurvey.id));
        }
    }, [uploadData]);

    // Update survey logo when uploadData, fetchingCredentials and logoUrl are true
    useEffect(() => {
        if (uploadData && fetchingCredentials && logoUrl) {
            setFetchingCredentials(false);
            dispatch(updateSurveyLogo(logoUrl));
        }
    }, [logoUrl]);

    // Function to open snackbar
    const openSnackbar = (msg) => {
        enqueueSnackbar(msg, {
            variant: 'error',
        });
    };

    // Function to handle theme color change
    const handleThemeColorChange = (value) => {
        const newColors = themeColors.find(t => t.value === value);
        setThemeColor(newColors);
        dispatch(updateSurveyColors(newColors));
    };

    // Debounce function to update survey colors
    const debouncedUpdateSurveyColors = useCallback(
        _.debounce((color) => {
            dispatch(updateSurveyColors(color));
        }, 1000),
        [dispatch]
    );

    // Function to handle color input
    const handleColorInput = (value, type) => {
        let color = { ...themeColor };
        switch (type) {
            case 'highlight':
                color.highlight = value;
                break;
            case 'main':
                color.main = value;
                break;
            default:
                break;
        }

        // Check if the color already exists in the theme colors
        const existingTheme = themeColors.find(t => t.main === color.main && t.highlight === color.highlight);
        color.value = existingTheme ? existingTheme.value : 'customTheme';

        setThemeColor(color);
        if (hexValidator(value)) {
            debouncedUpdateSurveyColors(color);
        }
    };

    // Function to handle file upload
    const onFileUpload = (event) => {
        const file = event.target.files[0];

        // Create a new file object with the file name + timestamp + file extension
        const newFile = new File([file], file.name.substring(0, file.name.lastIndexOf('.')) + '-' + Date.now() + file.name.substring(file.name.lastIndexOf('.')), { type: file.type });

        if (newFile && newFile.size > 5 * 1000000) {
            openSnackbar('Maximum file size exceeded, max file size is 5 MB');
            return;
        }
        if (newFile) {
            setLogoFile(newFile);
            setFetchingCredentials(true);
            dispatch(getS3Credentials(newFile.name, newFile.type));
        }
    };

    // Return the necessary functions and states
    return {
        themeColor,
        logoFile,
        inputFileRef,
        handleThemeColorChange,
        handleColorInput,
        onFileUpload,
    };
};

export default useSurveyStyleSettings;
