import React, { useEffect, useState } from "react";
import './survey-container.scss';
import Sidebar from "../../layout/sidebar/sidebar";
import EditContainer from "./edit-tab/edit-container";
import SurveyDashboardContainer from "./survey-dashboard-tab/survey-dashboard-container";
import TeamContainer from "./team-tab/team-container";
import DefaultStyleContainer from "./default-style-tab/default-style-container";
import { SIDEBAR_TAB_SURVEYS, SIDEBAR_TAB_TEAM, SIDEBAR_TAB_DEFAULT_STYLE } from "../../assets/constants";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentSurvey } from "../../redux/actions";

// SurveysContainer component to render the surveys page
const SurveyContainer = ({ userTemplate }) => {
    const dispatch = useDispatch(); // Dispatch hook to dispatch actions
    const [activeTab, setActiveTab] = useState(SIDEBAR_TAB_SURVEYS); // State to store the active tab, default value is SIDEBAR_TAB_SURVEYS
    const currentSurvey = useSelector(state => state.currentSurvey); // Get the current survey from the Redux store

    // Function to handle the click event of the tab
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // On component mount, set the currentSurvey to null
    useEffect(() => {
        dispatch(setCurrentSurvey(null));
    }, []);

    // Function to render the content based on the active tab
    const renderContent = () => {
        switch (activeTab) {
            case SIDEBAR_TAB_SURVEYS:
                return (
                    <>
                        {
                            userTemplate ?
                                (
                                    currentSurvey ?
                                        <EditContainer /> :
                                        <SurveyDashboardContainer userTemplate={userTemplate} />
                                ) :
                                // todo loading screen
                                <div className='loading-screen'>loading</div>
                        }
                    </>
                );
            case SIDEBAR_TAB_TEAM:
                return <TeamContainer />;
            case SIDEBAR_TAB_DEFAULT_STYLE:
                return <DefaultStyleContainer />;
            default:
                return null; // Add a default case to handle any other tab
        }
    };

    return (
        <>
            <Sidebar displaySurveyTabs={true} onTabClick={handleTabClick} />
            <div className="survey-container">
                {
                    renderContent() // Render the content based on the active tab
                }
            </div>
        </>
    );
}

export default SurveyContainer;