import React from 'react';
import { Box, DialogContent, Dialog, Typography } from '@mui/material';
import { colorTheme } from '../../assets/theme';
import HeaderDialog from './header-dialog';
import SelectDialog from '../form/select-dialog';
import PrimaryButton from '../buttons/primary-button';
import InputField from '../form/input-field';
import ReadOnlyInputField from '../form/input-read-only';
import { useEditAddUserDialog } from './../../hooks/teammates/use-edit-add-teammates';
import TooltipIcon from '../tooltip-text/tooltip-icon';

export default function EditAddUserDialog({
    open, handleClose, title, Icon, buttonText, selectedRow, handleAction,
    setInsightsLicense, setSurveyLicense, setRole
}) {
    const {
        email, setEmail, license, handleLicenseChange, handleSaveChanges, roleDescription, isLoading, roleTitle
    } = useEditAddUserDialog(selectedRow, handleClose, handleAction, setInsightsLicense, setSurveyLicense, setRole,);

    const label = 'Role';
    const options = [
        { group: 'Workspace', value: 'Owner', label: 'Owner' },
        { group: 'Apps', value: 'Survey Admin', label: 'Survey admin' },
        { group: 'Apps', value: 'Insights Admin', label: 'Insights admin' },
        { group: 'Apps', value: 'Survey & Insights Admin', label: 'Survey & Insights admin' }];


    const textFieldWidth = '100%';

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '398px',
                }
            }}
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog Icon={Icon} handleClose={handleClose} title={title} />

            <DialogContent sx={{ p: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        gap: 2,
                    }}
                >
                    {selectedRow
                        ? <ReadOnlyInputField
                            email={selectedRow.email} textFieldWidth={textFieldWidth} greyColor={colorTheme.theme.text.lightGrey} />
                        : <InputField
                            label="Enter email"
                            textFieldWidth={textFieldWidth}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    }
                    <SelectDialog
                        label={label}
                        options={options}
                        width='100%'
                        value={license}
                        onChange={handleLicenseChange}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <TooltipIcon title={roleTitle} content={roleDescription} placement={'bottom-start'} backgroundColor={colorTheme.theme.transform.white} strokeColor={colorTheme.theme.transform.darkBlueTwo} iconColor={colorTheme.theme.transform.darkBlueTwo} backgroundHover={colorTheme.theme.app.lightestGrey} strokeHover={colorTheme.theme.transform.darkBlue} iconHover={colorTheme.theme.transform.darkBlue} />
                        <Typography
                            sx={{
                                color: colorTheme.theme.text.lightGrey,
                                fontSize: '12px',
                                lineHeight: '14px',
                                fontWeight: '400',
                            }}
                        >
                            Role Details
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'flex-end' }}>
                        <PrimaryButton onClick={handleSaveChanges} color={colorTheme.theme.transform.darkBlueTwo} hoverColor={colorTheme.theme.transform.darkBlue} text={buttonText}
                            isLoading={isLoading}
                        />
                    </Box>
                </Box>
            </DialogContent>
        </Dialog >
    );
}
