import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { colorTheme } from '../../assets/theme';

// CustomTableHead component
function CustomTableHead({ headCells }) {
    return (
        <TableHead
            sx={{
                backgroundColor: colorTheme.theme.colors.teamTableHeaderBackground, borderRadius: '4px 4px 0px 0px',
                borderBottom: 'none !important'
            }}
        >
            <TableRow sx={{
                height: '56px', // Set the row height
                borderRadius: '4px 4px 0px 0px',
                '& > *': { padding: '6px 0px' },
            }}>
                {headCells.map((headCell) => (
                    <TableCell
                        sx={{ 
                            border: 'none !important', 
                            pl: '16px', 
                            width: headCell.width,
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            boxSizing: 'border-box',
                            maxWidth: headCell.width,
                            minWidth: headCell.width,
                            backgroundColor: colorTheme.theme.colors.teamTableHeaderBackground,
                        }}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}

                    >
                        {headCell.label}
                    </TableCell>
                ))}
                <TableCell
                    sx={{
                        borderTopRightRadius: '4px',
                        border: 'none !important'
                    }}
                    align="right"></TableCell>
            </TableRow>
        </TableHead>
    );
}

CustomTableHead.propTypes = {
    headCells: PropTypes.array.isRequired,
};

export default CustomTableHead;
