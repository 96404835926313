import React from 'react';
import './survey-filter-button.scss';
import { Fab, Typography } from '@mui/material';

// SurveyFilterButton component
const SurveyFilterButton = ({active, status, filter, icon, onClick}) => {
    return (
        <Fab onClick={() => onClick(filter)} variant="extended" className={`survey-filter-button${filter === active ? ' selected' : ''}`} size="small">
            {
                icon && <span className="icon">{icon}</span>
            }
            <Typography className='survey-filter-button-text'>{status}</Typography>
        </Fab>
    );
};

export default SurveyFilterButton;