export default [
    {
        element_type: 'section',
        question_text: 'Welcome to ...',
        section_description: '<p>Add some intro message here...&nbsp;</p>',
        style_type: null,
        question_explanation_string: '',
        page_break: false,
        hidden: true,
        expanded: false,
    },
    {
        element_type: 'search_choose',
        question_text: 'Who do you regularly collaborate with on work-related tasks?',
        question_explanation_string: 'Choose from 1 to 3 colleagues',
        items_source: 'respondents',
        items_source_type: 'LIST',
        items_name_field: ['Name'],
        items_min_number: 1,
        items_max_number: 3,
        item_other_allowed: false,
        required: true,
        required_fields: ['Name'],
        relationship: true,
        validation_min: true,
        validation_max: true,
        element_type: 'search_choose',
        style_type: null,
        expanded: false,
        question_export_name: 'Q-Collaboration edges',
        // if respondents add items_source_id to survey id
    },
    {
        element_type: 'multi_slider_single_picker',
        question_text: 'How often do you communicate with those colleagues on a weekly basis?',
        question_explanation_string: '1 = means once a week; 5 = every day of the week',
        items_input: true, // if true set previous question id
        items_source_type: 'QUESTION',
        items_name_field: ['Name'],
        items_min: 1,
        items_max: 5,
        items_min_text: 'Once a week',
        items_max_text: 'Every day of the week',
        items_increment: 1,
        all_required: true,
        required: true,
        expanded: true,
        element_type: 'multi_slider_single_picker',
        style_type: null,
        question_export_name: 'Q-Collaboration Weight',
        page_break: true,
        answer_source: true,
        items: [],
    },
    {
        element_type: 'section',
        question_text: 'Thank you!',
        section_description: '<p>That\'s it, thank you for being helpful and for sharing the info.</p><p>One final step - please click SUBMIT to send your answers.&nbsp;</p><p><br></p><p>We will be following back with the results!</p>',
        style_type: null,
        hidden: true,
        expanded: false,
        items: [],
        question_explanation_string: '',
    },
];