// Function to get the rows per page options based on the length of the data
export function getRowsPerPageOptions(length) {
    const options = [5]

    if (length >= 10) options.push(10);
    if (length >= 25) options.push(25);
    if (length >= 50) options.push(50);
    if (length >= 100) options.push(100);

    return Array.from(options);
}
